import { combineLatest, Observable, takeUntil } from 'rxjs';

import { Injectable } from '@angular/core';
import { STATE_G2VIEW_CORE } from '@config/constants';
import { SetCurrentG2Role, SetKeycloakData } from '@core/state/core.actions';
import { RxJSBaseDirective } from '@directives/rxjs-base.directive';
import { G2RolesResponse, KeycloakPublicGroup, KeycloakPublicUser } from '@g2view/g2view-commons';
import { Store } from '@ngxs/store';
import { ErrorHandlerService } from '@services//error-handler.service';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';
import { CacheService } from '@services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class KeycloakCoreService extends RxJSBaseDirective {
  constructor(
    private readonly http: ApiHttpService,
    private readonly apiEndpointsService: ApiEndpointsService,
    private readonly cacheService: CacheService,
    private readonly store: Store,
    private readonly errorHandler: ErrorHandlerService
  ) {
    super();
  }

  get clientId(): string {
    return this.store.selectSnapshot<string>((state) => state[STATE_G2VIEW_CORE].keycloak.clientId);
  }

  get g2Roles(): string {
    return this.store.selectSnapshot<string>((state) => state[STATE_G2VIEW_CORE].keycloak.g2Roles);
  }

  get areG2RolesInitialized(): boolean {
    return this.store.selectSnapshot<boolean>((state) => state[STATE_G2VIEW_CORE].keycloak.areG2RolesInitialized);
  }

  get users(): Map<string, string> {
    return this.store.selectSnapshot<Map<string, string>>((state) => state[STATE_G2VIEW_CORE].keycloak.users);
  }

  get groups(): Map<string, string> {
    return this.store.selectSnapshot<Map<string, string>>((state) => state[STATE_G2VIEW_CORE].keycloak.groups);
  }

  public init(): void {
    this.refreshKeycloakData();
  }

  public setCurrentG2Role(g2Role: string): void {
    this.store.dispatch(new SetCurrentG2Role(g2Role));
    this.cacheService.updateConfigItem('user', '', 'defaultG2Role', g2Role);
  }

  private refreshKeycloakData(): void {
    combineLatest([this.fetchUsers$(), this.fetchGroups$(), this.fetchG2Roles$()])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([users, groups, g2RolesResponse]) => {
          const usersMap: Map<string, string> = new Map(users.map((u) => [u.id, u.username]));
          const groupsMap: Map<string, string> = new Map(groups.map((g) => [g.id, g.name]));
          const g2Roles = g2RolesResponse.g2Roles;
          const g2RoleFromCache = this.cacheService.getConfigItem('user', '', 'defaultG2Role');
          const currentG2Role = g2Roles.includes(g2RoleFromCache) ? g2RoleFromCache : g2Roles.length > 0 ? g2Roles[0] : '';
          this.setCurrentG2Role(currentG2Role);
          this.store.dispatch(
            new SetKeycloakData({
              g2Roles,
              areG2RolesInitialized: true,
              users: usersMap,
              groups: groupsMap
            })
          );
        },
        error: (err: unknown) => this.errorHandler.handleError(err)
      });
  }

  private fetchG2Roles$(): Observable<G2RolesResponse> {
    return this.http.get(this.apiEndpointsService.getG2RolesEndpoint());
  }

  private fetchUsers$(): Observable<Array<KeycloakPublicUser>> {
    return this.http.get(this.apiEndpointsService.getUsersListingEndpoint());
  }

  private fetchGroups$(): Observable<Array<KeycloakPublicGroup>> {
    return this.http.get(this.apiEndpointsService.getGroupsListingEndpoint());
  }
}
