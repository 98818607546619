import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AFK_PATH,
  DASHBOARD_PATH,
  DASHBOARDS_PATH,
  DELAY_RESIZE_EVENT,
  DELAY_SECOND_RESIZE_EVENT,
  DELAY_TOGGLE_FULLSCREEN,
  MAT_DIALOG_CONFIG,
  SHARED_PATH,
  SHORTCUT_DASHBOARD_EXIT,
  SHORTCUT_DASHBOARD_SETTINGS,
  SHORTCUT_DASHBOARD_ZOOM_MINUS,
  SHORTCUT_DASHBOARD_ZOOM_PLUS,
  SHORTCUT_DASHBOARD_ZOOM_RESET,
  SHORTCUT_WORKSPACES_HIGHLIGHT,
  SNACKBAR_DURATION,
  SNACKBAR_HORIZONTAL_POSITION,
  SNACKBAR_VERTICAL_POSITION
} from '@config/constants';
import { getWindowHeight, getWindowWidth } from '@config/utils';
import { DashboardConfigComponent, DashboardConfigData } from '@dashboard/components/dashboard-config/dashboard-config.component';
import { DashboardQrCodeComponent } from '@dashboard/components/dashboard-qr-code/dashboard-qr-code.component';
import {
  DashboardsService,
  DashboardStorageConfig,
  getAllFramesOfDashboardViewAndChildrenViews,
  getAllUuidsOfDashboard,
  getAllUuidsOfDashboardViewAndChildrenViews
} from '@dashboards/services/dashboards.service';
import { DbasService } from '@dashboards/services/dbas.service';
import { ResetCurrentDashboard, UpdateCurrentDashboard } from '@dashboards/state/dashboards.actions';
import { debounce } from '@decorators/debounce.decorator';
import { Dashboard, DashboardView, Frame, WorkspaceDB } from '@g2view/g2view-commons';
import { DashboardConfigResponse } from '@interfaces/modal-responses';
import { Shortcut, StatefulComponentDirectiveWithShortcutsServices, WithShortcuts } from '@mixins/shortcuts.mixin';
import { HotToastService, ToastPosition } from '@ngneat/hot-toast';
import { TranslocoScope } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { FeathersService } from '@services/feathers.service';
import { HelpService } from '@services/help.service';
import { LayoutService } from '@services/layout.service';
import { LoggerService } from '@services/logger.service';
import { PageTitleService } from '@services/page-title.service';
import { RedirectService } from '@services/redirect.service';
import { VisibilityService } from '@services/visibility.service';
import { ResetMonitoredWorkspaces, SetMonitoredWorkspaces } from '@workspaces/state/workspaces.actions';
import { WorkspacesState } from '@workspaces/state/workspaces.state';

export const dashboardIdPathParam = 'did';
export const dashboardViewIdPathParam = 'dvid';
export const MAX_TIME_TO_LOAD = 10000;
export const TIME_BETWEEN_LOAD_TEST = 10;
const PADDING_PER_BORDER_PX = 1;
const ZOOM_MAX = 5000;
const SET_LOAD_TO_FALSE_AFTER_MONITORING_CHANGE_MS = 500;

interface ComponentState {
  dashboard: Dashboard | undefined;
  currentFrames: Array<Frame> | undefined;
  areWASHighlighted: boolean;
  wsLoaded: number;
  wsToLoad: number;
  activity: boolean;
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent extends WithShortcuts(StatefulComponentDirectiveWithShortcutsServices)<ComponentState> implements OnInit {
  @Select(WorkspacesState.SelectWorkspaces) public workspaces$: Observable<Array<WorkspaceDB>> | undefined;
  @Select(WorkspacesState.SelectMonitoredWorkspaces) public monitoredWorkspaces$: Observable<Array<string>> | undefined;

  @ViewChild(MatMenuTrigger) private readonly menuTrigger: MatMenuTrigger | undefined;

  @HostBinding('style.background-color') private backgroundColor = '';
  @HostBinding('style.width.px') private width = 0;
  @HostBinding('style.height.px') private height = 0;
  @HostBinding('style.left') private left = '';
  @HostBinding('style.top') private top = '';
  @HostBinding('style.border-top') private borderTop = '';
  @HostBinding('style.border-right') private borderRight = '';
  @HostBinding('style.border-bottom') private borderBottom = '';
  @HostBinding('style.border-left') private borderLeft = '';

  public currentViewId = '';

  public screenTooSmall = true;
  public zoomMax = 1;
  public zoomStep = 0.1;
  public currentZoom = 1;
  public readonly zoomNumberSteps = 10;

  public Math = Math;

  private configLoaded = false;
  private readonly dashboardId: string;
  private readonly dashboardViewId: string;
  private qrCodeSnackRef: MatSnackBarRef<DashboardQrCodeComponent> | undefined;

  private timeInit = 0;
  private timeoutInit: NodeJS.Timeout | undefined;
  private fullscreenTimeout: NodeJS.Timeout | undefined;
  private resizeTimeout: NodeJS.Timeout | undefined;
  private resizeSecondTimeout: NodeJS.Timeout | undefined;
  private monitoredWSTimeout: NodeJS.Timeout | undefined;

  constructor(
    public readonly layoutService: LayoutService,
    private readonly store: Store,
    private readonly dashboardsService: DashboardsService,
    private readonly dbasService: DbasService,
    private readonly feathersService: FeathersService,
    private readonly redirectService: RedirectService,
    private readonly pageTitleService: PageTitleService,
    private readonly visibilityService: VisibilityService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly helpService: HelpService,
    private readonly route: ActivatedRoute,
    private readonly toast: HotToastService,
    private readonly snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef
  ) {
    super({
      dashboard: new Dashboard(),
      currentFrames: [],
      areWASHighlighted: false,
      activity: true,
      wsLoaded: 0,
      wsToLoad: 0,
      loaded: false,
      error: undefined
    });
    this.dashboardId = this.route.snapshot.paramMap.get(dashboardIdPathParam) ?? '';
    this.dashboardViewId = this.route.snapshot.paramMap.get(dashboardViewIdPathParam) ?? '';
  }

  @HostListener('window:resize', ['$event'])
  @debounce(100)
  private onResize(): void {
    this.launchUpdateStyle();
  }

  @HostListener('window:orientationchange', ['$event']) private onOrientationChange(): void {
    this.launchUpdateStyle();
  }

  public ngOnInit(): void {
    this.initSub();
    this.initVisibilityMonitoring();
    this.initUnsub();
  }

  public refreshZoom(value: number): void {
    this.updateZoom(value === null ? 1 : value);
  }

  public onChangeViewFromUI(id = ''): void {
    this.dbasService.triggerActivity();
    this.onChangeView(id);
  }

  public onShareButtonClick(): void {
    navigator.clipboard.writeText(this.getFriendlyUrl()).catch(this.errorHandler.handleError);
    this.toast.success(this.translocoService.translate('toast.URLCopied', {}, 'dashboard'), {
      duration: SNACKBAR_DURATION,
      position: `${SNACKBAR_VERTICAL_POSITION}-${SNACKBAR_HORIZONTAL_POSITION}` as ToastPosition
    });
  }

  public onQRCodeButtonClick(): void {
    this.qrCodeSnackRef = this.snackBar.openFromComponent(DashboardQrCodeComponent, {
      data: this.getFriendlyUrl(),
      duration: 0,
      verticalPosition: SNACKBAR_VERTICAL_POSITION,
      horizontalPosition: SNACKBAR_HORIZONTAL_POSITION
    });
  }

  public onOpenSettings(): void {
    this.shortcutsService.setActive(false);
    const data: DashboardConfigData = {
      dashboardConfig: {
        dashboardZoom: Math.round((this.zoomNumberSteps * (this.currentZoom - 1)) / (this.zoomMax - 1)),
        highlightWAS: this.state.areWASHighlighted,
        fullScreen: this.layoutService.isFullScreen
      },
      scaleConfig: {
        display: this.zoomMax > 1,
        numberSteps: this.zoomNumberSteps
      }
    };
    const dialogRef = this.dialog.open(DashboardConfigComponent, { ...MAT_DIALOG_CONFIG, data });
    dialogRef.afterClosed().subscribe({
      next: (response: DashboardConfigResponse) => {
        if (response) {
          this.toast.success(this.translocoService.translate('toast.settingsSaved', {}, 'dashboard'), {
            duration: SNACKBAR_DURATION,
            position: `${SNACKBAR_VERTICAL_POSITION}-${SNACKBAR_HORIZONTAL_POSITION}` as ToastPosition
          });
          if (this.state.areWASHighlighted !== response.highlightWAS) {
            this.updateComponentState({
              areWASHighlighted: response.highlightWAS
            });
          }
          if (this.layoutService.isFullScreen !== response.fullScreen) {
            this.layoutService.toggleFullScreen();
            this.onToggleFullScreen();
          }
          this.updateZoom((response.dashboardZoom / this.zoomNumberSteps) * (this.zoomMax - 1) + 1);
        }
        this.shortcutsService.setActive(true);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  public onOpenHelp(): void {
    this.helpService.openHelp();
  }

  public onToggleFullScreen(): void {
    if (this.fullscreenTimeout) {
      clearTimeout(this.fullscreenTimeout);
    }
    this.fullscreenTimeout = setTimeout(() => {
      this.currentZoom = this.getMaxZoom();
      this.updateStyle();
    }, DELAY_TOGGLE_FULLSCREEN);
  }

  public onExit(): void {
    this.router.navigate([DASHBOARDS_PATH]).catch(this.errorHandler.handleError);
  }

  public viewsTrackBy(index: number, item: DashboardView): string {
    return item._id ?? '';
  }

  public closeMenu(): void {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }

  protected getInitShortcuts(): { shortcuts: Array<Shortcut>; scope: TranslocoScope } {
    /**
     * t(dashboard.dashboardHelp.dashboardShortcutsTitle,
        dashboard.dashboardHelp.workspaceShortcutsTitle,
        dashboard.dashboardHelp.exitShortcut,
        dashboard.dashboardHelp.highlightShortcut,
        dashboard.dashboardHelp.settingsShortcut,
        dashboard.dashboardHelp.zoomPlusShortcut,
        dashboard.dashboardHelp.zoomMinusShortcut,
        dashboard.dashboardHelp.zoomResetShortcut)
     */
    return {
      shortcuts: [
        {
          keys: SHORTCUT_DASHBOARD_EXIT,
          group: 'dashboardHelp.dashboardShortcutsTitle',
          description: 'dashboardHelp.exitShortcut',
          callback: this.onExit
        },
        {
          keys: SHORTCUT_WORKSPACES_HIGHLIGHT,
          group: 'dashboardHelp.workspaceShortcutsTitle',
          description: 'dashboardHelp.highlightShortcut',
          callback: () => {
            this.updateComponentState({ areWASHighlighted: !this.state.areWASHighlighted });
            this.saveConfig();
          }
        },
        {
          keys: SHORTCUT_DASHBOARD_SETTINGS,
          group: 'dashboardHelp.dashboardShortcutsTitle',
          description: 'dashboardHelp.settingsShortcut',
          callback: this.onOpenSettings
        },
        {
          keys: SHORTCUT_DASHBOARD_ZOOM_PLUS,
          group: 'dashboardHelp.dashboardShortcutsTitle',
          description: 'dashboardHelp.zoomPlusShortcut',
          callback: () => {
            const newScale = Math.min(this.zoomMax, Math.round((this.currentZoom + this.zoomStep) * 100) / 100);
            this.updateZoom(newScale);
          }
        },
        {
          keys: SHORTCUT_DASHBOARD_ZOOM_MINUS,
          group: 'dashboardHelp.dashboardShortcutsTitle',
          description: 'dashboardHelp.zoomMinusShortcut',
          callback: () => {
            const newScale = Math.max(1, Math.round((this.currentZoom - this.zoomStep) * 100) / 100);
            this.updateZoom(newScale);
          }
        },
        {
          keys: SHORTCUT_DASHBOARD_ZOOM_RESET,
          group: 'dashboardHelp.dashboardShortcutsTitle',
          description: 'dashboardHelp.zoomResetShortcut',
          callback: () => {
            this.updateZoom(1);
          }
        }
      ],
      scope: 'dashboard'
    };
  }

  private onChangeView(id = ''): void {
    if (!this.state.dashboard) {
      return;
    }
    if (id) {
      this.currentViewId = id;
    }
    this.router.navigate([DASHBOARD_PATH, this.dashboardId, this.currentViewId]).catch(this.errorHandler.handleError);
    this.closeMenu();
    const dashboard = this.state.dashboard;
    const views = dashboard.views.filter((view) => view.id === this.currentViewId);
    if (views.length > 0) {
      const view = views[0];
      this.dashboardsService.setCurrentDashboardViewId(view.id);
      this.refreshCurrentFramesAndMonitoredWorkspaces(dashboard);
    }
  }

  private initVisibilityMonitoring(): void {
    this.logger.log('trace', `DashboardComponent -> initVisibilityMonitoring -> isPageVisible: ${this.visibilityService.isPageVisible}`);
    this.visibilityService.pageHiddenAfterAfkTime.pipe(takeUntil(this.destroy$)).subscribe({
      next: (event) => {
        this.logger.log('trace', 'DashboardComponent -> initVisibilityMonitoring -> pageHiddenAfterAfkTime', event);
        this.redirectService.setPreviousPath(this.router.url);
        this.router.navigate([SHARED_PATH, AFK_PATH]).catch(this.errorHandler.handleError);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private launchUpdateStyle(): void {
    if (!this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    if (!this.resizeSecondTimeout) {
      clearTimeout(this.resizeSecondTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.updateStyle();
    }, DELAY_RESIZE_EVENT);
    this.resizeSecondTimeout = setTimeout(() => {
      this.updateStyle();
    }, DELAY_SECOND_RESIZE_EVENT);
  }

  private updateStyle(): void {
    if (!this.state.dashboard) {
      return;
    }
    const dashboard = this.state.dashboard;
    this.zoomMax = this.getMaxZoom();
    this.screenTooSmall = this.zoomMax <= 1;
    this.zoomStep = this.screenTooSmall ? this.zoomStep : (this.zoomMax - 1) / this.zoomNumberSteps;
    this.currentZoom = this.screenTooSmall || this.currentZoom > this.zoomMax ? this.zoomMax : this.currentZoom;
    if (!this.screenTooSmall && this.currentZoom < 1) {
      this.currentZoom = 1;
    }
    this.backgroundColor = dashboard.internalBackgroundColor;
    this.width = dashboard.width * this.currentZoom;
    this.height = dashboard.height * this.currentZoom;
    const borderWidth = dashboard.borderWidth.map((width) => width * this.currentZoom);
    const borderColor = dashboard.borderColor;
    const borderSizeWidth = borderWidth[1] + borderWidth[3];
    const borderSizeHeight = borderWidth[0] + borderWidth[2];
    this.left = `calc(50% - ${(this.width + borderSizeWidth) / 2}px)`;
    this.top = `calc(50% - ${(this.height + borderSizeHeight) / 2}px)`;
    this.borderTop = `${borderWidth[0]}px solid ${borderColor[0]}`;
    this.borderRight = `${borderWidth[1]}px solid ${borderColor[1]}`;
    this.borderBottom = `${borderWidth[2]}px solid ${borderColor[2]}`;
    this.borderLeft = `${borderWidth[3]}px solid ${borderColor[3]}`;
    this.layoutService.setBackgroundColor(dashboard.externalBackgroundColor);
    this.cdr.detectChanges();
  }

  private getMaxZoom(): number {
    if (!this.state.dashboard) {
      return 1;
    }
    const dashboard = this.state.dashboard;
    const borderWidth = Array.isArray(dashboard.borderWidth)
      ? dashboard.borderWidth
      : [dashboard.borderWidth, dashboard.borderWidth, dashboard.borderWidth, dashboard.borderWidth];
    if (borderWidth.length === 4) {
      const zoomWidth = (getWindowWidth() - 2 * PADDING_PER_BORDER_PX) / (dashboard.width + borderWidth[1] + borderWidth[3]);
      const zoomHeight = (getWindowHeight() - 2 * PADDING_PER_BORDER_PX) / (dashboard.height + borderWidth[0] + borderWidth[2]);

      const roundCoefficient = 10000000;
      return Math.round(Math.min(zoomWidth, zoomHeight, ZOOM_MAX) * roundCoefficient) / roundCoefficient;
    }

    return 1;
  }

  private refreshCurrentFramesAndMonitoredWorkspaces(dashboard: Dashboard, onlyCurrent = false): void {
    let monitoredWorkspaces: Array<string> = [];
    const currentView = dashboard.views.find((v) => v.id === this.currentViewId) ?? new DashboardView();
    if (onlyCurrent) {
      monitoredWorkspaces = this.dashboardsService.getUuidsOfDashboardViewAndCurrentFrames(dashboard, currentView);
    } else {
      const wsUuidsToMonitoredOfCurrentView = getAllUuidsOfDashboardViewAndChildrenViews(dashboard, currentView, 0);
      monitoredWorkspaces =
        dashboard.viewDeepOnActivation < 0
          ? getAllUuidsOfDashboard(dashboard)
          : dashboard.viewDeepOnActivation === 0
          ? wsUuidsToMonitoredOfCurrentView
          : getAllUuidsOfDashboardViewAndChildrenViews(dashboard, currentView, dashboard.viewDeepOnActivation);
    }
    this.store.dispatch(new SetMonitoredWorkspaces(monitoredWorkspaces));
    this.updateComponentState({
      currentFrames: getAllFramesOfDashboardViewAndChildrenViews(dashboard, currentView, dashboard.viewDeepOnActivation),
      wsToLoad: monitoredWorkspaces.length
    });
  }

  private initSub(): void {
    this.timeInit = this.timeInit === 0 ? new Date().getTime() : this.timeInit;
    if (new Date().getTime() - this.timeInit > MAX_TIME_TO_LOAD) {
      this.onExit();
    }
    if (this.dashboardsService.areDashboardsInitialized) {
      this.initVisibilitySub();
      this.initSubDbasClick();
      this.setDashboard();
    } else {
      if (this.timeoutInit) {
        clearTimeout(this.timeoutInit);
      }
      this.timeoutInit = setTimeout(() => {
        this.initSub();
      }, TIME_BETWEEN_LOAD_TEST);
    }
  }

  private setDashboard(): void {
    const dashboard = this.dashboardsService.getDashboardInStore(this.dashboardId);
    if (dashboard && dashboard._id) {
      if (!this.dashboardsService.allRequiredModulesAreLoaded(dashboard)) {
        const missingModules = this.dashboardsService.missingModulesOfThisDashboard(dashboard);
        this.toast.error(this.translocoService.translate('toast.missingModules', { missingModules }, 'dashboard'), {
          duration: SNACKBAR_DURATION,
          position: `${SNACKBAR_VERTICAL_POSITION}-${SNACKBAR_HORIZONTAL_POSITION}` as ToastPosition
        });
        this.onExit();
      } else {
        const currentViewIndex = dashboard.views.findIndex((v) => v.id === this.dashboardViewId);
        if (this.dashboardViewId && currentViewIndex > -1) {
          this.feathersService.joinDashboardRoom(dashboard._id);
          this.currentViewId = this.dashboardViewId;
          this.store.dispatch(new UpdateCurrentDashboard(dashboard, this.currentViewId));
          this.refreshCurrentFramesAndMonitoredWorkspaces(dashboard);
          this.initLoaderSub();
          this.updateComponentState({
            dashboard,
            loaded: false
          });
          this.loadConfig();
          this.updateStyle();
          this.dbasService.triggerActivity();
          this.initTranslations();
        } else {
          this.currentViewId = dashboard.views.length > 0 ? dashboard.views[0].id : '';
          if (this.currentViewId) {
            this.router.navigate([DASHBOARD_PATH, this.dashboardId, this.currentViewId]).catch(this.errorHandler.handleError);
          } else {
            this.onExit();
          }
        }
      }
    } else {
      this.onExit();
    }
  }

  private initSubDbasClick(): void {
    this.dbasService.activityObservable$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (activity) => {
        this.logger.log('trace', activity ? 'Activity' : 'Inactivity');
        this.updateComponentState({
          activity
        });
        if (this.state.dashboard) {
          this.refreshCurrentFramesAndMonitoredWorkspaces(this.state.dashboard, !activity);
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
    this.dbasService.clickObservable$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (event) => {
        this.onChangeView(event.targetView);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private initVisibilitySub(): void {
    this.visibilityService.pageVisible.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.launchUpdateStyle();
      }
    });
  }

  private initLoaderSub(): void {
    if (!this.workspaces$ || !this.monitoredWorkspaces$) {
      return;
    }
    combineLatest([this.workspaces$, this.monitoredWorkspaces$])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([workspaces, monitoredWorkspaces]) => {
          const isLoaded = monitoredWorkspaces.length > 0 && monitoredWorkspaces.every((uuid) => workspaces.map((ws) => ws.uuid).includes(uuid));
          if (this.monitoredWSTimeout) {
            clearTimeout(this.monitoredWSTimeout);
          }
          if (isLoaded) {
            this.updateComponentState({
              wsLoaded: workspaces.length,
              loaded: isLoaded
            });
          } else {
            this.monitoredWSTimeout = setTimeout(() => {
              this.updateComponentState({
                wsLoaded: workspaces.length,
                loaded: isLoaded
              });
            }, SET_LOAD_TO_FALSE_AFTER_MONITORING_CHANGE_MS);
          }
        },
        error: this.errorHandler.handleError
      });
  }

  private getDashboardConfigFromState(): DashboardStorageConfig {
    return {
      dashboardZoom: this.currentZoom,
      highlightWAS: this.state.areWASHighlighted
    };
  }

  private saveConfig(): void {
    if (!this.state.dashboard) {
      return;
    }
    const dashboard = this.state.dashboard;
    this.dashboardsService.storeDashboardConfig(dashboard._id ?? '', this.getDashboardConfigFromState());
  }

  private loadConfig(): void {
    if (this.configLoaded || !this.state.dashboard) {
      return;
    }
    const dashboard = this.state.dashboard;
    const config = this.dashboardsService.getDashboardConfig(dashboard._id ?? '');
    if (config) {
      this.updateZoom(config.dashboardZoom);
      this.updateComponentState({
        areWASHighlighted: config.highlightWAS
      });
    }
    this.configLoaded = true;
  }

  private updateZoom(zoom: number): void {
    this.currentZoom = zoom;
    this.updateStyle();
    this.saveConfig();
    this.cdr.detectChanges();
  }

  private getFriendlyUrl(): string {
    const url = this.router.url;
    const urlExploded = url.split('/');
    urlExploded[2] = this.state.dashboard && this.state.dashboard._id ? this.state.dashboard._id : '';

    return `${location.origin}/#${urlExploded.join('/')}`;
  }

  private initTranslations(): void {
    /**
     * t(dashboard.pageTitle)
     */
    this.translocoService
      .selectTranslate(['pageTitle'], {}, 'dashboard')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (translations) => {
          if (this.state.dashboard) {
            this.pageTitleService.setTitle([translations[0], this.state.dashboard.title]);
          }
        },
        error: (err: unknown) => this.errorHandler.handleError(err)
      });
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.state.dashboard && this.state.dashboard._id) {
          this.feathersService.leaveDashboardRoom(this.state.dashboard._id);
        }
        if (this.qrCodeSnackRef) {
          this.qrCodeSnackRef.dismiss();
        }
        this.dbasService.resetLastWSOfFrames();
        if (this.timeoutInit) {
          clearTimeout(this.timeoutInit);
        }
        if (this.fullscreenTimeout) {
          clearTimeout(this.fullscreenTimeout);
        }
        if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
        }
        if (this.resizeSecondTimeout) {
          clearTimeout(this.resizeSecondTimeout);
        }
        if (this.monitoredWSTimeout) {
          clearTimeout(this.monitoredWSTimeout);
        }
        this.store.dispatch(new ResetMonitoredWorkspaces());
        this.store.dispatch(new ResetCurrentDashboard());
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }
}
