import { lastValueFrom, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { STATE_G2VIEW_WORKSPACES } from '@config/constants';
import { g2RoleToRoleTransform, removeDuplicates } from '@config/utils';
import { RxJSBaseDirective } from '@directives/rxjs-base.directive';
import {
  ActivateWorkspacesEvent,
  getWorkspaceDescendants,
  MinimizedWorkspace,
  NamedWorkspace,
  NamedWorkspaceResponse,
  WorkspaceDB,
  WorkspaceModule,
  WorkspaceSession,
  WorkspaceTitlesResponse
} from '@g2view/g2view-commons';
import { Store } from '@ngxs/store';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { LoggerService } from '@services/logger.service';
import { UserService } from '@services/user.service';
import { ResetNamedWorkspaces, SetNamedWorkspaces, SetWorkspacesModule, UpdateMinimizedWorkspaces } from '@workspaces/state/workspaces.actions';

@Injectable({
  providedIn: 'root'
})
export class WorkspacesService extends RxJSBaseDirective {
  private readonly httpHeaders: HttpHeaders;

  private readonly workspacesCreatedSubject = new Subject<Array<string>>();
  private readonly workspacesUpdatedSubject = new Subject<Array<string>>();

  constructor(
    private readonly http: ApiHttpService,
    private readonly userService: UserService,
    private readonly store: Store,
    private readonly apiEndpointsService: ApiEndpointsService,
    private readonly errorHandler: ErrorHandlerService,
    private readonly logger: LoggerService
  ) {
    super();
    this.logger.log('trace', 'Init of WorkspacesService');
    this.httpHeaders = new HttpHeaders({ Accept: 'application/json' });
  }

  get workspacesCreated$(): Observable<Array<string>> {
    return this.workspacesCreatedSubject.asObservable();
  }

  get workspacesUpdated$(): Observable<Array<string>> {
    return this.workspacesUpdatedSubject.asObservable();
  }

  get areNamedWorkspacesInitialized(): boolean {
    return this.store.selectSnapshot<boolean>((state) => state[STATE_G2VIEW_WORKSPACES].namedWorkspacesLoaded);
  }

  get namedWorkspaces(): Array<NamedWorkspace> {
    return this.store.selectSnapshot<Array<NamedWorkspace>>((state) => state[STATE_G2VIEW_WORKSPACES].namedWorkspaces);
  }

  get workspacesModules(): Array<WorkspaceModule> {
    return this.store.selectSnapshot<Array<WorkspaceModule>>((state) => state[STATE_G2VIEW_WORKSPACES].workspacesModules);
  }

  public emitCreatedWorkspaces(uuids: Array<string>): void {
    this.workspacesCreatedSubject.next(uuids);
  }

  public emitUpdatedWorkspaces(uuids: Array<string>): void {
    this.workspacesUpdatedSubject.next(uuids);
  }

  public refreshNamedWorkspaces(): void {
    this.store.dispatch(new ResetNamedWorkspaces());
    const roles: Array<string> = this.userService.currentG2Roles.map((r) => g2RoleToRoleTransform(r));
    if (roles.length > 0) {
      this.getNamedWorkspaces$(roles).subscribe({
        next: (workspaces) => {
          this.store.dispatch(new SetNamedWorkspaces(workspaces));
        },
        error: (err: unknown) => this.errorHandler.handleError(err)
      });
    }
  }

  // This function can refresh the title of all currently minimized WS. We decided to not use it since the title of a workspace doesn't change a lot.
  public refreshAllMinimizedWorkspaces(): void {
    this.refreshMinimizedWorkspaces(this.getMinimizedWorkspacesInStore().map((ws) => ws.uuid));
  }

  public refreshMinimizedWorkspaces(uuids: Array<string>): void {
    if (uuids.length > 0) {
      this.getWorkspacesTitles$(uuids).subscribe({
        next: (workspaces) => {
          this.store.dispatch(new UpdateMinimizedWorkspaces(workspaces));
        },
        error: (err: unknown) => this.errorHandler.handleError(err)
      });
    }
  }

  public activateWorkspaces(data: ActivateWorkspacesEvent): void {
    this.http
      .post(this.apiEndpointsService.activateWorkspacesEndpoint(), data, { headers: this.httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public getIdOfWorkspace(uuid: string): string {
    const workspaces = this.store.selectSnapshot<Array<WorkspaceDB>>((state) =>
      state[STATE_G2VIEW_WORKSPACES].workspaces.filter((ws: WorkspaceDB) => ws.uuid === uuid)
    );

    return workspaces.length > 1 ? workspaces[0]._id ?? '' : '';
  }

  public addImgUrlToWorkspaces(workspaces: Array<WorkspaceDB>): Array<WorkspaceDB> {
    workspaces.forEach((ws) => {
      ws.imgUrl = this.getCurrentWorkspaceImageUrl(ws.uuid);
    });

    return workspaces;
  }

  public getWorkspaceInStore(uuid: string): WorkspaceDB | undefined {
    return (
      this.store.selectSnapshot<Array<WorkspaceDB>>((state) => state[STATE_G2VIEW_WORKSPACES].workspaces).find((ws) => ws.uuid === uuid) ?? undefined
    );
  }

  public getWorkspacesInStore(): Array<WorkspaceDB> {
    return this.store.selectSnapshot<Array<WorkspaceDB>>((state) => state[STATE_G2VIEW_WORKSPACES].workspaces);
  }

  public getMonitoredWorkspacesInStore(): Array<string> {
    return this.store.selectSnapshot<Array<string>>((state) => state[STATE_G2VIEW_WORKSPACES].monitoredWorkspaces);
  }

  public getWorkspacesInDB(uuids: Array<string>): Observable<Array<WorkspaceDB>> {
    return this.http.get(this.apiEndpointsService.getWorkspacesEndpoint(uuids)).pipe(map((workspaces) => workspaces.data));
  }

  public getCurrentWorkspaceImageUrl(uuid: string): string {
    return this.apiEndpointsService.getCurrentWorkspaceImageUrlEndpoint(uuid);
  }

  public getWorkspaceDescendants(uuid: string, workspacesInSession: Array<WorkspaceSession>): Array<string> {
    const workspacesInStore = this.getWorkspacesInStore();
    const workspacesOfSession = workspacesInStore.filter((ws) => workspacesInSession.map((ws) => ws.uuid).includes(ws.uuid));
    return getWorkspaceDescendants(uuid, workspacesOfSession);
  }

  public updateWorkspacesModules(): void {
    this.fetchWorkspacesModules().then((workspacesModules) => {
      this.setWorkspacesModules(workspacesModules);
    });
  }

  private getMinimizedWorkspacesInStore(): Array<MinimizedWorkspace> {
    return this.store.selectSnapshot<Array<MinimizedWorkspace>>((state) => state[STATE_G2VIEW_WORKSPACES].minimizedWorkspaces);
  }

  private getNamedWorkspaces$(roles: Array<string>): Observable<Array<NamedWorkspace>> {
    let namedWorkspaces: Array<NamedWorkspace> = [];

    return this.fetchNamedWorkspaces$(roles).pipe(
      map((res) => {
        if (res.code !== 200) {
          this.errorHandler.handleError(res.message ? res.message : 'Error', true);

          return [];
        }
        namedWorkspaces = namedWorkspaces.concat(res.data);
        return removeDuplicates(namedWorkspaces, 'uuid').sort((a, b) => a.wsName.localeCompare(b.wsName));
      })
    );
  }

  private fetchWorkspacesModules(): Promise<Array<WorkspaceModule>> {
    return lastValueFrom(this.http.get(this.apiEndpointsService.getWorkspacesModulesEndpoint()));
  }

  private setWorkspacesModules(workspacesModules: Array<WorkspaceModule>): void {
    this.store.dispatch(new SetWorkspacesModule(workspacesModules));
  }

  private fetchNamedWorkspaces$(roles: Array<string>): Observable<NamedWorkspaceResponse> {
    return this.http.post(this.apiEndpointsService.getNamedWorkspacesEndpoint(), { roles }, { headers: this.httpHeaders });
  }

  private getWorkspacesTitles$(uuids: Array<string>): Observable<Array<MinimizedWorkspace>> {
    return this.fetchWorkspaceTitles$(uuids).pipe(
      map((res) => {
        if (res.code !== 200) {
          this.errorHandler.handleError(res.message ? res.message : 'Error', true);
          return [];
        }
        return res.data;
      })
    );
  }

  private fetchWorkspaceTitles$(uuids: Array<string>): Observable<WorkspaceTitlesResponse> {
    return this.http.post(this.apiEndpointsService.geWorkspaceTitlesEndpoint(), { uuids }, { headers: this.httpHeaders });
  }
}

export const getWSId = (uuid: string): string => `WS-${uuid}`;
