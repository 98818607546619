<ng-container *transloco="let t; scope: 'app'; read: 'app.snackbar.disconnect'">
  <ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="state.loaded">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
        <h2>{{ t('text') }}</h2>
        <button mat-button mat-raised-button color="primary" title="{{ t('buttonTitle') }}" (click)="refresh()">
          {{ t('buttonLabel') }}
        </button>
      </div>
      <h4 style="margin-top: 5px; margin-bottom: 0px;">{{ t('subtitle') }}</h4>
    </ng-container>
    <ng-container *ngIf="!state.loaded">
      <ngx-g2v-progress-bar></ngx-g2v-progress-bar>
    </ng-container>
    <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
  </ng-container>
</ng-container>