import { AngularDraggableModule } from 'angular2-draggable';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { TranslocoRootModule } from '@app/transloco/transloco-root.module';
import { applyAutocompleteExtension } from '@components/formly/autocomplete.extension';
import { FormlyAutocompleteComponent } from '@components/formly/autocomplete/autocomplete.component';
import { FormlyCheckboxesLabelComponent } from '@components/formly/checkboxes-label/checkboxes-label.component';
import { FormlyDividerComponent } from '@components/formly/formly-divider/formly-divider.component';
import { FormlyFileComponent } from '@components/formly/formly-file/formly-file.component';
import { FormlySubtitleComponent } from '@components/formly/formly-subtitle/formly-subtitle.component';
import { FormlyStepperComponent } from '@components/formly/stepper/stepper.component';
import { LogButtonComponent } from '@components/log-button/log-button.component';
import { AccessControlDirective } from '@directives/access-control.directive';
import { FileValueAccessorDirective } from '@directives/file-value-accessor.directive';
import { MatButtonLoadingDirective } from '@directives/mat-button-loading.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HotToastModule } from '@ngneat/hot-toast';
import { HotkeysModule } from '@ngneat/hotkeys';
import { TranslocoService } from '@ngneat/transloco';
import { ConfigOption, FORMLY_CONFIG, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatSliderModule } from '@ngx-formly/material/slider';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { ArrayJoinPipe } from '@pipes/array-join';
import { G2RolePipe } from '@pipes/g2role.pipe';
import { G2RolesPipe } from '@pipes/g2roles.pipe';
import { PowerPipe } from '@pipes/power.pipe';
import { VideoURLPipe } from '@pipes/video-url.pipe';
import { ComponentsModule } from '@shared/components.module';
import { SharedRoutingModule } from '@shared/routing.module';

// The shared module holds common UI components, directives, and pipes that are going to be used in many — if not every — feature module.
// This can include common Material UI modules, such as MatButtonModule or MatSelectModule.
// If you notice you are importing a module into every feature module, you should refactor it to live in the shared module instead.

const emailValidator = (control: AbstractControl): ValidationErrors | null => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !control.value || re.test(String(control.value).toLowerCase()) ? null : { email: true };
};
const urlValidator = (control: AbstractControl): ValidationErrors | null => {
  const re = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;

  return !control.value || re.test(String(control.value).toLowerCase()) ? null : { url: true };
};
const integerValidator = (control: AbstractControl): ValidationErrors | null => {
  const re = /^[+-]?\d*$/;

  return !control.value || re.test(String(control.value).toLowerCase()) ? null : { integer: true };
};
const autocompleteValidator = (control: AbstractControl, field: FormlyFieldConfig): ValidationErrors | null => {
  const terms = field.props && field.props.terms ? field.props.terms : [];

  return !control.value || terms.includes(control.value) ? null : { autocomplete: true };
};

const formlyValidationConfig = (translocoService: TranslocoService): ConfigOption => ({
  extras: { lazyRender: true },
  types: [
    { name: 'checkboxesLabel', component: FormlyCheckboxesLabelComponent },
    { name: 'stepper', component: FormlyStepperComponent, wrappers: [] },
    { name: 'subTitle', component: FormlySubtitleComponent },
    { name: 'divider', component: FormlyDividerComponent },
    {
      name: 'autocomplete',
      component: FormlyAutocompleteComponent,
      wrappers: ['form-field']
    },
    { name: 'file', component: FormlyFileComponent, wrappers: [] }
  ],
  extensions: [{ name: 'autocomplete', extension: { prePopulate: applyAutocompleteExtension } }],
  validators: [
    { name: 'email', validation: emailValidator },
    { name: 'url', validation: urlValidator },
    { name: 'integer', validation: integerValidator },
    { name: 'autocomplete', validation: autocompleteValidator }
  ],
  /**
   * t(app.forms.validationMessage.required,
      app.forms.validationMessage.minLength,
      app.forms.validationMessage.maxLength,
      app.forms.validationMessage.min,
      app.forms.validationMessage.max,
      app.forms.validationMessage.email,
      app.forms.validationMessage.url,
      app.forms.validationMessage.integer,
      app.forms.validationMessage.autocomplete)
   */
  validationMessages: [
    {
      name: 'required',
      message: (): string => translocoService.translate('forms.validationMessage.required', {}, 'app')
    },
    {
      name: 'minLength',
      message: (_err, field): string =>
        translocoService.translate('forms.validationMessage.minLength', { minLength: field.props ? field.props.minLength : 0 }, 'app')
    },
    {
      name: 'maxLength',
      message: (_err, field): string =>
        translocoService.translate('forms.validationMessage.maxLength', { maxLength: field.props ? field.props.maxLength : 0 }, 'app')
    },
    {
      name: 'min',
      message: (_err, field): string => translocoService.translate('forms.validationMessage.min', { min: field.props ? field.props.min : 0 }, 'app')
    },
    {
      name: 'max',
      message: (_err, field): string => translocoService.translate('forms.validationMessage.max', { max: field.props ? field.props.max : 0 }, 'app')
    },
    {
      name: 'email',
      message: (_err, field): string =>
        translocoService.translate('forms.validationMessage.email', { email: field.formControl ? field.formControl.value : '' }, 'app')
    },
    {
      name: 'url',
      message: (_err, field): string =>
        translocoService.translate('forms.validationMessage.url', { url: field.formControl ? field.formControl.value : '' }, 'app')
    },
    {
      name: 'integer',
      message: (_err, field): string =>
        translocoService.translate('forms.validationMessage.integer', { integer: field.formControl ? field.formControl.value : '' }, 'app')
    },
    {
      name: 'autocomplete',
      message: (_err, field): string =>
        translocoService.translate('forms.validationMessage.autocomplete', { term: field.formControl ? field.formControl.value : '' }, 'app')
    }
  ]
});

@NgModule({
  declarations: [
    ArrayJoinPipe,
    G2RolePipe,
    G2RolesPipe,
    PowerPipe,
    VideoURLPipe,
    FormlyCheckboxesLabelComponent,
    FormlyAutocompleteComponent,
    FormlyStepperComponent,
    FormlySubtitleComponent,
    FormlyDividerComponent,
    FormlyFileComponent,
    AccessControlDirective,
    MatButtonLoadingDirective,
    FileValueAccessorDirective,
    LogButtonComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    ComponentsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    FormlyMatSliderModule,
    AngularDraggableModule,
    HotkeysModule,
    HotToastModule.forRoot(),
    MaterialFileInputModule,
    FontAwesomeModule
  ],
  exports: [
    ArrayJoinPipe,
    G2RolePipe,
    G2RolesPipe,
    VideoURLPipe,
    PowerPipe,
    AccessControlDirective,
    MatButtonLoadingDirective,
    ComponentsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    FormlyMatSliderModule,
    AngularDraggableModule,
    HotkeysModule,
    FontAwesomeModule,
    LogButtonComponent
  ],
  providers: [{ provide: FORMLY_CONFIG, multi: true, useFactory: formlyValidationConfig, deps: [TranslocoService] }]
})
export class SharedModule {}
