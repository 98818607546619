import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoRootModule } from '@app/transloco/transloco-root.module';
import { ThreeDViewerModalComponent } from '@components/3d-viewer/3d-viewer-modal/3d-viewer-modal.component';
import { ThreeDViewerComponent } from '@components/3d-viewer/3d-viewer.component';
import { ActionButtonsComponent } from '@components/action-buttons/action-buttons.component';
import { AfkComponent } from '@components/afk/afk.component';
import { CanvasComponent } from '@components/canvas/canvas.component';
import { ComponentErrorComponent } from '@components/component-error/component-error.component';
import { DialogActionsComponent } from '@components/dialog-actions/dialog-actions.component';
import { DialogErrorComponent } from '@components/dialog-error/dialog-error.component';
import { ErrorSocketComponent } from '@components/error-socket/error-socket.component';
import { FrameModalComponent } from '@components/frame/frame-modal/frame-modal.component';
import { FrameComponent } from '@components/frame/frame.component';
import { G2RoleSwitchComponent } from '@components/g2-role-switch/g2-role-switch.component';
import { MarkmapChartModalComponent } from '@components/markmap-chart/markmap-chart-modal/markmap-chart-modal.component';
import { MarkmapChartComponent } from '@components/markmap-chart/markmap-chart.component';
import { MermaidChartModalComponent } from '@components/mermaid-chart/mermaid-chart-modal/mermaid-chart-modal.component';
import { MermaidChartComponent } from '@components/mermaid-chart/mermaid-chart.component';
import { ModalMessageComponent } from '@components/modal-message/modal-message.component';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { ProgressSpinnerComponent } from '@components/progress-spinner/progress-spinner.component';
import { SelectMultipleComponent } from '@components/select-multiple/select-multiple.component';
import { SkeletonRectangleComponent } from '@components/skeleton/rectangle/rectangle.component';
import { SnackBarDisconnectComponent } from '@components/snack-bar-disconnect/snack-bar-disconnect.component';
import { SnackBarNoBackendComponent } from '@components/snack-bar-no-backend/snack-bar-no-backend.component';
import { TableAreaButtonComponent } from '@components/table-area-button/table-area-button.component';
import { TableComponent } from '@components/table/table.component';
import { TabulatorTableComponent } from '@components/tabulator-table/tabulator-table.component';
import { TimelineModalComponent } from '@components/timeline/timeline-modal/timeline-modal.component';
import { TimelineComponent } from '@components/timeline/timeline.component';
import { TitleComponent } from '@components/title/title.component';
import { ToggleDevModeComponent } from '@components/toggle-dev-mode/toggle-dev-mode.component';
import { ToggleFullScreenComponent } from '@components/toggle-full-screen/toggle-full-screen.component';
import { popperVariation, TippyModule, tooltipVariation, withContextMenuVariation } from '@ngneat/helipopper';
import { SafePipe } from '@pipes/safe.pipe';
import { MaterialModule } from '@shared/material.module';

@NgModule({
  declarations: [
    ActionButtonsComponent,
    DialogErrorComponent,
    ModalMessageComponent,
    ProgressBarComponent,
    ProgressSpinnerComponent,
    SelectMultipleComponent,
    TitleComponent,
    ToggleFullScreenComponent,
    ToggleDevModeComponent,
    AfkComponent,
    SnackBarDisconnectComponent,
    SnackBarNoBackendComponent,
    ComponentErrorComponent,
    DialogActionsComponent,
    ErrorSocketComponent,
    MarkmapChartComponent,
    MermaidChartComponent,
    MermaidChartModalComponent,
    TableComponent,
    TabulatorTableComponent,
    TableAreaButtonComponent,
    G2RoleSwitchComponent,
    SafePipe,
    MarkmapChartModalComponent,
    CanvasComponent,
    SkeletonRectangleComponent,
    ThreeDViewerComponent,
    ThreeDViewerModalComponent,
    FrameComponent,
    FrameModalComponent,
    TimelineComponent,
    TimelineModalComponent
  ],
  imports: [
    TranslocoRootModule,
    CommonModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
        contextMenu: withContextMenuVariation(popperVariation)
      }
    })
  ],
  exports: [
    MaterialModule,
    ActionButtonsComponent,
    DialogErrorComponent,
    ModalMessageComponent,
    ProgressBarComponent,
    ProgressSpinnerComponent,
    SelectMultipleComponent,
    TitleComponent,
    ToggleFullScreenComponent,
    ToggleDevModeComponent,
    ComponentErrorComponent,
    DialogActionsComponent,
    MarkmapChartComponent,
    MermaidChartComponent,
    MermaidChartModalComponent,
    TableComponent,
    TabulatorTableComponent,
    G2RoleSwitchComponent,
    SafePipe,
    CanvasComponent,
    SkeletonRectangleComponent,
    ThreeDViewerComponent,
    FrameComponent,
    TimelineComponent
  ]
})
export class ComponentsModule {}
