import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { UserConfigService } from '@services/user-config.service';

interface ComponentState {
  loaded: boolean;
  isDevMode: boolean;
}

@Component({
  selector: 'ngx-g2v-toggle-dev-mode',
  templateUrl: './toggle-dev-mode.component.html',
  styleUrls: ['./toggle-dev-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleDevModeComponent extends StatefulComponentDirective<ComponentState> implements OnInit {
  constructor(public userConfigService: UserConfigService) {
    super({
      loaded: false,
      isDevMode: false
    });
  }

  public ngOnInit(): void {
    this.setComponentState({
      loaded: true,
      isDevMode: this.userConfigService.isDevMode
    });
  }

  public onToggleDevMode(): void {
    this.userConfigService.toggleDevMode();
    this.updateComponentState({
      isDevMode: !this.state.isDevMode
    });
  }
}
