<ng-container *transloco="let t; scope: 'app'; read: 'app.navBar'">
  <mat-toolbar>
    <div fxFlex fxLayoutAlign="start center" fxLayoutGap="25px">
      <div class="logo-and-title cursor-pointer">
        <img [src]="logoUrl" alt="{{ t('logoTitle') }}" height="30" width="30" style="float: left;" />
        <a routerLink="/" class="nav-bar__title" title="{{ t('titleTitle') }}">{{ title }}</a> ({{ buildHash }})
      </div>
    </div>
    <ng-container *ngIf="state$ | async as state">
      <ng-container *ngIf="state.loaded">
        <nav fxFlex fxLayoutAlign="center center" fxHide fxShow.gt-sm>
          <ng-container *ngFor="let item of menuItems; trackBy: menuItemsTrackBy">
            <button mat-button *ngIf="item.route" [routerLink]="item.route" routerLinkActive="navigation-item--active" class="navigation-item" [title]="item.title">
              {{ item.name }}
            </button>
            <button mat-button *ngIf="!item.route" [matMenuTriggerFor]="menu" class="navigation-item" [title]="item.title"
              [ngClass]="{ 'navigation-item--active': state.activeMenuGroup === item.key }">
              {{ item.name }}
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let menuItem of item.menuItems; trackBy: menuItemsTrackBy">
                <a *ngIf="menuItem.external" [href]="menuItem.route" mat-menu-item class="menu-navigation-item" target="_blank" [title]="menuItem.title">
                  {{ menuItem.name}}
                </a>
                <a *ngIf="!menuItem.external" mat-menu-item class="menu-navigation-item" routerLinkActive="menu-navigation-item--active"
                  [routerLink]="menuItem.relativePath ? [item.key, menuItem.route] : menuItem.route" [title]="menuItem.title">{{ menuItem.name }}</a>
              </ng-container>
            </mat-menu>
          </ng-container>
        </nav>
        <ng-container *ngIf="state.langs as langs">
          <div fxFlex fxLayoutAlign="end center">
            <button mat-button class="navigation-item" (click)="onLogout()" fxHide fxShow.gt-sm title="{{ t('buttonLogoutTitle') }}">{{ t('logout') }}</button>
            <ng-container *ngIf="state.date as date">
              <span fxHide fxShow.gt-md class="nav-bar__info"> {{ date | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
              <span fxHide.gt-md fxHide.xs class="nav-bar__info"> {{ date | date: 'HH:mm:ss' }}</span>
            </ng-container>
            <ng-container *ngIf="langs.length > 1">
              <img width="32" height="100%" (click)="onFlagClick()" [src]="i18nFlagsFolder + currentLang + '.png'" alt="{{ t('flagTitle', { lang: currentLang }) }}"
                id="current-lang-flag" />
              <mat-form-field style="visibility: hidden; width: 200px; margin-left: -200px;">
                <mat-select #languageSwitch panelClass="languageSwitch" hideSingleSelectionIndicator="true" (selectionChange)="setActiveLang($event)" [value]="defaultLang"
                  id="language-switch">
                  <mat-option *ngFor="let lang of langs; trackBy: langsTrackBy" [value]="lang.id">
                    <img width="32" height="100%" [src]="i18nFlagsFolder + lang.id + '.png'" alt="{{ t('flagTitle', { lang: lang.id }) }}" /> {{ lang.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <button fxShow mat-icon-button (click)="onHelpClick()" *ngIf="layoutService.isDesktop" title="{{ t('buttonShortcutsTitle') }}">
              <mat-icon fontIcon="keyboard"></mat-icon>
            </button>
            <button fxShow fxHide.gt-sm mat-icon-button [matMenuTriggerFor]="menuMobile" title="{{ t('buttonMenuTitle') }}">
              <mat-icon fontIcon="menu"></mat-icon>
            </button>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!state.loaded">
        <ngx-g2v-progress-bar></ngx-g2v-progress-bar>
      </ng-container>
      <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
    </ng-container>
  </mat-toolbar>

  <mat-menu #menuMobile="matMenu">
    <ng-container *ngFor="let item of menuItems; trackBy: menuItemsTrackBy">
      <a *ngIf="item.route" mat-menu-item [routerLink]="item.route" routerLinkActive="navigation-item-menu--active" [title]="item.title">
        <span>{{ item.name }}</span>
      </a>
      <ng-container *ngIf="!item.route">
        <ng-container *ngFor="let menuItem of item.menuItems; trackBy: menuItemsTrackBy">
          <a *ngIf="menuItem.external" [href]="menuItem.route" mat-menu-item target="_blank" [title]="menuItem.title">
            <span>{{ menuItem.name }}</span>
          </a>
          <a *ngIf="!menuItem.external" mat-menu-item routerLinkActive="navigation-item-menu--active"
            [routerLink]="menuItem.relativePath ? [item.key, menuItem.route] : menuItem.route" [title]="menuItem.title">
            <span>{{ menuItem.name }}</span>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
    <button mat-menu-item (click)="onLogout()" title="{{ t('buttonLogoutTitle') }}">{{ t('logout') }}</button>
  </mat-menu>
</ng-container>