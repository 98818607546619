import { Observable, Subject } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';

const DATE_INTERVAL_UPDATE = 500;

@Injectable({
  providedIn: 'root'
})
export class TimerService implements OnDestroy {
  private intervalId: NodeJS.Timeout;
  private timer = new Subject<Date>();

  constructor() {
    this.intervalId = setInterval(() => {
      this.timer.next(new Date());
    }, DATE_INTERVAL_UPDATE);
  }

  get timer$(): Observable<Date> {
    return this.timer.asObservable();
  }

  public ngOnDestroy(): void {
    this.timer.complete();
    clearInterval(this.intervalId);
  }
}
