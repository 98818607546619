import { QueryStringParameters } from '@classes/query-string-parameters';

export class UrlBuilder {
  public url: string;
  public queryString: QueryStringParameters;

  constructor(baseUrl: string, action: string, queryString?: QueryStringParameters) {
    this.url = action === '' ? baseUrl : [baseUrl, action].join('/');
    this.queryString = queryString === undefined ? new QueryStringParameters() : queryString;
  }

  public toString(): string {
    const qs: string = this.queryString === undefined ? '' : this.queryString.toString();

    return qs ? `${this.url}?${qs}` : this.url;
  }
}
