import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  private getStoreFunc: (txMode: IDBTransactionMode, callback: any) => Promise<any>;

  constructor() {
    this.getStoreFunc = this.createStore('G2View-DB', 'G2View-Store');
  }

  public set(key: string, value: any) {
    return this.getStoreFunc('readwrite', (store: Storage) => {
      store.put(value, key);
      return this.promisifyRequest(store.transaction);
    });
  }

  public get(key: string) {
    return this.getStoreFunc('readonly', (store: Storage) => this.promisifyRequest(store.get(key)));
  }

  private createStore(dbName: string, storeName: string): (txMode: IDBTransactionMode, callback: any) => Promise<any> {
    const request = indexedDB.open(dbName);
    request.onupgradeneeded = () => request.result.createObjectStore(storeName);
    const dbp = this.promisifyRequest(request);

    return (txMode: IDBTransactionMode, callback: any) => dbp.then((db) => callback(db.transaction(storeName, txMode).objectStore(storeName)));
  }

  private promisifyRequest(request: IDBOpenDBRequest): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      request.onsuccess = request.onsuccess = () => resolve(request.result);
      request.onerror = request.onerror = () => reject(request.error);
    });
  }
}
