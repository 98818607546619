import { Observable, Subject, takeUntil } from 'rxjs';

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_CONFIG } from '@app/core/config/constants';
import { FrameModalComponent } from '@components/frame/frame-modal/frame-modal.component';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { WA_DISPLAY_FULL_SCREEN_DEFAULT } from '@g2view/g2view-commons';
import { ModalConfig } from '@interfaces/modal-config';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ShortcutsService } from '@services/shortcuts.service';
import { SessionsService } from '@sessions/services/sessions.service';

const MODAL_WIDTH_PERCENT = 95;

interface ComponentState {
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FrameComponent extends StatefulComponentDirective<ComponentState> implements OnInit, OnChanges {
  @Input() public readonly divId: string = '';
  @Input() public readonly url: string = '';
  @Input() public readonly displayModalLink: boolean = WA_DISPLAY_FULL_SCREEN_DEFAULT;
  @Input() private readonly workspaceUpdate$: Observable<void> = new Observable();

  private dialogFRA: MatDialogRef<FrameModalComponent> | undefined;

  private frameConfigSubject: Subject<FrameConfig> = new Subject<FrameConfig>();

  constructor(
    private readonly shortcutsService: ShortcutsService,
    private readonly sessionsService: SessionsService,
    private readonly dialog: MatDialog,
    private readonly elRef: ElementRef,
    private readonly errorHandler: ErrorHandlerService
  ) {
    super({
      loaded: false,
      error: undefined
    });
  }

  public ngOnInit(): void {
    this.updateComponentState({
      loaded: true
    });
    this.initSubscriptions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    delete changes.workspaceUpdate$;
    if (Object.keys(changes).length > 0) {
      this.frameConfigSubject.next({
        divId: `${this.divId}-modal`,
        url: this.url
      });
      this.refreshIframe();
    }
  }

  public onGoToModal(): void {
    this.openFrameAreaModal();
  }

  private openFrameAreaModal(): void {
    this.shortcutsService.setActive(false);
    const percent = `${MODAL_WIDTH_PERCENT}%`;
    const config: ModalConfig<FrameConfig> = {
      config$: this.frameConfigSubject.asObservable(),
      initConfig: {
        divId: `${this.divId}-modal`,
        url: this.url
      }
    };
    this.dialogFRA = this.dialog.open(FrameModalComponent, {
      ...MAT_DIALOG_CONFIG,
      width: percent,
      maxWidth: percent,
      height: percent,
      data: config
    });
    this.dialogFRA.afterClosed().subscribe({
      next: () => {
        this.shortcutsService.setActive(true);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private refreshIframe(): void {
    const iframe = this.getIframeInDom();
    if (iframe) {
      const src = iframe.src;
      iframe.src = src;
    }
  }

  private initSubscriptions(): void {
    this.workspaceUpdate$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        if (this.dialogFRA) {
          this.dialogFRA.close();
        }
      }
    });
    this.sessionsService.workspaceResizing$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (isResizing) => {
        this.elRef.nativeElement.style.setProperty('--iframe-pointer-events', isResizing ? 'none' : 'auto');
      }
    });
  }

  private getIframeInDomId(): string {
    return `iframe-${this.divId}`;
  }

  private getIframeInDom(): null | HTMLIFrameElement {
    return document.getElementById(this.getIframeInDomId()) as HTMLIFrameElement;
  }
}

export interface FrameConfig {
  divId: string;
  url: string;
}
