import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { INVALID_INTEGER_CHARS, INVALID_NUMBER_CHARS } from '@config/constants';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { DashboardConfigResponse } from '@interfaces/modal-responses';
import { TranslocoService } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LayoutService } from '@services/layout.service';

interface ComponentState {
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-dashboard-config',
  templateUrl: './dashboard-config.component.html',
  styleUrls: ['./dashboard-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardConfigComponent extends StatefulComponentDirective<ComponentState> implements OnInit, AfterViewInit {
  public form = new FormGroup({});
  public model: DashboardWindowConfig | undefined;
  public fields: Array<FormlyFieldConfig> = [];

  constructor(
    private readonly translocoService: TranslocoService,
    private readonly dialogRef: MatDialogRef<DashboardConfigComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: DashboardConfigData,
    private readonly layoutService: LayoutService
  ) {
    super({
      loaded: false,
      error: undefined
    });
  }

  public ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '10vh' });
    this.initModel();
    this.initFields();
    this.updateComponentState({
      loaded: true
    });
  }

  public ngAfterViewInit(): void {
    preventCharInNumberTextfields();
  }

  public onSave(): void {
    this.closeDialog(this.model);
  }

  public onCancel(): void {
    this.closeDialog(undefined);
  }

  private initModel(): void {
    this.model = { ...this.data.dashboardConfig };
  }

  private initFields(): void {
    this.fields = [
      {
        key: 'subTitle2',
        type: 'subTitle',
        props: {
          label: this.translocoService.translate('config.windowSettingsTitle', {}, 'dashboard')
        }
      }
    ];
    /**
     * t(dashboard.config.scaleCheckboxLabel,
        dashboard.config.scaleDescription)
     */
    /*if (this.data.scaleConfig.display) {
      this.fields.push({
        key: 'dashboardZoom',
        type: 'slider',
        props: {
          min: 0,
          max: this.data.scaleConfig.numberSteps,
          step: 1,
          label: this.translocoService.translate('config.scaleCheckboxLabel', {}, 'dashboard'),
          description: this.translocoService.translate('config.scaleDescription', {}, 'dashboard'),
          thumbLabel: false,
          required: true
        }
      });
    }*/
    this.fields.push({
      key: 'highlightWAS',
      type: 'checkbox',
      props: {
        label: this.translocoService.translate('config.highlightWASCheckboxLabel', {}, 'dashboard')
      }
    });
    if (this.layoutService.isDesktop) {
      this.fields.push({
        key: 'fullScreen',
        type: 'checkbox',
        props: {
          label: this.translocoService.translate('config.fullscreenModeCheckboxLabel', {}, 'dashboard')
        }
      });
    }
  }

  private closeDialog(response: DashboardConfigResponse): void {
    this.dialogRef.close(response);
  }
}

const preventCharInNumberTextfields = (): void => {
  const numberInputs = document.querySelectorAll('input[type=number]');

  numberInputs.forEach((input) => {
    input.addEventListener('keypress', (e: Event) => {
      if (INVALID_NUMBER_CHARS.includes((e as KeyboardEvent).key)) {
        e.preventDefault();
      }
    });
  });

  const integerInputs = document.querySelectorAll('.input-integer input');

  integerInputs.forEach((input) => {
    input.addEventListener('keypress', (e: Event) => {
      if (INVALID_INTEGER_CHARS.includes((e as KeyboardEvent).key)) {
        e.preventDefault();
      }
    });
  });
};

export interface DashboardConfigData {
  dashboardConfig: DashboardWindowConfig;
  scaleConfig: {
    display: boolean;
    numberSteps: number;
  };
}

export interface DashboardWindowConfig {
  dashboardZoom: number;
  highlightWAS: boolean;
  fullScreen: boolean;
}
