import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CustomPreloadingStrategy } from '@classes/custom-preloading-strategy';
import { CoreModule } from '@core/core.module';
import { environment } from '@environments/environment';

const initializeKeycloak = (keycloak: KeycloakService) => () =>
  keycloak.init({
    config: {
      url: environment.keycloak.issuer,
      realm: environment.keycloak.realm,
      clientId: environment.keycloak.clientId
    },
    initOptions: {
      onLoad: 'login-required', // Don't use "check-sso" -> bug with home page
      checkLoginIframe: false
    }
  });

const providers: Array<Provider> = [CustomPreloadingStrategy];
if (environment.keycloak.enabled) {
  providers.push({
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService]
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, CoreModule, BrowserAnimationsModule, KeycloakAngularModule, HttpClientModule],
  providers,
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

/*
graph TD
    A[App]
    AR[App routing]
    Ad[Admin]
    AdR[Admin routing]
    C[Core]
    D[Dashboards]
    DR[Dashboards routing]
    K[Keycloak]
    KR[Keycloak routing]
    S[Sessions]
    SR[Sessions routing]
    Sh[Shared]
    ShR[Shared routing]
    ShC[Components]
    ShM[Material]
    W[Workspaces]
    WR[Workspaces routing]
    A --> AR
    A --> C
    Ad --> Sh
    Ad --> AdR
    C --> Sh
    D --> DR
    D --> Sh
    D --> W
    K --> KR
    K --> Sh
    S --> Sh
    S --> SR
    S --> W
    Sh --> ShR
    Sh --> ShM
    Sh --> ShC
    ShC --> ShM
    W --> Sh
    W --> WR

graph TD
    A[App]
    Ad[Admin]
    C[Core]
    D[Dashboards]
    K[Keycloak]
    S[Sessions]
    Sh[Shared]
    ShC[Components]
    ShM[Material]
    W[Workspaces]
    A --> C
    Ad --> Sh
    C --> Sh
    D --> Sh
    D --> W
    K --> Sh
    S --> Sh
    S --> W
    Sh --> ShM
    Sh --> ShC
    ShC --> ShM
    W --> Sh
*/
