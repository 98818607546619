<ng-container *transloco="let t; scope: 'app'; read: 'app.errorDialog'">
  <h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ t('title') }}</h2>
  <mat-dialog-content>
    <p class="mat-body-1">{{ data }}</p>
    <p class="mat-body-1">JSON: {{ data | json }}</p>
  </mat-dialog-content>
</ng-container>
<ng-container *transloco="let t; scope: 'app'; read: 'app.dialog'">
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="primary" title="{{ t('buttonValidateTitle') }}">
      <mat-icon fontIcon="check"></mat-icon>&nbsp;&nbsp;{{ t('ok') }}
    </button>
  </mat-dialog-actions>
</ng-container>