<ng-container *transloco="let t; scope: 'dashboards'; read: 'dashboards.dashboardTreeView'">
	<ng-container *ngIf="state$ | async as state">
		<ng-container *ngIf="state.loaded">
			<ng-container *ngIf="state.dashboard as dashboard">
				<ngx-g2v-title>{{ dashboard.title }}</ngx-g2v-title>
				<ng-container *ngIf="state.actionButtons as actionButtons">
					<ngx-g2v-action-buttons [buttons]="actionButtons" (clickEmitter)="onActionButtonClick($event)">
					</ngx-g2v-action-buttons>
				</ng-container>
				<ng-container *ngIf="state.code as code">
					<ngx-g2v-mermaid-chart [displayEditLink]="false" [displayModalLink]="true" [isZoomable]="true" [chartId]="'dashboard-tree-' + dashboard._id" mermaidType=""
						[codeB64]="code" [maxSize]="state.maxSize" [fullHeight]="true">
					</ngx-g2v-mermaid-chart>
				</ng-container>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="!state.loaded">
			<ngx-g2v-progress-spinner></ngx-g2v-progress-spinner>
		</ng-container>
		<ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
	</ng-container>
</ng-container>