import * as d3 from 'd3';
import mermaid from 'mermaid';
import { Observable, Subject, takeUntil } from 'rxjs';

import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MermaidChartModalComponent } from '@components/mermaid-chart/mermaid-chart-modal/mermaid-chart-modal.component';
import { DELAY_RESIZE_EVENT, DELAY_SECOND_RESIZE_EVENT, MAT_DIALOG_CONFIG, MERMAID_REFRESH_INTERVAL_TIME } from '@config/constants';
import { b64ToUtf8, getWindowHeight, utf8ToB64 } from '@config/utils';
import { debounce } from '@decorators/debounce.decorator';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { WA_DISPLAY_FULL_SCREEN_DEFAULT } from '@g2view/g2view-commons';
import { ModalConfig } from '@interfaces/modal-config';
import { DialogService } from '@services/dialog.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ShortcutsService } from '@services/shortcuts.service';
import { VisibilityService } from '@services/visibility.service';

export type MermaidType = 'classDiagram' | 'sequenceDiagram' | 'pie' | '';
const PADDING_PX = 16;
const MODAL_WIDTH_PERCENT = 95;
const SMALL_TIMEOUT_MS = 10; // This timeout is needed when a MERA is updated via a WS update to let the time for the div to be re-created.

interface ComponentState {
  maxHeight: number;
  editLink: string;
  codeB64: string;
  maxSize: boolean;
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-mermaid-chart',
  templateUrl: './mermaid-chart.component.html',
  styleUrls: ['./mermaid-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MermaidChartComponent extends StatefulComponentDirective<ComponentState> implements OnInit, OnChanges {
  @Input() public readonly chartId: string = '';
  @Input() public displayEditLink = false;
  @Input() public isZoomable = false;
  @Input() public codeB64 = '';
  @Input() public maxSize = false;
  @Input() public readonly displayModalLink: boolean = WA_DISPLAY_FULL_SCREEN_DEFAULT;
  @Input() public readonly fullHeight: boolean = false;
  @Input() public readonly styleHeight: string = '100%';
  @Input() private readonly mermaidType: MermaidType = 'classDiagram';
  @Input() private readonly backgroundColor: string = '#ebebeb';
  @Input() private readonly isModal: boolean = false;
  @Input() private readonly workspaceUpdate$: Observable<void> = new Observable();

  private dialogMERA: MatDialogRef<MermaidChartModalComponent> | undefined;

  private mermaidRef: ElementRef | undefined;
  private mermaidConfigSubject: Subject<MermaidConfig> = new Subject<MermaidConfig>();

  private timeoutInit: NodeJS.Timeout | undefined;
  private timeoutInitFirstRefresh: NodeJS.Timeout | undefined;
  private resizeTimeout: NodeJS.Timeout | undefined;
  private resizeSecondTimeout: NodeJS.Timeout | undefined;

  constructor(
    public readonly visibilityService: VisibilityService,
    private readonly errorHandler: ErrorHandlerService,
    private readonly shortcutsService: ShortcutsService,
    private readonly dialogService: DialogService,
    private readonly dialog: MatDialog
  ) {
    super({
      maxHeight: 100,
      editLink: '',
      codeB64: '',
      maxSize: true,
      loaded: false,
      error: undefined
    });
  }

  @ViewChild('mermaidDiv') set content(content: ElementRef) {
    if (content) {
      this.mermaidRef = content;
      this.mermaidRef.nativeElement.style.setProperty('--background-color', this.backgroundColor);
      this.launchRefreshMaxHeight();
    }
  }

  @HostListener('window:resize', ['$event'])
  @debounce(100)
  private onResize(): void {
    this.launchRefreshMaxHeight();
  }

  @HostListener('window:orientationchange', ['$event']) private onOrientationChange(): void {
    this.launchRefreshMaxHeight();
  }

  public ngOnInit(): void {
    this.updateComponentState({
      editLink: this.displayEditLink ? this.getEditLink() : '',
      loaded: true
    });
    this.initVisibilitySub();
    this.initSubscriptions();
    this.initUnsub();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    delete changes.workspaceUpdate$;
    if (Object.keys(changes).length > 0) {
      const toUpdate: Partial<ComponentState> = {};
      if (changes.maxSize) {
        toUpdate.maxSize = changes.maxSize.currentValue;
      }
      if (changes.codeB64) {
        toUpdate.codeB64 = changes.codeB64.currentValue;
      }
      if (changes.editLink) {
        toUpdate.editLink = changes.editLink.currentValue;
      }
      if (changes.maxHeight) {
        toUpdate.maxHeight = changes.maxHeight.currentValue;
      }
      this.updateComponentState(toUpdate);
      if (this.mermaidRef) {
        this.mermaidRef.nativeElement.style.setProperty('--background-color', this.backgroundColor);
      }
      this.mermaidConfigSubject.next({
        codeB64: this.state.codeB64,
        chartId: `${this.chartId}-modal`,
        mermaidType: this.mermaidType,
        isZoomable: this.isZoomable,
        backgroundColor: this.backgroundColor
      });
      this.refreshMermaid();
    }
  }

  public onGoToModal(): void {
    this.openMermaidAreaModal();
  }

  private openMermaidAreaModal(): void {
    this.shortcutsService.setActive(false);
    const percent = `${MODAL_WIDTH_PERCENT}%`;
    const config: ModalConfig<MermaidConfig> = {
      config$: this.mermaidConfigSubject.asObservable(),
      initConfig: {
        codeB64: this.state.codeB64,
        chartId: `${this.chartId}-modal`,
        mermaidType: this.mermaidType,
        isZoomable: this.isZoomable,
        backgroundColor: this.backgroundColor
      }
    };
    this.dialogMERA = this.dialog.open(MermaidChartModalComponent, {
      ...MAT_DIALOG_CONFIG,
      width: percent,
      maxWidth: percent,
      height: percent,
      data: config
    });
    this.dialogService.setDialogRef(this.dialogMERA);
    this.dialogMERA.afterClosed().subscribe({
      next: () => {
        this.shortcutsService.setActive(true);
        this.dialogService.setDialogRef(undefined);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private refreshMermaid(): void {
    if (this.issetMermaidInDOM() && this.getMermaidDOMWidth() > 0) {
      if (this.timeoutInitFirstRefresh) {
        clearTimeout(this.timeoutInitFirstRefresh);
      }
      setTimeout(() => {
        this.renderMermaid();
        this.refreshMaxHeight();
      }, SMALL_TIMEOUT_MS);
    } else {
      if (this.timeoutInitFirstRefresh) {
        clearTimeout(this.timeoutInitFirstRefresh);
      }
      this.timeoutInitFirstRefresh = setTimeout(() => {
        this.refreshMermaid();
      }, MERMAID_REFRESH_INTERVAL_TIME);
    }
  }

  private renderMermaid(): void {
    const element = document.getElementById(this.getMermaidInDomId());
    const dataUtf8 = b64ToUtf8(this.state.codeB64);
    const isPie =
      dataUtf8
        .replace(/%%(.*)%%/, '')
        .replace(/(?:\r\n|\r|\n)/g, '')
        .startsWith('pie ') || this.mermaidType === 'pie';
    if (element) {
      mermaid
        .render(
          this.getMermaidSVGInDomId(),
          this.mermaidType
            ? `${this.mermaidType}
${dataUtf8}`
            : dataUtf8
        )
        .then(({ svg, bindFunctions }: { svg: string; bindFunctions: (arg0: HTMLElement | null) => void }) => {
          element.innerHTML = svg;
          bindFunctions?.(element);
          const svgElement = document.getElementById(this.getMermaidSVGInDomId());
          if (svgElement) {
            svgElement.style.margin = 'auto';
            svgElement.style.maxHeight = '100%';
            svgElement.style.maxWidth = '100%';
            if (this.state.maxSize) {
              svgElement.style.height = '100%';
              svgElement.style.width = '100%';
              svgElement.setAttribute('height', '100%');
              svgElement.setAttribute('width', '100%');
            }
            if (isPie) {
              const svgG = svgElement.getElementsByTagName('g');
              const maxGSize = { width: 0, height: 0 };
              let gIndexMax = 0;
              for (let i = 0; i < svgG.length; i++) {
                const item = svgG.item(i);
                if (item) {
                  const size = item.getBoundingClientRect();
                  if (size.width > maxGSize.width && size.height > maxGSize.height) {
                    maxGSize.width = size.width;
                    maxGSize.height = size.height;
                    gIndexMax = i;
                  }
                }
              }
              const ratio = maxGSize.width / maxGSize.height;
              const svgPath = svgElement.getElementsByTagName('path');
              const maxPathSize = { width: 0, height: 0 };
              for (let i = 0; i < svgPath.length; i++) {
                const item = svgPath.item(i);
                if (item) {
                  const size = item.getBoundingClientRect();
                  if (size.width > maxPathSize.width && size.height > maxPathSize.height) {
                    maxPathSize.width = size.width;
                    maxPathSize.height = size.height;
                  }
                }
              }
              const pieWidth = 450;
              const viewBox = `0 0 ${pieWidth * ratio} ${pieWidth}`;
              const transform = `translate(${(pieWidth * ratio - (maxGSize.width - maxPathSize.width)) / 2},${pieWidth / 2})`;
              svgElement.setAttribute('viewBox', viewBox);
              const maxGItem = svgG.item(gIndexMax);
              if (maxGItem) {
                maxGItem.setAttribute('transform', transform);
              }
            }
            if (this.isZoomable) {
              const svgs = d3.selectAll('.mermaid-div svg');
              svgs.each(function () {
                const svg = d3.select(this);
                const inner = svg.select('g');
                const zoom = d3.zoom().on('zoom', (event: any) => {
                  inner.attr('transform', event.transform);
                });
                svg.call(zoom as any);
              });
            }
          }
        });
    }
  }

  private initVisibilitySub(): void {
    this.visibilityService.pageVisible.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.launchRefreshMaxHeight();
      }
    });
  }

  private initSubscriptions(): void {
    this.workspaceUpdate$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        if (this.dialogMERA) {
          this.dialogMERA.close();
        }
      }
    });
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.timeoutInit) {
          clearTimeout(this.timeoutInit);
        }
        if (this.timeoutInitFirstRefresh) {
          clearTimeout(this.timeoutInitFirstRefresh);
        }
        if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
        }
        if (this.resizeSecondTimeout) {
          clearTimeout(this.resizeSecondTimeout);
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private getEditLink(): string {
    const b64 = utf8ToB64(
      `{"code":"${this.mermaidType}\\n${b64ToUtf8(this.state.codeB64)
        .replace(/"/g, '\\"')
        .replace(/[\n\r]/g, '\\n')}","mermaid":{},"updateEditor":false}`
    );
    if (b64) {
      return b64 ? 'https://mermaid-js.github.io/mermaid-live-editor/#/edit/' + b64.replace(/\+/g, '-').replace(/\//g, '_') : '';
    } else {
      this.displayEditLink = false;
      return '';
    }
  }

  private getMermaidInDomId(): string {
    return `mermaid-${this.chartId}`;
  }

  private getMermaidSVGInDomId(): string {
    return `${this.getMermaidInDomId()}-SVG`;
  }

  private issetMermaidInDOM(): boolean {
    return document.getElementById(this.getMermaidInDomId()) ? true : false;
  }

  private getMermaidDOMWidth(): number {
    if (this.issetMermaidInDOM()) {
      const element = document.getElementById(this.getMermaidInDomId());
      if (element) {
        return element.clientWidth;
      }
    }
    return 0;
  }

  private launchRefreshMaxHeight(): void {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    if (this.resizeSecondTimeout) {
      clearTimeout(this.resizeSecondTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.refreshMaxHeight();
    }, DELAY_RESIZE_EVENT);
    this.resizeSecondTimeout = setTimeout(() => {
      this.refreshMaxHeight();
    }, DELAY_SECOND_RESIZE_EVENT);
  }

  private refreshMaxHeight(): void {
    const maxHeight = this.getMaxHeight();
    if (this.fullHeight) {
      this.updateComponentState({ maxHeight });
    }
  }

  private getMaxHeight(): number {
    const top = this.mermaidRef ? this.mermaidRef.nativeElement.getBoundingClientRect().top : 0;
    const screenHeight = getWindowHeight();
    return this.isModal ? (screenHeight * MODAL_WIDTH_PERCENT) / 100 : screenHeight - top - PADDING_PX;
  }
}

export interface MermaidConfig {
  codeB64: string;
  chartId: string;
  mermaidType: MermaidType;
  isZoomable: boolean;
  backgroundColor: string;
}
