import { Injectable } from '@angular/core';
import { STATE_G2VIEW_CORE } from '@config/constants';
import { SetDevMode } from '@core/state/core.actions';
import { UserConfig } from '@core/state/core.state';
import { Store } from '@ngxs/store';
import { CacheService } from '@services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class UserConfigService {
  constructor(private readonly cacheService: CacheService, private readonly store: Store) {}

  get isDevMode(): boolean {
    return this.store.selectSnapshot<UserConfig>((state) => state[STATE_G2VIEW_CORE].userConfig).devMode;
  }

  public toggleDevMode(): void {
    this.setDevMode(!this.isDevMode);
  }

  public setDevMode(devMode: boolean): void {
    this.cacheService.updateConfigItem('user', '', 'devMode', devMode);
    this.store.dispatch(new SetDevMode(devMode));
  }
}
