import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'ngx-g2v-dialog-actions',
  templateUrl: './dialog-actions.component.html',
  styleUrls: ['./dialog-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogActionsComponent {
  @Input() public formValid = false;
  @Input() public enabledEnter = false;

  @Output() private readonly validateEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() private readonly cancelEmitter: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('window:keyup', ['$event'])
  private onKeyUp(keyboardEvent: KeyboardEvent) {
    if (this.enabledEnter && keyboardEvent.key === 'Enter') {
      this.onValidate();
    } else if (keyboardEvent.key === 'Escape') {
      this.onCancel();
    }
  }

  public onValidate(): void {
    this.validateEmitter.emit();
  }

  public onCancel(): void {
    this.cancelEmitter.emit();
  }
}
