<ng-container *transloco="let t; scope: 'app'; read: 'app.messageModal'">
  <h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ t('title') }}</h2>
  <div mat-dialog-content>
    <p [innerHTML]="message | safe: 'html'"></p>
  </div>
</ng-container>
<ng-container *transloco="let t; scope: 'app'; read: 'app.dialog'">
  <div mat-dialog-actions>
    <button mat-button (click)="onConfirm()" cdkFocusInitial title="{{ t('buttonValidateTitle') }}">
      <mat-icon fontIcon="check"></mat-icon>&nbsp;&nbsp;{{ t('ok') }}
    </button>
  </div>
</ng-container>