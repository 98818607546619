<ng-container *ngIf="state$ | async as state">
  <ng-container *ngIf="state.loaded">
    <div mat-dialog-content>
      <form [formGroup]="form">
        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      </form>
    </div>
  </ng-container>
  <ng-container *ngIf="!state.loaded">
    <ngx-g2v-progress-spinner></ngx-g2v-progress-spinner>
  </ng-container>
  <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
</ng-container>

<ngx-g2v-dialog-actions [formValid]="form.valid" (cancelEmitter)="onCancel()" (validateEmitter)="onSave()"></ngx-g2v-dialog-actions>