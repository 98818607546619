import { Observable } from 'rxjs';

import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import { FrameSwitchUuid } from '@g2view/g2view-commons';
import { ContextService } from '@services/context.service';

@Injectable({
  providedIn: 'root'
})
export class DbasService implements OnDestroy {
  private readonly lastWSOfFrames: Map<string, string> = new Map();
  private readonly clickEmitter: EventEmitter<DBAClickEvent> = new EventEmitter<DBAClickEvent>();
  private readonly activityEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  private timer: NodeJS.Timeout | undefined;

  constructor(private readonly contextService: ContextService) {
    this.launchInactivityTimer();
  }

  get clickObservable$(): Observable<DBAClickEvent> {
    return this.clickEmitter.asObservable();
  }

  get activityObservable$(): Observable<boolean> {
    return this.activityEmitter.asObservable();
  }

  public ngOnDestroy(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  public emitClick(event: DBAClickEvent): void {
    event.framesSwitchUuid.forEach((frameSwitchUuid) => {
      this.lastWSOfFrames.set(frameSwitchUuid.frameId, frameSwitchUuid.uuid);
    });
    this.clickEmitter.emit(event);
    this.resetTimer();
  }

  public triggerActivity(): void {
    this.resetTimer();
  }

  public getLastWSOfFrame(frameId: string): string | undefined {
    return this.lastWSOfFrames.get(frameId);
  }

  public resetLastWSOfFrames(): void {
    this.lastWSOfFrames.clear();
  }

  private resetTimer(): void {
    this.clearInactivityTimer();
    this.launchInactivityTimer();
  }

  private clearInactivityTimer(): void {
    this.activityEmitter.emit(true);
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  private launchInactivityTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    let inactivityTime = 0;
    const context = this.contextService.getContext();
    if (
      context.dashboard &&
      context.dashboard._id &&
      (context.dashboard.inactivityTimeoutMs || context.dashboard.inactivityTimeoutMs === 0) &&
      context.dashboard.inactivityTimeoutMs > -1
    ) {
      inactivityTime = context.dashboard.inactivityTimeoutMs;
    } else {
      inactivityTime = environment.settings.dashboard.inactivityTime;
    }
    if (inactivityTime > 0) {
      this.timer = setTimeout(() => {
        this.activityEmitter.emit(false);
      }, inactivityTime);
    }
  }
}

export interface DBAClickEvent {
  targetView: string;
  framesSwitchUuid: Array<FrameSwitchUuid>;
}
