import { DeviceDetectorService } from 'ngx-device-detector';

import { Injectable } from '@angular/core';
import { SHORTCUT_FULLSCREEN } from '@config/constants';
import { SetBackgroundColor } from '@core/state/core.actions';
import { RxJSBaseDirectiveWithServices, Shortcut, WithShortcuts } from '@mixins/shortcuts.mixin';
import { TranslocoScope } from '@ngneat/transloco';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class LayoutService extends WithShortcuts(RxJSBaseDirectiveWithServices) {
  private readonly _isDesktop: boolean;

  constructor(private readonly store: Store, private readonly deviceService: DeviceDetectorService) {
    super();
    this._isDesktop = this.deviceService.isDesktop();
  }

  get isDesktop(): boolean {
    return this._isDesktop;
  }

  get isFullScreen(): boolean {
    const fsDoc = document as FsDocument;

    return !!(
      (fsDoc.fullscreenElement !== undefined && fsDoc.fullscreenElement !== null) ||
      (fsDoc.mozFullScreenElement !== undefined && fsDoc.mozFullScreenElement !== null) ||
      (fsDoc.webkitFullscreenElement !== undefined && fsDoc.webkitFullscreenElement !== null) ||
      (fsDoc.msFullscreenElement !== undefined && fsDoc.msFullscreenElement !== null)
    );
  }

  public setBackgroundColor(color: string): void {
    this.store.dispatch(new SetBackgroundColor(color));
  }

  public toggleFullScreen(): void {
    const fsDoc = document as FsDocument;

    const isFullScreen = this.isFullScreen;

    if (!isFullScreen) {
      const fsDocElem = document.documentElement as FsDocumentElement;

      if (fsDocElem.requestFullscreen !== undefined && fsDocElem.requestFullscreen !== null) {
        fsDocElem.requestFullscreen().catch(this.errorHandler.handleError);
      } else if (fsDocElem.msRequestFullscreen) {
        fsDocElem.msRequestFullscreen();
      } else if (fsDocElem.mozRequestFullScreen) {
        fsDocElem.mozRequestFullScreen();
      } else if (fsDocElem.webkitRequestFullscreen !== undefined && fsDocElem.webkitRequestFullscreen !== null) {
        fsDocElem.webkitRequestFullscreen();
      }
    } else if (fsDoc.exitFullscreen !== undefined && fsDoc.exitFullscreen !== null) {
      fsDoc.exitFullscreen().catch(this.errorHandler.handleError);
    } else if (fsDoc.msExitFullscreen) {
      fsDoc.msExitFullscreen();
    } else if (fsDoc.mozCancelFullScreen) {
      fsDoc.mozCancelFullScreen();
    } else if (fsDoc.webkitExitFullscreen) {
      fsDoc.webkitExitFullscreen();
    }
  }

  protected getInitShortcuts(): { shortcuts: Array<Shortcut>; scope: TranslocoScope } {
    /**
     * t(app.shortcuts.layoutGroupTitle, app.shortcuts.fullScreenShortcut)
     */
    return {
      shortcuts: [
        {
          keys: SHORTCUT_FULLSCREEN,
          group: 'shortcuts.layoutGroupTitle',
          description: 'shortcuts.fullScreenShortcut',
          callback: this.toggleFullScreen
        }
      ],
      scope: 'app'
    };
  }
}

interface FsDocument extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
  fullscreenElement: Element;
  msExitFullscreen?(): void;
  mozCancelFullScreen?(): void;
  webkitExitFullscreen?(): void;
}

interface FsDocumentElement extends HTMLElement {
  msRequestFullscreen?(): void;
  mozRequestFullScreen?(): void;
  webkitRequestFullscreen(): void;
}
