import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionButtonConfig, EventEmitted } from '@components/action-buttons/action-buttons.component';
import { DASHBOARD_PATH, DASHBOARDS_PATH, SHORTCUT_DASHBOARD_TREE_VIEW_EXIT } from '@config/constants';
import { utf8ToB64 } from '@config/utils';
import { dashboardIdPathParam, MAX_TIME_TO_LOAD, TIME_BETWEEN_LOAD_TEST } from '@dashboard/components/dashboard/dashboard.component';
import { DashboardsService, dashboardToMermaid } from '@dashboards/services/dashboards.service';
import { UpdateCurrentDashboard } from '@dashboards/state/dashboards.actions';
import { Dashboard } from '@g2view/g2view-commons';
import { Shortcut, StatefulComponentDirectiveWithShortcutsServices, WithShortcuts } from '@mixins/shortcuts.mixin';
import { TranslocoScope } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { CacheService } from '@services/cache.service';
import { PageTitleService } from '@services/page-title.service';
import { MermaidAreaService } from '@workspaces/services/mermaid-area.service';

interface ComponentState {
  dashboard: Dashboard | undefined;
  actionButtons: Array<ActionButtonConfig> | undefined;
  code: string;
  maxSize: boolean;
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-dashboard-tree-view',
  templateUrl: './dashboard-tree-view.component.html',
  styleUrls: ['./dashboard-tree-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardTreeViewComponent extends WithShortcuts(StatefulComponentDirectiveWithShortcutsServices)<ComponentState> implements OnInit {
  private TDDirection = true;
  private includeLoop = true;
  private readonly dashboardId: string;

  private timeInit = 0;
  private timeoutInit: NodeJS.Timeout | undefined;

  constructor(
    private readonly dashboardsService: DashboardsService,
    private readonly mermaidAreaService: MermaidAreaService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly cacheService: CacheService,
    private readonly pageTitleService: PageTitleService,
    private readonly route: ActivatedRoute
  ) {
    super({
      dashboard: new Dashboard(),
      actionButtons: [],
      code: '',
      maxSize: true,
      loaded: false,
      error: undefined
    });
    this.dashboardId = this.route.snapshot.paramMap.get(dashboardIdPathParam) ?? '';
    this.initTogglesFromCache();
  }

  public ngOnInit(): void {
    this.initSub();
    this.initUnsub();
  }

  public onActionButtonClick(event: EventEmitted): void {
    const { id } = event;
    if (id === 'on_exit') {
      this.onExit();
    } else if (id === 'switch_loop') {
      this.switchLoop();
    } else if (id === 'switch_direction') {
      this.switchDirection();
    } else if (id === 'switch_max_size') {
      this.switchMaxSize();
    }
  }

  protected getInitShortcuts(): { shortcuts: Array<Shortcut>; scope: TranslocoScope } {
    /**
     * t(dashboards.dashboardTreeView.help.dashboardShortcutsTitle, dashboards.dashboardTreeView.help.exitShortcut)
     */
    return {
      shortcuts: [
        {
          keys: SHORTCUT_DASHBOARD_TREE_VIEW_EXIT,
          group: 'dashboardTreeView.help.dashboardShortcutsTitle',
          description: 'dashboardTreeView.help.exitShortcut',
          callback: this.onExit
        }
      ],
      scope: 'dashboards'
    };
  }

  private initTogglesFromCache(): void {
    this.TDDirection = this.cacheService.getConfigItem('dashboardTreeView', this.dashboardId, 'treeViewDirectionToggle');
    this.includeLoop = this.cacheService.getConfigItem('dashboardTreeView', this.dashboardId, 'treeViewLoopToggle');
    const maxSize = this.cacheService.getConfigItem('dashboardTreeView', this.dashboardId, 'treeViewMaxSizeToggle');
    if (!maxSize) {
      this.updateComponentState({
        maxSize: false
      });
    }
  }

  private goToDashboardView(viewId: string): void {
    this.router.navigate([DASHBOARD_PATH, this.dashboardId, viewId]).catch(this.errorHandler.handleError);
  }

  private onExit(): void {
    this.router.navigate([DASHBOARDS_PATH]).catch(this.errorHandler.handleError);
  }

  private switchLoop(): void {
    this.includeLoop = !this.includeLoop;
    this.refreshMermaid();
  }

  private switchDirection(): void {
    this.TDDirection = !this.TDDirection;
    this.refreshMermaid();
  }

  private switchMaxSize(): void {
    this.updateComponentState({
      maxSize: !this.state.maxSize
    });
  }

  private initSub(): void {
    this.timeInit = this.timeInit === 0 ? new Date().getTime() : this.timeInit;
    if (new Date().getTime() - this.timeInit > MAX_TIME_TO_LOAD) {
      this.onExit();
    }
    if (this.dashboardsService.areDashboardsInitialized) {
      const dashboard = this.dashboardsService.getDashboardInStore(this.dashboardId);
      if (dashboard) {
        this.dashboardsService.checkIfAllDBAsOfDashboardAreConfigured(dashboard).then((dbasMissing) => {
          console.log('Debug -------------------------------------------------------------------------------------------------Debug');
          console.log('Debug ~ file: dashboard-tree-view.component.ts:143 ~ DashboardTreeViewComponent ~ dbasMissing:', dbasMissing);
          console.log('Debug -------------------------------------------------------------------------------------------------Debug');
        });
        this.store.dispatch(new UpdateCurrentDashboard(dashboard, dashboard.views.length > 0 ? dashboard.views[0].id : ''));
        this.updateComponentState({
          dashboard
        });
        this.initTranslations();
      } else {
        this.onExit();
      }
      this.mermaidAreaService.mermaidTreeViewClicked$.pipe(takeUntil(this.destroy$)).subscribe({
        next: (nodeId) => {
          this.goToDashboardView(nodeId);
        }
      });
    } else {
      if (this.timeoutInit) {
        clearTimeout(this.timeoutInit);
      }
      this.timeoutInit = setTimeout(() => {
        this.initSub();
      }, TIME_BETWEEN_LOAD_TEST);
    }
  }

  private refreshMermaid(): void {
    const { code, hasLoop } = dashboardToMermaid(this.state.dashboard ?? new Dashboard(), this.TDDirection ? 'TD' : 'LR', this.includeLoop);
    if (!hasLoop) {
      const actionButtons = this.state.actionButtons ? [...this.state.actionButtons] : [];
      const loopButtonIndex = actionButtons.findIndex((ab) => ab.id === 'switch_loop');
      if (loopButtonIndex > -1) {
        const loopButton = actionButtons[loopButtonIndex];
        if (loopButton.type === 'button') {
          loopButton.isDisabled = true;
        }
        actionButtons[loopButtonIndex] = loopButton;
        this.updateComponentState({
          actionButtons
        });
      }
    }
    this.updateComponentState({ code: utf8ToB64(code), loaded: true });
  }

  private initTranslations(): void {
    /**
     * t(dashboards.dashboardTreeView.pageTitle,
          dashboards.dashboardTreeView.buttonSwitchDirectionOn,
          dashboards.dashboardTreeView.buttonSwitchDirectionOff,
          dashboards.dashboardTreeView.buttonSwitchDirectionTitle,
          dashboards.dashboardTreeView.buttonSwitchLoopOn,
          dashboards.dashboardTreeView.buttonSwitchLoopOff,
          dashboards.dashboardTreeView.buttonSwitchLoopTitle,
          dashboards.dashboardTreeView.buttonSwitchMaxSizeOn,
          dashboards.dashboardTreeView.buttonSwitchMaxSizeOff,
          dashboards.dashboardTreeView.buttonSwitchMaxSizeTitle,
          dashboards.dashboardTreeView.buttonExitLabel,
          dashboards.dashboardTreeView.buttonExitTitle)
     */
    this.translocoService
      .selectTranslate(
        [
          'dashboardTreeView.pageTitle',
          'dashboardTreeView.buttonSwitchDirectionOn',
          'dashboardTreeView.buttonSwitchDirectionOff',
          'dashboardTreeView.buttonSwitchDirectionTitle',
          'dashboardTreeView.buttonSwitchLoopOn',
          'dashboardTreeView.buttonSwitchLoopOff',
          'dashboardTreeView.buttonSwitchLoopTitle',
          'dashboardTreeView.buttonSwitchMaxSizeOn',
          'dashboardTreeView.buttonSwitchMaxSizeOff',
          'dashboardTreeView.buttonSwitchMaxSizeTitle',
          'dashboardTreeView.buttonExitLabel',
          'dashboardTreeView.buttonExitTitle'
        ],
        {},
        'dashboards'
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (translations: Array<string>) => {
          if (this.state.dashboard) {
            this.pageTitleService.setTitle([translations[0], this.state.dashboard.title]);
          }
          const dashboardId = this.state.dashboard?._id ?? '';
          const actionButtons: Array<ActionButtonConfig> = [
            {
              id: 'switch_direction',
              type: 'toggleButtonCached',
              icon: 'loop',
              label: translations[1],
              label2: translations[2],
              title: translations[3],
              color: 'accent',
              color2: 'primary',
              cacheType: 'dashboardTreeView',
              cacheId: dashboardId,
              cacheKey: 'treeViewDirectionToggle'
            },
            {
              id: 'switch_loop',
              type: 'toggleButtonCached',
              icon: '360',
              label: translations[4],
              label2: translations[5],
              title: translations[6],
              color: 'accent',
              color2: 'primary',
              cacheType: 'dashboardTreeView',
              cacheId: dashboardId,
              cacheKey: 'treeViewLoopToggle'
            },
            /*{
              id: 'switch_max_size',
              type: 'toggleButtonCached',
              icon: 'fullscreen',
              label: translations[7],
              label2: translations[8],
              title: translations[9],
              color: 'accent',
              color2: 'primary',
              cacheType: 'dashboardTreeView',
              cacheId: dashboardId,
              cacheKey: 'treeViewMaxSizeToggle'
            },*/
            {
              id: 'on_exit',
              type: 'button',
              icon: 'cancel',
              label: translations[10],
              title: translations[11],
              color: 'warn'
            }
          ];
          this.updateComponentState({ actionButtons });
          this.refreshMermaid();
        },
        error: (err: unknown) => this.errorHandler.handleError(err)
      });
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.timeoutInit) {
          clearTimeout(this.timeoutInit);
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }
}

export interface DashboardTreeViewStorageConfig {
  treeViewDirectionToggle: boolean;
  treeViewLoopToggle: boolean;
  treeViewMaxSizeToggle: boolean;
}

export const DEFAULT_DASHBOARD_TREE_VIEW_CONFIG: DashboardTreeViewStorageConfig = {
  treeViewDirectionToggle: false,
  treeViewLoopToggle: false,
  treeViewMaxSizeToggle: true
};
