import { Injectable } from '@angular/core';
import { STATE_G2VIEW_DASHBOARDS, STATE_G2VIEW_SESSIONS } from '@config/constants';
import { Position } from '@core/state/core.actions';
import { Dashboard, Session } from '@g2view/g2view-commons';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  constructor(private readonly store: Store) {}

  public getContext(): ContextModel {
    return {
      session: this.store.selectSnapshot<Session>((state) =>
        state[STATE_G2VIEW_SESSIONS] && state[STATE_G2VIEW_SESSIONS].currentSession ? state[STATE_G2VIEW_SESSIONS].currentSession : new Session()
      ),
      dragPosition: this.store.selectSnapshot<Position>((state) =>
        state[STATE_G2VIEW_SESSIONS] && state[STATE_G2VIEW_SESSIONS].currentDragPosition
          ? state[STATE_G2VIEW_SESSIONS].currentDragPosition
          : new Position()
      ),
      dashboard: this.store.selectSnapshot<Dashboard>((state) =>
        state[STATE_G2VIEW_DASHBOARDS] && state[STATE_G2VIEW_DASHBOARDS].currentDashboard
          ? state[STATE_G2VIEW_DASHBOARDS].currentDashboard
          : new Dashboard()
      ),
      currentViewId: this.store.selectSnapshot<string>((state) =>
        state[STATE_G2VIEW_DASHBOARDS] && state[STATE_G2VIEW_DASHBOARDS].currentViewId ? state[STATE_G2VIEW_DASHBOARDS].currentViewId : ''
      )
    };
  }
}

interface ContextModel {
  session: Session | undefined;
  dragPosition: Position;
  dashboard: Dashboard | undefined;
  currentViewId: string;
}
