import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { BEV_IMG_FOLDER, DELAY_RESIZE_EVENT, DELAY_SECOND_RESIZE_EVENT } from '@config/constants';
import { b64ToUtf8, getWindowHeight, getWindowWidth } from '@config/utils';
import { Position } from '@core/state/core.actions';
import { debounce } from '@decorators/debounce.decorator';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { ChartArea, ImageArea, MarkmapArea, MermaidArea, Session, TableArea, TimelineArea, WorkspaceDB } from '@g2view/g2view-commons';
import { Select } from '@ngxs/store';
import { ErrorHandlerService } from '@services/error-handler.service';
import { UserService } from '@services/user.service';
import { VisibilityService } from '@services/visibility.service';
import { MovingOffset } from '@workspaces/components/workspace/workspace.component';
import { WorkspacesState } from '@workspaces/state/workspaces.state';

const WORKSPACE_HANDLE_HEIGHT = 37;
const INIT_BEV_WIDTH = 300;
const INIT_BEV_HEIGHT = 300;
const INTERVAL_BEV_DRAG_MS = 10;
export const BEV_BORDER_STYLE = 'solid';
export const BEV_BORDER_COLOR = 'gray';
export const BEV_BORDER_WIDTH = 2;
export const BEV_MARGIN = 0;

interface ComponentState {
  visible: boolean;
  global: Rectangle;
  workspaces: Array<WorkspaceDimension> | null;
  viewerDimension: ViewerDimension;
  viewerPosition: ViewerPosition;
  scale: Scale;
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-bev',
  templateUrl: './bev.component.html',
  styleUrls: ['./bev.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BevComponent extends StatefulComponentDirective<ComponentState> implements OnInit {
  @Select(WorkspacesState.SelectWorkspaces) public workspaces$: Observable<Array<WorkspaceDB>> | undefined;
  @Select(WorkspacesState.SelectChartAreas) public cas$: Observable<Array<ChartArea>> | undefined;
  @Select(WorkspacesState.SelectImageAreas) public imas$: Observable<Array<ImageArea>> | undefined;
  @Select(WorkspacesState.SelectMarkmapAreas) public maras$: Observable<Array<MarkmapArea>> | undefined;
  @Select(WorkspacesState.SelectMermaidAreas) public meras$: Observable<Array<MermaidArea>> | undefined;
  @Select(WorkspacesState.SelectTableAreas) public tabas$: Observable<Array<TableArea>> | undefined;
  @Select(WorkspacesState.SelectTimelineAreas) public timas$: Observable<Array<TimelineArea>> | undefined;

  @Output() private readonly positionEmitter: EventEmitter<PositionEvent> = new EventEmitter<PositionEvent>();
  @Output() private readonly visibilityEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('style.width.px') private width = INIT_BEV_WIDTH;
  @HostBinding('style.height.px') private height = INIT_BEV_HEIGHT;
  @HostBinding('style.borderStyle') private readonly borderStyle = BEV_BORDER_STYLE;
  @HostBinding('style.borderColor') private readonly borderColor = BEV_BORDER_COLOR;
  @HostBinding('style.borderWidth.px') private borderSize = BEV_BORDER_WIDTH;
  @HostBinding('style.bottom.px') private readonly bottom = BEV_MARGIN;
  @HostBinding('style.right.px') private readonly right = BEV_MARGIN;

  private readonly hideCAS = true;
  private readonly hideIMAS = true;
  private readonly hideMARAS = true;
  private readonly hideMERAS = true;
  private readonly hideTABAS = true;
  private readonly hideTIMAS = true;

  private workspaces: Array<WorkspaceDB> = [];
  private cas: Array<ChartArea> = [];
  private imas: Array<ImageArea> = [];
  private maras: Array<MarkmapArea> = [];
  private meras: Array<MermaidArea> = [];
  private tabas: Array<TableArea> = [];
  private timas: Array<TimelineArea> = [];
  private canvas: Rectangle = rectangleInit;
  private position: Rectangle = rectangleInit;
  private sessionBarHeight = 0;
  private bevConfig: BEVConfig = bevConfigDefault;
  private session: Session = new Session();
  private sessionScale = 1;
  private innerWidth = 0;
  private innerHeight = 0;

  private lastTimeResizeUpdate = 0;
  private previousEventDragX = 0;
  private previousEventDragY = 0;
  private resizeTimeout: NodeJS.Timeout | undefined;
  private resizeSecondTimeout: NodeJS.Timeout | undefined;

  constructor(
    private readonly errorHandler: ErrorHandlerService,
    private readonly visibilityService: VisibilityService,
    private readonly userService: UserService
  ) {
    super({
      visible: false,
      global: rectangleInit,
      workspaces: [],
      viewerDimension: {
        width: 0,
        height: 0
      },
      viewerPosition: {
        x: 0,
        y: 0
      },
      scale: {
        x: 1,
        y: 1
      },
      loaded: false,
      error: undefined
    });
  }

  @Input() set updatePosition(position: Position) {
    this.position = { ...this.position, ...position };
    this.updatePositions();
  }

  @Input() set updateBarHeight(height: number) {
    this.sessionBarHeight = height;
    this.updatePositions();
  }

  @Input() set updateBevConfig(bevConfig: BEVConfig) {
    this.bevConfig = bevConfig;
    this.updatePositions();
  }

  @Input() set updateSession(session: Session) {
    this.session = session;
    this.updatePositions();
  }

  @Input() set updateSessionScale(sessionScale: number) {
    this.sessionScale = sessionScale;
    this.updatePositions();
  }

  @HostListener('window:resize', ['$event'])
  @debounce(100)
  private onResize(): void {
    this.launchUpdatePositions();
  }

  @HostListener('window:orientationchange', ['$event']) private onOrientationChange(): void {
    this.launchUpdatePositions();
  }

  public ngOnInit(): void {
    this.initWorkspacesSub();
    this.initCasSub();
    this.initImasSub();
    this.initMarasSub();
    this.initMerasSub();
    this.initTabasSub();
    this.initTimasSub();
    this.initVisibilitySub();
    this.updateComponentState({
      loaded: true
    });
    this.initUnsub();
  }

  public onMoving(event: MovingOffset): void {
    if (
      new Date().getTime() - this.lastTimeResizeUpdate > INTERVAL_BEV_DRAG_MS &&
      (event.x !== this.previousEventDragX || event.y !== this.previousEventDragY)
    ) {
      this.emitPosition(event, false);
      this.lastTimeResizeUpdate = new Date().getTime();
      this.previousEventDragX = event.x;
      this.previousEventDragY = event.y;
    }
  }

  public onMovingEnd(event: MovingOffset): void {
    this.emitPosition(event, true);
    this.updatePositions();
  }

  public onMouseDown(): boolean {
    return false;
  }

  public workspacesTrackBy(index: number, item: WorkspaceDimension): string {
    return item.uuid;
  }

  public chartAreasTrackBy(index: number, item: WAHiddenRepresentation): string {
    return item.uuid;
  }

  private emitPosition(event: MovingOffset, endMoving: boolean): void {
    if (!this.state.global) {
      return;
    }
    const global = this.state.global;
    const scale = this.state.scale;
    const viewer = this.state.viewerDimension;
    const threshold = 0.1;
    const diffX = this.innerWidth - viewer.width;
    const diffY = this.innerHeight - viewer.height;
    const minXThreshold = diffX * threshold;
    const maxXThreshold = diffX * (1 - threshold);
    const minYThreshold = diffY * threshold;
    const maxYThreshold = diffY * (1 - threshold);
    event.x = event.x < minXThreshold ? 0 : event.x > maxXThreshold ? diffX : Math.round(event.x);
    event.y = event.y < minYThreshold ? 0 : event.y > maxYThreshold ? diffY : Math.round(event.y);
    this.position = {
      ...this.position,
      left: global.left + event.x * scale.x,
      top: global.top + event.y * scale.y
    };
    const position: Position = { left: this.position.left, top: this.position.top };
    this.positionEmitter.emit({ position, endMoving });
  }

  private launchUpdatePositions(): void {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.updatePositions();
    }, DELAY_RESIZE_EVENT);
    if (this.resizeSecondTimeout) {
      clearTimeout(this.resizeSecondTimeout);
    }
    this.resizeSecondTimeout = setTimeout(() => {
      this.updatePositions();
    }, DELAY_SECOND_RESIZE_EVENT);
  }

  private updatePositions(): void {
    if (!this.session || !this.bevConfig) {
      return;
    }
    const bevConfig = this.bevConfig;
    this.width = Math.round((bevConfig.size / 100) * getWindowWidth());
    this.height = Math.round((bevConfig.size / 100) * getWindowHeight());
    this.innerWidth = this.width - this.borderSize * 2;
    this.innerHeight = this.height - this.borderSize * 2;
    this.position = this.computePosition();
    const workspaces = this.computeWorkspaces();
    this.canvas = this.computeCanvas(workspaces);
    const global = this.computeGlobal();
    const scale = this.computeScale(global);
    const viewer = this.computeViewer(global);
    const visible =
      bevConfig.mode === 'visible'
        ? true
        : bevConfig.mode === 'hidden'
        ? false
        : workspaces.length > 0 && (viewer.width !== this.innerWidth || viewer.height !== this.innerHeight);
    if (visible !== this.state.visible) {
      this.visibilityEmitter.emit(visible);
    }
    if (!visible) {
      this.width = 0;
      this.height = 0;
    }
    this.borderSize = visible ? BEV_BORDER_WIDTH : 0;
    this.updateComponentState({
      workspaces,
      visible,
      viewerDimension: {
        width: viewer.width,
        height: viewer.height
      },
      viewerPosition: {
        x: viewer.left,
        y: viewer.top
      },
      global,
      scale
    });
  }

  private computePosition(): Rectangle {
    return {
      ...this.position,
      width: getWindowWidth() / this.sessionScale,
      height: (getWindowHeight() - this.sessionBarHeight) / this.sessionScale
    };
  }

  private computeWorkspaces(): Array<WorkspaceDimension> {
    const session = this.session;

    return session.workspaces
      .filter((ws) => ws.state === 'ui' && (ws.socketIds.length === 0 || ws.socketIds.includes(this.userService.currentSocketId)))
      .map((ws) => {
        const workspaces = this.workspaces.filter((workspace) => workspace.uuid === ws.uuid);
        const wasToHide: Array<WAHiddenRepresentation> = [];

        if (workspaces.length > 0) {
          const workspace = workspaces[0];
          if (this.hideCAS) {
            workspace.cas
              .map((wa) => wa.dbId)
              .forEach((caKey) => {
                const wsCAS = this.cas.filter((ca) => ca._id === caKey);
                if (wsCAS.length > 0) {
                  const ca = wsCAS[0];
                  wasToHide.push({
                    uuid: ca.key,
                    left: ca.left,
                    bottom: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * ca.bottom,
                    width: ws.zoom * ca.width,
                    height: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * ca.height,
                    backgroundColor: ca.backgroundColor,
                    imgLogo: BEV_IMG_FOLDER + 'chart.png'
                  });
                }
              });
          }
          if (this.hideIMAS) {
            workspace.imas
              .map((wa) => wa.dbId)
              .forEach((imaKey) => {
                const wsIMAS = this.imas.filter((ima) => ima._id === imaKey);
                if (wsIMAS.length > 0) {
                  const ima = wsIMAS[0];
                  wasToHide.push({
                    uuid: ima.key,
                    left: ima.left,
                    bottom: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * ima.bottom,
                    width: ws.zoom * ima.width,
                    height: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * ima.height,
                    backgroundColor: ima.backgroundColor,
                    imgLogo: BEV_IMG_FOLDER + 'image.png'
                  });
                }
              });
          }
          if (this.hideMARAS) {
            workspace.maras
              .map((wa) => wa.dbId)
              .forEach((maraKey) => {
                const wsMARAS = this.maras.filter((mara) => mara._id === maraKey);
                if (wsMARAS.length > 0) {
                  const mara = wsMARAS[0];
                  wasToHide.push({
                    uuid: mara.key,
                    left: mara.left,
                    bottom: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * mara.bottom,
                    width: ws.zoom * mara.width,
                    height: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * mara.height,
                    backgroundColor: mara.backgroundColor,
                    imgLogo: BEV_IMG_FOLDER + 'mindmap.png'
                  });
                }
              });
          }
          if (this.hideMERAS) {
            workspace.meras
              .map((wa) => wa.dbId)
              .forEach((meraKey) => {
                const wsMERAS = this.meras.filter((mera) => mera._id === meraKey);
                if (wsMERAS.length > 0) {
                  const mera = wsMERAS[0];
                  const data = b64ToUtf8(mera.mermaidData);
                  const chartType = data.split(/\r?\n/)[0].split(' ')[0];
                  wasToHide.push({
                    uuid: mera.key,
                    left: mera.left,
                    bottom: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * mera.bottom,
                    width: ws.zoom * mera.width,
                    height: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * mera.height,
                    backgroundColor: mera.backgroundColor,
                    imgLogo: getImgPathFromMERAType(chartType)
                  });
                }
              });
          }
          if (this.hideTABAS) {
            workspace.tabas
              .map((wa) => wa.dbId)
              .forEach((tabaKey) => {
                const wsTABAS = this.tabas.filter((taba) => taba._id === tabaKey);
                if (wsTABAS.length > 0) {
                  const taba = wsTABAS[0];
                  wasToHide.push({
                    uuid: taba.key,
                    left: taba.left,
                    bottom: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * taba.bottom,
                    width: ws.zoom * taba.width,
                    height: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * taba.height,
                    backgroundColor: taba.backgroundColor,
                    imgLogo: BEV_IMG_FOLDER + 'table.png'
                  });
                }
              });
          }
          if (this.hideTIMAS) {
            workspace.timas
              .map((wa) => wa.dbId)
              .forEach((timaKey) => {
                const wsTIMAS = this.timas.filter((tima) => tima._id === timaKey);
                if (wsTIMAS.length > 0) {
                  const tima = wsTIMAS[0];
                  wasToHide.push({
                    uuid: tima.key,
                    left: tima.left,
                    bottom: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * tima.bottom,
                    width: ws.zoom * tima.width,
                    height: ((ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT) / workspaces[0].height) * tima.height,
                    backgroundColor: tima.colors.areaBackground,
                    imgLogo: BEV_IMG_FOLDER + 'timeline.png'
                  });
                }
              });
          }
        }

        return {
          uuid: ws.uuid,
          top: ws.top,
          left: ws.left,
          width: workspaces.length > 0 ? ws.zoom * workspaces[0].width : 0,
          height: workspaces.length > 0 ? ws.zoom * workspaces[0].height + WORKSPACE_HANDLE_HEIGHT : 0,
          useWorkspaceImage: workspaces.length > 0 ? workspaces[0].useWorkspaceImage : false,
          backgroundColor: workspaces.length > 0 ? workspaces[0].backgroundColor : 'white',
          imgUrl: workspaces.length > 0 ? workspaces[0].imgUrl ?? '' : '',
          wasToHide
        };
      });
  }

  private computeCanvas(wsDimensions: Array<WorkspaceDimension>): Rectangle {
    const minLeft = Math.min(...wsDimensions.map((ws) => ws.left));
    const minTop = Math.min(...wsDimensions.map((ws) => ws.top));
    const maxRight = Math.max(...wsDimensions.map((ws) => ws.left + ws.width));
    const maxBottom = Math.max(...wsDimensions.map((ws) => ws.top + ws.height));

    return {
      left: minLeft,
      top: minTop,
      width: maxRight - minLeft,
      height: maxBottom - minTop
    };
  }

  private computeGlobal(): Rectangle {
    const minLeft = Math.min(this.canvas.left, this.position.left);
    const minTop = Math.min(this.canvas.top, this.position.top);
    const maxRight = Math.max(this.canvas.left + this.canvas.width, this.position.left + this.position.width);
    const maxBottom = Math.max(this.canvas.top + this.canvas.height, this.position.top + this.position.height);

    return {
      left: minLeft,
      top: minTop,
      width: maxRight - minLeft,
      height: maxBottom - minTop
    };
  }

  private computeScale(global: Rectangle): Scale {
    return {
      x: global.width / this.innerWidth,
      y: global.height / this.innerHeight
    };
  }

  private computeViewer(global: Rectangle): Rectangle {
    return {
      left: (this.innerWidth * (this.position.left - global.left)) / global.width,
      top: (this.innerHeight * (this.position.top - global.top)) / global.height,
      width: Math.round((this.innerWidth * this.position.width) / global.width),
      height: Math.round((this.innerHeight * this.position.height) / global.height)
    };
  }

  private initWorkspacesSub(): void {
    if (!this.workspaces$) {
      return;
    }
    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (workspaces) => {
        this.workspaces = workspaces;
        this.updatePositions();
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private initCasSub(): void {
    if (!this.cas$) {
      return;
    }
    this.cas$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (cas) => {
        this.cas = cas;
        this.updatePositions();
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private initImasSub(): void {
    if (!this.imas$) {
      return;
    }
    this.imas$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (imas) => {
        this.imas = imas;
        this.updatePositions();
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private initMarasSub(): void {
    if (!this.maras$) {
      return;
    }
    this.maras$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (maras) => {
        this.maras = maras;
        this.updatePositions();
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private initMerasSub(): void {
    if (!this.meras$) {
      return;
    }
    this.meras$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (meras) => {
        this.meras = meras;
        this.updatePositions();
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private initTabasSub(): void {
    if (!this.tabas$) {
      return;
    }
    this.tabas$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (tabas) => {
        this.tabas = tabas;
        this.updatePositions();
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private initTimasSub(): void {
    if (!this.timas$) {
      return;
    }
    this.timas$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (timas) => {
        this.timas = timas;
        this.updatePositions();
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private initVisibilitySub(): void {
    this.visibilityService.pageVisible.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.launchUpdatePositions();
      }
    });
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
        }
        if (this.resizeSecondTimeout) {
          clearTimeout(this.resizeSecondTimeout);
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }
}

const getImgPathFromMERAType = (meraType: string): string => {
  switch (meraType) {
    case 'classDiagram':
      return BEV_IMG_FOLDER + 'mera-class.svg';
    case 'erDiagram':
      return BEV_IMG_FOLDER + 'mera-er.svg';
    case 'flowchart':
    case 'graph':
      return BEV_IMG_FOLDER + 'mera-flowchart.svg';
    case 'gantt':
      return BEV_IMG_FOLDER + 'mera-gantt.svg';
    case 'gitGraph':
      return BEV_IMG_FOLDER + 'mera-git.svg';
    case 'journey':
      return BEV_IMG_FOLDER + 'mera-journey.svg';
    case 'pie':
      return BEV_IMG_FOLDER + 'mera-pie.svg';
    case 'requirementDiagram':
      return BEV_IMG_FOLDER + 'mera-requirement.svg';
    case 'sequenceDiagram':
      return BEV_IMG_FOLDER + 'mera-sequence.svg';
    case 'stateDiagram':
    case 'stateDiagram-v2':
      return BEV_IMG_FOLDER + 'mera-state.svg';
    default:
      return BEV_IMG_FOLDER + 'mera-class.svg';
  }
};

interface WorkspaceDimension {
  uuid: string;
  left: number;
  top: number;
  width: number;
  height: number;
  useWorkspaceImage: boolean;
  imgUrl: string;
  backgroundColor: string;
  wasToHide: Array<WAHiddenRepresentation>;
}

interface WAHiddenRepresentation {
  uuid: string;
  left: number;
  bottom: number;
  width: number;
  height: number;
  backgroundColor: string;
  imgLogo: string;
}

export interface PositionEvent {
  position: Position;
  endMoving: boolean;
}

interface ViewerDimension {
  width: number;
  height: number;
}

interface ViewerPosition {
  x: number;
  y: number;
}

interface Rectangle {
  left: number;
  top: number;
  width: number;
  height: number;
}

const rectangleInit: Rectangle = {
  left: 0,
  top: 0,
  width: 0,
  height: 0
};

interface Scale {
  x: number;
  y: number;
}

export const getNextBEVMode = (mode: bevType): bevType => {
  switch (mode) {
    case 'auto':
      return 'visible';
    case 'visible':
      return 'hidden';
    case 'hidden':
      return 'auto';
    default:
      return 'auto';
  }
};

export type bevType = 'auto' | 'visible' | 'hidden';

export interface BevMode {
  value: bevType;
  label: string;
}

export interface BEVConfig {
  mode: bevType;
  size: number;
}

export const bevConfigDefault: BEVConfig = {
  mode: 'auto',
  size: 15
};
