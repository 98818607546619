import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreState } from '@core/state/core.state';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { environment } from '@environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { Select } from '@ngxs/store';
import { ErrorHandlerService } from '@services/error-handler.service';
import { PageTitleService } from '@services/page-title.service';
import { UserService } from '@services/user.service';

const MS_BEFORE_EXIT = environment.settings.global.licenseErrorTimeBeforeExit;
const MS_INTERVAL_REFRESH = 500;

interface ComponentState {
  timeLeft: number;
  timestampExit: number;
  maxSessions: number;
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-error-socket',
  templateUrl: './error-socket.component.html',
  styleUrls: ['./error-socket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorSocketComponent extends StatefulComponentDirective<ComponentState> implements OnInit {
  @Select(CoreState.SelectMaxSessions) public maxSessions$: Observable<number> | undefined;

  private countdownInterval: NodeJS.Timeout | undefined;

  constructor(
    private readonly errorHandler: ErrorHandlerService,
    private readonly translocoService: TranslocoService,
    private readonly keycloakService: KeycloakService,
    private readonly pageTitleService: PageTitleService,
    private readonly userService: UserService,
    private readonly router: Router
  ) {
    super({
      timeLeft: MS_BEFORE_EXIT,
      timestampExit: new Date().getTime() + MS_BEFORE_EXIT,
      maxSessions: 0,
      loaded: false,
      error: undefined
    });
  }

  public ngOnInit(): void {
    this.updateComponentState({
      maxSessions: this.userService.currentMaxSessions,
      loaded: true
    });
    this.initMaxSessionsRefresh();
    this.initCooldownInterval();
    this.initTranslations();
    this.initUnsub();
  }

  public onRetry(): void {
    this.router
      .navigate(['/'])
      .then(() => {
        location.reload();
      })
      .catch(this.errorHandler.handleError);
  }

  public onLogout(): void {
    this.router
      .navigate(['/'])
      .then(() => this.keycloakService.logout())
      .catch(this.errorHandler.handleError);
  }

  private initMaxSessionsRefresh(): void {
    if (!this.maxSessions$) {
      return;
    }
    this.maxSessions$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (maxSessions) => {
        this.updateComponentState({
          maxSessions,
          loaded: true
        });
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private initCooldownInterval(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    this.countdownInterval = setInterval(() => {
      const timeLeft = this.state.timestampExit - new Date().getTime();
      this.updateComponentState({
        timeLeft
      });
      if (timeLeft <= 0) {
        if (this.countdownInterval) {
          clearInterval(this.countdownInterval);
        }
        this.onLogout();
      }
    }, MS_INTERVAL_REFRESH);
  }

  private initTranslations(): void {
    /**
     * t(app.socketError.pageTitle)
     */
    this.translocoService
      .selectTranslate(['socketError.pageTitle'], {}, 'app')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (translations) => {
          this.pageTitleService.setTitle([translations[0]]);
        },
        error: (err: unknown) => this.errorHandler.handleError(err)
      });
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.countdownInterval) {
          clearInterval(this.countdownInterval);
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }
}
