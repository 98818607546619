import { Injectable } from '@angular/core';
import { STATE_G2VIEW_CORE } from '@config/constants';
import { areStringArraysEqual } from '@config/utils';
import {
  ResetRooms,
  SetBackgroundColor,
  SetCurrentG2ModulesLoaded,
  SetCurrentG2Role,
  SetDevMode,
  SetKeycloakData,
  SetMaxSessions,
  SetRooms,
  SetSocketId,
  SetUserAuth,
  SetUserData
} from '@core/state/core.actions';
import { RoomMemberships, RoomMembershipsDashboards, RoomMembershipsSessions, RoomMembershipsUsers } from '@g2view/g2view-commons';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ErrorHandlerService } from '@services/error-handler.service';

@State<CoreStateModel>({
  name: STATE_G2VIEW_CORE,
  defaults: {
    userData: {
      userId: '',
      groupIds: [],
      username: '',
      name: ''
    },
    userAuth: {
      token: '',
      tokenExpTime: 0,
      clientRoles: [],
      realmRoles: [],
      accountRoles: []
    },
    userConfig: {
      devMode: false
    },
    socketId: '',
    currentG2Role: '',
    currentG2ModulesLoaded: [],
    maxSessions: 0,
    layout: {
      backgroundColor: 'none'
    },
    keycloak: {
      g2Roles: [],
      areG2RolesInitialized: false,
      users: new Map(),
      groups: new Map()
    },
    rooms: {
      dashboards: [],
      sessions: [],
      users: [],
      workspaces: []
    }
  }
})
@Injectable()
export class CoreState {
  constructor(private readonly errorHandler: ErrorHandlerService) {}

  @Selector() public static SelectUserConfig(state: CoreStateModel): UserConfig {
    return state.userConfig;
  }

  @Selector() public static SelectLayout(state: CoreStateModel): LayoutModel {
    return state.layout;
  }

  @Selector() public static SelectUserId(state: CoreStateModel): string {
    return state.userData.userId;
  }

  @Selector() public static SelectSocketId(state: CoreStateModel): string {
    return state.socketId;
  }

  @Selector() public static SelectUserAuth(state: CoreStateModel): UserAuth {
    return state.userAuth;
  }

  @Selector() public static SelectMaxSessions(state: CoreStateModel): number {
    return state.maxSessions;
  }

  @Selector() public static SelectCurrentG2Role(state: CoreStateModel): string {
    return state.currentG2Role;
  }

  @Selector() public static SelectCurrentG2ModulesLoaded(state: CoreStateModel): Array<string> {
    return state.currentG2ModulesLoaded;
  }

  @Selector() public static SelectG2Roles(state: CoreStateModel): Array<string> {
    return state.keycloak.g2Roles;
  }

  @Selector() public static SelectUsers(state: CoreStateModel): Map<string, string> {
    return state.keycloak.users;
  }

  @Selector() public static SelectGroups(state: CoreStateModel): Map<string, string> {
    return state.keycloak.groups;
  }

  @Selector() public static SelectRoomsDashboards(state: CoreStateModel): RoomMembershipsDashboards {
    return state.rooms.dashboards;
  }

  @Selector() public static SelectRoomsSessions(state: CoreStateModel): RoomMembershipsSessions {
    return state.rooms.sessions;
  }

  @Selector() public static SelectRoomsUsers(state: CoreStateModel): RoomMembershipsUsers {
    return state.rooms.users;
  }

  @Action(SetSocketId) public setSocketId(ctx: StateContext<CoreStateModel>, action: SetSocketId): void {
    ctx.patchState({
      socketId: action.socketId
    });
  }

  @Action(SetCurrentG2Role) public setCurrentG2Role(ctx: StateContext<CoreStateModel>, action: SetCurrentG2Role): void {
    ctx.patchState({
      currentG2Role: action.g2Role
    });
  }

  @Action(SetCurrentG2ModulesLoaded) public setCurrentG2ModulesLoaded(ctx: StateContext<CoreStateModel>, action: SetCurrentG2ModulesLoaded): void {
    const current = [...ctx.getState().currentG2ModulesLoaded];
    const modules = [...action.modules];
    if (!areStringArraysEqual(current, modules)) {
      ctx.patchState({
        currentG2ModulesLoaded: modules
      });
    }
  }

  @Action(SetMaxSessions) public setMaxSessions(ctx: StateContext<CoreStateModel>, action: SetMaxSessions): void {
    ctx.patchState({
      maxSessions: action.maxSessions
    });
  }

  @Action(SetDevMode) public setDevMode(ctx: StateContext<CoreStateModel>, action: SetDevMode): void {
    const userConfig = ctx.getState().userConfig;
    ctx.patchState({
      userConfig: { ...userConfig, devMode: action.devMode }
    });
    this.errorHandler.setDevMode(action.devMode);
  }

  @Action(SetBackgroundColor) public setBackgroundColor(ctx: StateContext<CoreStateModel>, action: SetBackgroundColor): void {
    const layout = ctx.getState().layout;
    ctx.patchState({
      layout: { ...layout, backgroundColor: action.backgroundColor }
    });
  }

  @Action(SetUserAuth) public setUserAuth(ctx: StateContext<CoreStateModel>, action: SetUserAuth): void {
    ctx.patchState({
      userAuth: action.userAuth
    });
  }

  @Action(SetUserData) public setUserData(ctx: StateContext<CoreStateModel>, action: SetUserData): void {
    ctx.patchState({
      userData: action.userData
    });
  }

  @Action(SetKeycloakData) public setKeycloakData(ctx: StateContext<CoreStateModel>, action: SetKeycloakData): void {
    ctx.patchState({
      keycloak: action.keycloakData
    });
  }

  @Action(SetRooms) public setRooms(ctx: StateContext<CoreStateModel>, action: SetRooms): void {
    ctx.patchState({
      rooms: action.rooms
    });
  }

  @Action(ResetRooms) public resetRooms(ctx: StateContext<CoreStateModel>): void {
    ctx.patchState({
      rooms: {
        dashboards: [],
        sessions: [],
        users: [],
        workspaces: []
      }
    });
  }
}

export interface LayoutModel {
  backgroundColor: string;
}

export const DEFAULT_LAYOUT_MODEL: LayoutModel = {
  backgroundColor: ''
};

export interface UserData {
  userId: string;
  groupIds: Array<string>;
  username: string;
  name: string;
}

export interface UserAuth {
  token: string;
  tokenExpTime: number;
  clientRoles: Array<string>;
  realmRoles: Array<string>;
  accountRoles: Array<string>;
}

export interface UserConfig {
  devMode: boolean;
}

export interface KeycloakCore {
  g2Roles: Array<string>;
  areG2RolesInitialized: boolean;
  users: Map<string, string>;
  groups: Map<string, string>;
}

interface CoreStateModel {
  userData: UserData;
  userAuth: UserAuth;
  userConfig: UserConfig;
  layout: LayoutModel;
  socketId: string;
  currentG2Role: string;
  currentG2ModulesLoaded: Array<string>;
  maxSessions: number;
  keycloak: KeycloakCore;
  rooms: RoomMemberships;
}
