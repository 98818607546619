import { NGXLogger } from 'ngx-logger';

import { Injectable } from '@angular/core';

const logEquivalence: Map<LogLevelType, number> = new Map();
logEquivalence.set('trace', 0);
logEquivalence.set('debug', 1);
logEquivalence.set('info', 2);
logEquivalence.set('log', 3);
logEquivalence.set('warn', 4);
logEquivalence.set('error', 5);
logEquivalence.set('fatal', 6);

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(private readonly logger: NGXLogger) {}

  public log(level: LogLevelType, message: string, data: any = {}): void {
    switch (level) {
      case 'trace':
        this.logger.trace(message, data);
        break;
      case 'debug':
        this.logger.debug(message, data);
        break;
      case 'info':
        this.logger.info(message, data);
        break;
      case 'log':
        this.logger.log(message, data);
        break;
      case 'warn':
        this.logger.warn(message, data);
        break;
      case 'error':
        this.logger.error(message, data);
        break;
      case 'fatal':
        this.logger.fatal(message, data);
        break;
    }
  }
}

type LogLevelType = 'trace' | 'debug' | 'info' | 'log' | 'warn' | 'error' | 'fatal';
