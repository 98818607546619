// eslint-disable-next-line @typescript-eslint/no-explicit-any
const browserWindow: any = window;
const env = browserWindow.env;

export const environment: EnvironmentI = {
  envName: env.env_name,
  isDevMod: env.env_name === 'LOCAL' || env.env_name === 'DEV',
  isProdMod: env.env_name !== 'LOCAL' && env.env_name !== 'DEV',
  buildHash: env.build_hash,
  serverUrl: env.serverUrl,
  apiDocUrl: env.apiDocUrl,
  aws: {
    region: env.aws.region
  },
  ngxLogger: {
    consoleLogLevel: +env.ngxLogger.consoleLogLevel
  },
  keycloak: {
    enabled: env.keycloak.enabled === 'true',
    token: env.keycloak.token,
    issuer: env.keycloak.issuer,
    realm: env.keycloak.realm,
    clientId: env.keycloak.clientId,
    g2Namespace: env.keycloak.g2Namespace
  },
  settings: {
    global: {
      title: env.settings.global.title,
      logoUrl: env.settings.global.logoUrl,
      langAvailable: env.settings.global.langAvailable,
      langDefault: env.settings.global.langDefault,
      licenseErrorTimeBeforeExit: +env.settings.global.licenseErrorTimeBeforeExit
    },
    rooms: {
      intervalAutomaticRefresh: +env.settings.rooms.intervalAutomaticRefresh
    },
    afk: {
      time: +env.settings.afk.time,
      redirectTime: +env.settings.afk.redirectTime,
      displayButtonsTime: +env.settings.afk.displayButtonsTime
    },
    dashboard: {
      inactivityTime: +env.settings.dashboard.inactivityTime
    },
    session: {
      scaleStep: +env.settings.session.scaleStep,
      scaleMin: +env.settings.session.scaleMin,
      scaleMax: +env.settings.session.scaleMax
    },
    workspace: {
      minWidth: +env.settings.workspace.minWidth
    },
    workspaceArea: {
      chartArea: {
        exportSizePx: +env.settings.workspaceArea.chartArea.exportSizePx
      },
      textArea: {
        fontSizeMax: +env.settings.workspaceArea.textArea.fontSizeMax,
        fontSizeMin: +env.settings.workspaceArea.textArea.fontSizeMin
      }
    }
  }
};

export interface EnvironmentI {
  envName: string;
  isDevMod: boolean;
  isProdMod: boolean;
  buildHash: string;
  serverUrl: string;
  apiDocUrl: string;
  aws: {
    region: string;
  };
  ngxLogger: {
    consoleLogLevel: number;
  };
  keycloak: {
    enabled: boolean;
    token: string;
    issuer: string;
    realm: string;
    clientId: string;
    g2Namespace: string;
  };
  settings: {
    global: {
      title: string;
      logoUrl: string;
      langAvailable: string;
      langDefault: string;
      licenseErrorTimeBeforeExit: number;
    };
    rooms: {
      intervalAutomaticRefresh: number;
    };
    afk: {
      time: number;
      redirectTime: number;
      displayButtonsTime: number;
    };
    dashboard: {
      inactivityTime: number;
    };
    session: {
      scaleStep: number;
      scaleMin: number;
      scaleMax: number;
    };
    workspace: {
      minWidth: number;
    };
    workspaceArea: {
      chartArea: {
        exportSizePx: number;
      };
      textArea: {
        fontSizeMax: number;
        fontSizeMin: number;
      };
    };
  };
}
