import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';

const mainTitle = environment.settings.global.title;
const titleSeparator = ' | ';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  constructor(private readonly titleService: Title) {}

  public setTitle(title: Array<string>): void {
    title.unshift(mainTitle);
    this.titleService.setTitle(title.join(titleSeparator));
  }
}
