<ng-container *transloco="let t; scope: 'app'; read: 'app.toggles.devMode'">
  <ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="state.loaded">
      <button mat-icon-button (click)="onToggleDevMode()" title="{{ t('buttonTitle') }}">
        <mat-icon fontIcon="notifications_off" *ngIf="!state.isDevMode"></mat-icon>
        <mat-icon fontIcon="notifications_active" *ngIf="state.isDevMode"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="!state.loaded">
      <ngx-g2v-progress-bar></ngx-g2v-progress-bar>
    </ng-container>
  </ng-container>
</ng-container>