import { Subject } from 'rxjs';

import { Directive, OnDestroy } from '@angular/core';

@Directive()
export class RxJSBaseDirective implements OnDestroy {
  private _destroy$!: Subject<void>;

  get destroy$(): Subject<void> {
    if (!this._destroy$) {
      // Performance optimization:
      // Since this is likely used as base component everywhere,
      // only construct a Subject instance if actually used.
      this._destroy$ = new Subject();
    }

    return this._destroy$;
  }

  public ngOnDestroy(): void {
    if (this._destroy$) {
      this._destroy$.next();
      this._destroy$.complete();
    }
  }
}
