import { AccessRequest, Dashboard, DashboardFavorite, UpdateDashboardInfoEvent } from '@g2view/g2view-commons';

export class SetDashboards {
  public static readonly type = '[Dashboards API] Set Dashboards and favorites';
  constructor(public dashboards: Array<Dashboard>, public favorites: Array<DashboardFavorite>, public accessRequests: Array<AccessRequest>) {}
}

export class AddDashboard {
  public static readonly type = '[Dashboards API] Add Dashboard';
  constructor(public dashboard: Dashboard) {}
}

export class UpdateDashboard {
  public static readonly type = '[Dashboards API] Update Dashboard';
  constructor(public dashboard: Dashboard) {}
}

export class UpdateDashboardInfo {
  public static readonly type = '[Dashboards API] Update Dashboard info';
  constructor(public data: UpdateDashboardInfoEvent) {}
}

export class RemoveDashboard {
  public static readonly type = '[Dashboards API] Remove Dashboard';
  constructor(public dashboardId: string) {}
}

export class UpdateCurrentDashboard {
  public static readonly type = '[Dashboards API] Update current dashboard';
  constructor(public dashboard: Dashboard, public dashboardView: string) {}
}

export class ResetCurrentDashboard {
  public static readonly type = '[Dashboards API] Reset current dashboard';
}

export class SetDashboardView {
  public static readonly type = '[Core API] Set dashboard view';
  constructor(public currentViewId: string) {}
}

export class AddDashboardFavorite {
  public static readonly type = '[Dashboards API] Add Dashboard Favorite';
  constructor(public dashboardFavorite: DashboardFavorite) {}
}

export class RemoveDashboardFavorite {
  public static readonly type = '[Dashboards API] Remove Dashboard Favorite';
  constructor(public dashboardFavorite: DashboardFavorite) {}
}
export class AddDashboardAccessRequest {
  public static readonly type = '[Dashboards API] Add Dashboard access request';
  constructor(public accessRequest: AccessRequest) {}
}

export class RemoveDashboardAccessRequest {
  public static readonly type = '[Dashboards API] Remove Dashboard access request';
  constructor(public accessRequest: AccessRequest) {}
}
