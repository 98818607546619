import { animate, state, style, transition } from '@angular/animations';
import { MatDialogConfig } from '@angular/material/dialog';

export const ADMIN_PATH = 'admin';
export const ADMIN_DB_PATH = 'database';
export const ADMIN_DOC_PATH = 'doc';
export const ADMIN_G2_PATH = 'g2';
export const ADMIN_DEV_PATH = 'dev';
export const KEYCLOAK_PATH = 'users';
export const KEYCLOAK_USERS_PATH = 'list';
export const KEYCLOAK_GROUPS_PATH = 'groups';
export const KEYCLOAK_ROLES_PATH = 'roles';
export const ERROR_SOCKET_PATH = 'error-socket';
export const SESSIONS_PATH = 'sessions';
export const SESSION_PATH = 'session';
export const DASHBOARDS_PATH = 'dashboards';
export const DASHBOARD_PATH = 'dashboard';
export const DASHBOARDS_TREE_VIEW_PATH = 'tree-view';
export const SHARED_PATH = 'shared';
export const AFK_PATH = 'afk';

export const STATE_G2VIEW_CORE = 'CoreStore';
export const STATE_G2VIEW_DASHBOARDS = 'DashboardsStore';
export const STATE_G2VIEW_KEYCLOAK = 'KeycloakStore';
export const STATE_G2VIEW_SESSIONS = 'SessionsStore';
export const STATE_G2VIEW_WORKSPACES = 'WorkspacesStore';

const ASSETS_FOLDER = './assets/';
export const IMG_FOLDER = ASSETS_FOLDER + 'images/';
export const I18N_FOLDER = ASSETS_FOLDER + 'i18n/';
export const I18N_FLAG_FOLDER = I18N_FOLDER + 'flags/';
export const BEV_IMG_FOLDER = IMG_FOLDER + 'bev/';
export const DATA_FOLDER = ASSETS_FOLDER + 'data/';

export const SHORTCUT_HELP = 'shift.h';
export const SHORTCUT_FULLSCREEN = 'shift.f';
export const SHORTCUT_G2_ROLE_SWITCH = 'shift.m';
export const SHORTCUT_DASHBOARDS_ADD_DASHBOARD = 'shift.a';
export const SHORTCUT_DASHBOARDS_JOIN_DASHBOARD = 'shift.j';
export const SHORTCUT_DASHBOARD_SETTINGS = 'shift.s';
export const SHORTCUT_DASHBOARD_ZOOM_PLUS = 'shift.+';
export const SHORTCUT_DASHBOARD_ZOOM_MINUS = 'shift.-';
export const SHORTCUT_DASHBOARD_ZOOM_RESET = 'shift.*';
export const SHORTCUT_WORKSPACES_HIGHLIGHT = 'shift.c';
export const SHORTCUT_DASHBOARD_EXIT = 'shift.x';
export const SHORTCUT_DASHBOARD_TREE_VIEW_EXIT = 'shift.x';
export const SHORTCUT_SESSIONS_ADD_SESSION = 'shift.a';
export const SHORTCUT_SESSIONS_JOIN_SESSION = 'shift.j';
export const SHORTCUT_SESSION_ADD_WS = 'shift.a';
export const SHORTCUT_SESSION_SETTINGS = 'shift.s';
export const SHORTCUT_SESSION_LOCK = 'shift.l';
export const SHORTCUT_SESSION_BEV_MODE = 'shift.b';
export const SHORTCUT_SESSION_EXIT = 'shift.x';
export const SHORTCUT_SESSION_ZOOM_PLUS = 'shift.+';
export const SHORTCUT_SESSION_ZOOM_MINUS = 'shift.-';
export const SHORTCUT_SESSION_ZOOM_RESET = 'shift.*';
export const SHORTCUT_SESSION_MOVE_LEFT = 'arrowLeft';
export const SHORTCUT_SESSION_MOVE_RIGHT = 'arrowRight';
export const SHORTCUT_SESSION_MOVE_TOP = 'arrowUp';
export const SHORTCUT_SESSION_MOVE_BOTTOM = 'arrowDown';
export const SHORTCUT_REFRESH_TABLE = 'shift.r';

export const MAT_DIALOG_CONFIG = {
  ...new MatDialogConfig(),
  maxWidth: 500,
  minWidth: 300,
  width: '95%',
  disableClose: true,
  closeOnNavigation: true,
  autoFocus: false,
  restoreFocus: false
};

export const TABLE_PAGE_SIZE = 20;
export const TABLE_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const TABLE_PAGINATOR_CREATION_TIMEOUT_MS = 100;

export const SNACKBAR_DURATION = 5000;
export const SNACKBAR_HORIZONTAL_POSITION = 'center';
export const SNACKBAR_VERTICAL_POSITION = 'top';
export const SNACKBAR_CLOSE_DEFAULT = 'Close';

export const DELAY_RESIZE_EVENT = 50;
export const DELAY_SECOND_RESIZE_EVENT = 500;
export const DELAY_TOGGLE_FULLSCREEN = 100;

export const WORKSPACE_MINIMIZED_DEFAULT_TITLE = '...';

export const INVALID_NUMBER_CHARS = ['+', 'e'];
export const INVALID_INTEGER_CHARS = ['.', ','];

export const MERMAID_REFRESH_INTERVAL_TIME = 10;
export const MARKMAP_REFRESH_INTERVAL_TIME = MERMAID_REFRESH_INTERVAL_TIME;
export const VIEWER_3D_REFRESH_INTERVAL_TIME = MERMAID_REFRESH_INTERVAL_TIME;
export const CONNECTION_REFRESH_INTERVAL_TIME = MERMAID_REFRESH_INTERVAL_TIME;
export const TIMELINE_REFRESH_INTERVAL_TIME = MERMAID_REFRESH_INTERVAL_TIME;

export const ADMIN_ANIMATION = [
  state('collapsed', style({ height: '0px', minHeight: '0' })),
  state('expanded', style({ height: '*' })),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
];

export const ALL_LANGUAGES: Array<{ id: string; label: string }> = [
  { id: 'en', label: 'English' },
  { id: 'ch', label: '中文' },
  { id: 'fr', label: 'Français' }
];

export const IMAGE_TYPES = [
  '.apng',
  '.avif',
  '.gif',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.jpe',
  '.jif',
  '.pjpeg',
  '.pjp',
  '.png',
  '.svg',
  '.svgz',
  '.webp',
  '.bmp',
  '.ico',
  '.cur',
  '.tif',
  '.tiff',
  '.xbm'
];
export const VIDEO_TYPES = ['.ogm', '.wmv', '.mpg', '.webm', '.ovg', '.mov', '.asx', '.mpeg', '.mp4', '.m4v', '.avi'];
export const AUDIO_TYPES = ['.opus', '.flac', '.webm', '.weba', '.wav', '.ogg', '.m4a', '.oga', '.mid', '.mp3', '.aiff', '.wma', '.au'];

// To resolve carriage return bug: use ([\\s\\S]+?) instead of (.+?)
export const bbCodeParserCodes = {
  '\\[br\\]': '<br>',

  '\\[b\\](.+?)\\[/b\\]': '<strong>$1</strong>',
  '\\[i\\](.+?)\\[/i\\]': '<em>$1</em>',
  '\\[u\\](.+?)\\[/u\\]': '<u>$1</u>',

  '\\[left\\](.+?)\\[/left\\]': '<div style="text-align: left;">$1</div>',
  '\\[right\\](.+?)\\[/right\\]': '<div style="text-align: right;">$1</div>',
  '\\[center\\](.+?)\\[/center\\]': '<div style="text-align: center;">$1</div>',
  '\\[justify\\](.+?)\\[/justify\\]': '<div style="text-align: justify;">$1</div>',

  '\\[p\\](.+?)\\[/p\\]': '<p>$1</p>',

  '\\[h1\\](.+?)\\[/h1\\]': '<h1>$1</h1>',
  '\\[h2\\](.+?)\\[/h2\\]': '<h2>$1</h2>',
  '\\[h3\\](.+?)\\[/h3\\]': '<h3>$1</h3>',
  '\\[h4\\](.+?)\\[/h4\\]': '<h4>$1</h4>',
  '\\[h5\\](.+?)\\[/h5\\]': '<h5>$1</h5>',
  '\\[h6\\](.+?)\\[/h6\\]': '<h6>$1</h6>',

  '\\[color=(.+?)\\](.+?)\\[/color\\]': '<span style="color:$1;">$2</span>',

  '\\[font=(.+?)\\](.+?)\\[/font\\]': '<span style="font-family:$1;">$2</span>',

  '\\[email\\](.+?)\\[/email\\]': '<a href="mailto:$1">$1</a>',
  '\\[email=(.+?)\\](.+?)\\[/email\\]': '<a href="mailto:$1">$2</a>',

  '\\[url=(.+?)\\](.+?)\\[/url\\]': '<a href="$1" target=_blank>$2</a>',
  '\\[urlId=(.+?)\\ id=(.+?)](.+?)\\[/url\\]': '<a href="$1" id="$2" target=_blank>$3</a>',

  '\\[list\\](.+?)\\[/list\\]': '<ul>$1</ul>',
  '\\[olist\\](.+?)\\[/olist\\]': '<ol>$1</ol>',
  '\\[\\*\\](.+?)\\[/\\*\\]': '<li>$1</li>'
};

export const MERMAID_DEFAULT_CONFIG = {
  securityLevel: 'loose'
};

export const G2_SYNTAX_VALIDATOR = 'g2Syntax';

export const bbExamples = {
  bubble: {
    data: {
      columns: [
        ['data1', 30, 350, 200, 380, 150, 250, 50, 80, 55, 220],
        ['data2', 130, 100, 10, 200, 80, 50, 200, 123, 185, 98],
        ['data3', 230, 153, 85, 300, 250, 120, 5, 84, 99, 289]
      ],
      type: 'bubble', // for ESM specify as: bubble()
      labels: true
    },
    bubble: {
      maxR: 50
    },
    axis: {
      x: {
        type: 'category'
      },
      y: {
        max: 450
      }
    },
    bindto: '#bubbleChart'
  },
  gaugeFunction: {
    data: {
      columns: [['data', 91.4]],
      type: 'gauge' // for ESM specify as: gauge()
    },
    gauge: {
      label: {
        format: "function(d) { return '$' + d;}"
      }
    },
    color: {
      pattern: ['#FF0000', '#F97600', '#F6C600', '#60B044'],
      threshold: {
        values: [30, 60, 90, 100]
      }
    },
    size: {
      height: 180
    },
    bindto: '#gaugeChart'
  },
  gauge: {
    data: {
      columns: [['data', 91.4]],
      type: 'gauge'
    },
    gauge: {
      label: {
        extents: 'hide',
        fontSize: 10
      },
      background: 'yellow',
      strokeColor: 'red',
      fullCircle: true,
      startingAngle: -0.75 * Math.PI,
      arcLength: 75
    },
    color: {
      pattern: ['#FF0000', '#F97600', '#F6C600', '#60B044'],
      threshold: {
        values: [30, 60, 90, 100]
      }
    },
    size: {
      height: 180
    },
    bindto: '#gaugeChart'
  },
  gaugeMulti: {
    data: {
      columns: [
        ['data0', -100],
        ['data1', -50],
        ['data2', -25],
        ['data3', 0],
        ['data4', 25],
        ['data5', 50],
        ['data6', 100]
      ],
      type: 'gauge',
      names: {
        data1: 'Name 1',
        data2: 'Name 2'
      }
    },
    gauge: {
      type: 'multi',
      max: 100,
      min: -100,
      arcs: {
        minWidth: 5
      }
    },
    color: {
      pattern: ['#FF0000', '#FFA500', '#FFFF00', '#008000', '#0000FF', '#4B0082', '#EE82EE'],
      threshold: {
        values: [-50, -25, 0, 25, 50, 75, 100]
      }
    },
    size: {
      height: 300
    },
    bindto: '#gaugeTypeMulti'
  },
  lineTimeSeries: {
    data: {
      x: 'x',
      xFormat: '%Y-%m-%d %H:%M:%S',
      columns: [
        ['x', '2012-12-29 15:22:25', '2012-12-29 15:22:26', '2012-12-29 15:22:27'],
        ['data1', 230, 300, 330],
        ['data2', 190, 230, 200],
        ['data3', 90, 130, 180]
      ],
      type: 'line' // for ESM specify as: line()
    },
    subchart: {
      show: 'true' // for ESM specify as: subchart()
    },
    axis: {
      x: {
        type: 'timeseries',
        tick: {
          format: '%Y-%m-%d %H:%M:%S'
        }
      }
    },
    bindto: '#flow'
  },
  lineIndexed: {
    data: {
      columns: [
        ['data1', 30, 200, 100, 400, 150, 250],
        ['data2', 50, 20, 10, 40, 15, 25]
      ],
      type: 'line' // for ESM specify as: line()
    },
    zoom: {
      enabled: 'true',
      type: 'drag'
    },
    bindto: '#lineChart'
  },
  lineCategories: {
    data: {
      x: 'x',
      columns: [
        ['x', 'cat1', 'cat2', 'cat3', 'cat4', 'cat5', 'cat6', 'cat7', 'cat8', 'cat9'],
        ['data1', 30, 200, 100, 400, 150, 250, 50, 100, 250]
      ],
      type: 'line' // for ESM specify as: line()
    },
    axis: {
      x: {
        type: 'category'
      }
    },
    bindto: '#categoryAxis'
  },
  radar: {
    data: {
      x: 'x',
      columns: [
        ['x', 'Data A', 'Data B', 'Data C', 'Data D', 'Data E'],
        ['data1', 330, 350, 200, 380, 150],
        ['data2', 130, 100, 30, 200, 80],
        ['data3', 230, 153, 85, 300, 250]
      ],
      type: 'radar',
      labels: true
    },
    bindto: '#radar'
  },
  tooltipFormat: {
    data: {
      columns: [
        ['data1', 30000, 20000, 10000, 40000, 15000, 250000],
        ['data2', 100, 200, 100, 40, 150, 250]
      ],
      type: 'line', // for ESM specify as: line()
      axes: {
        data2: 'y2'
      }
    },
    axis: {
      y: {
        tick: {
          format: 'D3-FORMAT s'
        }
      },
      y2: {
        show: true,
        tick: {
          format: 'D3-FORMAT $'
        }
      }
    },
    tooltip: {
      format: {
        title: "function(d) {return 'Data ' + d;}",
        value: 'D3-FORMAT ,'
      }
    },
    bindto: '#tooltipFormat'
  },
  xAxisTickTimeseries: {
    data: {
      x: 'x',
      json: {
        Temperature: [
          '29.39',
          '29.7',
          '29.37',
          '28.87',
          '28.62',
          '27.72',
          '27.61',
          '27.82',
          '27.48',
          '26.78',
          '26.62',
          '26.64',
          '26.29',
          '26.01',
          '25.84',
          '25.07',
          '24.85',
          '24.01',
          '23.83',
          '22.8',
          '23',
          '22.64',
          '22.77',
          '22.64',
          '22.64',
          '22.62',
          '22.51',
          '21.42',
          '21.18',
          '20.93',
          '20.66',
          '20.48',
          '20.7',
          '21.24',
          '22.14',
          '22.78',
          '23.43',
          '23.16',
          '27.48',
          '26.78',
          '26.62',
          '26.64',
          '26.29',
          '26.01',
          '25.84',
          '25.07',
          '24.85',
          '24.01'
        ],
        x: [
          '01-01-2015 00:00',
          '02-01-2015 00:00',
          '03-01-2015 00:00',
          '04-01-2015 00:00',
          '05-01-2015 00:00',
          '06-01-2015 00:00',
          '07-01-2015 00:00',
          '08-01-2015 00:00',
          '09-01-2015 00:00',
          '10-01-2015 00:00',
          '11-01-2015 00:00',
          '12-01-2015 00:00',
          '01-01-2016 00:00',
          '02-01-2016 00:00',
          '03-01-2016 00:00',
          '04-01-2016 00:00',
          '05-01-2016 00:00',
          '06-01-2016 00:00',
          '07-01-2016 00:00',
          '08-01-2016 00:00',
          '09-01-2016 00:00',
          '10-01-2016 00:00',
          '11-01-2016 00:00',
          '12-01-2016 00:00',
          '01-01-2017 00:00',
          '02-01-2017 00:00',
          '03-01-2017 00:00',
          '04-01-2017 00:00',
          '05-01-2017 00:00',
          '06-01-2017 00:00',
          '07-01-2017 00:00',
          '08-01-2017 00:00',
          '09-01-2017 00:00',
          '10-01-2017 00:00',
          '11-01-2017 00:00',
          '12-01-2017 00:00',
          '01-01-2018 00:00',
          '02-01-2018 00:00',
          '03-01-2018 00:00',
          '04-01-2018 00:00',
          '05-01-2018 00:00',
          '06-01-2018 00:00',
          '07-01-2018 00:00',
          '08-01-2018 00:00',
          '09-01-2018 00:00',
          '10-01-2018 00:00',
          '11-01-2018 00:00',
          '12-01-2018 00:00'
        ]
      },
      type: 'area', // for ESM specify as: area()
      xFormat: '%m-%d-%Y %H:%M'
    },
    axis: {
      x: {
        tick: {
          fit: false,
          count: 5
        },
        type: 'timeseries'
      }
    },
    zoom: {
      enabled: true, // for ESM specify as: zoom()
      type: 'drag'
    },
    tooltip: {
      format: {
        title: 'D3-TIME %Y-%m'
      }
    },
    point: {
      focus: {
        only: true
      }
    },
    bindto: '#xAxisTickTimeseries'
  },
  candlestick: {
    data: {
      columns: [
        [
          'data1',
          [1327, 1369, 1289, 1348],
          [1348, 1371, 1314, 1320],
          [1320, 1412, 1314, 1394],
          [1394, 1458, 1393, 1453],
          [1453, 1501, 1448, 1500],
          [1500, 1510, 1492, 1496],
          [1496, 1496, 1448, 1448],
          [1448, 1490, 1433, 1490],
          [1490, 1544, 1490, 1537],
          [1537, 1563, 1534, 1544],
          [1544, 1550, 1511, 1525],
          [1525, 1609, 1517, 1604],
          [1604, 1614, 1585, 1592],
          [1592, 1632, 1586, 1620],
          [1620, 1633, 1609, 1622],
          [1622, 1697, 1620, 1687],
          [1687, 1691, 1624, 1648],
          [1648, 1689, 1640, 1671],
          [1671, 1702, 1671, 1695],
          [1695, 1727, 1689, 1724],
          [1724, 1733, 1691, 1696],
          [1696, 1733, 1696, 1731],
          [1731, 1756, 1716, 1748],
          [1748, 1769, 1734, 1762],
          [1762, 1792, 1752, 1778],
          [1778, 1783, 1763, 1769],
          [1769, 1791, 1740, 1755],
          [1755, 1755, 1711, 1725],
          [1725, 1739, 1683, 1701],
          [1701, 1731, 1694, 1730],
          [1730, 1739, 1703, 1715],
          [1715, 1745, 1710, 1731],
          [1731, 1732, 1643, 1643],
          [1643, 1662, 1608, 1615],
          [1615, 1667, 1615, 1665],
          [1665, 1689, 1663, 1671],
          [1671, 1671, 1587, 1588],
          [1588, 1599, 1521, 1533],
          [1533, 1554, 1476, 1490],
          [1490, 1494, 1432, 1443]
        ]
      ],
      type: 'candlestick', // for ESM specify as: candlestick()
      colors: {
        data1: 'green'
      },
      labels: true
    },
    candlestick: {
      color: {
        down: 'red'
      },
      width: {
        ratio: 0.5
      }
    },
    axis: {
      x: {
        padding: {
          left: 1,
          right: 1
        }
      }
    }
  }
};

export const faExamples = {
  mix: [
    {
      key: 'day',
      type: 'input',
      props: {
        type: 'date',
        label: 'Day of the trip',
        required: true
      }
    },
    {
      key: 'checkboxesLabel',
      type: 'checkboxesLabel',
      props: {
        label: 'Label test'
      }
    },
    {
      key: 'markup',
      type: 'markup',
      props: {
        markup: 'Markup test'
      }
    },
    {
      key: 'divider',
      type: 'divider'
    },
    {
      key: 'subTitle',
      type: 'subTitle',
      props: {
        label: 'Subtitle test'
      }
    },
    {
      key: 'stepper',
      type: 'stepper',
      props: { orientation: 'horizontal', buttons: true },
      fieldGroup: [
        {
          props: { label: 'Personal data' },
          fieldGroup: [
            {
              key: 'firstname',
              type: 'autocomplete',
              props: {
                label: 'First name',
                required: true,
                terms: ['tata', 'toto', 'test']
              },
              validators: {
                validation: ['autocomplete']
              }
            },
            {
              key: 'email',
              type: 'input',
              defaultValue: 'test@gmail.com',
              props: {
                type: 'email',
                label: 'Email',
                placeholder: 'Enter an email',
                description: 'Description',
                required: true
              },
              validators: {
                validation: ['email']
              }
            },
            {
              key: 'age',
              type: 'input',
              props: {
                type: 'number',
                label: 'Age',
                required: true
              }
            }
          ]
        },
        {
          props: { label: 'Destination' },
          fieldGroup: [
            {
              key: 'country',
              type: 'input',
              props: {
                label: 'Country',
                required: true
              }
            },
            {
              key: 'Slider',
              type: 'slider',
              props: {
                label: 'Slider label',
                placeholder: 'Slider Placeholder',
                min: 0,
                max: 100,
                step: 3,
                thumbLabel: true,
                description: 'Slider Description',
                required: true
              }
            }
          ]
        },
        {
          props: { label: 'Day of the trip' },
          fieldGroup: [
            {
              key: 'day',
              type: 'input',
              props: {
                type: 'date',
                label: 'Day of the trip',
                required: true
              }
            },
            {
              key: 'select_multi',
              type: 'select',
              defaultValue: ['2', '3'],
              props: {
                label: 'Select Multiple',
                placeholder: 'Placeholder',
                description: 'Description',
                required: true,
                multiple: true,
                selectAllOption: 'Select All',
                options: [
                  {
                    value: '1',
                    label: 'Option 1'
                  },
                  {
                    value: '2',
                    label: 'Option 2'
                  },
                  {
                    value: '3',
                    label: 'Option 3'
                  },
                  {
                    value: '4',
                    label: 'Option 4'
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  inceptionStepper: [
    {
      key: 'stepper',
      type: 'stepper',
      props: { orientation: 'horizontal', buttons: true },
      fieldGroup: [
        {
          props: { label: 'Personal data' },
          fieldGroup: [
            {
              key: 'stepper2',
              type: 'stepper',
              props: { orientation: 'vertical', buttons: true },
              fieldGroup: [
                {
                  props: { label: 'Firstname' },
                  fieldGroup: [
                    {
                      key: 'firstname',
                      type: 'file',
                      props: {
                        label: 'First name',
                        maxSize: 10,
                        fileType: 'image/*',
                        required: true
                      }
                    }
                  ]
                },
                {
                  props: { label: 'Email' },
                  fieldGroup: [
                    {
                      key: 'email',
                      type: 'input',
                      defaultValue: 'test@gmail.com',
                      props: {
                        type: 'email',
                        label: 'Email',
                        placeholder: 'Enter an email',
                        description: 'Description',
                        required: true
                      },
                      validators: {
                        validation: ['email']
                      }
                    }
                  ]
                },
                {
                  props: { label: 'Age' },
                  fieldGroup: [
                    {
                      key: 'age',
                      type: 'input',
                      props: {
                        type: 'number',
                        label: 'Age',
                        required: true
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          props: { label: 'Destination' },
          fieldGroup: [
            {
              key: 'country',
              type: 'input',
              props: {
                label: 'Country',
                required: true
              }
            },
            {
              key: 'Slider',
              type: 'slider',
              props: {
                label: 'Slider label',
                placeholder: 'Slider Placeholder',
                min: 0,
                max: 100,
                step: 3,
                thumbLabel: true,
                description: 'Slider Description',
                required: true
              }
            }
          ]
        },
        {
          props: { label: 'Day of the trip' },
          fieldGroup: [
            {
              key: 'day',
              type: 'input',
              props: {
                type: 'date',
                label: 'Day of the trip',
                required: true
              }
            },
            {
              key: 'select_multi',
              type: 'select',
              defaultValue: ['2', '3'],
              props: {
                label: 'Select Multiple',
                placeholder: 'Placeholder',
                description: 'Description',
                required: true,
                multiple: true,
                selectAllOption: 'Select All',
                options: [
                  {
                    value: '1',
                    label: 'Option 1'
                  },
                  {
                    value: '2',
                    label: 'Option 2'
                  },
                  {
                    value: '3',
                    label: 'Option 3'
                  },
                  {
                    value: '4',
                    label: 'Option 4'
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  verticalStepper: [
    {
      key: 'stepper',
      type: 'stepper',
      props: { orientation: 'horizontal', buttons: true },
      fieldGroup: [
        {
          props: { label: 'Personal data' },
          fieldGroup: [
            {
              key: 'firstname',
              type: 'input',
              props: {
                label: 'First name',
                required: true
              }
            },
            {
              key: 'email',
              type: 'input',
              defaultValue: 'test@gmail.com',
              props: {
                type: 'email',
                label: 'Email',
                placeholder: 'Enter an email',
                description: 'Description',
                required: true
              },
              validators: {
                validation: ['email']
              }
            },
            {
              key: 'age',
              type: 'input',
              props: {
                type: 'number',
                label: 'Age',
                required: true
              }
            }
          ]
        },
        {
          props: { label: 'Destination' },
          fieldGroup: [
            {
              key: 'country',
              type: 'input',
              props: {
                label: 'Country',
                required: true
              }
            },
            {
              key: 'Slider',
              type: 'slider',
              props: {
                label: 'Slider label',
                placeholder: 'Slider Placeholder',
                min: 0,
                max: 100,
                step: 3,
                thumbLabel: true,
                description: 'Slider Description',
                required: true
              }
            }
          ]
        },
        {
          props: { label: 'Day of the trip' },
          fieldGroup: [
            {
              key: 'day',
              type: 'input',
              props: {
                type: 'date',
                label: 'Day of the trip',
                required: true
              }
            },
            {
              key: 'select_multi',
              type: 'select',
              defaultValue: ['2', '3'],
              props: {
                label: 'Select Multiple',
                placeholder: 'Placeholder',
                description: 'Description',
                required: true,
                multiple: true,
                selectAllOption: 'Select All',
                options: [
                  {
                    value: '1',
                    label: 'Option 1'
                  },
                  {
                    value: '2',
                    label: 'Option 2'
                  },
                  {
                    value: '3',
                    label: 'Option 3'
                  },
                  {
                    value: '4',
                    label: 'Option 4'
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  fileUpload: [
    {
      key: 'file',
      type: 'file',
      props: {
        description: 'File description',
        required: true
      }
    },
    {
      key: 'file2',
      type: 'file',
      props: {
        description: 'File description',
        required: true
      }
    },
    {
      key: 'file3',
      type: 'file',
      props: {
        description: 'File description',
        required: true
      }
    }
  ],
  g2Validation: [
    {
      key: 'textarea',
      type: 'textarea',
      props: {
        label: 'Textarea',
        placeholder: 'Placeholder',
        description: 'Description',
        required: true
      },
      validators: {
        validation: [G2_SYNTAX_VALIDATOR]
      }
    }
  ]
};

export const markmapExamples = {
  explanation: `# Root
##  Open
- <https://dev.g2-view.com/>
- [G2View](https://dev.g2-view.com/)
## Closed --FOLDED--
- Leaf
- Leaf
## Features
- Max depth: root + 5 nodes
- Normal text
- **inline** ~~text~~ *styles*
- multiline
text`,
  explanationHyphens: `- Root
  - Open
    - <https://dev.g2-view.com/>
    - [G2View](https://dev.g2-view.com/)
  - Closed --FOLDED--
    - Leaf
    - Leaf
  - Features
    - Normal text
    - **inline** ~~text~~ *styles*
    - multiline
text`,
  hyphens: `- root 
  - A 释量论·成量品释科判
  - B全品分三： 277
  - C 甲一、宣说佛为量士夫分二： 277--FOLDED--
  - D 乙一、宣说量之总法相分三： 277
      - E 丙一、宣说正量之法相： 277
      - F 丙二、其中否定成立理： 277
          - G 丙三、遣除于此之辩诤分三： 281
              - 丁一、唯识是量合理性： 281
              - 丁二、量论并非无意义： 282
              - 丁三、依法相说此一义分二： 284
                  - 戊一、真实说： 284
                  - 戊二、断疑虑： 285
      - 乙二、是故说佛为正量分二： 286
          - 丙一、安立成量之自宗： 286
          - 丙二、破许自生量他宗分二： 287
              - 丁一、遮破正量为常有： 287
              - 丁二、遮破具量自在天分二： 288
                  - 戊一、观察彼常无常破： 288
                  - 戊二、破是一切之作者分二： 289
                      - 己一、无能立分二： 289
                          - 庚一、说非真实之能立： 289
                          - 庚二、详细说明彼之理分三： 291
                              - 辛一、真实分二： 291
                                  - 壬一、形义不能堪当因分二： 291
                                      - 癸一、具特点形无宗法： 291
                                      - 癸二、唯形是因不决定分三： 292
                                          - 子一、不定： 292
                                          - 子二、若定极过分： 294
                                          - 子三、遣成果同似能破： 294
                                  - 壬二、唯独形名非是因分二： 296
                                      - 癸一、唯独形名非因喻： 296
                                      - 癸二、若是因则极过分： 297
                              - 辛二、破他宗理： 297
                              - 辛三、宣说彼之成立义： 299
                      - 己二、有能害分三： 302
                          - 庚一、因与非因成相同： 302
                          - 庚二、应成无关因之喻： 303
                          - 庚三、宣说彼宗极过分分二： 304
                              - 辛一、真说分二： 304
                                  - 壬一、随存随灭不成理： 304
                                  - 壬二、是故宣说极过分： 305
                              - 辛二、破彼答复分二： 305
                                  - 壬一、分破不定之二喻： 305
                                  - 壬二、归摄共同之意义： 306
  - 甲二、广说彼为量能立分二： 307--FOLDED--
      - 乙一、安立能立之理证分二： 307
          - 丙一、遮破承许无能立分二： 307
              - 丁一、说对方许： 307
              - 丁二、破彼： 308
          2. 丙二、宣说真实之能立分二： 310
              - 丁一、欲等顺式依道说分二： 310
                  - 戊一、因圆满分二： 310
                      - 己一、各自宣说分二： 310
                          - 庚一、意乐圆满说欲利分二： 310
                              - 辛一、真实宣说： 310
                              - 辛二、遣诤分二： 310
                                  - 壬一、遣除修习不容诤分二： 310
                                      - 癸一、略说： 311
                                      - 癸二、广宣说分二： 311
                                          - 子一、许心依身无能立分二： 311
                                              - 丑一、破他宗分二： 311
                                                  - 寅一、破许非同时所依分二： 311
                                                      - 卯一、宣说彼者无随存： 311
                                                          - 辰一、遮破相似之随存分二： 311
                                                              - 巳一、建立自同类随存： 311
                                                              - 巳二、广说彼之合理性分二： 312
                                                                  - 午一、生之前际续随存分二： 312
                                                                      - 未一、总说分二： 312
                                                                          - 申一、宣说遮破分二： 312
                                                                              - 酉一、一切皆应成产生： 312
                                                                              - 酉二、因果应成颠倒矣： 313
                                                                          - 申二、除违教： 314
                                                                      - 未二、分别破常法： 315
                                                                  - 午二、后际相续随存理分二： 316
                                                                      - 未一、简略宣说无能立： 316
                                                                      - 未二、详细宣说彼意义分二： 316
                                                                          - 申一、遮破分二： 316
                                                                              - 酉一、比喻不成： 316
                                                                              - 酉二、无推理分二： 317
                                                                                  - 戌一、许不成立： 317
                                                                                  - 戌二、破彼分二： 317
                                                                                      - 亥一、破由具根身所生： 317
                                                                                      - 亥二、破由无根身所生： 318
                                                                          - 申二、除诤论分二： 319
                                                                              - 酉一、遣除并存不容有： 319
                                                                              - 酉二、除依能依变非理： 320
                                                          - 辰二、成义自宗立随存： 320
                                                      - 卯二、宣说彼者无随灭分二： 321
                                                          - 辰一、总破分二： 321
                                                              - 巳一、说无随灭之立宗： 321
                                                              - 巳二、广立彼之合理性分二： 321
                                                                  - 午一、说过失： 321
                                                                  - 午二、破遣过分二： 321
                                                                      - 未一、顺缘不全答非理分二： 321
                                                                          - 申一、遮破： 321
                                                                          - 申二、自不同理： 322
                                                                      - 未二、具有违缘非答复分二： 323
                                                                          - 申一、前答复分二： 323
                                                                              - 酉一、宣说他宗： 323
                                                                              - 酉二、破彼： 324
                                                                          - 申二、后答复分二： 324
                                                                              - 酉一、宣说他宗： 324
                                                                              - 酉二、破彼分三： 325
                                                                                  - 戍一、略说： 325
                                                                                  - 戍二、广解： 325
                                                                                  - 戍三、说摄义： 326
                                                          - 辰二、别破身常有： 327 （11课）
                                                              - 巳一、真实说： 327
                                                              - 巳二、除彼疑： 328
                                                  - 寅二、遮破同时之所依分二： 329
                                                      - 卯一、破不合理之他宗分二： 329
                                                          - 辰一、观察能依有无破分二： 329
                                                              - 巳一、观察能依有无破： 329
                                                                  - 午一、遮破： 329
                                                                  - 午二、破彼答： 331
                                                              - 巳二、依彼理破其余理： 332
                                                          - 辰二、观察能依生灭破分二： 334
                                                              - 巳一、观察灭式而遮破： 334
                                                              - 巳二、观察增式而遮破分二： 335
                                                                  - 午一、真破分二： 335
                                                                      - 未一、真实： 335
                                                                      - 未二、破彼答复： 336
                                                                  - 午二、破他理： 337
                                                      - 卯二、安立自宗合理性分二： 338
                                                          - 辰一、真实： 338
                                                          - 辰二、他非理： 338
                                              - 丑二、立自宗分二： 339
                                                  - 寅一、真实自宗： 339
                                                  - 寅二、除诤论： 340
                                          - 子二、宣说彼者有能害分二： 341
                                              - 丑一、非由有支所产生分二： 342
                                                  - 寅一、宣说有支有妨害： 342
                                                  - 寅二、遣除破彼非理诤分二： 343
                                                      - 卯一、彼无然见等合理分二： 343
                                                          - 辰一、遣除见等非理诤分二： 343
                                                              - 巳一、真实： 343
                                                              - 巳二、依彼破他理： 344
                                                          - 辰二、以喻说他许合理分二： 344
                                                              - 巳一、真实： 344
                                                              - 巳二、破彼答复： 346
                                                      - 卯二、有支虽无宜用词分二： 348
                                                          - 辰一、破许真名之有境分二： 348
                                                              - 巳一、说无实境运用理： 348
                                                              - 巳二、破分真假他答复： 348
                                                          - 辰二、说是反体有境理分三： 350
                                                              - 巳一、数等用词之原因： 350
                                                              - 巳二、宣说彼名之必要： 351
                                                              - 巳三、依彼说理及说喻分三： 351
                                                                  - 午一、诠法有法之名称： 351
                                                                  - 午二、诠聚聚支之名称： 352
                                                                  - 午三、依彼教他亦通达： 355
                                              - 丑二、破由分支而产生分二： 356
                                                  - 寅一、非由一切分支生： 356
                                                  - 寅二、非由一一分支生分二： 356
                                                      - 卯一、真实： 356
                                                      - 卯二、破彼遣过分二： 357
                                                          - 辰一、遮破分二： 357
                                                              - 巳一、略说： 357
                                                              - 巳二、广宣说分二： 357
                                                                  - 午一、无分一类息非因： 357
                                                                  - 午二、有分他类非是因分二： 358
                                                                      - 未一、多是多因不合理： 359
                                                                      - 未二、多是一因不合理： 360
                                                          - 辰二、自不相同分二： 361
                                                              - 巳一、真实： 361
                                                              - 巳二、他不同此分二： 362
                                                                  - 午一、不同之理： 362
                                                                  - 午二、除疑分二： 362
                                                                      - 未一、略说： 362
                                                                      - 未二、证彼合理分二： 364
                                                                          - 申一、他宗破彼无能害： 364
                                                                          - 申二、说他能立无意义： 365
                                  - 壬二、遣修无边不容有分二： 366
                                      - 癸一、辩论： 366
                                      - 癸二、答复彼分二： 366
                                          - 子一、差别改变分二： 366
                                              - 丑一、略说： 366
                                              - 丑二、广宣说分二： 367
                                                  - 寅一、改变不变说差别： 367
                                                  - 寅二、彼等结合差别基： 368
                                          - 子二、无量分二： 369
                                              - 丑一、真实： 369
                                              - 丑二、除诤论分二： 370
                                                  - 寅一、遣除他亦成无量： 370
                                                  - 寅二、遣由种子生之诤： 371
                          - 庚二、加行圆满说导师分三： 373
                              - 辛一、由悲修习方便生： 373
                              - 辛二、如何证悟方便理： 373
                              - 辛三、修习方便说导师： 376
                      - 己二、结尾： 377
                  - 戊二、果圆满分二： 378
                      - 己一、自利圆满说善逝分二： 378
                          - 庚一、略说： 378
                          - 庚二、广宣说分三： 378
                              - 辛一、善妙而逝： 379
                              - 辛二、不退而逝： 379
                              - 辛三、无余而逝分二： 380
                                  - 壬一、真实： 380
                                  - 壬二、除彼诤分二： 381
                                      - 癸一、略说： 381
                                      - 癸二、广宣说： 381
                      - 己二、他利圆满说救护： 382
              - 丁二、救等逆式说能知分四： 384
                  - 戊一、宣说救护自性者分二： 384
                      - 己一、宣讲四谛说救护： 384
                      - 己二、宣说四谛各自性分四： 384
                          - 庚一、宣说当知之苦谛分二： 384
                              - 辛一、宣说痛苦之事相分二： 385
                                  - 壬一、真实： 385
                                  - 壬二、破邪分别分二： 385
                                      - 癸一、破无轮回之承许： 385
                                      - 癸二、破彼遣过之答复分三： 386
                                          - 子一、破无轮回之依据分二： 386
                                              - 丑一、风等惑因不合理分二： 386
                                                  - 寅一、略说： 386
                                                  - 寅二、广宣说分二： 386
                                                      - 卯一、遮破分三： 387
                                                          - 辰一、因果应成皆错乱： 387
                                                          - 辰二、因果应成颠倒矣分二： 389
                                                              - 巳一、真实： 390
                                                              - 巳二、破彼答复分二： 390
                                                                  - 午一、对方承许： 390
                                                                  - 午二、破彼分二： 390
                                                                      - 未一、真破： 391
                                                                      - 未二、破除过失： 391
                                                          - 辰三、应成决定唯一果： 392
                                                      - 卯二、自不同理： 393
                                              - 丑二、亦破大种许为因分二： 393
                                                  - 寅一、真实破： 393
                                                  - 寅二、除诤论分二： 394
                                                      - 卯一、除教义说之相违： 394
                                                      - 卯二、除于彼时之诤论分二： 395
                                                          - 辰一、遣除无别不成诤： 395
                                                          - 辰二、于彼他许不相同分二： 396
                                                              - 巳一、宣说他许有妨害： 396
                                                              - 巳二、于彼自宗不相同： 398
                                          - 子二、立有轮回之依据分二： 399
                                              - 丑一、真实： 399
                                              - 丑二、破彼诤论： 399
                                          - 子三、说无轮回有妨害分三： 401
                                              - 丑一、略说： 401
                                              - 丑二、广说分二： 401
                                                  - 寅一、唯大种因太过分： 401
                                                  - 寅二、大种差别不合理分二： 402
                                                      - 卯一、真实： 402
                                                      - 卯二、破彼答复分二： 403
                                                          - 辰一、遮破意义理： 403
                                                          - 辰二、遮破比喻理分二： 404
                                                              - 巳一、比喻意义不相同： 404
                                                              - 巳二、若许相同太过分： 405
                                              - 丑三、摄义： 406
                              - 辛二、宣说苦谛之法相分四： 407
                                  - 壬一、无常： 407
                                  - 壬二、苦： 407
                                  - 壬三、空： 407
                                  - 壬四、无我分二： 408
                                      - 癸一、真实： 408
                                      - 癸二、彼合理性： 408
                          - 庚二、宣说当断之集谛分二： 409
                              - 辛一、建立痛苦具有因： 409
                              - 辛二、认清痛苦彼之因分二： 409
                                  - 壬一、遮破非理之他许分二： 410
                                      - 癸一、遮破承许无有因分二： 410
                                          - 子一、真实： 410
                                          - 子二、除诤论： 410
                                      - 癸二、破自在天等他因： 411
                                  - 壬二、建立理成之自宗分三： 411
                                      - 癸一、认识生因即是爱： 412
                                      - 癸二、结合教证宣说义： 413
                                      - 癸三、遣除于彼之诤论： 414
                          - 庚三、宣说当得之灭谛分二： 416
                              - 辛一、建立痛苦有灭尽； 416
                              - 辛二、遮彼不容之疑虑分二： 417
                                  - 壬一、无我缚解则合理分二： 417
                                      - 癸一、我非轮回之自性： 417
                                      - 癸二、我执说为轮回因分二： 418
                                          - 子一、真实： 418
                                          - 子二、除彼诤论分二： 419
                                              - 丑一、离贪不住轮回中分二： 419
                                                  - 寅一、真实： 419
                                                  - 寅二、除彼疑虑分二： 419
                                                      - 卯一、除以业住之诤论： 419
                                                      - 卯二、除以悲住之诤论： 420
                                                      - 卯三、宣说住亦无过失： 421
                                              - 丑二、断除道初无有有分二： 422
                                                  - 寅一、略说： 422
                                                  - 寅二、广宣说分二： 423
                                                      - 卯一、宣说俱生我见体： 423
                                                      - 卯二、彼无无有有之理： 423
                                  - 壬二、有我缚解不合理分二： 423
                                      - 癸一、常我无缚无解脱： 423
                                      - 癸二、不可说我无有彼： 424
                          - 庚四、宣说当修之道谛分二： 426
                              - 辛一、无我永是解脱道分二： 426
                                  - 壬一、真实： 426
                                  - 壬二、除彼诤论分四： 426
                                      - 癸一、遣除断治能力同分二： 426
                                          - 子一、略说： 426
                                          - 子二、广宣说分二： 427
                                              - 丑一、无有勤奋无退转分二： 427
                                                  - 寅一、现前永久无能力： 427
                                                  - 寅二、纵非现前亦不退： 429
                                              - 丑二、纵有勤奋亦不退： 429
                                      - 癸二、除烦恼非无余尽分二： 429
                                          - 子一、贪等他法不毁他： 429
                                          - 子二、认识摧毁之对治分二： 430
                                              - 丑一、宣说所毁诸过根分二： 431
                                                  - 寅一、真实： 431
                                                  - 寅二、除彼疑虑： 431
                                              - 丑二、宣说能毁之对治： 432
                                      - 癸三、除惑永尽不可能： 433
                                      - 癸四、遣除虽尽复退转： 433
                              - 辛二、彼外余道非如是分二： 434
                                  - 壬一、有我执中不解脱分二： 434
                                      - 癸一、真实： 434
                                      - 癸二、说彼之理分三： 435
                                          - 子一、唯有我中无离贪分三： 435
                                              - 丑一、略说： 435
                                              - 丑二、广说分二： 435
                                                  - 寅一、遮破见过而除贪分二： 436
                                                      - 卯一、唯见过失不能断： 436
                                                      - 卯二、无有过失亦过分： 437
                                                  - 寅二、遮破修苦而除贪分二： 438
                                                      - 卯一、唯知痛苦非离贪分二： 438
                                                          - 辰一、唯知痛苦非离贪分二： 438
                                                              - 巳一、唯见有境为痛苦分二： 438
                                                                  - 午一、唯见痛苦亦不除： 438
                                                                  - 午二、依彼不舍我所心分三： 440
                                                                      - 未一、并非始终是痛苦： 440
                                                                      - 未二、纵然本是不成见分二： 442
                                                                          - 申一、断除贪因不合理分二： 442
                                                                              - 酉一、贪因之我无有断： 442
                                                                              - 酉二、是故知苦无实义： 445
                                                                          - 申二、虽贪变成无过失： 446
                                                                      - 未三、虽见不断我所心： 447
                                                              - 巳二、了知异体非离贪： 449
                                                          - 辰二、宣说离贪即如何： 451
                                                      - 卯二、释说观修痛苦义： 451
                                              - 丑三、末义： 452
                                          - 子二、虽有解脱我无义： 453
                                          - 子三、是故教诫断我执： 453
                                  - 壬二、破许解脱之余道分二： 453
                                      - 癸一、破依自在教解脱分二： 453
                                          - 子一、唯教非为真能立： 454
                                          - 子二、遮破其是合理性分二： 454
                                              - 丑一、遮破能立似现量分二： 454
                                                  - 寅一、种子力失非能立： 454
                                                  - 寅二、重变成轻亦非理： 455
                                              - 丑二、宣说比量有妨害分二： 456
                                                  - 寅一、生因与彼不相违分二： 456
                                                      - 卯一、真实： 456
                                                      - 卯二、破彼除过分二： 457
                                                          - 辰一、未见失毁答非理： 457
                                                          - 辰二、说与彼许实相违： 459
                                                  - 寅二、立因相违之自宗： 461
                                      - 癸二、破由业身尽解脱分二： 462
                                          - 子一、真实： 462
                                          - 子二、破彼答复： 464
                  - 戊二、说由救护知善逝： 467
                  - 戊三、说由善逝知导师： 468
                  - 戊四、说由导师知具悲： 468
      - 乙二、摄集彼之一切义： 468
  
  - 甲三、如是宣说之必要： 469`
};

export const mermaidExamples = {
  flowChartCall: `graph LR;
  A[alert]-->B[mermaidProcessClickCallBack];
  B-->C[github];
  click A alert
  click B call mermaidProcessClickCallBack(0f0e02377c2711eb82a8169f74ea5649, meraKey, B, HIDE, 855941a87c2611eb82a8169f74ea5649)
  click C href "http://www.github.com" _blank`,
  flowchartTD: `graph TD
  A[Start] --> B{Is it?};
  B -->|Yes| C[OK];
  C --> D[Rethink];
  D --> B;
  B ---->|No| E[End]
  E --> F;`,
  flowchartTDLarge: `graph TB
  sq[Square shape] --> ci((Circle shape))

  subgraph A
      od>Odd shape]-- Two line<br/>edge comment --> ro
      di{Diamond with <br/> line break} -.-> ro(Rounded<br>square<br>shape)
      di==>ro2(Rounded square shape)
  end

  %% Notice that no text in shape are added here instead that is appended further down
  e --> od3>Really long text with linebreak<br>in an Odd shape]

  %% Comments after double percent signs
  e((Inner / circle<br>and some odd <br>special characters)) --> f(,.?!+-*ز)

  cyr[Cyrillic]-->cyr2((Circle shape Начало));

  classDef green fill:#9f6,stroke:#333,stroke-width:2px;
  classDef orange fill:#f96,stroke:#333,stroke-width:4px;
  class sq,e green
  class di orange`,
  flowchartLR: `graph LR
  A -- text --> B -- text2 --> C`,
  flowchartLRLarge: `graph LR
  A -- text --> B -- text2 --> C -- text3 --> D -- text4 --> E -- text5 --> F -- text6 --> G`,
  sequenceDiagram: `sequenceDiagram
  autonumber
  Alice->>John: Hello John, how are you?
  loop Healthcheck
      John->>John: Fight against hypochondria
  end
  Note right of John: Rational thoughts!
  John-->>Alice: Great!
  John->>Bob: How about you?
  Bob-->>John: Jolly good!
  John->>Bob: How about you?
  Bob-->>John: Jolly good!
  John->>Bob: How about you?
  Bob-->>John: Jolly good!
  John->>Bob: How about you?
  Bob-->>John: Jolly good!
  John->>Bob: How about you?
  Bob-->>John: Jolly good!
  John->>Bob: How about you?
  Bob-->>John: Jolly good!
  John->>Bob: How about you?
  Bob-->>John: Jolly good!`,
  classDiagram: `classDiagram
  Customer "1" --> "*" Ticket
  Student "1" --> "1..*" Course
  Galaxy --> "many" Star : Contains`,
  stateDiagram: `stateDiagram-v2
  [*] --> Still
  Still --> [*]

  Still --> Moving
  Moving --> Still
  Moving --> Crash
  Crash --> [*]`,
  stateDiagramLarge: `stateDiagram-v2
[*] --> Active

state Active {
    [*] --> NumLockOff
    NumLockOff --> NumLockOn : EvNumLockPressed
    NumLockOn --> NumLockOff : EvNumLockPressed
    --
    [*] --> CapsLockOff
    CapsLockOff --> CapsLockOn : EvCapsLockPressed
    CapsLockOn --> CapsLockOff : EvCapsLockPressed
    --
    [*] --> ScrollLockOff
    ScrollLockOff --> ScrollLockOn : EvScrollLockPressed
    ScrollLockOn --> ScrollLockOff : EvScrollLockPressed
}`,
  er: `erDiagram
  CUSTOMER }|..|{ DELIVERY-ADDRESS : has
  CUSTOMER ||--o{ ORDER : places
  CUSTOMER ||--o{ INVOICE : "liable for"
  DELIVERY-ADDRESS ||--o{ ORDER : receives
  INVOICE ||--|{ ORDER : covers
  ORDER ||--|{ ORDER-ITEM : includes
  PRODUCT-CATEGORY ||--|{ PRODUCT : contains
  PRODUCT ||--o{ ORDER-ITEM : "ordered in"`,
  journey: `journey
  title My working day
  section Go to work
    Make tea: 5: Me
    Go upstairs: 3: Me
    Do work: 1: Me, Cat
  section Go home
    Go downstairs: 5: Me
    Sit down: 5: Me`,
  gantt: `gantt
  title A Gantt Diagram
  dateFormat  YYYY-MM-DD
  section Section
  A task           :a1, 2014-01-01, 30d
  Another task     :after a1  , 20d
  section Another
  Task in sec      :a2, 2014-01-12  , 12d
  another task      : 24d
  click a1 call mermaidProcessClickCallBack(0f0e02377c2711eb82a8169f74ea5649, meraKey, B, HIDE, 855941a87c2611eb82a8169f74ea5649)`,
  pie: `pie
  title Pets adopted by volunteers
  "Dogs" : 386
  "Cats" : 85
  "Rats" : 15`
};
