<ng-container *transloco="let t; scope: 'app'; read: 'app.threeDViewer'">
  <ngx-g2v-3d-viewer [divId]="threeDViewerConfig.divId" [displayModalLink]="false" [modelPaths]="threeDViewerConfig.modelPaths" [cameraEye]="threeDViewerConfig.cameraEye"
    [cameraCenter]="threeDViewerConfig.cameraCenter" [cameraUp]="threeDViewerConfig.cameraUp" [cameraFieldOfView]="threeDViewerConfig.cameraFieldOfView"
    [backgroundColor]="threeDViewerConfig.backgroundColor" [defaultColor]="threeDViewerConfig.defaultColor" [showEdges]="threeDViewerConfig.showEdges"
    [edgeThreshold]="threeDViewerConfig.edgeThreshold" [edgeColor]="threeDViewerConfig.edgeColor" [environmentMapFolderPath]="threeDViewerConfig.environmentMapFolderPath"
    [displayWebInterfaceURL]="false" [backgroundIsEnvMap]="threeDViewerConfig.backgroundIsEnvMap" [initVisibility]="threeDViewerConfig.initVisibility"
    [initZoom]="threeDViewerConfig.initZoom" [initColors]="threeDViewerConfig.initColors">
  </ngx-g2v-3d-viewer>
  <a (click)="onExitModal()" mat-icon-button color="warn" class="button-modal-exit" title="{{ t('buttonExitTitle') }}">
    <mat-icon fontIcon="close"></mat-icon>
  </a>
</ng-container>