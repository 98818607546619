import { Observable, Subject, takeUntil } from 'rxjs';
import { Timeline } from 'vis-timeline/standalone';

import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TimelineModalComponent } from '@components/timeline/timeline-modal/timeline-modal.component';
import { MAT_DIALOG_CONFIG, TIMELINE_REFRESH_INTERVAL_TIME } from '@config/constants';
import { debounce } from '@decorators/debounce.decorator';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { TimelineArea, WA_DISPLAY_FULL_SCREEN_DEFAULT, WorkspaceSession } from '@g2view/g2view-commons';
import { ModalConfig } from '@interfaces/modal-config';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ShortcutsService } from '@services/shortcuts.service';
import { VisibilityService } from '@services/visibility.service';
import { WorkspaceAreasService } from '@workspaces/services/workspace-areas.service';

// TODO3: add/remove/update item/group ?

const MODAL_WIDTH_PERCENT = 95;
const SMALL_TIMEOUT_MS = 10; // This timeout is needed when a TIMA is updated via a WS update to let the time for the div to be re-created.

interface ComponentState {
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineComponent extends StatefulComponentDirective<ComponentState> implements OnInit, OnChanges {
  @Input() public readonly timelineArea: TimelineArea | undefined;
  @Input() public readonly displayModalLink: boolean = WA_DISPLAY_FULL_SCREEN_DEFAULT;
  @Input() private readonly workspaceInfo: WorkspaceSession | undefined;
  @Input() private readonly workspaceResize$: Observable<void> = new Observable();
  @Input() private readonly workspaceUpdate$: Observable<void> = new Observable();

  private dialogMA: MatDialogRef<TimelineModalComponent> | undefined;

  private timeline: Timeline | undefined;
  private timelineRef: ElementRef | undefined;
  private timelineConfigSubject: Subject<TimelineConfig> = new Subject<TimelineConfig>();

  private timeoutInitFirstRefresh: NodeJS.Timeout | undefined;

  constructor(
    public readonly visibilityService: VisibilityService,
    private readonly workspaceAreasService: WorkspaceAreasService,
    private readonly shortcutsService: ShortcutsService,
    private readonly dialog: MatDialog,
    private readonly errorHandler: ErrorHandlerService
  ) {
    super({
      loaded: false,
      error: undefined
    });
  }

  @ViewChild('timelineDiv') set content(content: ElementRef) {
    if (content) {
      this.timelineRef = content;
      this.styling();
    }
  }

  @HostListener('window:resize', ['$event'])
  @debounce(100)
  private onResize(): void {
    refitTimeline(this.timeline);
  }

  @HostListener('window:orientationchange', ['$event']) private onOrientationChange(): void {
    refitTimeline(this.timeline);
  }

  public ngOnInit(): void {
    this.updateComponentState({
      loaded: true
    });
    this.initSubscriptions();
    this.initVisibilitySub();
    this.initUnsub();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    delete changes.workspaceResize$;
    delete changes.workspaceUpdate$;
    if (Object.keys(changes).length > 0 && this.timelineArea && this.workspaceInfo) {
      this.timelineConfigSubject.next({
        timelineArea: this.timelineArea,
        workspaceInfo: this.workspaceInfo
      });
      this.refreshTimeline();
    }
  }

  public onGoToModal(): void {
    this.openTimelineAreaModal();
  }

  private openTimelineAreaModal(): void {
    this.shortcutsService.setActive(false);
    if (!this.timelineArea || !this.workspaceInfo) {
      return;
    }
    const percent = `${MODAL_WIDTH_PERCENT}%`;
    const config: ModalConfig<TimelineConfig> = {
      config$: this.timelineConfigSubject.asObservable(),
      initConfig: {
        timelineArea: this.timelineArea,
        workspaceInfo: this.workspaceInfo
      }
    };
    this.dialogMA = this.dialog.open(TimelineModalComponent, {
      ...MAT_DIALOG_CONFIG,
      width: percent,
      maxWidth: percent,
      height: percent,
      data: config
    });
    this.dialogMA.afterClosed().subscribe({
      next: () => {
        this.shortcutsService.setActive(true);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private refreshTimeline(): void {
    if (this.issetTimelineInDOM() && this.getTimelineDOMWidth() > 0) {
      if (this.timeoutInitFirstRefresh) {
        clearTimeout(this.timeoutInitFirstRefresh);
      }
      setTimeout(() => {
        this.renderTimeline();
      }, SMALL_TIMEOUT_MS);
    } else {
      if (this.timeoutInitFirstRefresh) {
        clearTimeout(this.timeoutInitFirstRefresh);
      }
      this.timeoutInitFirstRefresh = setTimeout(() => {
        this.refreshTimeline();
      }, TIMELINE_REFRESH_INTERVAL_TIME);
    }
  }

  private renderTimeline(): void {
    this.destroyTimeline();
    if (this.timelineArea && this.timelineRef) {
      const options: any = { ...this.timelineArea.options };
      if (this.timelineArea.options.utcOffset) {
        // eslint-disable-next-line no-new-func
        options.moment = Function('date', `return vis.moment(date).utcOffset("${this.timelineArea.options.utcOffset}")`);
        delete options.utcOffset;
      }
      const setCurrentTimeMillis = this.timelineArea.options.setCurrentTimeMillis ?? null;
      delete options.setCurrentTimeMillis;
      this.timeline = new Timeline(
        this.timelineRef.nativeElement,
        this.timelineArea.items.map((i) => ({ ...i })),
        this.timelineArea.groups.map((g) => ({ ...g })),
        options
      );
      if (setCurrentTimeMillis) {
        this.timeline.setCurrentTime(setCurrentTimeMillis);
      }
      if (this.timelineArea.listenToSelectEvent) {
        this.timeline.on('select', (properties: { event: any; items: Array<string> }) => {
          if (!this.workspaceInfo || !this.timelineArea) {
            return;
          }
          const itemIds = properties.items;
          if (itemIds.length > 0) {
            this.workspaceAreasService.processClick({
              uuid: this.workspaceInfo.uuid,
              workspaceLeft: this.workspaceInfo.left,
              workspaceTop: this.workspaceInfo.top,
              workspaceZoom: this.workspaceInfo.zoom,
              waType: 'timas',
              key: this.timelineArea.key,
              eventType: this.timelineArea.type,
              timaData: { itemIds }
            });
          }
        });
      }
      this.styling();
    }
  }

  private initSubscriptions(): void {
    this.workspaceResize$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        refitTimeline(this.timeline);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
    this.workspaceUpdate$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        if (this.dialogMA) {
          this.dialogMA.close();
        }
      }
    });
  }

  private initVisibilitySub(): void {
    this.visibilityService.pageVisible.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        refitTimeline(this.timeline);
      }
    });
  }

  private destroyTimeline(): void {
    if (this.timeline) {
      this.timeline.destroy();
    }
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.timeoutInitFirstRefresh) {
          clearTimeout(this.timeoutInitFirstRefresh);
        }
        if (this.timelineArea && this.timelineArea.listenToSelectEvent && this.timeline) {
          this.timeline.off('select');
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private styling(): void {
    if (!this.timelineRef || !this.timelineArea) {
      return;
    }
    this.timelineRef.nativeElement.style.setProperty('--wa-background-color', this.timelineArea.colors.areaBackground);
    this.timelineRef.nativeElement.style.setProperty('--background-color', this.timelineArea.colors.timelineBackground);
    this.timelineRef.nativeElement.style.setProperty('--item-background-color', this.timelineArea.colors.itemBackground);
    this.timelineRef.nativeElement.style.setProperty('--item-text-color', this.timelineArea.colors.itemColor);
    this.timelineRef.nativeElement.style.setProperty('--group-text-color', this.timelineArea.colors.groupColor);
    this.timelineRef.nativeElement.style.setProperty('--column-text-color', this.timelineArea.colors.timeColor);
  }

  private getTimelineInDomId(): string {
    return `timeline-${this.timelineArea?._id}`;
  }

  private getTimelineInDom(): null | HTMLElement {
    return document.getElementById(this.getTimelineInDomId());
  }

  private issetTimelineInDOM(): boolean {
    return this.getTimelineInDom() ? true : false;
  }

  private getTimelineDOMWidth(): number {
    if (this.issetTimelineInDOM()) {
      const element = this.getTimelineInDom();
      if (element) {
        return element.clientWidth;
      }
    }
    return 0;
  }
}

export const refitTimeline = (timeline: Timeline | undefined): void => {
  if (timeline) {
    timeline.redraw();
  }
};

export interface TimelineConfig {
  timelineArea: TimelineArea;
  workspaceInfo: WorkspaceSession;
}
