import { fromEvent, Observable, partition } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import { ErrorHandlerService } from '@services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService implements OnDestroy {
  private readonly pageHidden$: Observable<Event>;
  private readonly pageVisible$: Observable<Event>;
  private readonly _pageHiddenAfterAfkTime: EventEmitter<Event> = new EventEmitter<Event>();
  private readonly _pageVisibleAfterAfkTime: EventEmitter<Event> = new EventEmitter<Event>();
  private isAfk = false;
  private timer: NodeJS.Timeout | undefined;

  constructor(private readonly errorHandler: ErrorHandlerService) {
    if (!this.isPageVisible) {
      this.launchTimer(new Event('visibilitychange'));
    }

    const visibilityChange$ = fromEvent(document, 'visibilitychange').pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    [this.pageVisible$, this.pageHidden$] = partition(visibilityChange$, () => this.isPageVisible);

    /*const whenPageVisible = (): (<T>(source: Observable<T>) => Observable<T>) => <T>(source: Observable<T>): Observable<T> =>
      source.pipe(
        repeatWhen(() => pageVisible$),
        takeUntil(pageHidden$)
      );*/

    this.pageHidden$.subscribe({
      next: (event) => {
        this.launchTimer(event);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });

    this.pageVisible$.subscribe({
      next: (event) => {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        if (this.isAfk) {
          this._pageVisibleAfterAfkTime.emit(event);
        }
        this.isAfk = false;
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  get isPageVisible(): boolean {
    return document.visibilityState === 'visible';
  }

  get pageHiddenAfterAfkTime(): Observable<Event> {
    return this._pageHiddenAfterAfkTime.asObservable();
  }

  get pageVisibleAfterAfkTime(): Observable<Event> {
    return this._pageVisibleAfterAfkTime.asObservable();
  }

  get pageVisible(): Observable<Event> {
    return this.pageVisible$;
  }

  public ngOnDestroy(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  private launchTimer(event: Event): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    const afkTime = environment.settings.afk.time;
    if (afkTime > 0) {
      this.timer = setTimeout(() => {
        this.isAfk = true;
        this._pageHiddenAfterAfkTime.emit(event);
      }, afkTime);
    }
  }
}
