<ng-container *transloco="let t; scope: 'app'; read: 'app.mermaidChart'">
  <ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="state.loaded">
      <ng-container *ngIf="state.maxHeight as maxHeight">
        <div fxLayout="column" fxLayoutGap="10px" class="action-buttons">
          <a *ngIf="displayModalLink" mat-icon-button color="accent" class="button" (click)="onGoToModal()" title="{{ t('buttonModalTitle') }}">
            <mat-icon fontIcon="fullscreen"></mat-icon>
          </a>
          <a *ngIf="displayEditLink" mat-icon-button color="accent" class="button" [href]="state.editLink" target="_blank" title="{{ t('buttonEditTitle') }}">
            <mat-icon fontIcon="edit"></mat-icon>
          </a>
        </div>
        <div [id]="'mermaid-' + chartId" #mermaidDiv class="mermaid-div" [style.height]="fullHeight ? maxHeight + 'px' : styleHeight"></div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!state.loaded">
      <ngx-g2v-progress-spinner></ngx-g2v-progress-spinner>
    </ng-container>
    <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
  </ng-container>
</ng-container>