import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FrameConfig } from '@components/frame/frame.component';
import { ModalConfig } from '@interfaces/modal-config';

@Component({
  selector: 'ngx-g2v-frame-modal',
  templateUrl: './frame-modal.component.html',
  styleUrls: ['./frame-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FrameModalComponent implements AfterViewInit {
  public frameConfig: FrameConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly config: ModalConfig<FrameConfig>,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialogRef: MatDialogRef<FrameModalComponent>
  ) {
    this.frameConfig = { ...config.initConfig };
  }

  public ngAfterViewInit(): void {
    this.config.config$.subscribe({
      next: (config) => {
        this.frameConfig = { ...config };
        this.cdr.detectChanges();
      }
    });
  }

  public onExitModal(): void {
    this.dialogRef.close();
  }
}
