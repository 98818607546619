import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { STATE_G2VIEW_CORE } from '@config/constants';
import { hasEnoughRoles } from '@config/utils';
import { UserAuth } from '@core/state/core.state';
import { Store } from '@ngxs/store';

@Directive({
  selector: '[accessControl]'
})
export class AccessControlDirective implements OnInit {
  @Input() public clientRoles: Array<string> = [];
  @Input() public realmRoles: Array<string> = [];
  @Input() public accountRoles: Array<string> = [];

  constructor(private readonly elementRef: ElementRef, private readonly store: Store) {}

  public ngOnInit(): void {
    this.elementRef.nativeElement.style.display = 'none';
    this.checkAccess();
  }

  private checkAccess(): void {
    const user = this.store.selectSnapshot<UserAuth>((state) => state[STATE_G2VIEW_CORE].userAuth);

    this.elementRef.nativeElement.style.display = hasEnoughRoles(
      user.clientRoles,
      user.realmRoles,
      user.accountRoles,
      this.clientRoles,
      this.realmRoles,
      this.accountRoles
    )
      ? 'inline-flex'
      : 'none';
  }
}
