import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { refreshPage } from '@config/utils';
import { StatefulComponentDirective } from '@directives/stateful.directive';

interface ComponentState {
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-snack-bar-disconnect',
  templateUrl: './snack-bar-disconnect.component.html',
  styleUrls: ['./snack-bar-disconnect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackBarDisconnectComponent extends StatefulComponentDirective<ComponentState> implements OnInit {
  constructor(public snackBar: MatSnackBar, @Inject(MAT_SNACK_BAR_DATA) public data: unknown) {
    super({
      loaded: false,
      error: undefined
    });
  }

  public ngOnInit(): void {
    this.updateComponentState({
      loaded: true
    });
  }

  public refresh(): void {
    this.snackBar.dismiss();
    refreshPage();
  }
}
