import { FormlyFieldConfig } from '@ngx-formly/core';

export function applyAutocompleteExtension(field: FormlyFieldConfig): void {
  if (field.type !== 'input') {
    return;
  }

  field.props = field.props || {};
  field.props.attributes = field.props.attributes || {};
  field.props.attributes.autocomplete = 'off';
}
