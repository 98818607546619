import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LoggerService } from '@services/logger.service';
import { UserService } from '@services/user.service';

const DEFAULT_NOTIFICATION_TIMEOUT = 10000;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private readonly logger: LoggerService,
    private readonly userService: UserService,
    private readonly translocoService: TranslocoService
  ) {
    this.logger.log('trace', 'Init of NotificationService');
    this.init();
  }

  /**
    * t(app.notifications.newDashboardAccessRequest.title,
      app.notifications.newDashboardAccessRequest.body,
      app.notifications.newSessionAccessRequest.title,
      app.notifications.newSessionAccessRequest.body,
      app.notifications.newDashboard.title,
      app.notifications.newDashboard.body,
      app.notifications.newSession.title,
      app.notifications.newSession.body)
    */
  public sendNotification(
    translationKey: string,
    titleArgs = {},
    bodyArgs = {},
    userIdsToOmit: Array<string> = [],
    timeout = DEFAULT_NOTIFICATION_TIMEOUT
  ): void {
    if (!this.isNotificationAvailable() || userIdsToOmit.includes(this.userService.currentUserId)) {
      return;
    }
    const title = this.translocoService.translate(`notifications.${translationKey}.title`, titleArgs, 'app');
    const options = {
      body: this.translocoService.translate(`notifications.${translationKey}.body`, bodyArgs, 'app'),
      tag: translationKey,
      icon: 'assets/images/logo.png'
    };
    const n = new Notification(title, options);
    if (timeout > 0) {
      setTimeout(() => {
        n.close();
      }, timeout);
    }
  }

  private init(): void {
    this.logger.log('trace', 'Init of NotificationService -> init');
    this.requestPermission();
  }

  private requestPermission(): void {
    if (this.isNotificationAvailable()) {
      Notification.requestPermission();
    }
  }

  private isNotificationAvailable(): boolean {
    return 'Notification' in window;
  }
}
