<ng-container *transloco="let t; scope: 'app'; read: 'app.markmapChart'">
  <ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="state.loaded">
      <div fxLayout="column" fxLayoutGap="10px" class="action-buttons">
        <a *ngIf="displayModalLink" mat-icon-button color="accent" class="button" (click)="onGoToModal()" title="{{ t('buttonModalTitle') }}">
          <mat-icon fontIcon="fullscreen"></mat-icon>
        </a>
      </div>
      <svg [id]="'markmap-' + chartId" #markmapDiv class="markmap-div"></svg>
    </ng-container>
    <ng-container *ngIf="!state.loaded">
      <ngx-g2v-progress-spinner></ngx-g2v-progress-spinner>
    </ng-container>
    <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
  </ng-container>
</ng-container>