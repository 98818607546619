import { KeycloakService } from 'keycloak-angular';
import { filter, takeUntil } from 'rxjs/operators';

import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { NavigationEnd, Router } from '@angular/router';
import { I18N_FLAG_FOLDER } from '@config/constants';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { environment } from '@environments/environment';
import { MenuItem } from '@interfaces/menu-item';
import { TranslocoService } from '@ngneat/transloco';
import { CacheService } from '@services/cache.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { HelpService } from '@services/help.service';
import { LayoutService } from '@services/layout.service';
import { TimerService } from '@services/timer.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let VanillaTilt: any;

interface ComponentState {
  langs: Array<Lang>;
  date: Date;
  activeMenuGroup: string;
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent extends StatefulComponentDirective<ComponentState> implements OnInit, AfterViewInit {
  @Input() public menuItems: Array<MenuItem> = [];
  @Input() public title = '';
  @Input() public logoUrl = '';
  @Input() public defaultLang = '';

  @ViewChild('languageSwitch') private readonly languageSwitch: MatSelect | undefined;

  public currentLang = this.defaultLang;
  public i18nFlagsFolder = I18N_FLAG_FOLDER;
  public buildHash = environment.buildHash === 'undefined' ? 'local' : environment.buildHash;

  constructor(
    private readonly errorHandler: ErrorHandlerService,
    private readonly keycloakService: KeycloakService,
    public readonly layoutService: LayoutService,
    private readonly timerService: TimerService,
    private readonly transloco: TranslocoService,
    private readonly router: Router,
    private readonly cacheService: CacheService,
    private readonly helpService: HelpService
  ) {
    super({
      langs: [],
      date: new Date(),
      activeMenuGroup: '',
      loaded: false,
      error: undefined
    });
  }

  public ngOnInit(): void {
    this.initLang();
    this.initSub();
  }

  public ngAfterViewInit(): void {
    if (this.layoutService.isDesktop) {
      VanillaTilt.init(document.querySelector('.logo-and-title'), { max: 25, speed: 400, glare: false, scale: 1.1 });
    }
  }

  public setActiveLang(event: MatSelectChange): void {
    this.currentLang = event.value;
    this.transloco.setActiveLang(this.currentLang);
    this.cacheService.updateConfigItem('user', '', 'lang', this.currentLang);
  }

  public onFlagClick(): void {
    if (!this.languageSwitch) {
      return;
    }
    this.languageSwitch.open();
  }

  public onHelpClick(): void {
    this.helpService.openHelp();
  }

  public menuItemsTrackBy(index: number, item: MenuItem): string {
    return item.key;
  }

  public langsTrackBy(index: number, item: Lang): string {
    return item.id;
  }

  public onLogout(): void {
    this.keycloakService.logout().catch(this.errorHandler.handleError);
  }

  private initSub(): void {
    this.init();
    this.initTimerSub();
    this.initRouterSub();
  }

  private init(): void {
    this.updateComponentState({
      langs: this.transloco.getAvailableLangs() as Array<Lang>,
      loaded: true,
      error: undefined
    });
  }

  private initTimerSub(): void {
    this.timerService.timer$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (date) => {
        this.updateComponentState({ date });
      }
    });
  }

  private initRouterSub(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => {
          this.updateActiveMenuGroup(this.getUrlKey());
        },
        error: (err: unknown) => this.errorHandler.handleError(err)
      });
  }

  private initLang(): void {
    this.defaultLang = this.cacheService.getConfigItem('user', '', 'lang');
    this.currentLang = this.defaultLang;
  }

  private getUrlKey(): string {
    const url = this.router.url;
    const urlExploded = url.split('/');

    return urlExploded[1];
  }

  private updateActiveMenuGroup(activeMenu: string): void {
    this.updateComponentState({
      activeMenuGroup: activeMenu === 'admin' ? 'users' : activeMenu // Admin pages are in 'users' menu super-item
    });
  }
}

interface Lang {
  id: string;
  label: string;
}
