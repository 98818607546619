import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { Observable, Subject, takeUntil } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DASHBOARD_PATH, DASHBOARDS_PATH, SESSIONS_PATH, STATE_G2VIEW_CORE } from '@config/constants';
import { SetUserAuth, SetUserData } from '@core/state/core.actions';
import { UserAuth, UserData } from '@core/state/core.state';
import { DbasService } from '@dashboards/services/dbas.service';
import { environment } from '@environments/environment';
import { extractDataFromToken, RedirectToEvent } from '@g2view/g2view-commons';
import { Store } from '@ngxs/store';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';
import { BackendService } from '@services/backend.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { LoggerService } from '@services/logger.service';

const NUMBER_REFRESH_TOKEN_PER_PERIOD = 10; // Number of times that a refresh of the token will be done (to allow a few miss)
const MIN_TIME_BETWEEN_REFRESH_MS = 10000;

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  private refreshValuesTimeout: NodeJS.Timeout | undefined;
  private readonly destroy$: Subject<void> = new Subject<void>();

  private minValidityTokenSec = 0;

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly backendService: BackendService,
    private readonly http: ApiHttpService,
    private readonly apiEndpointsService: ApiEndpointsService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly dbasService: DbasService,
    private readonly errorHandler: ErrorHandlerService
  ) {}

  get currentUser(): UserData {
    return this.store.selectSnapshot<UserData>((state) => state[STATE_G2VIEW_CORE].userData);
  }

  get currentUserId(): string {
    return this.currentUser.userId;
  }

  get currentUsername(): string {
    return this.currentUser.username;
  }

  get currentUserGroupIds(): Array<string> {
    return this.currentUser.groupIds;
  }

  get currentUserAuth(): UserAuth {
    return this.store.selectSnapshot<UserAuth>((state) => state[STATE_G2VIEW_CORE].userAuth);
  }

  get currentToken(): string {
    return this.currentUserAuth.token;
  }

  get currentTokenExpTime(): number {
    return this.currentUserAuth.tokenExpTime;
  }

  get currentG2Roles(): Array<string> {
    return this.currentUserAuth.clientRoles.filter((r) => r.startsWith(environment.keycloak.g2Namespace));
  }

  get currentG2Role(): string {
    return this.store.selectSnapshot<string>((state) => state[STATE_G2VIEW_CORE].currentG2Role);
  }

  get currentClientRoles(): Array<string> {
    return this.currentUserAuth.clientRoles.filter((r) => !r.startsWith(environment.keycloak.g2Namespace));
  }

  get currentRealmRoles(): Array<string> {
    return this.currentUserAuth.realmRoles;
  }

  get currentSocketId(): string {
    return this.store.selectSnapshot<string>((state) => state[STATE_G2VIEW_CORE].socketId);
  }

  get currentMaxSessions(): number {
    return this.store.selectSnapshot<number>((state) => state[STATE_G2VIEW_CORE].maxSessions);
  }

  public ngOnDestroy(): void {
    if (this.refreshValuesTimeout) {
      clearTimeout(this.refreshValuesTimeout);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  public init(): void {
    if (environment.keycloak.enabled) {
      this.keycloakEventsSubscription();
    } else {
      this.refreshValues(true);
    }
  }

  public askKeycloakToRefreshToken(): void {
    this.forceRefreshToken();
  }

  public redirectUserTo(data: RedirectToEvent): void {
    const urlInfo = this.getUrlInfo(data);
    switch (data.target) {
      case 'dashboards':
        this.router.navigate([DASHBOARDS_PATH]).catch(this.errorHandler.handleError);
        break;
      case 'dashboard':
        if (urlInfo.alreadyOnDashboardView) {
          this.dbasService.emitClick({
            targetView: data.viewId,
            framesSwitchUuid: data.wsOnFrames
          });
        } else {
          if (data.viewId) {
            if (urlInfo.alreadyOnDashboard) {
              this.dbasService.emitClick({
                targetView: data.viewId,
                framesSwitchUuid: data.wsOnFrames
              });
            } else {
              if (urlInfo.alreadyOnAnotherDashboard) {
                this.router
                  .navigate([DASHBOARDS_PATH])
                  .then(() => {
                    this.router
                      .navigate([DASHBOARD_PATH, data.id, data.viewId])
                      .then(() => {
                        this.dbasService.emitClick({
                          targetView: data.viewId,
                          framesSwitchUuid: data.wsOnFrames
                        });
                      })
                      .catch(this.errorHandler.handleError);
                  })
                  .catch(this.errorHandler.handleError);
              } else {
                this.router
                  .navigate([DASHBOARD_PATH, data.id, data.viewId])
                  .then(() => {
                    this.dbasService.emitClick({
                      targetView: data.viewId,
                      framesSwitchUuid: data.wsOnFrames
                    });
                  })
                  .catch(this.errorHandler.handleError);
              }
            }
          } else {
            if (!urlInfo.alreadyOnDashboard) {
              this.router.navigate([DASHBOARD_PATH, data.id]).catch(this.errorHandler.handleError);
            }
          }
        }
        break;
      case 'sessions':
        this.router.navigate([SESSIONS_PATH]).catch(this.errorHandler.handleError);
        break;
      case 'session':
        if (!urlInfo.alreadyOnSession) {
          this.router.navigate([SESSIONS_PATH]).then(() => {
            this.router.navigate([SESSIONS_PATH, data.id]).catch(this.errorHandler.handleError);
          });
        }
        break;
    }
  }

  public getUrlInfo(data: { id: string; viewId: string }): URLInfo {
    const url = this.router.url;
    const urlExploded = url.split('/');
    const alreadyOnADashboard = urlExploded.length >= 2 && urlExploded[1] === DASHBOARD_PATH;
    const alreadyOnDashboard = alreadyOnADashboard && urlExploded[2] === data.id;
    const alreadyOnAnotherDashboard = alreadyOnADashboard && !alreadyOnDashboard;
    const alreadyOnDashboardView = alreadyOnDashboard && urlExploded.length >= 3 && urlExploded[3] === data.viewId;
    const alreadyOnSession = urlExploded.length >= 2 && urlExploded[1] === SESSIONS_PATH && urlExploded[2] === data.id;
    return {
      alreadyOnDashboard,
      alreadyOnAnotherDashboard,
      alreadyOnDashboardView,
      alreadyOnSession
    };
  }

  private forceRefreshToken(): void {
    this.keycloakService.clearToken();
  }

  private refreshValues(updateGroupIds = false): void {
    if (environment.keycloak.enabled) {
      this.keycloakService.updateToken(this.minValidityTokenSec).then(() => {
        this.keycloakService.getToken().then((token) => {
          if (token) {
            const data = extractDataFromToken(token, environment.keycloak.clientId);
            this.logger.log(
              'log',
              `Keycloak token - Token expiration: ${data.expDate.toLocaleString()} (in ${Math.round(
                data.expTime - new Date().getTime() / 1000
              )} sec)`,
              { token }
            );
            this.minValidityTokenSec = Math.round(Math.max(data.expTime - new Date().getTime() / 1000, this.minValidityTokenSec));
            const timeToRefresh = Math.round(
              Math.max(MIN_TIME_BETWEEN_REFRESH_MS, (data.expTime * 1000 - new Date().getTime()) / NUMBER_REFRESH_TOKEN_PER_PERIOD)
            );
            this.logger.log(
              'log',
              `Keycloak token - Next token refresh: ${new Date(new Date().getTime() + timeToRefresh).toLocaleString()} (in ${
                timeToRefresh / 1000
              } sec)`,
              { 'New validity token (sec)': this.minValidityTokenSec }
            );
            if (this.refreshValuesTimeout) {
              clearTimeout(this.refreshValuesTimeout);
            }
            this.refreshValuesTimeout = setTimeout(() => {
              this.refreshValues(false);
            }, timeToRefresh);
            this.store.dispatch(
              new SetUserAuth({
                token,
                tokenExpTime: data.expTime,
                clientRoles: data.clientRoles,
                realmRoles: data.realmRoles,
                accountRoles: data.accountRoles
              })
            );
            if (updateGroupIds) {
              this.backendService.checkBackendVersion();
              this.getGroupIds$()
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: (groupIds) => {
                    this.store.dispatch(
                      new SetUserData({
                        username: data.username,
                        name: data.name,
                        userId: data.userId,
                        groupIds
                      })
                    );
                  }
                });
            } else {
              this.store.dispatch(
                new SetUserData({
                  username: data.username,
                  name: data.name,
                  userId: data.userId,
                  groupIds: this.currentUserGroupIds
                })
              );
            }
          } else {
            this.logger.log('error', `New keycloak token is empty !`);
          }
        }, this.errorHandler.handleError);
      }, this.errorHandler.handleError);
    } else {
      const token = environment.keycloak.token;
      const data = extractDataFromToken(token, environment.keycloak.clientId);
      this.store.dispatch(
        new SetUserAuth({
          token,
          tokenExpTime: data.expTime,
          clientRoles: data.clientRoles,
          realmRoles: data.realmRoles,
          accountRoles: data.accountRoles
        })
      );
      this.store.dispatch(
        new SetUserData({
          username: data.username,
          name: data.name,
          userId: data.userId,
          groupIds: []
        })
      );
    }
  }

  private getGroupIds$(): Observable<Array<string>> {
    return this.http.get(this.apiEndpointsService.getGroupIdsEndpoint());
  }

  private keycloakEventsSubscription(): void {
    this.keycloakService.keycloakEvents$.subscribe({
      next: (data) => {
        switch (data.type) {
          case KeycloakEventType.OnAuthError:
            this.logger.log('error', 'Keycloak event: OnAuthError');
            break;
          case KeycloakEventType.OnAuthLogout:
            this.logger.log('info', 'Keycloak event: OnAuthLogout');
            break;
          case KeycloakEventType.OnAuthRefreshError:
            this.logger.log('error', 'Keycloak event: OnAuthRefreshError');
            this.refreshValues(false);
            break;
          case KeycloakEventType.OnAuthRefreshSuccess:
            this.logger.log('log', 'Keycloak event: OnAuthRefreshSuccess');
            break;
          case KeycloakEventType.OnAuthSuccess:
            this.logger.log('log', 'Keycloak event: OnAuthSuccess');
            break;
          case KeycloakEventType.OnReady:
            this.logger.log('log', 'Keycloak event: OnReady');
            this.refreshValues(true);
            break;
          case KeycloakEventType.OnTokenExpired:
            this.logger.log('warn', 'Keycloak event: OnTokenExpired');
            break;
          default:
            this.logger.log('info', 'Keycloak event');
            break;
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }
}

interface URLInfo {
  alreadyOnDashboard: boolean;
  alreadyOnAnotherDashboard: boolean;
  alreadyOnDashboardView: boolean;
  alreadyOnSession: boolean;
}
