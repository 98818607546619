import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-g2v-component-error',
  templateUrl: './component-error.component.html',
  styleUrls: ['./component-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentErrorComponent {
  @Input() public error: unknown;
}
