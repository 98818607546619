import { combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap, startWith, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ADMIN_G2_PATH, ADMIN_PATH } from '@config/constants';
import { hasEnoughRoles } from '@config/utils';
import { CoreState, DEFAULT_LAYOUT_MODEL, LayoutModel } from '@core/state/core.state';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { GET_G2_INFO_ROLES } from '@g2view/g2view-commons';
import { MenuItem } from '@interfaces/menu-item';
import { Select } from '@ngxs/store';
import { g2Status } from '@services/server.service';
import { UserService } from '@services/user.service';

interface ComponentState {
  layout: LayoutModel;
  hasTopBar: boolean;
  backgroundColor: string;
  g2StatusIcon: g2StatusIcon;
  displayIcons: boolean;
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent extends StatefulComponentDirective<ComponentState> implements OnInit {
  @Select(CoreState.SelectLayout) public layout$: Observable<LayoutModel> | undefined;

  @Input() public menuItems: Array<MenuItem> = [];
  @Input() public title = '';
  @Input() public logoUrl = '';
  @Input() public defaultLang = '';

  public canAccessG2Page = false;
  public g2RouterLink = ['/', ADMIN_PATH, ADMIN_G2_PATH];
  public clientRolesToAccessG2Page = GET_G2_INFO_ROLES;
  public clientRolesToSwitchDevMode = GET_G2_INFO_ROLES;

  constructor(public readonly userService: UserService, private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {
    super({
      layout: DEFAULT_LAYOUT_MODEL,
      hasTopBar: true,
      backgroundColor: '',
      g2StatusIcon: getIconFromG2Status(defaultG2Status),
      displayIcons: false,
      loaded: false,
      error: undefined
    });
    this.canAccessG2Page = hasEnoughRoles(this.userService.currentClientRoles, [], [], GET_G2_INFO_ROLES, [], []);
  }

  @Input() set g2Status(g2Status: g2Status) {
    this.updateComponentState({ g2StatusIcon: getIconFromG2Status(g2Status) });
  }

  public ngOnInit(): void {
    if (!this.layout$) {
      return;
    }

    const router$ = this.router.events
      .pipe(
        filter((events) => events instanceof NavigationEnd),
        startWith(this.router),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        takeUntil(this.destroy$)
      )
      .pipe(
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      );
    const layout$ = this.layout$.pipe(takeUntil(this.destroy$));

    combineLatest([router$, layout$]).subscribe({
      next: ([routerData, layout]) => {
        this.updateComponentState({
          layout,
          hasTopBar: !(routerData.hasTopBar === false),
          backgroundColor: routerData.useCustomBackgroundColor ? layout.backgroundColor : '',
          displayIcons: routerData.displayIcons ?? true,
          loaded: true,
          error: undefined
        });
      },
      error: (error: unknown) => {
        this.setComponentState({
          layout: DEFAULT_LAYOUT_MODEL,
          hasTopBar: true,
          backgroundColor: '',
          g2StatusIcon: getIconFromG2Status(defaultG2Status),
          displayIcons: false,
          loaded: true,
          error
        });
      }
    });
  }
}

type g2StatusIcon = 'wifi_find' | 'wifi' | 'wifi_off';
const defaultG2Status: g2Status = 'check';

const getIconFromG2Status = (g2Status: g2Status): g2StatusIcon => {
  switch (g2Status) {
    case 'check':
      return 'wifi_find';
    case 'online':
      return 'wifi';
    case 'offline':
      return 'wifi_off';
  }
};
