import { EMPTY, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ErrorHandlerService } from '@services/error-handler.service';
import { LoggerService } from '@services/logger.service';
import { UserService } from '@services/user.service';

const FEATHERS = environment.serverUrl;

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  constructor(
    private readonly userService: UserService,
    private readonly logger: LoggerService,
    private readonly router: Router,
    private readonly errorHandler: ErrorHandlerService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.userService.currentToken;
    const tokenExpTime = this.userService.currentTokenExpTime;
    const socketId = this.userService.currentSocketId;
    const begin = performance.now();

    if (request.url.startsWith(FEATHERS)) {
      if (request.url.endsWith('$limit=0')) {
        return of(new HttpResponse({ status: 200, body: { total: 0, skip: 0, data: [] } })).pipe(
          finalize(() => {
            this.logger.log('debug', `${logIntro(request, begin)} has been automatically responded with [].`, {
              body: request.body,
              'full-token': token,
              socketId
            });
          })
        );
      }
      const requestWithSocketId = environment.keycloak.enabled
        ? request.clone({
            headers: request.headers.set('socketid', socketId)
          })
        : request.clone({
            headers: request.headers.set('socketid', socketId),
            setHeaders: { Authorization: `bearer ${token}` }
          });
      if (tokenExpTime !== 0 && tokenExpTime * 1000 < new Date().getTime()) {
        this.reloadPage();
        return EMPTY.pipe(
          finalize(() => {
            this.logger.log('warn', `${logIntro(request, begin)} has been canceled since the token has expired.`, {
              body: requestWithSocketId.body,
              tokenExpTime,
              'Date of token expiration': new Date(tokenExpTime * 1000).toLocaleString(),
              authToken: token,
              socketId
            });
          })
        );
      }
      return next.handle(requestWithSocketId).pipe(
        finalize(() => {
          this.logger.log('debug', `${logIntro(request, begin)}`, {
            body: requestWithSocketId.body,
            authToken: token,
            socketId
          });
        })
      );
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.logger.log('debug', `${logIntro(request, begin)}`, {
          body: request.body,
          authToken: token,
          socketId
        });
      })
    );
  }

  private reloadPage(): void {
    this.router
      .navigate(['/'])
      .then(() => {
        location.reload();
      })
      .catch(this.errorHandler.handleError);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logIntro = (request: HttpRequest<any>, begin: number): string => {
  const time = Math.round(performance.now() - begin);
  return `HTTP ${request.method} to ${cleanURL(request.urlWithParams)} (${time}ms)`;
};

const cleanURL = (url: string): string => url.replace(environment.keycloak.issuer, 'KEYCLOAK').replace(environment.serverUrl, 'BACKEND');
