<ng-container *transloco="let t; scope: 'app'; read: 'app.formly.stepper'">
  <ng-container *ngIf="field.props && field.props.orientation === 'vertical'; then vertical else horizontal"></ng-container>

  <ng-template #vertical>
    <mat-vertical-stepper>
      <mat-step *ngFor="let step of field.fieldGroup; trackBy:stepsTrackBy; let index = index; let last = last;">
        <ng-template matStepLabel>{{ step.props?.label ?? '' }}</ng-template>
        <formly-field [field]="step"></formly-field>
        <div *ngIf="field.props && field.props.buttons !== false">
          <button class="navigation" mat-raised-button matStepperPrevious *ngIf="index !== 0" title="{{ t('buttonPreviousTitle') }}">{{ t('previousLabel') }}</button>
          <button class="navigation" mat-raised-button matStepperNext *ngIf="!last" [disabled]="!isValid(step)" title="{{ t('buttonNextTitle') }}">{{ t('nextLabel') }}</button>
          <!--<button mat-raised-button *ngIf="last" [disabled]="!form.valid" type="submit" title="{{ t('buttonSubmitTitle') }}">Submit</button>-->
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </ng-template>

  <ng-template #horizontal>
    <mat-horizontal-stepper>
      <mat-step *ngFor="let step of field.fieldGroup; trackBy: stepsTrackBy; let index = index; let last = last;">
        <ng-template matStepLabel>{{ step.props?.label ?? '' }}</ng-template>
        <formly-field [field]="step"></formly-field>
        <div *ngIf="field.props && field.props.buttons !== false">
          <button class="navigation" mat-raised-button matStepperPrevious title="{{ t('buttonPreviousTitle') }}" *ngIf="index !== 0">{{ t('previousLabel') }}</button>
          <button class="navigation" mat-raised-button matStepperNext title="{{ t('buttonNextTitle') }}" *ngIf="!last" [disabled]="!isValid(step)">{{ t('nextLabel') }}</button>
          <!--<button mat-raised-button *ngIf="last" [disabled]="!form.valid" type="submit" title="{{ t('buttonSubmitTitle') }}">Submit</button>-->
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-template>

</ng-container>