import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { environment } from '@environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from '@services/error-handler.service';
import { LoggerService } from '@services/logger.service';
import { PageTitleService } from '@services/page-title.service';
import { RedirectService } from '@services/redirect.service';
import { VisibilityService } from '@services/visibility.service';

interface ComponentState {
  loaded: boolean;
  displayButton: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-afk',
  templateUrl: './afk.component.html',
  styleUrls: ['./afk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AfkComponent extends StatefulComponentDirective<ComponentState> implements OnInit {
  private buttonTimer: NodeJS.Timeout | undefined;
  private redirectionTimer: NodeJS.Timeout | undefined;

  constructor(
    private readonly errorHandler: ErrorHandlerService,
    private readonly router: Router,
    private readonly visibilityService: VisibilityService,
    private readonly translocoService: TranslocoService,
    private readonly pageTitleService: PageTitleService,
    private readonly logger: LoggerService,
    private readonly redirectService: RedirectService
  ) {
    super({
      loaded: false,
      displayButton: false,
      error: undefined
    });
  }

  public ngOnInit(): void {
    this.updateComponentState({
      loaded: true
    });
    this.logger.log('trace', `AfkComponent -> ngOnInit -> isPageVisible: ${this.visibilityService.isPageVisible}`);
    if (this.visibilityService.isPageVisible) {
      this.goToPreviousPage();
    }
    this.initSub();
    this.initTranslations();
    this.initUnsub();
  }

  public onReload(): void {
    this.router
      .navigate(['/'])
      .catch(this.errorHandler.handleError)
      .finally(() => {
        location.reload();
      });
  }

  private goToPreviousPage(): void {
    this.logger.log('trace', 'AfkComponent -> goToPreviousPage -> previousPath', this.redirectService.previousPath.split('/'));
    this.router.navigate(this.redirectService.previousPath.split('/')).catch(this.errorHandler.handleError);
  }

  private initSub(): void {
    this.visibilityService.pageVisible.pipe(takeUntil(this.destroy$)).subscribe({
      next: (event) => {
        this.logger.log('trace', 'AfkComponent -> ngOnInit -> pageVisible', event);
        if (this.buttonTimer) {
          clearTimeout(this.buttonTimer);
        }
        this.buttonTimer = setTimeout(() => {
          this.updateComponentState({
            displayButton: true
          });
        }, environment.settings.afk.displayButtonsTime);
        if (this.redirectionTimer) {
          clearTimeout(this.redirectionTimer);
        }
        this.redirectionTimer = setTimeout(() => {
          this.goToPreviousPage();
        }, environment.settings.afk.redirectTime);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private initTranslations(): void {
    /**
     * t(app.afk.pageTitle)
     */
    this.translocoService
      .selectTranslate(['afk.pageTitle'], {}, 'app')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (translations) => {
          this.pageTitleService.setTitle([translations[0]]);
        },
        error: (err: unknown) => this.errorHandler.handleError(err)
      });
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.buttonTimer) {
          clearTimeout(this.buttonTimer);
        }
        if (this.redirectionTimer) {
          clearTimeout(this.redirectionTimer);
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }
}
