import { Observable, takeUntil } from 'rxjs';

import { Injectable } from '@angular/core';
import { RxJSBaseDirective } from '@app/shared/directives/rxjs-base.directive';
import { environment } from '@environments/environment';
import { BackendInfoResponse } from '@g2view/g2view-commons';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslocoService } from '@ngneat/transloco';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';

@Injectable({
  providedIn: 'root'
})
export class BackendService extends RxJSBaseDirective {
  constructor(
    private readonly toast: HotToastService,
    private readonly translocoService: TranslocoService,
    private readonly http: ApiHttpService,
    private readonly apiEndpointsService: ApiEndpointsService
  ) {
    super();
  }

  public checkBackendVersion(): void {
    this.getBackendData$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          if (environment.envName !== 'LOCAL' && data.version !== environment.buildHash) {
            import(`assets/i18n/app/${this.translocoService.getActiveLang()}.json`).then(() => {
              this.toast.error(this.translocoService.translate('toast.backend.differentVersion', {}, 'app'), {
                autoClose: false,
                dismissible: true,
                position: 'bottom-center'
              });
            });
          }
        }
      });
  }

  private getBackendData$(): Observable<BackendInfoResponse> {
    return this.http.get(this.apiEndpointsService.getBackendInfoEndpoint());
  }
}
