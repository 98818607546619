import { KeycloakService } from 'keycloak-angular';
import { Observable, takeUntil } from 'rxjs';
import * as io from 'socket.io-client';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ModalMessageComponent } from '@components/modal-message/modal-message.component';
import { SnackBarDisconnectComponent } from '@components/snack-bar-disconnect/snack-bar-disconnect.component';
import { SnackBarNoBackendComponent } from '@components/snack-bar-no-backend/snack-bar-no-backend.component';
import { ERROR_SOCKET_PATH, MAT_DIALOG_CONFIG, SHARED_PATH } from '@config/constants';
import { intersectArray, refreshPage } from '@config/utils';
import { SetMaxSessions, SetSocketId } from '@core/state/core.actions';
import { CoreState } from '@core/state/core.state';
import { DashboardsService } from '@dashboards/services/dashboards.service';
import {
  AddDashboard,
  AddDashboardAccessRequest,
  AddDashboardFavorite,
  RemoveDashboard,
  RemoveDashboardAccessRequest,
  RemoveDashboardFavorite,
  UpdateDashboard,
  UpdateDashboardInfo
} from '@dashboards/state/dashboards.actions';
import { RxJSBaseDirective } from '@directives/rxjs-base.directive';
import { environment } from '@environments/environment';
import feathers from '@feathersjs/feathers';
import feathersSocketIOClient from '@feathersjs/socketio-client';
import {
  AccessRequest,
  ActionArea,
  AddMAItemsEvent,
  AddTABARowsEvent,
  ChartArea,
  ConnectionArea,
  Dashboard,
  DashboardButtonArea,
  DashboardFavorite,
  DASHBOARDS_ADMIN_CLIENT_ROLES,
  DeleteAllTABARowsEvent,
  DeleteMAItemsEvent,
  DeleteTABARowsEvent,
  DynamicFormEvent,
  EmbeddedVideoArea,
  FormArea,
  FrameArea,
  HighlightMeshesEvent,
  ImageArea,
  MapArea,
  MarkmapArea,
  MenuArea,
  MermaidArea,
  MoveCameraEvent,
  NavigationArea,
  RedirectSocketToEvent,
  RedirectUserToEvent,
  ResetWorkspacesPositionInSessionEvent,
  RoomData,
  RoomDataWrapper,
  RoomServiceResponse,
  SendMessageToDashboardsEvent,
  SendMessageToSessionsEvent,
  Session,
  SessionFavorite,
  SESSIONS_ADMIN_CLIENT_ROLES,
  SliderArea,
  SpeechRecognitionArea,
  TableArea,
  TextArea,
  TimelineArea,
  ToggleLayerGroupsEvent,
  ToggleMeshesVisibilityEvent,
  ToggleWAVisibilityEvent,
  TriggerFileDownloadEvent,
  TypeInBoxArea,
  UpdateChartConfigEvent,
  UpdateChartDataEvent,
  UpdateDashboardInfoEvent,
  UpdateDashboardOwnerEvent,
  UpdateMAItemsEvent,
  UpdateSessionInfoEvent,
  UpdateSessionOwnerEvent,
  UpdateTABARowsEvent,
  UpdateTextEvent,
  Viewer3DArea,
  WorkspaceDB,
  ZoomMAItemsEvent,
  ZoomOnMeshesEvent
} from '@g2view/g2view-commons';
import { Select, Store } from '@ngxs/store';
import { ContextService } from '@services/context.service';
import { DownloadFileService } from '@services/download-file.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { LoggerService } from '@services/logger.service';
import { NotificationService } from '@services/notification.service';
import { ServerService } from '@services/server.service';
import { ShortcutsService } from '@services/shortcuts.service';
import { UserService } from '@services/user.service';
import { SessionsService } from '@sessions/services/sessions.service';
import {
  AddSession,
  AddSessionAccessRequest,
  AddSessionFavorite,
  RemoveSession,
  RemoveSessionAccessRequest,
  RemoveSessionFavorite,
  UpdateCurrentSession,
  UpdateSessionInfo
} from '@sessions/state/sessions.actions';
import { DynamicFormAreaService } from '@workspaces/services/dynamic-form-area.service';
import { WorkspaceAreasService } from '@workspaces/services/workspace-areas.service';
import {
  AddActionAreas,
  AddChartAreas,
  AddConnectionAreas,
  AddDashboardButtonAreas,
  AddEmbeddedVideoAreas,
  AddFormAreas,
  AddFrameAreas,
  AddImageAreas,
  AddMapAreas,
  AddMarkmapAreas,
  AddMenuAreas,
  AddMermaidAreas,
  AddNavigationAreas,
  AddSliderAreas,
  AddSpeechRecognitionAreas,
  AddTableAreas,
  AddTextAreas,
  AddTimelineAreas,
  AddTypeInBoxAreas,
  AddViewer3DAreas,
  AddWorkspaces,
  RefreshWorkspacesImageUrl,
  RemoveActionAreas,
  RemoveChartAreas,
  RemoveConnectionAreas,
  RemoveDashboardButtonAreas,
  RemoveEmbeddedVideoAreas,
  RemoveFormAreas,
  RemoveFrameAreas,
  RemoveImageAreas,
  RemoveMapAreas,
  RemoveMarkmapAreas,
  RemoveMenuAreas,
  RemoveMermaidAreas,
  RemoveNavigationAreas,
  RemoveSliderAreas,
  RemoveSpeechRecognitionAreas,
  RemoveTableAreas,
  RemoveTextAreas,
  RemoveTimelineAreas,
  RemoveTypeInBoxAreas,
  RemoveViewer3DAreas,
  RemoveWorkspaces,
  UpdateActionArea,
  UpdateChartArea,
  UpdateConnectionArea,
  UpdateDashboardButtonArea,
  UpdateEmbeddedVideoArea,
  UpdateFormArea,
  UpdateFrameArea,
  UpdateImageArea,
  UpdateMapArea,
  UpdateMarkmapArea,
  UpdateMenuArea,
  UpdateMermaidArea,
  UpdateNavigationArea,
  UpdateSliderArea,
  UpdateSpeechRecognitionArea,
  UpdateTableArea,
  UpdateTextArea,
  UpdateTimelineArea,
  UpdateTypeInBoxArea,
  UpdateViewer3DArea,
  UpdateWorkspace
} from '@workspaces/state/workspaces.actions';

const ACTION_AFTER_RECONNECT: 'reload' | 'navigate' = 'reload';

@Injectable({
  providedIn: 'root'
})
export class FeathersService extends RxJSBaseDirective {
  @Select(CoreState.SelectUserId) public userId$: Observable<string> | undefined;

  private readonly socket = io.connect(environment.serverUrl, { transports: ['websocket'], timeout: 180000 });
  private readonly client = feathers();

  private hasBeenDisconnected = false;
  private snackBarOpened = false;
  private snackBarRef: MatSnackBarRef<SnackBarNoBackendComponent | SnackBarDisconnectComponent> | undefined;

  constructor(
    private readonly logger: LoggerService,
    private readonly userService: UserService,
    private readonly keycloakService: KeycloakService,
    private readonly contextService: ContextService,
    private readonly workspaceAreasService: WorkspaceAreasService,
    private readonly dashboardsService: DashboardsService,
    private readonly sessionsService: SessionsService,
    private readonly serverService: ServerService,
    private readonly shortcutsService: ShortcutsService,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly dynamicFormAreaService: DynamicFormAreaService,
    private readonly downloadFileService: DownloadFileService,
    private readonly errorHandler: ErrorHandlerService
  ) {
    super();
  }

  public init(): void {
    if (!this.userId$) {
      return;
    }
    this.client.configure(feathersSocketIOClient(this.socket));
    this.userId$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (userId) => {
        if (userId) {
          this.joinAppRoom(userId);
        }
      }
    });

    this.socket.on('connect', () => {
      if (this.hasBeenDisconnected) {
        if (ACTION_AFTER_RECONNECT === 'reload') {
          location.reload();
        } else if (ACTION_AFTER_RECONNECT === 'navigate') {
          this.hideSnackBars();
          const url = this.router.url;
          this.router
            .navigate(['/'])
            .then(() => {
              this.router.navigate([url]);
            })
            .catch(this.errorHandler.handleError);
        }
        this.hasBeenDisconnected = false;
      }
    });

    this.socket.on('connect_error', () => {
      this.hasBeenDisconnected = true;
      this.logger.log('error', 'connect_error');
      this.showSnackToRefresh();
    });
    this.socket.on('connect_failed', () => {
      this.showSnackToReload();
    });
    this.socket.on('disconnect', (reason: any) => {
      this.hasBeenDisconnected = true;
      this.logger.log('error', 'disconnect', reason);
      this.showSnackToRefresh();
    });

    const serverService = this.client.service('server');
    serverService.on('updateG2CurrentModules', (g2Modules: Array<string>) => {
      this.serverService.setCurrentG2ModulesLoaded(g2Modules);
    });

    const roomService = this.client.service('roomMembership');
    roomService.on('refreshUserToken', () => {
      this.userService.askKeycloakToRefreshToken();
    });
    roomService.on('redirectUserTo', (data: RedirectUserToEvent) => {
      this.userService.redirectUserTo(data);
    });
    roomService.on('redirectSocketTo', (data: RedirectSocketToEvent) => {
      this.userService.redirectUserTo(data);
    });

    const dashboardService = this.client.service('dashboard');
    dashboardService.on('created', (dashboard: Dashboard) => {
      if (this.canSeeThisDashboard(dashboard)) {
        this.logger.log('debug', 'Created a dashboard', dashboard);
        this.store.dispatch(new AddDashboard(dashboard));
        this.notificationService.sendNotification('newDashboard', {}, { title: dashboard.title }, [dashboard.owner]);
      }
    });
    dashboardService.on('updated', (dashboard: Dashboard) => {
      if (this.canSeeThisDashboard(dashboard)) {
        this.logger.log('debug', 'Update of a dashboard', dashboard);
        this.store.dispatch(new UpdateDashboard(dashboard));
        if (this.isCurrentDashboard(dashboard)) {
          refreshPage();
        }
      }
    });
    dashboardService.on('removed', (dashboard: Dashboard) => {
      if (this.canSeeThisDashboard(dashboard)) {
        this.logger.log('debug', 'Removed a dashboard', dashboard);
        this.store.dispatch(new RemoveDashboard(dashboard._id ?? ''));
      }
    });
    dashboardService.on('dashboardInfoUpdate', (data: UpdateDashboardInfoEvent) => {
      if (this.dashboardsService.areDashboardsInitialized) {
        const dashboard = this.dashboardsService.getDashboardInStore(data.did);
        const canSeeBeforeUpdate = !!dashboard;
        if (canSeeBeforeUpdate) {
          const canSeeAfterUpdate = this.canSeeThisDashboard({ ...data, owner: dashboard.owner });
          if (canSeeAfterUpdate) {
            this.logger.log('debug', 'Update dashboard info', data);
            this.store.dispatch(new UpdateDashboardInfo(data));
          } else {
            this.logger.log('debug', 'Update dashboard info - dashboard no longer available', data);
            this.store.dispatch(new RemoveDashboard(data.did));
          }
        } else {
          const canSeeAfterUpdateWithoutBeingOwner = this.canSeeThisDashboard({ ...data, owner: '' });
          if (canSeeAfterUpdateWithoutBeingOwner) {
            this.logger.log('debug', 'Update dashboard info - new dashboard', data);
            this.dashboardsService.refreshDashboards();
            this.notificationService.sendNotification('newDashboard', {}, { title: data.title });
          } else {
            this.logger.log('debug', 'Update dashboard info - not applicable', data);
          }
        }
      }
    });
    dashboardService.on('dashboardOwnerUpdate', (data: UpdateDashboardOwnerEventResponse) => {
      if (this.dashboardsService.areDashboardsInitialized) {
        const dashboard = this.dashboardsService.getDashboardInStore(data.did);
        const canSeeBeforeUpdate = !!dashboard;
        if (canSeeBeforeUpdate) {
          const canSeeAfterUpdate = this.canSeeThisDashboard({ ...data, users: dashboard.users, groups: dashboard.groups });
          if (canSeeAfterUpdate) {
            this.logger.log('debug', 'Update dashboard owner', data);
            this.dashboardsService.refreshDashboards();
          } else {
            this.logger.log('debug', 'Update dashboard owner - dashboard no longer available', data);
            this.store.dispatch(new RemoveDashboard(data.did));
          }
        } else {
          const canSeeAfterUpdate = this.canSeeThisDashboard({ ...data, users: [], groups: [] });
          if (canSeeAfterUpdate) {
            this.logger.log('debug', 'Update dashboard owner - new dashboard', data);
            this.dashboardsService.refreshDashboards();
            this.notificationService.sendNotification('newDashboard', {}, { title: data.title });
          } else {
            this.logger.log('debug', 'Update dashboard owner - not applicable', data);
          }
        }
      }
    });
    dashboardService.on('sendMessage', (data: SendMessageToDashboardsEvent) => {
      const context = this.contextService.getContext();
      if (context.dashboard && context.dashboard._id && data.dashboardIds.includes(context.dashboard._id)) {
        this.logger.log('debug', 'Message for this dashboard', data);
        this.shortcutsService.setActive(false);
        const dialog = this.dialog.open(ModalMessageComponent, { ...MAT_DIALOG_CONFIG, data: data.message });
        if (data.ttl > -1) {
          setTimeout(() => {
            dialog.close();
          }, data.ttl);
          dialog.afterClosed().subscribe({
            next: () => {
              this.shortcutsService.setActive(true);
            },
            error: (err: unknown) => this.errorHandler.handleError(err)
          });
        } else {
          this.shortcutsService.setActive(true);
        }
      }
    });

    const sessionService = this.client.service('sessions');
    sessionService.on('created', (session: Session) => {
      if (this.canSeeThisSession(session)) {
        this.logger.log('debug', 'Created a session', session);
        this.store.dispatch(new AddSession(session));
        this.notificationService.sendNotification('newSession', {}, { title: session.title }, [session.owner]);
      }
    });
    sessionService.on('patched', (session: Session) => {
      if (this.canSeeThisSession(session)) {
        this.logger.log('debug', 'Patched a session', session);
        // Update CURRENT Session since only users in this session will receive the message
        this.store.dispatch(new UpdateCurrentSession(session));
      }
    });
    sessionService.on('updated', (session: Session) => {
      if (this.canSeeThisSession(session)) {
        this.logger.log('debug', 'Update of a session', session);
        // Update CURRENT Session since only users in this session will receive the message
        this.store.dispatch(new UpdateCurrentSession(session));
      }
    });
    sessionService.on('removed', (session: Session) => {
      if (this.canSeeThisSession(session)) {
        this.logger.log('debug', 'Removed a session', session);
        this.store.dispatch(new RemoveSession(session._id ?? ''));
      }
    });
    sessionService.on('sessionInfoUpdate', (data: UpdateSessionInfoEvent) => {
      if (this.sessionsService.areSessionsInitialized) {
        const sessionInfo = this.sessionsService.getSessionInfoInStore(data.sid);
        const canSeeBeforeUpdate = !!sessionInfo;
        if (canSeeBeforeUpdate) {
          const canSeeAfterUpdate = this.canSeeThisSession({ ...data, owner: sessionInfo.owner });
          if (canSeeAfterUpdate) {
            this.logger.log('debug', 'Update session info', data);
            this.store.dispatch(new UpdateSessionInfo(data));
          } else {
            this.logger.log('debug', 'Update session info - session no longer available', data);
            this.store.dispatch(new RemoveSession(data.sid));
          }
        } else {
          const canSeeAfterUpdateWithoutBeingOwner = this.canSeeThisSession({ ...data, owner: '' });
          if (canSeeAfterUpdateWithoutBeingOwner) {
            this.logger.log('debug', 'Update session info - new session', data);
            this.sessionsService.refreshSessions();
            this.notificationService.sendNotification('newSession', {}, { title: data.title });
          } else {
            this.logger.log('debug', 'Update session info - not applicable', data);
          }
        }
      }
    });
    sessionService.on('sessionOwnerUpdate', (data: UpdateSessionOwnerEventResponse) => {
      if (this.sessionsService.areSessionsInitialized) {
        const sessionInfo = this.sessionsService.getSessionInfoInStore(data.sid);
        const canSeeBeforeUpdate = !!sessionInfo;
        if (canSeeBeforeUpdate) {
          const canSeeAfterUpdate = this.canSeeThisSession({ ...data, users: sessionInfo.users, groups: sessionInfo.groups });
          if (canSeeAfterUpdate) {
            this.logger.log('debug', 'Update session owner', data);
            this.sessionsService.refreshSessions();
          } else {
            this.logger.log('debug', 'Update session owner - session no longer available', data);
            this.store.dispatch(new RemoveSession(data.sid));
          }
        } else {
          const canSeeAfterUpdate = this.canSeeThisSession({ ...data, users: [], groups: [] });
          if (canSeeAfterUpdate) {
            this.logger.log('debug', 'Update session owner - new session', data);
            this.sessionsService.refreshSessions();
            this.notificationService.sendNotification('newSession', {}, { title: data.title });
          } else {
            this.logger.log('debug', 'Update session owner - not applicable', data);
          }
        }
      }
    });
    sessionService.on('resetDragPosition', (data: ResetWorkspacesPositionInSessionEvent) => {
      this.logger.log('debug', 'Reset session drag position', data);
      const config = this.sessionsService.getSessionConfig(data.sid);
      if (config) {
        this.sessionsService.storeSessionConfig(data.sid, { ...config, position: { top: 0, left: 0 } });
      }
      const url = this.router.url;
      this.router
        .navigate(['/'])
        .then(() => {
          this.router.navigate([url]);
        })
        .catch(this.errorHandler.handleError);
    });
    sessionService.on('sendMessage', (data: SendMessageToSessionsEvent) => {
      const context = this.contextService.getContext();
      if (context.session && context.session._id && data.sessionIds.includes(context.session._id)) {
        this.logger.log('debug', 'Message for this session', data);
        this.shortcutsService.setActive(false);
        const dialog = this.dialog.open(ModalMessageComponent, { ...MAT_DIALOG_CONFIG, data: data.message });
        if (data.ttl > -1) {
          setTimeout(() => {
            dialog.close();
          }, data.ttl);
          dialog.afterClosed().subscribe({
            next: () => {
              this.shortcutsService.setActive(true);
            },
            error: (err: unknown) => this.errorHandler.handleError(err)
          });
        } else {
          this.shortcutsService.setActive(true);
        }
      }
    });

    const dashboardFavoritesService = this.client.service('dashboardFavorite');
    dashboardFavoritesService.on('created', (dashboardFavorite: DashboardFavorite) => {
      this.logger.log('debug', 'Created a dashboard favorite', dashboardFavorite);
      this.store.dispatch(new AddDashboardFavorite(dashboardFavorite));
    });
    dashboardFavoritesService.on('removed', (dashboardFavorite: DashboardFavorite) => {
      this.logger.log('debug', 'Removed a dashboard favorite', dashboardFavorite);
      this.store.dispatch(new RemoveDashboardFavorite(dashboardFavorite));
    });

    const sessionFavoritesService = this.client.service('sessionFavorite');
    sessionFavoritesService.on('created', (sessionFavorite: SessionFavorite) => {
      this.logger.log('debug', 'Created a session favorite', sessionFavorite);
      this.store.dispatch(new AddSessionFavorite(sessionFavorite));
    });
    sessionFavoritesService.on('removed', (sessionFavorite: SessionFavorite) => {
      this.logger.log('debug', 'Removed a session favorite', sessionFavorite);
      this.store.dispatch(new RemoveSessionFavorite(sessionFavorite));
    });

    const accessRequestsService = this.client.service('accessRequests');
    accessRequestsService.on('created', (accessRequest: AccessRequest) => {
      if (accessRequest.type === 'session') {
        const session = this.sessionsService.getSessionInfoInStore(accessRequest.objectDbId);
        if (!!session && this.sessionsService.isAdminOfThisSession(session.owner)) {
          this.logger.log('debug', 'Created a session access request', accessRequest);
          this.store.dispatch(new AddSessionAccessRequest(accessRequest));
          this.notificationService.sendNotification('newSessionAccessRequest', {}, { title: session.title }, [accessRequest.userId]);
        }
      } else {
        const dashboard = this.dashboardsService.getDashboardInStore(accessRequest.objectDbId);
        if (!!dashboard && this.dashboardsService.isAdminOfThisDashboard(dashboard.owner)) {
          this.logger.log('debug', 'Created a dashboard access request', accessRequest);
          this.store.dispatch(new AddDashboardAccessRequest(accessRequest));
          this.notificationService.sendNotification('newDashboardAccessRequest', {}, { title: dashboard.title }, [accessRequest.userId]);
        }
      }
    });
    accessRequestsService.on('removed', (accessRequest: AccessRequest) => {
      if (accessRequest.type === 'session') {
        const session = this.sessionsService.getSessionInfoInStore(accessRequest.objectDbId);
        if (!!session && this.sessionsService.isAdminOfThisSession(session.owner)) {
          this.logger.log('debug', 'Removed a session access request', accessRequest);
          this.store.dispatch(new RemoveSessionAccessRequest(accessRequest));
        }
      } else {
        const dashboard = this.dashboardsService.getDashboardInStore(accessRequest.objectDbId);
        if (!!dashboard && this.dashboardsService.isAdminOfThisDashboard(dashboard.owner)) {
          this.logger.log('debug', 'Removed a dashboard access request', accessRequest);
          this.store.dispatch(new RemoveDashboardAccessRequest(accessRequest));
        }
      }
    });

    const workspacesService = this.client.service('workspaces');
    workspacesService.on('created', (workspace: WorkspaceDB) => {
      this.logger.log('debug', 'Created a workspace', workspace);
      this.store.dispatch(new AddWorkspaces([workspace]));
    });
    workspacesService.on('updateWorkspaceDB', (workspace: WorkspaceDB) => {
      this.logger.log('debug', 'Updated a workspace', workspace);
      this.store.dispatch(new UpdateWorkspace(workspace));
    });
    workspacesService.on('removed', (workspace: WorkspaceDB) => {
      this.logger.log('debug', 'Removed a workspace', workspace);
      this.store.dispatch(new RemoveWorkspaces([workspace]));
    });
    workspacesService.on('updateImage', (uuids: Array<string>) => {
      this.logger.log('debug', 'Image of workspace updated', uuids);
      this.store.dispatch(new RefreshWorkspacesImageUrl(uuids));
    });
    workspacesService.on('toggleWAVisibility', (data: ToggleWAVisibilityEvent) => {
      this.logger.log('debug', 'WAs visibility toggle', data);
      this.workspaceAreasService.emitToggleVisibilityWorkspaceAreas(data);
    });

    const actionAreasService = this.client.service('actionAreas');
    actionAreasService.on('created', (aa: ActionArea) => {
      this.logger.log('debug', 'Created a action area', aa);
      this.store.dispatch(new AddActionAreas([aa]));
    });
    actionAreasService.on('updated', (aa: ActionArea) => {
      this.logger.log('debug', 'Updated a action area', aa);
      this.store.dispatch(new UpdateActionArea(aa));
    });
    actionAreasService.on('removed', (aa: ActionArea) => {
      this.logger.log('debug', 'Removed a action area', aa);
      if (aa._id) {
        this.store.dispatch(new RemoveActionAreas([aa._id]));
      }
    });

    const chartAreasService = this.client.service('chartAreas');
    chartAreasService.on('created', (ca: ChartArea) => {
      this.logger.log('debug', 'Created a chart area', ca);
      this.store.dispatch(new AddChartAreas([ca]));
    });
    chartAreasService.on('updated', (ca: ChartArea) => {
      this.logger.log('debug', 'Updated a chart area', ca);
      this.store.dispatch(new UpdateChartArea(ca));
    });
    chartAreasService.on('removed', (ca: ChartArea) => {
      this.logger.log('debug', 'Removed a chart area', ca);
      if (ca._id) {
        this.store.dispatch(new RemoveChartAreas([ca._id]));
      }
    });
    chartAreasService.on('updateChartData', (data: UpdateChartDataEvent) => {
      this.logger.log('debug', 'Chart area - update data', data);
      this.workspaceAreasService.updateChartAreaUpdateChartData(data);
    });
    chartAreasService.on('updateChartConfig', (data: UpdateChartConfigEvent) => {
      this.logger.log('debug', 'Chart area - update config', data);
      this.workspaceAreasService.updateChartAreaUpdateChartConfig(data);
    });

    const connectionAreasService = this.client.service('connectionAreas');
    connectionAreasService.on('created', (cona: ConnectionArea) => {
      this.logger.log('debug', 'Created a connection area', cona);
      this.store.dispatch(new AddConnectionAreas([cona]));
    });
    connectionAreasService.on('updated', (cona: ConnectionArea) => {
      this.logger.log('debug', 'Updated a connection area', cona);
      this.store.dispatch(new UpdateConnectionArea(cona));
    });
    connectionAreasService.on('removed', (cona: ConnectionArea) => {
      this.logger.log('debug', 'Removed a connection area', cona);
      if (cona._id) {
        this.store.dispatch(new RemoveConnectionAreas([cona._id]));
      }
    });

    const dashboardButtonAreasService = this.client.service('dashboardButtonAreas');
    dashboardButtonAreasService.on('created', (dba: DashboardButtonArea) => {
      this.logger.log('debug', 'Created a dashboard button area', dba);
      this.store.dispatch(new AddDashboardButtonAreas([dba]));
    });
    dashboardButtonAreasService.on('updated', (dba: DashboardButtonArea) => {
      this.logger.log('debug', 'Updated a dashboard button area', dba);
      this.store.dispatch(new UpdateDashboardButtonArea(dba));
    });
    dashboardButtonAreasService.on('removed', (dba: DashboardButtonArea) => {
      this.logger.log('debug', 'Removed a dashboard button area', dba);
      if (dba._id) {
        this.store.dispatch(new RemoveDashboardButtonAreas([dba._id]));
      }
    });

    const embeddedVideoAreasService = this.client.service('embeddedVideoAreas');
    embeddedVideoAreasService.on('created', (eva: EmbeddedVideoArea) => {
      this.logger.log('debug', 'Created an embedded video area', eva);
      this.store.dispatch(new AddEmbeddedVideoAreas([eva]));
    });
    embeddedVideoAreasService.on('updated', (eva: EmbeddedVideoArea) => {
      this.logger.log('debug', 'Updated an embedded video area', eva);
      this.store.dispatch(new UpdateEmbeddedVideoArea(eva));
    });
    embeddedVideoAreasService.on('removed', (eva: EmbeddedVideoArea) => {
      this.logger.log('debug', 'Removed an embedded video area', eva);
      if (eva._id) {
        this.store.dispatch(new RemoveEmbeddedVideoAreas([eva._id]));
      }
    });

    const formAreasService = this.client.service('formAreas');
    formAreasService.on('created', (fa: FormArea) => {
      this.logger.log('debug', 'Created a form area', fa);
      this.store.dispatch(new AddFormAreas([fa]));
    });
    formAreasService.on('updated', (fa: FormArea) => {
      this.logger.log('debug', 'Updated a form area', fa);
      this.store.dispatch(new UpdateFormArea(fa));
    });
    formAreasService.on('removed', (fa: FormArea) => {
      this.logger.log('debug', 'Removed a form area', fa);
      if (fa._id) {
        this.store.dispatch(new RemoveFormAreas([fa._id]));
      }
    });
    formAreasService.on('createDynamicForm', (data: DynamicFormEvent) => {
      this.dynamicFormAreaService.displayForm(
        data.id,
        data.elements,
        data.options,
        data.dialogMinWidth,
        data.dialogTitle,
        data.initData,
        data.position
      );
    });
    formAreasService.on('triggerFileDownload', (data: TriggerFileDownloadEvent) => {
      this.downloadFileService.triggerDownload(data.url);
    });

    const frameAreasService = this.client.service('frameAreas');
    frameAreasService.on('created', (fra: FrameArea) => {
      this.logger.log('debug', 'Created a frame area', fra);
      this.store.dispatch(new AddFrameAreas([fra]));
    });
    frameAreasService.on('updated', (fra: FrameArea) => {
      this.logger.log('debug', 'Updated a frame area', fra);
      this.store.dispatch(new UpdateFrameArea(fra));
    });
    frameAreasService.on('removed', (fra: FrameArea) => {
      this.logger.log('debug', 'Removed a frame area', fra);
      if (fra._id) {
        this.store.dispatch(new RemoveFrameAreas([fra._id]));
      }
    });

    const imageAreasService = this.client.service('imageAreas');
    imageAreasService.on('created', (ima: ImageArea) => {
      this.logger.log('debug', 'Created an image area', ima);
      this.store.dispatch(new AddImageAreas([ima]));
    });
    imageAreasService.on('updated', (ima: ImageArea) => {
      this.logger.log('debug', 'Updated an image area', ima);
      this.store.dispatch(new UpdateImageArea(ima));
    });
    imageAreasService.on('removed', (ima: ImageArea) => {
      this.logger.log('debug', 'Removed an image area', ima);
      if (ima._id) {
        this.store.dispatch(new RemoveImageAreas([ima._id]));
      }
    });

    const markmapAreasService = this.client.service('markmapAreas');
    markmapAreasService.on('created', (mara: MarkmapArea) => {
      this.logger.log('debug', 'Created a markmap area', mara);
      this.store.dispatch(new AddMarkmapAreas([mara]));
    });
    markmapAreasService.on('updated', (mara: MarkmapArea) => {
      this.logger.log('debug', 'Updated a markmap area', mara);
      this.store.dispatch(new UpdateMarkmapArea(mara));
    });
    markmapAreasService.on('removed', (mara: MarkmapArea) => {
      this.logger.log('debug', 'Removed a markmap area', mara);
      if (mara._id) {
        this.store.dispatch(new RemoveMarkmapAreas([mara._id]));
      }
    });

    const mapAreasService = this.client.service('mapAreas');
    mapAreasService.on('created', (ma: MapArea) => {
      this.logger.log('debug', 'Created a map area', ma);
      this.store.dispatch(new AddMapAreas([ma]));
    });
    mapAreasService.on('updated', (ma: MapArea) => {
      this.logger.log('debug', 'Updated a map area', ma);
      this.store.dispatch(new UpdateMapArea(ma));
    });
    mapAreasService.on('removed', (ma: MapArea) => {
      this.logger.log('debug', 'Removed a map area', ma);
      if (ma._id) {
        this.store.dispatch(new RemoveMapAreas([ma._id]));
      }
    });
    mapAreasService.on('addMAItems', (data: AddMAItemsEvent) => {
      this.logger.log('debug', 'Map area - add MA items', data);
      this.workspaceAreasService.updateMapAreaAddMAItems(data);
    });
    mapAreasService.on('updateMAItems', (data: UpdateMAItemsEvent) => {
      this.logger.log('debug', 'Map area - update MA items', data);
      this.workspaceAreasService.updateMapAreaUpdateMAItems(data);
    });
    mapAreasService.on('deleteMAItems', (data: DeleteMAItemsEvent) => {
      this.logger.log('debug', 'Map area - delete MA items', data);
      this.workspaceAreasService.updateMapAreaDeleteMAItems(data);
    });
    mapAreasService.on('toggleLayerGroups', (data: ToggleLayerGroupsEvent) => {
      this.logger.log('debug', 'Map area - toggle layer groups', data);
      this.workspaceAreasService.updateMapAreaToggleLayerGroups(data);
    });
    mapAreasService.on('zoomMAItems', (data: ZoomMAItemsEvent) => {
      this.logger.log('debug', 'Map area - zoom MA items', data);
      this.workspaceAreasService.updateMapAreaZoomMAItems(data);
    });

    const menuAreasService = this.client.service('menuAreas');
    menuAreasService.on('created', (mena: MenuArea) => {
      this.logger.log('debug', 'Created a menu area', mena);
      this.store.dispatch(new AddMenuAreas([mena]));
    });
    menuAreasService.on('updated', (mena: MenuArea) => {
      this.logger.log('debug', 'Updated a menu area', mena);
      this.store.dispatch(new UpdateMenuArea(mena));
    });
    menuAreasService.on('removed', (mena: MenuArea) => {
      this.logger.log('debug', 'Removed a menu area', mena);
      if (mena._id) {
        this.store.dispatch(new RemoveMenuAreas([mena._id]));
      }
    });

    const mermaidAreasService = this.client.service('mermaidAreas');
    mermaidAreasService.on('created', (mera: MermaidArea) => {
      this.logger.log('debug', 'Created a mermaid area', mera);
      this.store.dispatch(new AddMermaidAreas([mera]));
    });
    mermaidAreasService.on('updated', (mera: MermaidArea) => {
      this.logger.log('debug', 'Updated a mermaid area', mera);
      this.store.dispatch(new UpdateMermaidArea(mera));
    });
    mermaidAreasService.on('removed', (mera: MermaidArea) => {
      this.logger.log('debug', 'Removed a mermaid area', mera);
      if (mera._id) {
        this.store.dispatch(new RemoveMermaidAreas([mera._id]));
      }
    });

    const navigationAreasService = this.client.service('navigationAreas');
    navigationAreasService.on('created', (na: NavigationArea) => {
      this.logger.log('debug', 'Created a navigation area', na);
      this.store.dispatch(new AddNavigationAreas([na]));
    });
    navigationAreasService.on('updated', (na: NavigationArea) => {
      this.logger.log('debug', 'Updated a navigation area', na);
      this.store.dispatch(new UpdateNavigationArea(na));
    });
    navigationAreasService.on('removed', (na: NavigationArea) => {
      this.logger.log('debug', 'Removed a navigation area', na);
      if (na._id) {
        this.store.dispatch(new RemoveNavigationAreas([na._id]));
      }
    });

    const sliderAreasService = this.client.service('sliderAreas');
    sliderAreasService.on('created', (sa: SliderArea) => {
      this.logger.log('debug', 'Created a slider area', sa);
      this.store.dispatch(new AddSliderAreas([sa]));
    });
    sliderAreasService.on('updated', (sa: SliderArea) => {
      this.logger.log('debug', 'Updated a slider area', sa);
      this.store.dispatch(new UpdateSliderArea(sa));
    });
    sliderAreasService.on('removed', (sa: SliderArea) => {
      this.logger.log('debug', 'Removed a slider area', sa);
      if (sa._id) {
        this.store.dispatch(new RemoveSliderAreas([sa._id]));
      }
    });

    const speeachRecognitionAreasService = this.client.service('speechRecognitionAreas');
    speeachRecognitionAreasService.on('created', (sra: SpeechRecognitionArea) => {
      this.logger.log('debug', 'Created a speech recognition area', sra);
      this.store.dispatch(new AddSpeechRecognitionAreas([sra]));
    });
    speeachRecognitionAreasService.on('updated', (sra: SpeechRecognitionArea) => {
      this.logger.log('debug', 'Updated a speech recognition area', sra);
      this.store.dispatch(new UpdateSpeechRecognitionArea(sra));
    });
    speeachRecognitionAreasService.on('removed', (sra: SpeechRecognitionArea) => {
      this.logger.log('debug', 'Removed a speech recognition area', sra);
      if (sra._id) {
        this.store.dispatch(new RemoveSpeechRecognitionAreas([sra._id]));
      }
    });

    const tableAreasService = this.client.service('tableAreas');
    tableAreasService.on('created', (taba: TableArea) => {
      this.logger.log('debug', 'Created a table area', taba);
      this.store.dispatch(new AddTableAreas([taba]));
    });
    tableAreasService.on('updated', (taba: TableArea) => {
      this.logger.log('debug', 'Updated a table area', taba);
      this.store.dispatch(new UpdateTableArea(taba));
    });
    tableAreasService.on('removed', (taba: TableArea) => {
      this.logger.log('debug', 'Removed a table area', taba);
      if (taba._id) {
        this.store.dispatch(new RemoveTableAreas([taba._id]));
      }
    });
    tableAreasService.on('addTABARows', (data: AddTABARowsEvent) => {
      this.logger.log('debug', 'Table area - add TABA rows', data);
      this.workspaceAreasService.updateTableAreaAddTABARows(data);
    });
    tableAreasService.on('updateTABARows', (data: UpdateTABARowsEvent) => {
      this.logger.log('debug', 'Table area - edit TABA rows', data);
      this.workspaceAreasService.updateTableAreaUpdateTABARows(data);
    });
    tableAreasService.on('deleteTABARows', (data: DeleteTABARowsEvent) => {
      this.logger.log('debug', 'Table area - delete TABA rows', data);
      this.workspaceAreasService.updateTableAreaDeleteTABARows(data);
    });
    tableAreasService.on('deleteAllTABARows', (data: DeleteAllTABARowsEvent) => {
      this.logger.log('debug', 'Table area - delete all TABA rows', data);
      this.workspaceAreasService.updateTableAreaDeleteAllTABARows(data);
    });

    const textAreasService = this.client.service('textAreas');
    textAreasService.on('created', (ta: TextArea) => {
      this.logger.log('debug', 'Created a text area', ta);
      this.store.dispatch(new AddTextAreas([ta]));
    });
    textAreasService.on('updated', (ta: TextArea) => {
      this.logger.log('debug', 'Updated a text area', ta);
      this.store.dispatch(new UpdateTextArea(ta));
    });
    textAreasService.on('removed', (ta: TextArea) => {
      this.logger.log('debug', 'Removed a text area', ta);
      if (ta._id) {
        this.store.dispatch(new RemoveTextAreas([ta._id]));
      }
    });
    textAreasService.on('updateText', (data: UpdateTextEvent) => {
      this.logger.log('debug', 'Text area - update text', data);
      this.workspaceAreasService.updateTextAreaUpdateText(data);
    });

    const timelineAreasService = this.client.service('timelineAreas');
    timelineAreasService.on('created', (tima: TimelineArea) => {
      this.logger.log('debug', 'Created a timeline area', tima);
      this.store.dispatch(new AddTimelineAreas([tima]));
    });
    timelineAreasService.on('updated', (tima: TimelineArea) => {
      this.logger.log('debug', 'Updated a timeline area', tima);
      this.store.dispatch(new UpdateTimelineArea(tima));
    });
    timelineAreasService.on('removed', (tima: TimelineArea) => {
      this.logger.log('debug', 'Removed a timeline area', tima);
      if (tima._id) {
        this.store.dispatch(new RemoveTimelineAreas([tima._id]));
      }
    });

    const typeInBoxAreasService = this.client.service('typeInBoxAreas');
    typeInBoxAreasService.on('created', (tib: TypeInBoxArea) => {
      this.logger.log('debug', 'Created a typeInBox area', tib);
      this.store.dispatch(new AddTypeInBoxAreas([tib]));
    });
    typeInBoxAreasService.on('updated', (tib: TypeInBoxArea) => {
      this.logger.log('debug', 'Updated a typeInBox area', tib);
      this.store.dispatch(new UpdateTypeInBoxArea(tib));
    });
    typeInBoxAreasService.on('removed', (tib: TypeInBoxArea) => {
      this.logger.log('debug', 'Removed a typeInBox area', tib);
      if (tib._id) {
        this.store.dispatch(new RemoveTypeInBoxAreas([tib._id]));
      }
    });

    const viewer3DAreasService = this.client.service('viewer3DAreas');
    viewer3DAreasService.on('created', (va: Viewer3DArea) => {
      this.logger.log('debug', 'Created a 3D viewer area', va);
      this.store.dispatch(new AddViewer3DAreas([va]));
    });
    viewer3DAreasService.on('updated', (va: Viewer3DArea) => {
      this.logger.log('debug', 'Updated a 3D viewer area', va);
      this.store.dispatch(new UpdateViewer3DArea(va));
    });
    viewer3DAreasService.on('removed', (va: Viewer3DArea) => {
      this.logger.log('debug', 'Removed a 3D viewer area', va);
      if (va._id) {
        this.store.dispatch(new RemoveViewer3DAreas([va._id]));
      }
    });
    viewer3DAreasService.on('highlightMeshes', (data: HighlightMeshesEvent) => {
      this.logger.log('debug', '3D viewer area - highlight meshes', data);
      this.workspaceAreasService.updateViewerAreaHighlightMeshes(data);
    });
    viewer3DAreasService.on('toggleMeshesVisibility', (data: ToggleMeshesVisibilityEvent) => {
      this.logger.log('debug', '3D viewer area - toggle meshes visibility', data);
      this.workspaceAreasService.updateViewerAreaToggleMeshesVisibility(data);
    });
    viewer3DAreasService.on('zoomOnMeshes', (data: ZoomOnMeshesEvent) => {
      this.logger.log('debug', '3D viewer area - zoom on meshes', data);
      this.workspaceAreasService.updateViewerAreaZoomOnMeshes(data);
    });
    viewer3DAreasService.on('moveCamera', (data: MoveCameraEvent) => {
      this.logger.log('debug', '3D viewer area - move camera', data);
      this.workspaceAreasService.updateViewerAreaMoveCamera(data);
    });
  }

  public joinWorkspaceRoom(uuid: string): void {
    this.joinRoom({ type: 'workspace', id: uuid }).then((response) => {
      if (response) {
        if (!response.joined) {
          this.keycloakService.logout().catch(this.errorHandler.handleError);
        }
      } else {
        this.reloadPage();
      }
    }, this.errorHandler.handleError);
  }

  public leaveWorkspaceRoom(uuid: string): void {
    this.leaveRoom({ type: 'workspace', id: uuid });
  }

  public joinSessionRoom(id: string): void {
    this.joinRoom({ type: 'session', id }).then((response) => {
      if (response) {
        if (!response.joined) {
          this.keycloakService.logout().catch(this.errorHandler.handleError);
        }
      } else {
        this.reloadPage();
      }
    }, this.errorHandler.handleError);
  }

  public leaveSessionRoom(id: string): void {
    this.leaveRoom({ type: 'session', id });
  }

  public joinDashboardRoom(id: string): void {
    this.joinRoom({ type: 'dashboard', id }).then((response) => {
      if (response) {
        if (!response.joined) {
          this.keycloakService.logout().catch(this.errorHandler.handleError);
        }
      } else {
        this.reloadPage();
      }
    }, this.errorHandler.handleError);
  }

  public leaveDashboardRoom(id: string): void {
    this.leaveRoom({ type: 'dashboard', id });
  }

  private joinAppRoom(userId: string): void {
    this.joinRoom({ type: 'app', id: userId }).then((response) => {
      if (response) {
        this.store.dispatch(new SetSocketId(response.socketId));
        this.store.dispatch(new SetMaxSessions(response.maxSessions));
        if (!response.joined) {
          this.router.navigate([SHARED_PATH, ERROR_SOCKET_PATH]).catch(this.errorHandler.handleError);
        }
      } else {
        this.reloadPage();
      }
    }, this.errorHandler.handleError);
  }

  private async joinRoom(data: RoomData): Promise<RoomServiceResponse> {
    data.action = 'join';
    const dataWrapper: RoomDataWrapper = { data, token: this.userService.currentToken };
    const tokenExpTime = this.userService.currentTokenExpTime;

    return new Promise((resolve, reject) => {
      if (tokenExpTime * 1000 < new Date().getTime()) {
        return reject(`Join room ${data.id} has been canceled since the token has expired.`);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.socket.emit('create', 'roomMembership', dataWrapper, (error: any, response: RoomServiceResponse) => {
          this.handleSocketError(error);
          return resolve(response);
        });
      }
    });
  }

  private leaveRoom(data: RoomData): void {
    data.action = 'leave';
    const dataWrapper: RoomDataWrapper = { data, token: this.userService.currentToken };
    const tokenExpTime = this.userService.currentTokenExpTime;
    if (tokenExpTime * 1000 < new Date().getTime()) {
      this.logger.log('warn', `Leaving room ${data.id} has been canceled since the token has expired.`);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.socket.emit('create', 'roomMembership', dataWrapper, (error: any) => {
        this.handleSocketError(error);
      });
    }
  }

  private reloadPage(): void {
    this.router
      .navigate(['/'])
      .then(() => {
        location.reload();
      })
      .catch(this.errorHandler.handleError);
  }

  private showSnackToReload(): void {
    if (!this.snackBarOpened) {
      this.snackBarOpened = true;
      this.snackBarRef = this.snackBar.openFromComponent(SnackBarNoBackendComponent, {
        duration: 0,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
      this.snackBarRef.afterDismissed().subscribe({
        next: () => {
          this.snackBarOpened = false;
        }
      });
    }
  }

  private showSnackToRefresh(): void {
    if (!this.snackBarOpened) {
      this.snackBarOpened = true;
      this.snackBarRef = this.snackBar.openFromComponent(SnackBarDisconnectComponent, {
        duration: 0,
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
      this.snackBarRef.afterDismissed().subscribe({
        next: () => {
          this.snackBarOpened = false;
        }
      });
    }
  }

  private hideSnackBars(): void {
    if (this.snackBarOpened && this.snackBarRef) {
      this.snackBarRef.dismiss();
      this.snackBarOpened = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleSocketError(error: any): void {
    if (error) {
      this.errorHandler.handleError(error);
    }
  }

  private canSeeThisSession(session: { owner: string; groups: Array<string>; users: Array<string> }): boolean {
    return (
      session.owner === this.userService.currentUserId ||
      intersectArray(session.groups, this.userService.currentUserGroupIds).length > 0 ||
      session.users.includes(this.userService.currentUserId) ||
      this.canSeeAllSessions()
    );
  }

  private canSeeThisDashboard(dashboard: { owner: string; groups: Array<string>; users: Array<string> }): boolean {
    return (
      dashboard.owner === this.userService.currentUserId ||
      intersectArray(dashboard.groups, this.userService.currentUserGroupIds).length > 0 ||
      dashboard.users.includes(this.userService.currentUserId) ||
      this.canSeeAllDashboards()
    );
  }

  private isCurrentDashboard(dashboard: Dashboard): boolean {
    const context = this.contextService.getContext();
    return !!context.dashboard && !!context.dashboard._id && context.dashboard._id === dashboard._id;
  }

  private canSeeAllSessions(): boolean {
    return SESSIONS_ADMIN_CLIENT_ROLES.every((r) => this.userService.currentClientRoles.includes(r));
  }

  private canSeeAllDashboards(): boolean {
    return DASHBOARDS_ADMIN_CLIENT_ROLES.every((r) => this.userService.currentClientRoles.includes(r));
  }
}

interface UpdateSessionOwnerEventResponse extends UpdateSessionOwnerEvent {
  title: string;
}

interface UpdateDashboardOwnerEventResponse extends UpdateDashboardOwnerEvent {
  title: string;
}
