import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShortcutsService {
  private active = true;

  get areActive(): boolean {
    return this.active;
  }

  public setActive(status: boolean): void {
    this.active = status;
  }
}
