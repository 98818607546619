import { BehaviorSubject, Observable } from 'rxjs';

import { Directive } from '@angular/core';
import { RxJSBaseDirective } from '@directives/rxjs-base.directive';

@Directive()
// eslint-disable-next-line @typescript-eslint/ban-types
export class StatefulComponentDirective<State extends {}> extends RxJSBaseDirective {
  /** Public/Template facing observable */
  public readonly state$: Observable<State>;

  /** Component State BehaviorSubject */
  private readonly _state$: BehaviorSubject<State>;

  /** Initial Component state stored in the case of calling `resetState` */
  private readonly _initState: State;

  constructor(protected readonly initState: State) {
    super();
    this._initState = initState;
    this._state$ = new BehaviorSubject<State>(initState);
    this.state$ = this._state$.asObservable();
  }

  /** Current Component State */
  protected get state(): State {
    return this._state$.value;
  }

  /** Set New Component State */
  protected setComponentState(newState: State): void {
    this._state$.next(newState);
  }

  /** Update State partially and keep non-inputted fields the same */
  protected updateComponentState(newState: Partial<State>): void {
    this._state$.next({ ...this.state, ...newState });
  }

  /** Reset State back to initial state given in class constructor */
  protected resetComponentState(): void {
    this.setComponentState(this._initState);
  }

  /** Reset State back to initial state except a list of properties */
  protected resetComponentStateExcept(properties: Array<string>): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state: any = this._initState;
    properties.forEach((prop) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state[prop] = (this.state as any)[prop];
    });
    this.setComponentState(state);
  }
}
