import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'ngx-g2v-formly-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyStepperComponent extends FieldType<FieldTypeConfig> {
  public isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field.formControl ? field.formControl.valid : false;
    }

    return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : false;
  }

  public stepsTrackBy(index: number, item: FormlyFieldConfig): string {
    return item.key ? (Array.isArray(item.key) ? item.key[0].toString() : '' + item.key.toString()) : item.id ? item.id : '';
  }
}
