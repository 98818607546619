import { Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'ngx-g2v-formly-autocomplete',
  templateUrl: './autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyAutocompleteComponent extends FieldType<FieldTypeConfig> implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public filter: Observable<any> | undefined;

  public ngOnInit(): void {
    this.filter = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap((term) => of(term ? this.filterTerms(term) : this.props.terms ? this.props.terms.slice() : []))
    );
  }

  public valuesTrackBy(index: number, item: string): string {
    return item;
  }

  private filterTerms(term: string): Array<string> {
    return this.to.terms ? this.to.terms.filter((state: string) => state.toLowerCase().includes(term.toLowerCase())) : [];
  }
}
