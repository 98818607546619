import cloneDeep from 'lodash.clonedeep';
import { of } from 'rxjs';

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AUDIO_TYPES,
  G2_SYNTAX_VALIDATOR,
  IMAGE_TYPES,
  INVALID_INTEGER_CHARS,
  INVALID_NUMBER_CHARS,
  SNACKBAR_DURATION,
  SNACKBAR_HORIZONTAL_POSITION,
  SNACKBAR_VERTICAL_POSITION,
  VIDEO_TYPES
} from '@config/constants';
import { deepFind } from '@config/utils';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { FormArea } from '@g2view/g2view-commons';
import { FormAreaResponse } from '@interfaces/modal-responses';
import { HotToastService, ToastPosition } from '@ngneat/hot-toast';
import { TranslocoService } from '@ngneat/transloco';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ErrorHandlerService } from '@services/error-handler.service';
import { WorkspaceAreasService } from '@workspaces/services/workspace-areas.service';

// TODO4: new WA for codeMirror

const EXPRESSION_PROPERTIES_SEPARATOR = '___';
const INTERVAL_CHECK_NUMBER = 10;

interface ComponentState {
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-form-area-modal',
  templateUrl: './form-area-modal.component.html',
  styleUrls: ['./form-area-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormAreaModalComponent extends StatefulComponentDirective<ComponentState> implements OnInit, AfterViewInit {
  public form: FormGroup = new FormGroup({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public model: any = {};
  public formArea: FormArea;
  public fields: Array<FormlyFieldConfig>;
  public options: FormlyFormOptions = {};

  private canAskG2Validation = false; // Prevent double call to getG2SyntaxValidatorEndpoint in initData
  private interval: NodeJS.Timeout | undefined;
  private fileConfigs: Array<FileConfig> = [];

  constructor(
    private readonly errorHandler: ErrorHandlerService,
    private readonly translocoService: TranslocoService,
    private readonly dialogRef: MatDialogRef<FormAreaModalComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: DialogFAData,
    private readonly workspaceAreasService: WorkspaceAreasService,
    private readonly toast: HotToastService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super({
      loaded: false,
      error: undefined
    });
    this.formArea = data.fa;
    this.fields = data.fa.elements ? cloneDeep(data.fa.elements) : [];
    this.fields.forEach((field) => {
      if (
        field.type &&
        field.type === 'textarea' &&
        field.validators &&
        field.validators.validation &&
        field.validators.validation.includes(G2_SYNTAX_VALIDATOR)
      ) {
        const validatorKey = field.key + '___validator';
        const validatorResultsField: FormlyFieldConfig = {
          key: validatorKey,
          type: 'markup'
        };
        this.fields.push(validatorResultsField);
        field.modelOptions = { updateOn: 'blur' };
        field.asyncValidators = {
          g2Validation: {
            expression: (control: AbstractControl) => {
              if (!control.value) {
                return of(false);
              }
              return new Promise((resolve) => {
                if (!this.canAskG2Validation) {
                  return resolve(true);
                }
                this.workspaceAreasService.getG2SyntaxValidation(control.value).then((data) => {
                  const message = data.description
                    ? this.translocoService.translate(
                        'workspaceAreas.formArea.g2SyntaxErrorDescription',
                        { description: data.description, errorIndex: data.errorIndex },
                        'workspaces'
                      )
                    : this.translocoService.translate(
                        'workspaceAreas.formArea.g2SyntaxChoices',
                        { choices: data.categoryChoices.concat(data.completionChoices).join(', ') },
                        'workspaces'
                      );
                  this.model = { ...this.model, [validatorKey]: message };
                  resolve(data.endableP);
                });
              });
            },
            message: this.translocoService.translate('workspaceAreas.formArea.g2SyntaxError', {}, 'workspaces')
          }
        };
        const index = field.validators.validation.indexOf(G2_SYNTAX_VALIDATOR);
        if (index > -1) {
          field.validators.validation.splice(index, 1);
        }
      }
      if (field.expressionProperties) {
        for (const [key, value] of Object.entries(field.expressionProperties)) {
          field.expressionProperties[key.replace(EXPRESSION_PROPERTIES_SEPARATOR, '.')] = value;
          delete field.expressionProperties[key];
        }
      }
      if (field.fieldGroup && field.fieldGroup.length === 0) {
        delete field.fieldGroup;
      }
    });
    this.options = data.fa.options ? cloneDeep(data.fa.options) : {};
  }

  public ngOnInit(): void {
    if (this.data.initData) {
      this.setInitialData(this.data.initData);
    } else {
      this.getInitData();
    }
    this.initUnsub();
  }

  public ngAfterViewInit(): void {
    this.preventCharInNumberTextfields();
  }

  public save(): void {
    let fileOk = true;
    this.fileConfigs.forEach((file) => {
      const fileUploaded = getFileFromModel(this.model, file.id);
      const fileExists = !!fileUploaded;
      if (fileExists) {
        const fileTypes = file.fileType;
        if (fileTypes.includes('image/*')) {
          IMAGE_TYPES.forEach((imageType) => {
            fileTypes.push(imageType);
          });
        }
        if (fileTypes.includes('video/*')) {
          VIDEO_TYPES.forEach((videoType) => {
            fileTypes.push(videoType);
          });
        }
        if (fileTypes.includes('audio/*')) {
          AUDIO_TYPES.forEach((audioType) => {
            fileTypes.push(audioType);
          });
        }
        if (fileTypes.length > 0) {
          const extension = `.${fileUploaded.name.split('.').pop()}`;
          if (!fileTypes.includes(extension)) {
            this.toastExtensionFileError(file.label, fileTypes, extension);
            fileOk = false;
          }
        }
        const fileUploadedKo = fileUploaded.size / 1024;
        if (file.maxSize > -1 && fileUploadedKo > file.maxSize) {
          this.toastFileTooBig(file.label, file.maxSize, fileUploadedKo);
          fileOk = false;
        }
      } else {
        if (file.required) {
          fileOk = false;
        }
      }
    });
    if (this.form.valid && fileOk) {
      this.closeDialog({ model: this.model, fileConfigs: this.fileConfigs });
    }
  }

  public close(): void {
    this.closeDialog(undefined);
  }

  private setInitialData(data: any): void {
    this.model = data;
    if (this.data.mapData) {
      this.model.lat = this.data.mapData.lat;
      this.model.lng = this.data.mapData.lng;
    }
    this.setComponentState({
      loaded: true,
      error: undefined
    });
    setTimeout(() => {
      this.cdr.detectChanges();
      this.canAskG2Validation = true;
    }, 0);
  }

  private getInitData(): void {
    this.workspaceAreasService
      .getFAInitialData(this.formArea.wsUuid, this.formArea.key, this.formArea.type, this.data.miKey, this.data.rowId, this.data.buttonId)
      .then((response) => {
        this.setInitialData(response.data);
      })
      .catch((error) => {
        this.setComponentState({
          loaded: true,
          error
        });
      });
  }

  private preventCharInNumberTextfields(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      if (this.areAllFieldsReady(this.fields)) {
        if (this.interval) {
          clearInterval(this.interval);
        }
        const ids = this.getFilesAndIntegerAndNumberInputOfFields(this.fields, []);
        this.fileConfigs = ids.fileConfigs;
        ids.numberIds.forEach((id) => {
          const numberInputBox = document.getElementById(id);
          if (numberInputBox) {
            numberInputBox.addEventListener('keydown', (e) => {
              if (INVALID_NUMBER_CHARS.includes(e.key)) {
                e.preventDefault();
              }
            });
          }
        });
        ids.integerIds.forEach((id) => {
          const integerInputBox = document.getElementById(id);
          if (integerInputBox) {
            integerInputBox.addEventListener('keydown', (e) => {
              if (INVALID_INTEGER_CHARS.includes(e.key)) {
                e.preventDefault();
              }
            });
          }
        });
      }
    }, INTERVAL_CHECK_NUMBER);
  }

  private areAllFieldsReady(fields: Array<FormlyFieldConfig>): boolean {
    let isReady = true;
    fields.forEach((field) => {
      if (field.fieldGroup) {
        if (!this.areAllFieldsReady(field.fieldGroup)) {
          isReady = false;
        }
      } else {
        if (!field.id) {
          isReady = false;
        }
      }
    });
    return isReady;
  }

  private getFilesAndIntegerAndNumberInputOfFields(
    fields: Array<FormlyFieldConfig>,
    path: Array<string>
  ): { fileConfigs: Array<FileConfig>; integerIds: Array<string>; numberIds: Array<string> } {
    const idsToReturn: { fileConfigs: Array<FileConfig>; integerIds: Array<string>; numberIds: Array<string> } = {
      fileConfigs: [],
      integerIds: [],
      numberIds: []
    };
    fields.forEach((field) => {
      if (field.fieldGroup) {
        const ids = this.getFilesAndIntegerAndNumberInputOfFields(field.fieldGroup, [...path, field.key ? field.key.toString() : '']);
        idsToReturn.fileConfigs = idsToReturn.fileConfigs.concat(ids.fileConfigs);
        idsToReturn.integerIds = idsToReturn.integerIds.concat(ids.integerIds);
        idsToReturn.numberIds = idsToReturn.numberIds.concat(ids.numberIds);
      } else if (field.type && field.type === 'input' && field.props && field.props.type && field.props.type === 'number') {
        if (field.id) {
          idsToReturn.numberIds.push(field.id);
          if (field.className && field.className.split(' ').includes('input-integer')) {
            idsToReturn.integerIds.push(field.id);
          }
        }
      } else if (field.type && field.type === 'file') {
        if (field.key && typeof field.key === 'string') {
          idsToReturn.fileConfigs.push({
            id: [...path, field.key].join(EXPRESSION_PROPERTIES_SEPARATOR),
            label: field.props && field.props.label ? field.props.label : '',
            required: !!field.props && !!field.props.required ? field.props.required : false,
            maxSize: field.props && field.props.maxSize ? field.props.maxSize : -1,
            fileType: field.props && field.props.fileType ? field.props.fileType.split(',') : []
          });
        }
      }
    });
    return idsToReturn;
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.interval) {
          clearInterval(this.interval);
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private toastExtensionFileError(fieldLabel: string, fileTypes: Array<string>, fileType: string): void {
    this.toast.error(
      this.translocoService.translate(
        'workspaceAreas.formArea.incorrectFileType',
        { fieldLabel, fileTypes: fileTypes.join(', '), fileType },
        'workspaces'
      ),
      {
        duration: SNACKBAR_DURATION,
        position: `${SNACKBAR_VERTICAL_POSITION}-${SNACKBAR_HORIZONTAL_POSITION}` as ToastPosition
      }
    );
  }

  private toastFileTooBig(fieldLabel: string, maxSize: number, fileSize: number): void {
    this.toast.error(
      this.translocoService.translate('workspaceAreas.formArea.incorrectFile', { fieldLabel, maxSize, fileSize: Math.round(fileSize) }, 'workspaces'),
      {
        duration: SNACKBAR_DURATION,
        position: `${SNACKBAR_VERTICAL_POSITION}-${SNACKBAR_HORIZONTAL_POSITION}` as ToastPosition
      }
    );
  }

  private closeDialog(response: FormAreaResponse): void {
    this.dialogRef.close(response);
  }
}

export interface DialogFAData {
  fa: FormArea;
  miKey: string;
  rowId: string;
  buttonId: string;
  initData?: any;
  mapData?: FormAreaMapData;
}
export interface FormAreaMapData {
  lat: number;
  lng: number;
}

export interface FileConfig {
  id: string;
  label: string;
  required: boolean;
  maxSize: number;
  fileType: Array<string>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/explicit-module-boundary-types
export const getFileFromModel = (model: any, fileKey: string): File | undefined => {
  const path = fileKey.split(EXPRESSION_PROPERTIES_SEPARATOR).filter((s) => s !== '');
  const file = deepFind(model, path);
  return file && file._files && file._files[0] ? file._files[0] : undefined;
};
