/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {
  constructor(private readonly http: HttpClient) {}

  public head(url: string, options?: any): Observable<any> {
    return this.http.head(url, options);
  }

  public get(url: string, options?: any): Observable<any> {
    return this.http.get(url, options);
  }

  public post(url: string, data: any, options?: any): Observable<any> {
    return this.http.post(url, data, options);
  }

  public patch(url: string, data: any, options?: any): Observable<any> {
    return this.http.patch(url, data, options);
  }

  public put(url: string, data: any, options?: any): Observable<any> {
    return this.http.put(url, data, options);
  }

  public delete(url: string, options?: any): Observable<any> {
    return this.http.delete(url, options);
  }
}
