import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@services/error-handler.service';
import { LoggerService } from '@services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  private _previousPath = '';

  constructor(private readonly router: Router, private readonly errorHandler: ErrorHandlerService, private readonly logger: LoggerService) {}

  get previousPath(): string {
    return this._previousPath;
  }

  public redirectWithRefresh(path: Array<string>): void {
    this.logger.log('trace', 'Navigate to "/".');
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.logger.log('trace', `Navigate to "${path.join('/')}".`);
        this.router.navigate(path).catch(this.errorHandler.handleError);
      })
      .catch(this.errorHandler.handleError);
  }

  public setPreviousPath(path: string): void {
    this._previousPath = path;
  }
}
