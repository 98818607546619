import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_CONFIG } from '@config/constants';
import { HotkeysHelpComponent } from '@ngneat/hotkeys';
import { TranslocoService } from '@ngneat/transloco';
import { ShortcutsService } from '@services/shortcuts.service';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly translocoService: TranslocoService,
    private readonly shortcutsService: ShortcutsService
  ) {}

  public openHelp(): void {
    if (this.shortcutsService.areActive) {
      this.shortcutsService.setActive(false);
      const ref = this.dialog
        .open(HotkeysHelpComponent, { ...MAT_DIALOG_CONFIG, disableClose: false })
        .updatePosition({ top: '10vh' })
        .addPanelClass('modal-help');
      ref.componentInstance.title = this.translocoService.translate('shortcuts.title', {}, 'app');
      ref.componentInstance.dimiss.subscribe({
        next: () => {
          ref.close();
        }
      });
      ref.afterClosed().subscribe({
        next: () => {
          this.shortcutsService.setActive(true);
        }
      });
    }
  }
}
