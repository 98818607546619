import { Observable, Subject, takeUntil } from 'rxjs';

import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MarkmapChartModalComponent } from '@components/markmap-chart/markmap-chart-modal/markmap-chart-modal.component';
import { MARKMAP_REFRESH_INTERVAL_TIME, MAT_DIALOG_CONFIG } from '@config/constants';
import { b64ToUtf8 } from '@config/utils';
import { debounce } from '@decorators/debounce.decorator';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { WA_DISPLAY_FULL_SCREEN_DEFAULT } from '@g2view/g2view-commons';
import { transform } from '@g2view/markmap-lib/dist/transform.common';
import { markmap } from '@g2view/markmap-lib/dist/view.common';
import { ModalConfig } from '@interfaces/modal-config';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ShortcutsService } from '@services/shortcuts.service';
import { VisibilityService } from '@services/visibility.service';

const FOLDED_TEXT = '--FOLDED--';
const MODAL_WIDTH_PERCENT = 95;
const SMALL_TIMEOUT_MS = 10; // This timeout is needed when a MARA is updated via a WS update to let the time for the div to be re-created.

interface ComponentState {
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-markmap-chart',
  templateUrl: './markmap-chart.component.html',
  styleUrls: ['./markmap-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkmapChartComponent extends StatefulComponentDirective<ComponentState> implements OnInit, OnChanges {
  @Input() public readonly chartId: string = '';
  @Input() public readonly displayModalLink: boolean = WA_DISPLAY_FULL_SCREEN_DEFAULT;
  @Input() private readonly codeB64: string = '';
  @Input() private readonly textColor: string = '#ffffff';
  @Input() private readonly backgroundColor: string = '#ebebeb';
  @Input() private readonly useZoom: boolean = false;
  @Input() private readonly autoFit: boolean = true;
  @Input() private readonly animationDuration: number = 300;
  @Input() private readonly nodeFont: string = '300 10px/12px Palatino';
  @Input() private readonly workspaceResize$: Observable<void> = new Observable();
  @Input() private readonly workspaceUpdate$: Observable<void> = new Observable();

  private dialogMA: MatDialogRef<MarkmapChartModalComponent> | undefined;

  private markmapRef: ElementRef | undefined;
  private markmapConfigSubject: Subject<MarkmapConfig> = new Subject<MarkmapConfig>();

  private timeoutInitFirstRefresh: NodeJS.Timeout | undefined;

  constructor(
    public readonly visibilityService: VisibilityService,
    private readonly shortcutsService: ShortcutsService,
    private readonly dialog: MatDialog,
    private readonly errorHandler: ErrorHandlerService
  ) {
    super({
      loaded: false,
      error: undefined
    });
  }

  @ViewChild('markmapDiv') set content(content: ElementRef) {
    if (content) {
      this.markmapRef = content;
      this.styling();
    }
  }

  @HostListener('window:resize', ['$event'])
  @debounce(100)
  private onResize(): void {
    refitMarkmap(this.getMarkmapInDomId());
  }

  @HostListener('window:orientationchange', ['$event']) private onOrientationChange(): void {
    refitMarkmap(this.getMarkmapInDomId());
  }

  public ngOnInit(): void {
    this.updateComponentState({
      loaded: true
    });
    this.initSubscriptions();
    this.initVisibilitySub();
    this.initUnsub();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    delete changes.workspaceResize$;
    delete changes.workspaceUpdate$;
    if (Object.keys(changes).length > 0) {
      this.markmapConfigSubject.next({
        chartId: `${this.chartId}-modal`,
        codeB64: this.codeB64,
        textColor: this.textColor,
        backgroundColor: this.backgroundColor,
        useZoom: this.useZoom,
        autoFit: this.autoFit,
        animationDuration: this.animationDuration,
        nodeFont: this.nodeFont
      });
      this.refreshMarkmap();
    }
  }

  public onGoToModal(): void {
    this.openMarkmapAreaModal();
  }

  private openMarkmapAreaModal(): void {
    this.shortcutsService.setActive(false);
    const percent = `${MODAL_WIDTH_PERCENT}%`;
    const config: ModalConfig<MarkmapConfig> = {
      config$: this.markmapConfigSubject.asObservable(),
      initConfig: {
        chartId: `${this.chartId}-modal`,
        codeB64: this.codeB64,
        textColor: this.textColor,
        backgroundColor: this.backgroundColor,
        useZoom: this.useZoom,
        autoFit: this.autoFit,
        animationDuration: this.animationDuration,
        nodeFont: this.nodeFont
      }
    };
    this.dialogMA = this.dialog.open(MarkmapChartModalComponent, {
      ...MAT_DIALOG_CONFIG,
      width: percent,
      maxWidth: percent,
      height: percent,
      data: config
    });
    this.dialogMA.afterClosed().subscribe({
      next: () => {
        this.shortcutsService.setActive(true);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private refreshMarkmap(): void {
    if (this.issetMarkmapInDOM() && this.getMarkmapDOMWidth() > 0) {
      if (this.timeoutInitFirstRefresh) {
        clearTimeout(this.timeoutInitFirstRefresh);
      }
      setTimeout(() => {
        this.renderMarkmap();
      }, SMALL_TIMEOUT_MS);
    } else {
      if (this.timeoutInitFirstRefresh) {
        clearTimeout(this.timeoutInitFirstRefresh);
      }
      this.timeoutInitFirstRefresh = setTimeout(() => {
        this.refreshMarkmap();
      }, MARKMAP_REFRESH_INTERVAL_TIME);
    }
  }

  private renderMarkmap(): void {
    this.destroyMarkmap();
    const dataUtf8 = b64ToUtf8(this.codeB64);
    const dataTransformed = transform(dataUtf8);
    this.foldNodes(dataTransformed);
    markmap('#' + this.getMarkmapInDomId(), dataTransformed, {
      duration: this.animationDuration,
      autoFit: this.autoFit,
      useZoom: this.useZoom,
      nodeFont: this.nodeFont
    });
    this.styling();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private foldNodes(data: any): void {
    if (data.v.endsWith(FOLDED_TEXT)) {
      const index = data.v.lastIndexOf(FOLDED_TEXT);
      if (index >= 0) {
        data.v = data.v.substring(0, index);
      }
      data.p = { f: true };
    }
    for (const i in data.c) {
      this.foldNodes(data.c[i]);
    }
  }

  private destroyMarkmap(): void {
    const markmap = this.getMarkmapInDom();
    if (markmap) {
      markmap.innerHTML = '';
    }
  }

  private initSubscriptions(): void {
    this.workspaceResize$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        if (this.autoFit) {
          refitMarkmap(this.getMarkmapInDomId());
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
    this.workspaceUpdate$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        if (this.dialogMA) {
          this.dialogMA.close();
        }
      }
    });
  }

  private initVisibilitySub(): void {
    this.visibilityService.pageVisible.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        refitMarkmap(this.getMarkmapInDomId());
      }
    });
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.timeoutInitFirstRefresh) {
          clearTimeout(this.timeoutInitFirstRefresh);
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private styling(): void {
    if (!this.markmapRef) {
      return;
    }
    this.markmapRef.nativeElement.style.setProperty('--background-color', this.backgroundColor);
    this.markmapRef.nativeElement.style.setProperty('--text-color', this.textColor);
  }

  private getMarkmapInDomId(): string {
    return `markmap-${this.chartId}`;
  }

  private getMarkmapInDom(): null | HTMLElement {
    return document.getElementById(this.getMarkmapInDomId());
  }

  private issetMarkmapInDOM(): boolean {
    return this.getMarkmapInDom() ? true : false;
  }

  private getMarkmapDOMWidth(): number {
    if (this.issetMarkmapInDOM()) {
      const element = this.getMarkmapInDom();
      if (element) {
        return element.clientWidth;
      }
    }
    return 0;
  }
}

export const refitMarkmap = (id: string): void => {
  const markmap = document.getElementById(id);
  if (markmap) {
    const gs = markmap.getElementsByTagName('g');
    if (gs.length > 1) {
      const g = gs[1];
      // 2 clicks to open/close a node
      g.dispatchEvent(new Event('click'));
      g.dispatchEvent(new Event('click'));
    }
  }
};

export interface MarkmapConfig {
  codeB64: string;
  chartId: string;
  textColor: string;
  backgroundColor: string;
  useZoom: boolean;
  autoFit: boolean;
  animationDuration: number;
  nodeFont: string;
}
