<ng-container *transloco="let t; scope: 'app'; read: 'app.afk'">
    <ng-container *ngIf="state$ | async as state">
        <div class="container">
            <ng-container *ngIf="state.loaded">
                <h1>{{ t('title') }}</h1>
                <h2>{{ t('content') }}</h2>
                <ng-container *ngIf="state.displayButton">
                    <h2>{{ t('timeoutRedirectMessage') }}</h2>
                    <button mat-raised-button color="primary" title="{{ t('buttonReloadTitle') }}" (click)="onReload()">{{ t('timeoutRedirectButtonLabel') }}</button>
                </ng-container>
                <div class="spinner">
                    <ngx-g2v-progress-spinner></ngx-g2v-progress-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!state.loaded">
                <ngx-g2v-progress-spinner></ngx-g2v-progress-spinner>
            </ng-container>
            <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
        </div>
    </ng-container>
</ng-container>