<ng-container *transloco="let t; scope: 'app'; read: 'app.3dViewer'">
  <ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="state.loaded">
      <div fxLayout="column" fxLayoutGap="10px" class="action-buttons">
        <a mat-icon-button *ngIf="displayModalLink" mat-icon-button color="accent" class="button" (click)="onGoToModal()" title="{{ t('buttonModalTitle') }}">
          <mat-icon fontIcon="fullscreen"></mat-icon>
        </a>
        <a mat-icon-button *ngIf="state.displayWebInterfaceURL" [href]="state.webInterfaceURL" title="{{ t('buttonWebInterfaceTitle') }}" target="_blank">
          <mat-icon fontIcon="view_in_ar"></mat-icon>
        </a>
      </div>
      <div [id]="'viewer-' + divId" class="viewer">
      </div>
      <pre id="camera-info" *ngIf="isDevMode">{{ state.camera | json }}</pre>
    </ng-container>
    <ng-container *ngIf="!state.loaded">
      <ngx-g2v-progress-spinner></ngx-g2v-progress-spinner>
    </ng-container>
    <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
  </ng-container>
</ng-container>