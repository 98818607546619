import { LoggerModule } from 'ngx-logger';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EnsureImportedOnceModule } from '@classes/ensure-imported-once.module';
import { ComponentErrorComponent } from '@components/component-error/component-error.component';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { ProgressSpinnerComponent } from '@components/progress-spinner/progress-spinner.component';
import { LayoutComponent } from '@core/components/layout/layout.component';
import { NavBarComponent } from '@core/components/nav-bar/nav-bar.component';
import { HttpInterceptorInterceptor } from '@core/interceptors/http-interceptor.interceptor';
import { CoreState } from '@core/state/core.state';
import { environment } from '@environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { UserService } from '@services/user.service';
import { SharedModule } from '@shared/shared.module';

// The core module is used to provide your singleton services and any high-level functionality.
// Any component, directive, or pipe that isn't feature specific and is only used once in the app should end up in the core module.
// An example of this could be a navbar or footer component that is called once in your app component.
// This module should be imported once in the App module.
// This module should not export any components.

const userServiceInit = (userService: UserService) => () => {
  userService.init();
};

const imports = [
  CommonModule,
  SharedModule,
  HttpClientModule,
  FlexLayoutModule,
  RouterModule,
  HammerModule,
  LoggerModule.forRoot({
    level: environment.ngxLogger.consoleLogLevel
  }),
  NgxsModule.forRoot([CoreState], {
    developmentMode: environment.isDevMod
  })
];
if (environment.isDevMod) {
  imports.push(NgxsReduxDevtoolsPluginModule.forRoot());
}

@NgModule({
  declarations: [LayoutComponent, NavBarComponent],
  imports,
  exports: [LayoutComponent, ProgressBarComponent, ProgressSpinnerComponent, ComponentErrorComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: userServiceInit,
      multi: true,
      deps: [UserService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorInterceptor, multi: true }
  ]
})
export class CoreModule extends EnsureImportedOnceModule {
  constructor(@SkipSelf() @Optional() parent: CoreModule) {
    super(parent);
  }
}
