import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';
import { DialogService } from '@services/dialog.service';
import { SessionsService } from '@sessions/services/sessions.service';
import { WorkspaceAreasService } from '@workspaces/services/workspace-areas.service';

@Injectable({
  providedIn: 'root'
})
export class MermaidAreaService {
  private readonly mermaidTreeViewClickedSubject = new Subject<string>();
  private readonly mermaidUMLClickedSubject = new Subject<string>();

  constructor(
    private readonly workspaceAreasService: WorkspaceAreasService,
    private readonly sessionsService: SessionsService,
    private readonly dialogService: DialogService
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).mermaidNodeClickEmitter.on('treeView', (nodeId: string) => {
      this.mermaidTreeViewClickedSubject.next(nodeId);
      this.dialogService.closeDialog();
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).mermaidNodeClickEmitter.on('UML', (umlDest: string) => {
      this.mermaidUMLClickedSubject.next(umlDest);
      this.dialogService.closeDialog();
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).mermaidNodeClickEmitter.on(
      'processClick',
      ({
        uuid,
        meraKey,
        nodeId,
        eventType,
        targetUuid
      }: {
        uuid: string;
        meraKey: string;
        nodeId: string;
        eventType: string;
        targetUuid: string;
      }) => {
        const session = this.sessionsService.getCurrentSession();
        const wsIndex = session ? session.workspaces.findIndex((ws) => ws.uuid === uuid) : -1;
        const ws = session && wsIndex > -1 ? session.workspaces[wsIndex] : null;
        this.workspaceAreasService.processClick({
          uuid,
          workspaceLeft: ws ? ws.left : 0,
          workspaceTop: ws ? ws.top : 0,
          workspaceZoom: ws ? ws.zoom : 1,
          waType: 'meras',
          key: meraKey,
          eventType,
          targetUuid,
          meraNodeId: nodeId
        });
      }
    );
  }

  get mermaidTreeViewClicked$(): Observable<string> {
    return this.mermaidTreeViewClickedSubject.asObservable();
  }

  get mermaidUMLClicked$(): Observable<string> {
    return this.mermaidUMLClickedSubject.asObservable();
  }
}
