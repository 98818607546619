import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomPreloadingStrategy } from '@classes/custom-preloading-strategy';
import { ADMIN_PATH, DASHBOARD_PATH, DASHBOARDS_PATH, KEYCLOAK_PATH, SESSION_PATH, SESSIONS_PATH, SHARED_PATH } from '@config/constants';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

const routes: Routes = [
  {
    path: ADMIN_PATH,
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    data: { preload: false }
  },
  {
    path: KEYCLOAK_PATH,
    loadChildren: () => import('./keycloak/keycloak.module').then((m) => m.KeycloakModule),
    data: { preload: false }
  },
  {
    path: DASHBOARDS_PATH,
    loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
    data: { preload: true, delay: 1000 }
  },
  {
    path: DASHBOARD_PATH,
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: { preload: true, delay: 3000 }
  },
  {
    path: SESSIONS_PATH,
    loadChildren: () => import('./sessions/sessions.module').then((m) => m.SessionsModule),
    data: { preload: true, delay: 1000 }
  },
  {
    path: SESSION_PATH,
    loadChildren: () => import('./session/session.module').then((m) => m.SessionModule),
    data: { preload: true, delay: 3000 }
  },
  {
    path: SHARED_PATH,
    loadChildren: () => import('./shared/shared.module').then((m) => m.SharedModule),
    data: { preload: true, delay: 0 }
  },
  { path: '**', redirectTo: DASHBOARDS_PATH }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: CustomPreloadingStrategy })], // useHash to avoid 404 with Cloudfront
  exports: [RouterModule],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'app' }]
})
export class AppRoutingModule {}
