import * as OV from 'online-3d-viewer';
import { Observable, Subject, takeUntil } from 'rxjs';

import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ThreeDViewerModalComponent } from '@components/3d-viewer/3d-viewer-modal/3d-viewer-modal.component';
import { MAT_DIALOG_CONFIG, VIEWER_3D_REFRESH_INTERVAL_TIME } from '@config/constants';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import {
  HighlightColor,
  HighlightMeshesEvent,
  MeshesVisibility,
  MoveCameraEvent,
  ToggleMeshesVisibilityEvent,
  VA_BACKGROUND_COLOR_DEFAULT,
  VA_BACKGROUND_IS_ENV_MAP_DEFAULT,
  VA_CAMERA_CENTER_DEFAULT,
  VA_CAMERA_EYE_DEFAULT,
  VA_CAMERA_FIELD_OF_VIEW_DEFAULT,
  VA_CAMERA_UP_DEFAULT,
  VA_DEFAULT_COLOR_DEFAULT,
  VA_DISPLAY_WEB_INTERFACE_URL_DEFAULT,
  VA_EDGE_COLOR_DEFAULT,
  VA_EDGE_THRESHOLD_DEFAULT,
  VA_INIT_COLOR_DEFAULT,
  VA_INIT_VISIBILITY_DEFAULT,
  VA_INIT_ZOOM_DEFAULT,
  VA_SHOW_EDGE_DEFAULT,
  WA_DISPLAY_FULL_SCREEN_DEFAULT,
  ZoomOnMeshesEvent
} from '@g2view/g2view-commons';
import { ModalConfig } from '@interfaces/modal-config';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { IndexedDbService } from '@services/indexed-db.service';
import { LoggerService } from '@services/logger.service';
import { ShortcutsService } from '@services/shortcuts.service';

const MODAL_WIDTH_PERCENT = 95;
const SMALL_TIMEOUT_MS = 10; // This timeout is needed when a VA is updated via a WS update to let the time for the div to be re-created.
const ENV_IMAGE_NAMES = ['posx', 'negx', 'posy', 'negy', 'posz', 'negz'];
const RESET_SIZE_INTERVAL_MS = 50;
const CAMERA_INTERVAL_UPDATE_MS = 1000;

interface ComponentState {
  webInterfaceURL: string;
  displayWebInterfaceURL: boolean;
  camera: any;
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-3d-viewer',
  templateUrl: './3d-viewer.component.html',
  styleUrls: ['./3d-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreeDViewerComponent extends StatefulComponentDirective<ComponentState> implements OnInit, OnChanges {
  @Input() public readonly divId: string = '';
  @Input() public readonly displayModalLink: boolean = WA_DISPLAY_FULL_SCREEN_DEFAULT;
  @Input() public readonly isDevMode: boolean = false;
  @Input() private readonly modelPaths: Array<string> = [];
  @Input() private readonly cameraEye: [number, number, number] = VA_CAMERA_EYE_DEFAULT;
  @Input() private readonly cameraCenter: [number, number, number] = VA_CAMERA_CENTER_DEFAULT;
  @Input() private readonly cameraUp: [number, number, number] = VA_CAMERA_UP_DEFAULT;
  @Input() private readonly cameraFieldOfView: number = VA_CAMERA_FIELD_OF_VIEW_DEFAULT;
  @Input() private readonly backgroundColor: [number, number, number, number] = VA_BACKGROUND_COLOR_DEFAULT;
  @Input() private readonly defaultColor: [number, number, number] = VA_DEFAULT_COLOR_DEFAULT;
  @Input() private readonly showEdges: boolean = VA_SHOW_EDGE_DEFAULT;
  @Input() private readonly edgeThreshold: number = VA_EDGE_THRESHOLD_DEFAULT;
  @Input() private readonly edgeColor: [number, number, number] = VA_EDGE_COLOR_DEFAULT;
  @Input() private readonly environmentMapFolderPath: string = '';
  @Input() private readonly displayWebInterfaceURL: boolean = VA_DISPLAY_WEB_INTERFACE_URL_DEFAULT;
  @Input() private readonly backgroundIsEnvMap: boolean = VA_BACKGROUND_IS_ENV_MAP_DEFAULT;
  @Input() private readonly initVisibility: MeshesVisibility | undefined = VA_INIT_VISIBILITY_DEFAULT;
  @Input() private readonly initZoom: Array<number> | undefined = VA_INIT_ZOOM_DEFAULT;
  @Input() private readonly initColors: Array<HighlightColor> | undefined = VA_INIT_COLOR_DEFAULT;
  @Input() private readonly workspaceResize$: Observable<void> = new Observable();
  @Input() private readonly workspaceAreaUpdate$: Observable<void> = new Observable();
  @Input() private readonly workspaceUpdate$: Observable<void> = new Observable();
  @Input() private readonly viewerAreaHighlightMeshes$: Observable<HighlightMeshesEvent> = new Observable();
  @Input() private readonly viewerAreaToggleMeshesVisibility$: Observable<ToggleMeshesVisibilityEvent> = new Observable();
  @Input() private readonly viewerAreaZoomOnMeshes$: Observable<ZoomOnMeshesEvent> = new Observable();
  @Input() private readonly viewerAreaMoveCamera$: Observable<MoveCameraEvent> = new Observable();

  private dialogVA: MatDialogRef<ThreeDViewerModalComponent> | undefined;

  private threeDViewerConfigSubject: Subject<ThreeDViewerConfig> = new Subject<ThreeDViewerConfig>();
  private modelHasBeenLoaded = false;
  private originalMaterials: Map<number, any> = new Map();
  private originalMaterialsHasBeenSet = false;

  private timeoutInitFirstRefresh: NodeJS.Timeout | undefined;
  private resetSizeInterval: NodeJS.Timeout | undefined;
  private viewer: any;
  private cameraUpdateIntervalId: NodeJS.Timeout | undefined;

  constructor(
    private readonly apiEndpointsService: ApiEndpointsService,
    private readonly shortcutsService: ShortcutsService,
    private readonly dialog: MatDialog,
    private readonly http: ApiHttpService,
    private readonly indexedDbService: IndexedDbService,
    private readonly logger: LoggerService,
    private readonly errorHandler: ErrorHandlerService
  ) {
    super({
      webInterfaceURL: '',
      displayWebInterfaceURL: false,
      camera: {},
      loaded: false,
      error: undefined
    });
  }

  public ngOnInit(): void {
    OV.SetExternalLibLocation('libs');
    this.updateComponentState({
      loaded: true
    });
    this.initSubscriptions();
    this.initUnsub();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    delete changes.workspaceResize$;
    delete changes.workspaceAreaUpdate$;
    delete changes.workspaceUpdate$;
    delete changes.viewerAreaHighlightMeshes$;
    delete changes.viewerAreaToggleMeshesVisibility$;
    delete changes.viewerAreaZoomOnMeshes$;
    delete changes.viewerAreaMoveCamera$;
    if (Object.keys(changes).length > 0) {
      this.threeDViewerConfigSubject.next({
        divId: `${this.divId}-modal`,
        displayModalLink: this.displayModalLink,
        modelPaths: this.modelPaths,
        cameraEye: this.cameraEye,
        cameraCenter: this.cameraCenter,
        cameraUp: this.cameraUp,
        cameraFieldOfView: this.cameraFieldOfView,
        backgroundColor: this.backgroundColor,
        defaultColor: this.defaultColor,
        showEdges: this.showEdges,
        edgeThreshold: this.edgeThreshold,
        edgeColor: this.edgeColor,
        environmentMapFolderPath: this.environmentMapFolderPath,
        backgroundIsEnvMap: this.backgroundIsEnvMap,
        initVisibility: this.initVisibility,
        initZoom: this.initZoom,
        initColors: this.initColors
      });
      this.refreshViewer();
    }
  }

  public onGoToModal(): void {
    this.open3DViewerAreaModal();
  }

  private open3DViewerAreaModal(): void {
    this.shortcutsService.setActive(false);
    const percent = `${MODAL_WIDTH_PERCENT}%`;
    const config: ModalConfig<ThreeDViewerConfig> = {
      config$: this.threeDViewerConfigSubject.asObservable(),
      initConfig: {
        divId: `${this.divId}-modal`,
        displayModalLink: this.displayModalLink,
        modelPaths: this.modelPaths,
        cameraEye: this.cameraEye,
        cameraCenter: this.cameraCenter,
        cameraUp: this.cameraUp,
        cameraFieldOfView: this.cameraFieldOfView,
        backgroundColor: this.backgroundColor,
        defaultColor: this.defaultColor,
        showEdges: this.showEdges,
        edgeThreshold: this.edgeThreshold,
        edgeColor: this.edgeColor,
        environmentMapFolderPath: this.environmentMapFolderPath,
        backgroundIsEnvMap: this.backgroundIsEnvMap,
        initVisibility: this.initVisibility,
        initZoom: this.initZoom,
        initColors: this.initColors
      }
    };
    this.dialogVA = this.dialog.open(ThreeDViewerModalComponent, {
      ...MAT_DIALOG_CONFIG,
      width: percent,
      maxWidth: percent,
      height: percent,
      data: config
    });
    this.dialogVA.afterClosed().subscribe({
      next: () => {
        this.shortcutsService.setActive(true);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private refreshViewer(): void {
    if (this.issetViewerInDOM() && this.getViewerDOMWidth() > 0) {
      if (this.timeoutInitFirstRefresh) {
        clearTimeout(this.timeoutInitFirstRefresh);
      }
      setTimeout(() => {
        this.renderViewer();
      }, SMALL_TIMEOUT_MS);
    } else {
      if (this.timeoutInitFirstRefresh) {
        clearTimeout(this.timeoutInitFirstRefresh);
      }
      this.timeoutInitFirstRefresh = setTimeout(() => {
        this.refreshViewer();
      }, VIEWER_3D_REFRESH_INTERVAL_TIME);
    }
  }

  private renderViewer(): void {
    const viewer = this.getViewerInDom();
    if (viewer) {
      viewer.innerHTML = '';
      this.viewer = new OV.EmbeddedViewer(viewer, {
        camera: this.getCamera(),
        backgroundColor: new OV.RGBAColor(...this.backgroundColor),
        defaultColor: new OV.RGBColor(...this.defaultColor),
        edgeSettings: new OV.EdgeSettings(this.showEdges, new OV.RGBColor(...this.edgeColor), this.edgeThreshold),
        environmentSettings: new OV.EnvironmentSettings(this.getEnvironmentPaths(this.environmentMapFolderPath), this.backgroundIsEnvMap),
        onModelLoaded: () => {
          this.onModelLoaded();
        }
      });

      const promises: Array<Promise<File>> = [];
      this.modelPaths.forEach((modelPath) => {
        promises.push(this.loadModelPromise(modelPath));
      });
      Promise.all(promises).then((files) => {
        this.viewer.LoadModelFromFileList(files);
        this.refreshWebInterfaceURL();
      });
    }
  }

  private onModelLoaded(): void {
    this.modelHasBeenLoaded = true;
    this.displayMeshes();
    this.setOriginalMaterials();
    if (this.initColors) {
      this.setMeshesHighlight(this.initColors);
    }
    if (this.initZoom) {
      this.zoomOnMeshes(this.initZoom);
    }
    if (this.initVisibility) {
      this.setMeshesVisibility(this.initVisibility.isVisible, this.initVisibility.ids);
    }
  }

  private displayMeshes(): void {
    const meshes: Array<Mesh> = [];
    this.viewer.viewer.mainModel.EnumerateMeshes((obj: Mesh) => {
      meshes.push(obj);
    });
    this.logger.log('info', 'Debug -----------------------------------------------------------------------------------------------------Debug');
    this.logger.log(
      'info',
      'Debug ~ meshes: ',
      meshes.map((m, i) => ({ id: i, name: m.name }))
    );
    this.logger.log('info', 'Debug -----------------------------------------------------------------------------------------------------Debug');
  }

  private async getLastModifiedDateOfModelInServer(modelPath: string): Promise<Date> {
    return new Promise((resolve, reject) => {
      this.http.head(modelPath, { observe: 'response' }).subscribe({
        next: async (response: HttpResponse<any>) => resolve(new Date(response.headers.get('Last-Modified') || '')),
        error: (err: unknown) => reject(err)
      });
    });
  }

  private async loadModelPromise(modelPath: string): Promise<File> {
    const filename = modelPath;
    const blob = await this.indexedDbService.get(filename);
    const blobType = await this.indexedDbService.get(`${filename}-metadata`);
    const fullModelPath = this.getModelPaths([modelPath])[0];
    const serverLastModifiedDate = await this.getLastModifiedDateOfModelInServer(fullModelPath);
    if (blob && blobType && blobType.lastUpdate && serverLastModifiedDate <= new Date(blobType.lastUpdate)) {
      return new File([blob], filename, { type: blobType });
    } else {
      return new Promise((resolve, reject) => {
        this.http.get(fullModelPath, { responseType: 'blob' }).subscribe({
          next: async (blob: Blob) => {
            this.indexedDbService.set(`${filename}-metadata`, { type: blob.type, lastUpdate: serverLastModifiedDate });
            this.indexedDbService.set(`${filename}`, blob);
            resolve(new File([blob], modelPath));
          },
          error: (err: unknown) => reject(err)
        });
      });
    }
  }

  private refreshWebInterfaceURL(): void {
    this.updateComponentState({
      webInterfaceURL: this.getWebInterfaceUrl(this.modelPaths),
      displayWebInterfaceURL: this.modelPaths.length === 0 ? false : this.displayWebInterfaceURL
    });
  }

  private initSubscriptions(): void {
    this.workspaceResize$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.resetSize();
      }
    });
    this.workspaceAreaUpdate$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.refreshViewer();
      }
    });
    this.workspaceUpdate$.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        if (this.dialogVA) {
          this.dialogVA.close();
        }
      }
    });
    this.viewerAreaHighlightMeshes$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        this.setMeshesHighlight(data.idsByColor);
      }
    });
    this.viewerAreaToggleMeshesVisibility$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        this.setMeshesVisibility(data.isVisible, data.meshIds);
      }
    });
    this.viewerAreaZoomOnMeshes$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        this.zoomOnMeshes(data.meshIds);
      }
    });
    this.viewerAreaMoveCamera$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (data.stepCount === 0) {
          this.setCamera(data.cameraEye, data.cameraCenter, data.cameraUp);
        } else {
          this.moveCamera(data.cameraEye, data.cameraCenter, data.cameraUp, data.stepCount);
        }
      }
    });
    this.cameraUpdateIntervalId = setInterval(() => {
      if (this.viewer && this.viewer.viewer && this.modelHasBeenLoaded) {
        this.updateComponentState({ camera: this.viewer.viewer.navigation.GetCamera() });
      }
    }, CAMERA_INTERVAL_UPDATE_MS);
  }

  private setOriginalMaterials(): void {
    if (!this.modelHasBeenLoaded) {
      return;
    }
    const viewer = this.viewer.viewer;
    viewer.mainModel.EnumerateMeshes((mesh: Mesh) => {
      const meshId = mesh.userData.originalMeshId.meshIndex;
      this.originalMaterials.set(meshId, mesh.material);
    });
    this.originalMaterialsHasBeenSet = true;
  }

  private createHighlightMaterials(originalMaterials: Array<any>, highlightMaterial: any) {
    const highlightMaterials = [];
    for (let i = 0; i < originalMaterials.length; i++) {
      highlightMaterials.push(highlightMaterial);
    }
    return highlightMaterials;
  }

  private setMeshesHighlight(idsByColor: Array<HighlightColor>): void {
    if (!this.viewer || !this.viewer.viewer || !this.modelHasBeenLoaded || !this.originalMaterialsHasBeenSet) {
      return;
    }
    const viewer = this.viewer.viewer;
    const colorMap: Map<number, string> = new Map();
    const colors: Array<string> = [];

    idsByColor.forEach((idsForThisColor) => {
      const color = idsForThisColor.color;
      colors.push(color);
      idsForThisColor.ids.forEach((id) => {
        colorMap.set(id, color);
      });
    });

    const highlightMaterialPerColor: Map<string, any> = new Map();
    colors.forEach((color) => highlightMaterialPerColor.set(color, viewer.CreateHighlightMaterial(color)));

    viewer.mainModel.EnumerateMeshes((mesh: Mesh) => {
      const meshId = mesh.userData.originalMeshId.meshIndex;
      const color = colorMap.get(meshId);
      if (color) {
        const highlightMaterial = highlightMaterialPerColor.get(color);
        if (highlightMaterial) {
          mesh.material = this.createHighlightMaterials(mesh.material, highlightMaterial);
        } else {
          mesh.material = this.originalMaterials.get(meshId);
        }
      } else {
        mesh.material = this.originalMaterials.get(meshId);
      }
    });
    viewer.Render();
  }

  private setMeshesVisibility(isVisible: boolean, meshIds: Array<number>): void {
    if (!this.viewer || !this.viewer.viewer || !this.modelHasBeenLoaded) {
      return;
    }
    const viewer = this.viewer.viewer;
    viewer.SetMeshesVisibility((userData: MeshUserData) =>
      isVisible ? meshIds.includes(userData.originalMeshId.meshIndex) : !meshIds.includes(userData.originalMeshId.meshIndex)
    );
  }

  private zoomOnMeshes(meshIds: Array<number> | undefined): void {
    if (!this.viewer || !this.viewer.viewer || !this.modelHasBeenLoaded) {
      return;
    }
    const viewer = this.viewer.viewer;
    if (meshIds) {
      const boundingSphere = viewer.GetBoundingSphere(
        (userData: MeshUserData) => meshIds.length === 0 || meshIds.includes(userData.originalMeshId.meshIndex)
      );
      viewer.FitSphereToWindow(boundingSphere, false);
    } else {
      viewer.SetCamera(this.getCamera());
    }
  }

  private setCamera(cameraEye: [number, number, number], cameraCenter: [number, number, number], cameraUp: [number, number, number]) {
    if (!this.viewer || !this.viewer.viewer || !this.modelHasBeenLoaded) {
      return;
    }
    const viewer = this.viewer.viewer;
    viewer.SetCamera(
      new OV.Camera(new OV.Coord3D(...cameraEye), new OV.Coord3D(...cameraCenter), new OV.Coord3D(...cameraUp), this.cameraFieldOfView)
    );
  }

  private moveCamera(
    cameraEye: [number, number, number],
    cameraCenter: [number, number, number],
    cameraUp: [number, number, number],
    stepCount: number
  ) {
    if (!this.viewer || !this.viewer.viewer || !this.modelHasBeenLoaded) {
      return;
    }
    const navigation = this.viewer.viewer.navigation;
    navigation.MoveCamera(new OV.Camera(new OV.Coord3D(...cameraEye), new OV.Coord3D(...cameraCenter), new OV.Coord3D(...cameraUp)), stepCount);
  }

  /*private getMeshId(meshName: string): number {
    const meshes: Array<Mesh> = [];
    this.viewer.viewer.mainModel.EnumerateMeshes((obj: Mesh) => {
      meshes.push(obj);
    });
    return meshes.findIndex((m) => m.name === meshName);
  }*/

  private resetSize(): void {
    if (this.resetSizeInterval) {
      clearInterval(this.resetSizeInterval);
    }
    this.resetSizeInterval = setInterval(() => {
      if (this.viewer && this.issetViewerInDOM() && this.getViewerDOMWidth() > 0) {
        this.viewer.Resize();
        clearInterval(this.resetSizeInterval);
      }
    }, RESET_SIZE_INTERVAL_MS);
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.timeoutInitFirstRefresh) {
          clearTimeout(this.timeoutInitFirstRefresh);
        }
        if (this.resetSizeInterval) {
          clearInterval(this.resetSizeInterval);
        }
        if (this.cameraUpdateIntervalId) {
          clearInterval(this.cameraUpdateIntervalId);
        }
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }

  private getEnvironmentPaths(folderPath: string): Array<string> {
    return folderPath === ''
      ? []
      : ENV_IMAGE_NAMES.map((image) => this.apiEndpointsService.get3DEnvironmentsUrlEndpoint(`${folderPath}/${image}.jpg`));
  }

  private getModelPaths(paths: Array<string>): Array<string> {
    return paths.map((path) => this.apiEndpointsService.get3DModelsUrlEndpoint(path));
  }

  private getWebInterfaceUrl(paths: Array<string>): string {
    const modelPaths = this.getModelPaths(paths);
    return `https://3dviewer.net/index.html#model=${modelPaths.join(',')}`;
  }

  private getCamera(): any {
    return new OV.Camera(
      new OV.Coord3D(...this.cameraEye),
      new OV.Coord3D(...this.cameraCenter),
      new OV.Coord3D(...this.cameraUp),
      this.cameraFieldOfView
    );
  }

  private getViewerInDomId(): string {
    return `viewer-${this.divId}`;
  }

  private getViewerInDom(): null | HTMLElement {
    return document.getElementById(this.getViewerInDomId());
  }

  private issetViewerInDOM(): boolean {
    return this.getViewerInDom() ? true : false;
  }

  private getViewerDOMWidth(): number {
    if (this.issetViewerInDOM()) {
      const element = this.getViewerInDom();
      if (element) {
        return element.clientWidth;
      }
    }
    return 0;
  }
}

export interface ThreeDViewerConfig {
  divId: string;
  displayModalLink: boolean;
  modelPaths: Array<string>;
  cameraEye: [number, number, number];
  cameraCenter: [number, number, number];
  cameraUp: [number, number, number];
  cameraFieldOfView: number;
  backgroundColor: [number, number, number, number];
  defaultColor: [number, number, number];
  showEdges: boolean;
  edgeThreshold: number;
  edgeColor: [number, number, number];
  environmentMapFolderPath: string;
  backgroundIsEnvMap: boolean;
  initVisibility: MeshesVisibility | undefined;
  initZoom: Array<number> | undefined;
  initColors: Array<HighlightColor> | undefined;
}

interface MeshUserData {
  originalMeshId: {
    meshIndex: number;
    nodeId: number;
  };
  originalMaterials: Array<number>;
  threeMaterials: any;
}

interface Mesh {
  material: any;
  name: string;
  userData: MeshUserData;
}
