export class QueryStringParameters {
  private readonly paramsAndValues: Array<string>;

  constructor() {
    this.paramsAndValues = [];
  }

  public push(key: string, value: string | number): void {
    this.paramsAndValues.push([key, encodeURIComponent(value.toString())].join('='));
  }

  public toString = (): string => this.paramsAndValues.join('&');
}
