import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'ngx-g2v-dashboard-qr-code',
  templateUrl: './dashboard-qr-code.component.html',
  styleUrls: ['./dashboard-qr-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardQrCodeComponent implements OnInit {
  public qrConfig: QRConfig = {
    elementType: NgxQrcodeElementTypes.URL,
    correctionLevel: NgxQrcodeErrorCorrectionLevels.HIGH,
    value: ''
  };
  constructor(@Inject(MAT_SNACK_BAR_DATA) public url: string, public snackBar: MatSnackBar) {}

  public ngOnInit(): void {
    this.qrConfig.value = this.url;
  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }
}

interface QRConfig {
  elementType: NgxQrcodeElementTypes;
  correctionLevel: NgxQrcodeErrorCorrectionLevels;
  value: string;
}
