<ng-container *transloco="let t; scope: 'app'; read: 'app.layout'">
  <ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="state.loaded">
      <div class="full-height full-width mat-app-background" [ngStyle]="{ 'background-color': state.backgroundColor }">
        <div fxLayout="column" fxFlex class="full-width full-height">
          <ngx-g2v-nav-bar *ngIf="state.hasTopBar" [menuItems]="menuItems" [title]="title" [logoUrl]="logoUrl" [defaultLang]="defaultLang"></ngx-g2v-nav-bar>
          <div class="main-layout__container" [ngClass]="{ 'with-top-bar': state.hasTopBar }" fxLayout="row">
            <div class="main-layout__content" fxLayout="column" [class.main-layout__content--padding]="state.hasTopBar">
              <ng-content></ng-content>
              <ng-container *ngIf="state.displayIcons">
                <ng-container *ngIf="state.g2StatusIcon as g2StatusIcon">
                  <a *ngIf="canAccessG2Page" mat-icon-button id="g2-status-icon" [routerLink]="g2RouterLink" title="{{ t('buttonG2StatusWithAccessTitle') }}">
                    <mat-icon [fontIcon]="g2StatusIcon"></mat-icon>
                  </a>
                  <a *ngIf="!canAccessG2Page" mat-icon-button class="cursor-default" id="g2-status-icon" [routerLink]="[]" title="{{ t('buttonG2StatusTitle') }}">
                    <mat-icon [fontIcon]="g2StatusIcon"></mat-icon>
                  </a>
                </ng-container>
                <ngx-g2v-toggle-dev-mode id="dev-mode-toggle" accessControl [clientRoles]="clientRolesToSwitchDevMode"></ngx-g2v-toggle-dev-mode>
                <div accessControl [clientRoles]="clientRolesToSwitchDevMode" id="socketId">{{ userService.currentSocketId }}</div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!state.loaded">
      <ngx-g2v-progress-spinner></ngx-g2v-progress-spinner>
    </ng-container>
    <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
  </ng-container>
</ng-container>