import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'ngx-g2v-formly-divider',
  templateUrl: './formly-divider.component.html',
  styleUrls: ['./formly-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyDividerComponent extends FieldType<FieldTypeConfig> {}
