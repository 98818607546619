import { Injectable } from '@angular/core';
import { STATE_G2VIEW_WORKSPACES, WORKSPACE_MINIMIZED_DEFAULT_TITLE } from '@config/constants';
import { areStringArraysEqual, removeDuplicates } from '@config/utils';
import { RxJSBaseDirective } from '@directives/rxjs-base.directive';
import {
  ActionArea,
  ChartArea,
  ConnectionArea,
  DashboardButtonArea,
  EmbeddedVideoArea,
  FormArea,
  FrameArea,
  ImageArea,
  MapArea,
  MarkmapArea,
  MenuArea,
  MermaidArea,
  MinimizedWorkspace,
  NamedWorkspace,
  NavigationArea,
  SliderArea,
  SpeechRecognitionArea,
  TableArea,
  TextArea,
  TimelineArea,
  TypeInBoxArea,
  Viewer3DArea,
  WorkspaceArea,
  WorkspaceDB,
  WorkspaceModule,
  WORKSPACES_MODULES_INTERVAL_UPDATE_MS
} from '@g2view/g2view-commons';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { ErrorHandlerService } from '@services/error-handler.service';
import { WorkspaceAreasService } from '@workspaces/services/workspace-areas.service';
import { WorkspacesService } from '@workspaces/services/workspaces.service';
import {
  AddActionAreas,
  AddChartAreas,
  AddConnectionAreas,
  AddDashboardButtonAreas,
  AddEmbeddedVideoAreas,
  AddFormAreas,
  AddFrameAreas,
  AddImageAreas,
  AddMapAreas,
  AddMarkmapAreas,
  AddMenuAreas,
  AddMermaidAreas,
  AddMinimizedWorkspaces,
  AddNavigationAreas,
  AddSliderAreas,
  AddSpeechRecognitionAreas,
  AddTableAreas,
  AddTextAreas,
  AddTimelineAreas,
  AddTypeInBoxAreas,
  AddViewer3DAreas,
  AddWorkspaces,
  RefreshWorkspacesImageUrl,
  RemoveActionAreas,
  RemoveChartAreas,
  RemoveConnectionAreas,
  RemoveDashboardButtonAreas,
  RemoveEmbeddedVideoAreas,
  RemoveFormAreas,
  RemoveFrameAreas,
  RemoveImageAreas,
  RemoveMapAreas,
  RemoveMarkmapAreas,
  RemoveMenuAreas,
  RemoveMermaidAreas,
  RemoveNavigationAreas,
  RemoveSliderAreas,
  RemoveSpeechRecognitionAreas,
  RemoveTableAreas,
  RemoveTextAreas,
  RemoveTimelineAreas,
  RemoveTypeInBoxAreas,
  RemoveViewer3DAreas,
  RemoveWorkspaces,
  ResetMinimizedWorkspaces,
  ResetMonitoredWorkspaces,
  ResetNamedWorkspaces,
  SetMinimizedWorkspaces,
  SetMonitoredWorkspaces,
  SetNamedWorkspaces,
  SetWorkspacesModule,
  UpdateActionArea,
  UpdateChartArea,
  UpdateConnectionArea,
  UpdateDashboardButtonArea,
  UpdateEmbeddedVideoArea,
  UpdateFormArea,
  UpdateFrameArea,
  UpdateImageArea,
  UpdateMapArea,
  UpdateMarkmapArea,
  UpdateMenuArea,
  UpdateMermaidArea,
  UpdateMinimizedWorkspaces,
  UpdateNavigationArea,
  UpdateSliderArea,
  UpdateSpeechRecognitionArea,
  UpdateTableArea,
  UpdateTextArea,
  UpdateTimelineArea,
  UpdateTypeInBoxArea,
  UpdateViewer3DArea,
  UpdateWorkspace
} from '@workspaces/state/workspaces.actions';

@State<WorkspacesStateModel>({
  name: STATE_G2VIEW_WORKSPACES,
  defaults: {
    workspaces: [],
    monitoredWorkspaces: [],
    namedWorkspacesLoaded: false,
    namedWorkspaces: [],
    minimizedWorkspaces: [],
    workspacesModules: [],
    aas: [],
    cas: [],
    conas: [],
    dbas: [],
    evas: [],
    fas: [],
    fras: [],
    imas: [],
    maras: [],
    mas: [],
    menas: [],
    meras: [],
    nas: [],
    sas: [],
    sras: [],
    tabas: [],
    tas: [],
    timas: [],
    tibs: [],
    vas: []
  }
})
@Injectable()
export class WorkspacesState extends RxJSBaseDirective implements NgxsOnInit {
  private workspacesModulesIntervalId: undefined | NodeJS.Timeout = undefined;

  constructor(
    private readonly workspacesService: WorkspacesService,
    private readonly workspaceAreasService: WorkspaceAreasService,
    private readonly store: Store,
    private readonly errorHandler: ErrorHandlerService
  ) {
    super();
    this.initUnsub();
  }

  @Selector() public static SelectWorkspaces(state: WorkspacesStateModel): Array<WorkspaceDB> {
    return state.workspaces;
  }

  @Selector() public static SelectMonitoredWorkspaces(state: WorkspacesStateModel): Array<string> {
    return state.monitoredWorkspaces;
  }

  @Selector() public static SelectNamedWorkspaces(state: WorkspacesStateModel): Array<NamedWorkspace> {
    return state.namedWorkspaces;
  }

  @Selector() public static SelectMinimizedWorkspaces(state: WorkspacesStateModel): Array<MinimizedWorkspace> {
    return state.minimizedWorkspaces;
  }

  @Selector() public static SelectChartAreas(state: WorkspacesStateModel): Array<ChartArea> {
    return state.cas;
  }

  @Selector() public static SelectImageAreas(state: WorkspacesStateModel): Array<ImageArea> {
    return state.imas;
  }

  @Selector() public static SelectMarkmapAreas(state: WorkspacesStateModel): Array<MarkmapArea> {
    return state.maras;
  }

  @Selector() public static SelectMermaidAreas(state: WorkspacesStateModel): Array<MermaidArea> {
    return state.meras;
  }

  @Selector() public static SelectTableAreas(state: WorkspacesStateModel): Array<TableArea> {
    return state.tabas;
  }

  @Selector() public static SelectTimelineAreas(state: WorkspacesStateModel): Array<TimelineArea> {
    return state.timas;
  }

  @Action(AddWorkspaces) public addWorkspaces(ctx: StateContext<WorkspacesStateModel>, action: AddWorkspaces): void {
    const workspaces = this.workspacesService.addImgUrlToWorkspaces(action.workspaces);
    this.setWASInStore(workspaces);
    ctx.patchState({
      workspaces: removeDuplicates([...ctx.getState().workspaces, ...workspaces], 'uuid')
    });
    this.workspacesService.emitCreatedWorkspaces(workspaces.map((ws) => ws.uuid));
  }

  @Action(UpdateWorkspace) public updateWorkspace(ctx: StateContext<WorkspacesStateModel>, action: UpdateWorkspace): void {
    const workspaceToUpdate = this.workspacesService.addImgUrlToWorkspaces([action.workspace])[0];
    this.setWASInStore([workspaceToUpdate]);
    const workspaces = [...ctx.getState().workspaces];
    const workspaceIndex = workspaces.findIndex((s) => s._id === workspaceToUpdate._id);
    if (workspaceIndex > -1) {
      workspaces[workspaceIndex] = workspaceToUpdate;
      ctx.patchState({
        workspaces
      });
    }
    this.workspacesService.emitUpdatedWorkspaces([workspaceToUpdate.uuid]);
  }

  @Action(RemoveWorkspaces) public removeWorkspaces(ctx: StateContext<WorkspacesStateModel>, action: RemoveWorkspaces): void {
    action.workspaces.forEach((ws) => {
      this.removeWASFromStore(ws);
    });
    ctx.patchState({
      workspaces: [...ctx.getState().workspaces.filter((ws) => !action.workspaces.map((w) => w._id).includes(ws._id))]
    });
  }

  @Action(RefreshWorkspacesImageUrl) public refreshWorkspacesImageUrl(
    ctx: StateContext<WorkspacesStateModel>,
    action: RefreshWorkspacesImageUrl
  ): void {
    let atLeastOneUpdate = false;
    const workspaces = [...ctx.getState().workspaces];
    action.uuids.forEach((uuid) => {
      const wsIndex = workspaces.findIndex((ws) => ws.uuid === uuid);
      if (wsIndex > -1) {
        atLeastOneUpdate = true;
        workspaces[wsIndex] = {
          ...workspaces[wsIndex],
          imgUrl: this.workspacesService.getCurrentWorkspaceImageUrl(uuid)
        };
      }
    });
    if (atLeastOneUpdate) {
      ctx.patchState({
        workspaces
      });
      this.workspacesService.emitUpdatedWorkspaces(action.uuids);
    }
  }

  @Action(SetMonitoredWorkspaces) public setMonitoredWorkspaces(ctx: StateContext<WorkspacesStateModel>, action: SetMonitoredWorkspaces): void {
    const current = [...ctx.getState().monitoredWorkspaces];
    const newWs = [...action.workspaces];
    if (!areStringArraysEqual(current, newWs)) {
      ctx.patchState({
        monitoredWorkspaces: newWs
      });
    }
  }

  @Action(ResetMonitoredWorkspaces) public resetMonitoredWorkspaces(ctx: StateContext<WorkspacesStateModel>): void {
    ctx.patchState({
      monitoredWorkspaces: []
    });
  }

  @Action(SetNamedWorkspaces) public setNamedWorkspaces(ctx: StateContext<WorkspacesStateModel>, action: SetNamedWorkspaces): void {
    ctx.patchState({
      namedWorkspacesLoaded: true,
      namedWorkspaces: action.namedWorkspaces
    });
  }

  @Action(ResetNamedWorkspaces) public resetNamedWorkspaces(ctx: StateContext<WorkspacesStateModel>): void {
    ctx.patchState({
      namedWorkspaces: []
    });
  }

  @Action(SetMinimizedWorkspaces) public setMinimizedWorkspaces(ctx: StateContext<WorkspacesStateModel>, action: SetMinimizedWorkspaces): void {
    const current = [...ctx.getState().minimizedWorkspaces];
    const minimizedWS = [...action.minimizedWorkspacesUuids];
    const currentFiltered = current.filter((ws) => minimizedWS.includes(ws.uuid));
    const currentUuids = currentFiltered.map((ws) => ws.uuid);
    minimizedWS.forEach((uuid) => {
      if (!currentUuids.includes(uuid)) {
        currentFiltered.push({ uuid, title: WORKSPACE_MINIMIZED_DEFAULT_TITLE });
      }
    });
    ctx.patchState({
      minimizedWorkspaces: currentFiltered.sort((wsA, wsB) => wsA.title.localeCompare(wsB.title))
    });
  }

  @Action(AddMinimizedWorkspaces) public addMinimizedWorkspaces(ctx: StateContext<WorkspacesStateModel>, action: AddMinimizedWorkspaces): void {
    const current = [...ctx.getState().minimizedWorkspaces];
    const minimizedWS = [...action.minimizedWorkspaces];
    minimizedWS.forEach((minWs) => {
      const index = current.findIndex((ws) => ws.uuid === minWs.uuid);
      if (index > -1) {
        current[index] = {
          ...current[index],
          title: minWs.title
        };
      } else {
        current.push(minWs);
      }
    });
    ctx.patchState({
      minimizedWorkspaces: current.sort((wsA, wsB) => wsA.title.localeCompare(wsB.title))
    });
  }

  @Action(UpdateMinimizedWorkspaces) public updateMinimizedWorkspaces(
    ctx: StateContext<WorkspacesStateModel>,
    action: UpdateMinimizedWorkspaces
  ): void {
    const current = [...ctx.getState().minimizedWorkspaces];
    const minimizedWS = [...action.minimizedWorkspaces];
    minimizedWS.forEach((minWs) => {
      const index = current.findIndex((ws) => ws.uuid === minWs.uuid);
      if (index > -1) {
        current[index] = {
          ...current[index],
          title: minWs.title
        };
      }
    });
    ctx.patchState({
      minimizedWorkspaces: current.sort((wsA, wsB) => wsA.title.localeCompare(wsB.title))
    });
  }

  @Action(ResetMinimizedWorkspaces) public resetMinimizedWorkspaces(ctx: StateContext<WorkspacesStateModel>): void {
    ctx.patchState({
      minimizedWorkspaces: []
    });
  }

  @Action(SetWorkspacesModule) public setWorkspacesModule(ctx: StateContext<WorkspacesStateModel>, action: SetWorkspacesModule): void {
    ctx.patchState({
      workspacesModules: action.workspacesModules
    });
  }

  @Action(AddActionAreas) public addActionAreas(ctx: StateContext<WorkspacesStateModel>, action: AddActionAreas): void {
    ctx.patchState({
      aas: removeDuplicates([...ctx.getState().aas, ...action.actionAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'aas',
      action.actionAreas.map((aa) => aa._id ?? ''),
      action.actionAreas.map((aa) => aa.key)
    );
  }

  @Action(UpdateActionArea) public updateActionArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateActionArea): void {
    const aaToUpdate = action.actionArea;
    const aas = [...ctx.getState().aas];
    const aaIndex = aas.findIndex((aa) => aa._id === aaToUpdate._id);
    let aaPrevious;
    if (aaIndex > -1) {
      aaPrevious = { ...aas[aaIndex] };
      aas[aaIndex] = aaToUpdate;
      ctx.patchState({
        aas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('aas', [aaToUpdate._id ?? ''], [aaToUpdate.key], [hasMoved(aaPrevious, aaToUpdate)]);
  }

  @Action(RemoveActionAreas) public removeActionAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveActionAreas): void {
    ctx.patchState({
      aas: [...ctx.getState().aas.filter((aa) => !action.ids.includes(aa._id ?? ''))]
    });
  }

  @Action(AddChartAreas) public addChartAreas(ctx: StateContext<WorkspacesStateModel>, action: AddChartAreas): void {
    ctx.patchState({
      cas: removeDuplicates([...ctx.getState().cas, ...action.chartAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'cas',
      action.chartAreas.map((ca) => ca._id ?? ''),
      action.chartAreas.map((ca) => ca.key)
    );
  }

  @Action(UpdateChartArea) public updateChartArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateChartArea): void {
    const caToUpdate = action.chartArea;
    const cas = [...ctx.getState().cas];
    const caIndex = cas.findIndex((ca) => ca._id === caToUpdate._id);
    let caPrevious;
    if (caIndex > -1) {
      caPrevious = { ...cas[caIndex] };
      cas[caIndex] = caToUpdate;
      ctx.patchState({
        cas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('cas', [caToUpdate._id ?? ''], [caToUpdate.key], [hasMoved(caPrevious, caToUpdate)]);
  }

  @Action(RemoveChartAreas) public removeChartAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveChartAreas): void {
    ctx.patchState({
      cas: [...ctx.getState().cas.filter((ca) => !action.ids.includes(ca._id ?? ''))]
    });
  }

  @Action(AddConnectionAreas) public addConnectionAreas(ctx: StateContext<WorkspacesStateModel>, action: AddConnectionAreas): void {
    ctx.patchState({
      conas: removeDuplicates([...ctx.getState().conas, ...action.connectionAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'conas',
      action.connectionAreas.map((cona) => cona._id ?? ''),
      action.connectionAreas.map((cona) => cona.key)
    );
  }

  @Action(UpdateConnectionArea) public updateConnectionArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateConnectionArea): void {
    const conaToUpdate = action.connectionArea;
    const conas = [...ctx.getState().conas];
    const conaIndex = conas.findIndex((cona) => cona._id === conaToUpdate._id);
    let conaPrevious;
    if (conaIndex > -1) {
      conaPrevious = { ...conas[conaIndex] };
      conas[conaIndex] = conaToUpdate;
      ctx.patchState({
        conas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas(
      'conas',
      [conaToUpdate._id ?? ''],
      [conaToUpdate.key],
      [hasMoved(conaPrevious, conaToUpdate)]
    );
  }

  @Action(RemoveConnectionAreas) public removeConnectionAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveConnectionAreas): void {
    ctx.patchState({
      conas: [...ctx.getState().conas.filter((cona) => !action.ids.includes(cona._id ?? ''))]
    });
  }

  @Action(AddDashboardButtonAreas) public addDashboardButtonAreas(ctx: StateContext<WorkspacesStateModel>, action: AddDashboardButtonAreas): void {
    ctx.patchState({
      dbas: removeDuplicates([...ctx.getState().dbas, ...action.dashboardButtonAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'dbas',
      action.dashboardButtonAreas.map((dba) => dba._id ?? ''),
      action.dashboardButtonAreas.map((dba) => dba.key)
    );
  }

  @Action(UpdateDashboardButtonArea) public updateDashboardButtonArea(
    ctx: StateContext<WorkspacesStateModel>,
    action: UpdateDashboardButtonArea
  ): void {
    const dbaToUpdate = action.dashboardButtonArea;
    const dbas = [...ctx.getState().dbas];
    const dbaIndex = dbas.findIndex((dba) => dba._id === dbaToUpdate._id);
    let dbaPrevious;
    if (dbaIndex > -1) {
      dbaPrevious = { ...dbas[dbaIndex] };
      dbas[dbaIndex] = dbaToUpdate;
      ctx.patchState({
        dbas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('dbas', [dbaToUpdate._id ?? ''], [dbaToUpdate.key], [hasMoved(dbaPrevious, dbaToUpdate)]);
  }

  @Action(RemoveDashboardButtonAreas) public removeDashboardButtonAreas(
    ctx: StateContext<WorkspacesStateModel>,
    action: RemoveDashboardButtonAreas
  ): void {
    ctx.patchState({
      dbas: [...ctx.getState().dbas.filter((dba) => !action.ids.includes(dba._id ?? ''))]
    });
  }

  @Action(AddEmbeddedVideoAreas) public addEmbeddedVideoAreas(ctx: StateContext<WorkspacesStateModel>, action: AddEmbeddedVideoAreas): void {
    ctx.patchState({
      evas: removeDuplicates([...ctx.getState().evas, ...action.embeddedVideoAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'evas',
      action.embeddedVideoAreas.map((eva) => eva._id ?? ''),
      action.embeddedVideoAreas.map((eva) => eva.key)
    );
  }

  @Action(UpdateEmbeddedVideoArea) public updateEmbeddedVideoArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateEmbeddedVideoArea): void {
    const evaToUpdate = action.embeddedVideoArea;
    const evas = [...ctx.getState().evas];
    const evaIndex = evas.findIndex((eva) => eva._id === evaToUpdate._id);
    let evaPrevious;
    if (evaIndex > -1) {
      evaPrevious = { ...evas[evaIndex] };
      evas[evaIndex] = evaToUpdate;
      ctx.patchState({
        evas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('evas', [evaToUpdate._id ?? ''], [evaToUpdate.key], [hasMoved(evaPrevious, evaToUpdate)]);
  }

  @Action(RemoveEmbeddedVideoAreas) public removeEmbeddedVideoAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveEmbeddedVideoAreas): void {
    ctx.patchState({
      evas: [...ctx.getState().evas.filter((eva) => !action.ids.includes(eva._id ?? ''))]
    });
  }

  @Action(AddFormAreas) public addFormAreas(ctx: StateContext<WorkspacesStateModel>, action: AddFormAreas): void {
    ctx.patchState({
      fas: removeDuplicates([...ctx.getState().fas, ...action.formArea], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'fas',
      action.formArea.map((fa) => fa._id ?? ''),
      action.formArea.map((fa) => fa.key)
    );
  }

  @Action(UpdateFormArea) public updateFormArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateFormArea): void {
    const faToUpdate = action.formArea;
    const fas = [...ctx.getState().fas];
    const faIndex = fas.findIndex((fa) => fa._id === faToUpdate._id);
    let faPrevious;
    if (faIndex > -1) {
      faPrevious = { ...fas[faIndex] };
      fas[faIndex] = faToUpdate;
      ctx.patchState({
        fas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('fas', [faToUpdate._id ?? ''], [faToUpdate.key], [hasMoved(faPrevious, faToUpdate)]);
  }

  @Action(RemoveFormAreas) public removeFormAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveFormAreas): void {
    ctx.patchState({
      fas: [...ctx.getState().fas.filter((fa) => !action.ids.includes(fa._id ?? ''))]
    });
  }

  @Action(AddFrameAreas) public addFrameAreas(ctx: StateContext<WorkspacesStateModel>, action: AddFrameAreas): void {
    ctx.patchState({
      fras: removeDuplicates([...ctx.getState().fras, ...action.frameAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'fras',
      action.frameAreas.map((fra) => fra._id ?? ''),
      action.frameAreas.map((fra) => fra.key)
    );
  }

  @Action(UpdateFrameArea) public updateFrameArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateFrameArea): void {
    const fraToUpdate = action.frameArea;
    const fras = [...ctx.getState().fras];
    const fraIndex = fras.findIndex((fra) => fra._id === fraToUpdate._id);
    let fraPrevious;
    if (fraIndex > -1) {
      fraPrevious = { ...fras[fraIndex] };
      fras[fraIndex] = fraToUpdate;
      ctx.patchState({
        fras
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('fras', [fraToUpdate._id ?? ''], [fraToUpdate.key], [hasMoved(fraPrevious, fraToUpdate)]);
  }

  @Action(RemoveFrameAreas) public removeFrameAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveFrameAreas): void {
    ctx.patchState({
      fras: [...ctx.getState().fras.filter((fra) => !action.ids.includes(fra._id ?? ''))]
    });
  }

  @Action(AddImageAreas) public addImageAreas(ctx: StateContext<WorkspacesStateModel>, action: AddImageAreas): void {
    ctx.patchState({
      imas: removeDuplicates([...ctx.getState().imas, ...action.imageAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'imas',
      action.imageAreas.map((ima) => ima._id ?? ''),
      action.imageAreas.map((ima) => ima.key)
    );
  }

  @Action(UpdateImageArea) public updateImageArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateImageArea): void {
    const imaToUpdate = action.imageArea;
    const imas = [...ctx.getState().imas];
    const imaIndex = imas.findIndex((ima) => ima._id === imaToUpdate._id);
    let imaPrevious;
    if (imaIndex > -1) {
      imaPrevious = { ...imas[imaIndex] };
      imas[imaIndex] = imaToUpdate;
      ctx.patchState({
        imas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('imas', [imaToUpdate._id ?? ''], [imaToUpdate.key], [hasMoved(imaPrevious, imaToUpdate)]);
  }

  @Action(RemoveImageAreas) public removeImageAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveImageAreas): void {
    ctx.patchState({
      imas: [...ctx.getState().imas.filter((ima) => !action.ids.includes(ima._id ?? ''))]
    });
  }

  @Action(AddMarkmapAreas) public addMarkmapAreas(ctx: StateContext<WorkspacesStateModel>, action: AddMarkmapAreas): void {
    ctx.patchState({
      maras: removeDuplicates([...ctx.getState().maras, ...action.markmapAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'maras',
      action.markmapAreas.map((mara) => mara._id ?? ''),
      action.markmapAreas.map((mara) => mara.key)
    );
  }

  @Action(UpdateMarkmapArea) public updateMarkmapArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateMarkmapArea): void {
    const maraToUpdate = action.markmapArea;
    const maras = [...ctx.getState().maras];
    const maraIndex = maras.findIndex((mara) => mara._id === maraToUpdate._id);
    let maraPrevious;
    if (maraIndex > -1) {
      maraPrevious = { ...maras[maraIndex] };
      maras[maraIndex] = maraToUpdate;
      ctx.patchState({
        maras
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas(
      'maras',
      [maraToUpdate._id ?? ''],
      [maraToUpdate.key],
      [hasMoved(maraPrevious, maraToUpdate)]
    );
  }

  @Action(RemoveMarkmapAreas) public removeMarkmapArea(ctx: StateContext<WorkspacesStateModel>, action: RemoveMarkmapAreas): void {
    ctx.patchState({
      maras: [...ctx.getState().maras.filter((mara) => !action.ids.includes(mara._id ?? ''))]
    });
  }

  @Action(AddMapAreas) public addMapAreas(ctx: StateContext<WorkspacesStateModel>, action: AddMapAreas): void {
    ctx.patchState({
      mas: removeDuplicates([...ctx.getState().mas, ...action.mapAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'mas',
      action.mapAreas.map((ma) => ma._id ?? ''),
      action.mapAreas.map((ma) => ma.key)
    );
  }

  @Action(UpdateMapArea) public updateMapArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateMapArea): void {
    const maToUpdate = action.mapArea;
    const mas = [...ctx.getState().mas];
    const maIndex = mas.findIndex((ma) => ma._id === maToUpdate._id);
    let maPrevious;
    if (maIndex > -1) {
      maPrevious = { ...mas[maIndex] };
      mas[maIndex] = maToUpdate;
      ctx.patchState({
        mas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('mas', [maToUpdate._id ?? ''], [maToUpdate.key], [hasMoved(maPrevious, maToUpdate)]);
  }

  @Action(RemoveMapAreas) public removeMapAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveMapAreas): void {
    ctx.patchState({
      mas: [...ctx.getState().mas.filter((ma) => !action.ids.includes(ma._id ?? ''))]
    });
  }

  @Action(AddMenuAreas) public addMenuAreas(ctx: StateContext<WorkspacesStateModel>, action: AddMenuAreas): void {
    ctx.patchState({
      menas: removeDuplicates([...ctx.getState().menas, ...action.menuAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'menas',
      action.menuAreas.map((mena) => mena._id ?? ''),
      action.menuAreas.map((mena) => mena.key)
    );
  }

  @Action(UpdateMenuArea) public updateMenuArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateMenuArea): void {
    const menaToUpdate = action.menuArea;
    const menas = [...ctx.getState().menas];
    const menaIndex = menas.findIndex((mena) => mena._id === menaToUpdate._id);
    let menaPrevious;
    if (menaIndex > -1) {
      menaPrevious = { ...menas[menaIndex] };
      menas[menaIndex] = menaToUpdate;
      ctx.patchState({
        menas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas(
      'menas',
      [menaToUpdate._id ?? ''],
      [menaToUpdate.key],
      [hasMoved(menaPrevious, menaToUpdate)]
    );
  }

  @Action(RemoveMenuAreas) public removeMenuAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveMenuAreas): void {
    ctx.patchState({
      menas: [...ctx.getState().menas.filter((mena) => !action.ids.includes(mena._id ?? ''))]
    });
  }

  @Action(AddMermaidAreas) public addMermaidAreas(ctx: StateContext<WorkspacesStateModel>, action: AddMermaidAreas): void {
    ctx.patchState({
      meras: removeDuplicates([...ctx.getState().meras, ...action.mermaidAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'meras',
      action.mermaidAreas.map((mera) => mera._id ?? ''),
      action.mermaidAreas.map((mera) => mera.key)
    );
  }

  @Action(UpdateMermaidArea) public updateMermaidArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateMermaidArea): void {
    const meraToUpdate = action.mermaidArea;
    const meras = [...ctx.getState().meras];
    const meraIndex = meras.findIndex((mera) => mera._id === meraToUpdate._id);
    let meraPrevious;
    if (meraIndex > -1) {
      meraPrevious = { ...meras[meraIndex] };
      meras[meraIndex] = meraToUpdate;
      ctx.patchState({
        meras
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas(
      'meras',
      [meraToUpdate._id ?? ''],
      [meraToUpdate.key],
      [hasMoved(meraPrevious, meraToUpdate)]
    );
  }

  @Action(RemoveMermaidAreas) public removeMermaidAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveMermaidAreas): void {
    ctx.patchState({
      meras: [...ctx.getState().meras.filter((mera) => !action.ids.includes(mera._id ?? ''))]
    });
  }

  @Action(AddNavigationAreas) public addNavigationAreas(ctx: StateContext<WorkspacesStateModel>, action: AddNavigationAreas): void {
    ctx.patchState({
      nas: removeDuplicates([...ctx.getState().nas, ...action.navigationAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'nas',
      action.navigationAreas.map((na) => na._id ?? ''),
      action.navigationAreas.map((na) => na.key)
    );
  }

  @Action(UpdateNavigationArea) public updateNavigationArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateNavigationArea): void {
    const naToUpdate = action.navigationArea;
    const nas = [...ctx.getState().nas];
    const naIndex = nas.findIndex((na) => na._id === naToUpdate._id);
    let naPrevious;
    if (naIndex > -1) {
      naPrevious = { ...nas[naIndex] };
      nas[naIndex] = naToUpdate;
      ctx.patchState({
        nas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('nas', [naToUpdate._id ?? ''], [naToUpdate.key], [hasMoved(naPrevious, naToUpdate)]);
  }

  @Action(RemoveNavigationAreas) public removeNavigationAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveNavigationAreas): void {
    ctx.patchState({
      nas: [...ctx.getState().nas.filter((na) => !action.ids.includes(na._id ?? ''))]
    });
  }

  @Action(AddSliderAreas) public addSliderAreas(ctx: StateContext<WorkspacesStateModel>, action: AddSliderAreas): void {
    ctx.patchState({
      sas: removeDuplicates([...ctx.getState().sas, ...action.sliderAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'sas',
      action.sliderAreas.map((sa) => sa._id ?? ''),
      action.sliderAreas.map((sa) => sa.key)
    );
  }

  @Action(UpdateSliderArea) public updateSliderArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateSliderArea): void {
    const saToUpdate = action.sliderArea;
    const sas = [...ctx.getState().sas];
    const saIndex = sas.findIndex((sa) => sa._id === saToUpdate._id);
    let saPrevious;
    if (saIndex > -1) {
      saPrevious = { ...sas[saIndex] };
      sas[saIndex] = saToUpdate;
      ctx.patchState({
        sas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('sas', [saToUpdate._id ?? ''], [saToUpdate.key], [hasMoved(saPrevious, saToUpdate)]);
  }

  @Action(RemoveSliderAreas) public removeSliderAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveSliderAreas): void {
    ctx.patchState({
      sas: [...ctx.getState().sas.filter((sa) => !action.ids.includes(sa._id ?? ''))]
    });
  }

  @Action(AddSpeechRecognitionAreas) public addSpeechRecognitionAreas(
    ctx: StateContext<WorkspacesStateModel>,
    action: AddSpeechRecognitionAreas
  ): void {
    ctx.patchState({
      sras: removeDuplicates([...ctx.getState().sras, ...action.speechRecognitionAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'sras',
      action.speechRecognitionAreas.map((sra) => sra._id ?? ''),
      action.speechRecognitionAreas.map((sra) => sra.key)
    );
  }

  @Action(UpdateSpeechRecognitionArea) public updateSpeechRecognitionArea(
    ctx: StateContext<WorkspacesStateModel>,
    action: UpdateSpeechRecognitionArea
  ): void {
    const sraToUpdate = action.speechRecognitionArea;
    const sras = [...ctx.getState().sras];
    const sraIndex = sras.findIndex((sra) => sra._id === sraToUpdate._id);
    let sraPrevious;
    if (sraIndex > -1) {
      sraPrevious = { ...sras[sraIndex] };
      sras[sraIndex] = sraToUpdate;
      ctx.patchState({
        sras
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('sras', [sraToUpdate._id ?? ''], [sraToUpdate.key], [hasMoved(sraPrevious, sraToUpdate)]);
  }

  @Action(RemoveSpeechRecognitionAreas) public removeSpeechRecognitionAreas(
    ctx: StateContext<WorkspacesStateModel>,
    action: RemoveSpeechRecognitionAreas
  ): void {
    ctx.patchState({
      sras: [...ctx.getState().sras.filter((sra) => !action.ids.includes(sra._id ?? ''))]
    });
  }

  @Action(AddTableAreas) public addTableAreas(ctx: StateContext<WorkspacesStateModel>, action: AddTableAreas): void {
    ctx.patchState({
      tabas: removeDuplicates([...ctx.getState().tabas, ...action.tableAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'tabas',
      action.tableAreas.map((taba) => taba._id ?? ''),
      action.tableAreas.map((taba) => taba.key)
    );
  }

  @Action(UpdateTableArea) public updateTableArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateTableArea): void {
    const tabaToUpdate = action.tableArea;
    const tabas = [...ctx.getState().tabas];
    const tabaIndex = tabas.findIndex((taba) => taba._id === tabaToUpdate._id);
    let tabaPrevious;
    if (tabaIndex > -1) {
      tabaPrevious = { ...tabas[tabaIndex] };
      tabas[tabaIndex] = tabaToUpdate;
      ctx.patchState({
        tabas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas(
      'tabas',
      [tabaToUpdate._id ?? ''],
      [tabaToUpdate.key],
      [hasMoved(tabaPrevious, tabaToUpdate)]
    );
  }

  @Action(RemoveTableAreas) public removeTableAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveTableAreas): void {
    ctx.patchState({
      tabas: [...ctx.getState().tabas.filter((taba) => !action.ids.includes(taba._id ?? ''))]
    });
  }

  @Action(AddTextAreas) public addTextAreas(ctx: StateContext<WorkspacesStateModel>, action: AddTextAreas): void {
    ctx.patchState({
      tas: removeDuplicates([...ctx.getState().tas, ...action.textAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'tas',
      action.textAreas.map((ta) => ta._id ?? ''),
      action.textAreas.map((ta) => ta.key)
    );
  }

  @Action(UpdateTextArea) public updateTextArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateTextArea): void {
    const taToUpdate = action.textArea;
    const tas = [...ctx.getState().tas];
    const taIndex = tas.findIndex((ta) => ta._id === taToUpdate._id);
    let taPrevious;
    if (taIndex > -1) {
      taPrevious = { ...tas[taIndex] };
      tas[taIndex] = taToUpdate;
      ctx.patchState({
        tas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('tas', [taToUpdate._id ?? ''], [taToUpdate.key], [hasMoved(taPrevious, taToUpdate)]);
  }

  @Action(RemoveTextAreas) public removeTextAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveTextAreas): void {
    ctx.patchState({
      tas: [...ctx.getState().tas.filter((ta) => !action.ids.includes(ta._id ?? ''))]
    });
  }

  @Action(AddTimelineAreas) public addTimelineAreas(ctx: StateContext<WorkspacesStateModel>, action: AddTimelineAreas): void {
    ctx.patchState({
      timas: removeDuplicates([...ctx.getState().timas, ...action.timelineAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'timas',
      action.timelineAreas.map((tima) => tima._id ?? ''),
      action.timelineAreas.map((tima) => tima.key)
    );
  }

  @Action(UpdateTimelineArea) public updateTimelineArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateTimelineArea): void {
    const timaToUpdate = action.timelineArea;
    const timas = [...ctx.getState().timas];
    const timaIndex = timas.findIndex((tima) => tima._id === timaToUpdate._id);
    let timaPrevious;
    if (timaIndex > -1) {
      timaPrevious = { ...timas[timaIndex] };
      timas[timaIndex] = timaToUpdate;
      ctx.patchState({
        timas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas(
      'timas',
      [timaToUpdate._id ?? ''],
      [timaToUpdate.key],
      [hasMoved(timaPrevious, timaToUpdate)]
    );
  }

  @Action(RemoveTimelineAreas) public removeTimelineAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveTimelineAreas): void {
    ctx.patchState({
      timas: [...ctx.getState().timas.filter((tima) => !action.ids.includes(tima._id ?? ''))]
    });
  }

  @Action(AddTypeInBoxAreas) public addTypeInBoxAreas(ctx: StateContext<WorkspacesStateModel>, action: AddTypeInBoxAreas): void {
    ctx.patchState({
      tibs: removeDuplicates([...ctx.getState().tibs, ...action.typeInBoxAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'tibs',
      action.typeInBoxAreas.map((tib) => tib._id ?? ''),
      action.typeInBoxAreas.map((tib) => tib.key)
    );
  }

  @Action(UpdateTypeInBoxArea) public updateTypeInBoxArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateTypeInBoxArea): void {
    const tibToUpdate = action.typeInBoxArea;
    const tibs = [...ctx.getState().tibs];
    const tibIndex = tibs.findIndex((tib) => tib._id === tibToUpdate._id);
    let tibPrevious;
    if (tibIndex > -1) {
      tibPrevious = { ...tibs[tibIndex] };
      tibs[tibIndex] = tibToUpdate;
      ctx.patchState({
        tibs
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('tibs', [tibToUpdate._id ?? ''], [tibToUpdate.key], [hasMoved(tibPrevious, tibToUpdate)]);
  }

  @Action(RemoveTypeInBoxAreas) public removeTypeInBoxAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveTypeInBoxAreas): void {
    ctx.patchState({
      tibs: [...ctx.getState().tibs.filter((tib) => !action.ids.includes(tib._id ?? ''))]
    });
  }

  @Action(AddViewer3DAreas) public addViewer3DAreas(ctx: StateContext<WorkspacesStateModel>, action: AddViewer3DAreas): void {
    ctx.patchState({
      vas: removeDuplicates([...ctx.getState().vas, ...action.viewer3DAreas], '_id')
    });
    this.workspaceAreasService.emitCreatedWorkspaceAreas(
      'vas',
      action.viewer3DAreas.map((va) => va._id ?? ''),
      action.viewer3DAreas.map((va) => va.key)
    );
  }

  @Action(UpdateViewer3DArea) public updateViewer3DArea(ctx: StateContext<WorkspacesStateModel>, action: UpdateViewer3DArea): void {
    const vaToUpdate = action.viewer3DArea;
    const vas = [...ctx.getState().vas];
    const vaIndex = vas.findIndex((va) => va._id === vaToUpdate._id);
    let vaPrevious;
    if (vaIndex > -1) {
      vaPrevious = { ...vas[vaIndex] };
      vas[vaIndex] = vaToUpdate;
      ctx.patchState({
        vas
      });
    }
    this.workspaceAreasService.emitUpdatedWorkspaceAreas('vas', [vaToUpdate._id ?? ''], [vaToUpdate.key], [hasMoved(vaPrevious, vaToUpdate)]);
  }

  @Action(RemoveViewer3DAreas) public removeViewer3DAreas(ctx: StateContext<WorkspacesStateModel>, action: RemoveViewer3DAreas): void {
    ctx.patchState({
      vas: [...ctx.getState().vas.filter((va) => !action.ids.includes(va._id ?? ''))]
    });
  }

  public ngxsOnInit() {
    this.workspacesService.refreshNamedWorkspaces();
    this.workspacesService.updateWorkspacesModules();
    this.workspacesModulesIntervalId = setInterval(() => {
      this.workspacesService.updateWorkspacesModules();
    }, WORKSPACES_MODULES_INTERVAL_UPDATE_MS);
  }

  private initUnsub(): void {
    this.destroy$.subscribe({
      next: () => {
        if (this.workspacesModulesIntervalId) {
          clearInterval(this.workspacesModulesIntervalId);
        }
      }
    });
  }

  private setWASInStore(workspaces: Array<WorkspaceDB>): void {
    const strArr: Array<string> = [];

    const aasInStore = this.workspaceAreasService.getAllActionAreaIdsInStore();
    const aasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.aas.map((wa) => wa.dbId.toString())), strArr)
      .filter((aa) => !aasInStore.includes(aa));
    this.workspaceAreasService.getActionAreas(aasToGetInDb).then((aas) => {
      if (aas.length > 0) {
        this.store.dispatch(new AddActionAreas(aas));
      }
    }, this.errorHandler.handleError);

    const casInStore = this.workspaceAreasService.getAllChartAreaIdsInStore();
    const casToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.cas.map((wa) => wa.dbId.toString())), strArr)
      .filter((ca) => !casInStore.includes(ca));
    this.workspaceAreasService.getChartAreas(casToGetInDb).then((cas) => {
      if (cas.length > 0) {
        this.store.dispatch(new AddChartAreas(cas));
      }
    }, this.errorHandler.handleError);

    const conasInStore = this.workspaceAreasService.getAllConnectionAreaIdsInStore();
    const conasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.conas.map((wa) => wa.dbId.toString())), strArr)
      .filter((cona) => !conasInStore.includes(cona));
    this.workspaceAreasService.getConnectionAreas(conasToGetInDb).then((conas) => {
      if (conas.length > 0) {
        this.store.dispatch(new AddConnectionAreas(conas));
      }
    }, this.errorHandler.handleError);

    const dbasInStore = this.workspaceAreasService.getAllDashboardButtonAreaIdsInStore();
    const dbasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.dbas.map((wa) => wa.dbId.toString())), strArr)
      .filter((dba) => !dbasInStore.includes(dba));
    this.workspaceAreasService.getDashboardButtonAreas(dbasToGetInDb).then((dbas) => {
      if (dbas.length > 0) {
        this.store.dispatch(new AddDashboardButtonAreas(dbas));
      }
    }, this.errorHandler.handleError);

    const evasInStore = this.workspaceAreasService.getAllEmbeddedVideoAreaIdsInStore();
    const evasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.evas.map((wa) => wa.dbId.toString().toString())), strArr)
      .filter((eva) => !evasInStore.includes(eva));
    this.workspaceAreasService.getEmbeddedVideoAreas(evasToGetInDb).then((evas) => {
      if (evas.length > 0) {
        this.store.dispatch(new AddEmbeddedVideoAreas(evas));
      }
    }, this.errorHandler.handleError);

    const fasInStore = this.workspaceAreasService.getAllFormAreaIdsInStore();
    const fasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.fas.map((wa) => wa.dbId.toString())), strArr)
      .filter((fa) => !fasInStore.includes(fa));
    this.workspaceAreasService.getFormAreas(fasToGetInDb).then((fas) => {
      if (fas.length > 0) {
        this.store.dispatch(new AddFormAreas(fas));
      }
    }, this.errorHandler.handleError);

    const frasInStore = this.workspaceAreasService.getAllFrameAreaIdsInStore();
    const frasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.fras.map((wa) => wa.dbId.toString())), strArr)
      .filter((fra) => !frasInStore.includes(fra));
    this.workspaceAreasService.getFrameAreas(frasToGetInDb).then((fras) => {
      if (fras.length > 0) {
        this.store.dispatch(new AddFrameAreas(fras));
      }
    }, this.errorHandler.handleError);

    const imasInStore = this.workspaceAreasService.getAllImageAreaIdsInStore();
    const imasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.imas.map((wa) => wa.dbId.toString().toString())), strArr)
      .filter((ima) => !imasInStore.includes(ima));
    this.workspaceAreasService.getImageAreas(imasToGetInDb).then((imas) => {
      if (imas.length > 0) {
        this.store.dispatch(new AddImageAreas(imas));
      }
    }, this.errorHandler.handleError);

    const marasInStore = this.workspaceAreasService.getAllMarkmapAreaIdsInStore();
    const marasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.maras.map((wa) => wa.dbId.toString().toString())), strArr)
      .filter((mara) => !marasInStore.includes(mara));
    this.workspaceAreasService.getMarkmapAreas(marasToGetInDb).then((maras) => {
      if (maras.length > 0) {
        this.store.dispatch(new AddMarkmapAreas(maras));
      }
    }, this.errorHandler.handleError);

    const masInStore = this.workspaceAreasService.getAllMapAreaIdsInStore();
    const masToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.mas.map((wa) => wa.dbId.toString())), strArr)
      .filter((ma) => !masInStore.includes(ma));
    this.workspaceAreasService.getMapAreas(masToGetInDb).then((mas) => {
      if (mas.length > 0) {
        this.store.dispatch(new AddMapAreas(mas));
      }
    }, this.errorHandler.handleError);

    const menasInStore = this.workspaceAreasService.getAllMenuAreaIdsInStore();
    const menasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.menas.map((wa) => wa.dbId.toString())), strArr)
      .filter((mena) => !menasInStore.includes(mena));
    this.workspaceAreasService.getMenuAreas(menasToGetInDb).then((menas) => {
      if (menas.length > 0) {
        this.store.dispatch(new AddMenuAreas(menas));
      }
    }, this.errorHandler.handleError);

    const merasInStore = this.workspaceAreasService.getAllMermaidAreaIdsInStore();
    const merasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.meras.map((wa) => wa.dbId.toString().toString())), strArr)
      .filter((mera) => !merasInStore.includes(mera));
    this.workspaceAreasService.getMermaidAreas(merasToGetInDb).then((meras) => {
      if (meras.length > 0) {
        this.store.dispatch(new AddMermaidAreas(meras));
      }
    }, this.errorHandler.handleError);

    const nasInStore = this.workspaceAreasService.getAllNavigationAreaIdsInStore();
    const nasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.nas.map((wa) => wa.dbId.toString())), strArr)
      .filter((na) => !nasInStore.includes(na));
    this.workspaceAreasService.getNavigationAreas(nasToGetInDb).then((nas) => {
      if (nas.length > 0) {
        this.store.dispatch(new AddNavigationAreas(nas));
      }
    }, this.errorHandler.handleError);

    const sasInStore = this.workspaceAreasService.getAllSliderAreaIdsInStore();
    const sasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.sas.map((wa) => wa.dbId.toString())), strArr)
      .filter((sa) => !sasInStore.includes(sa));
    this.workspaceAreasService.getSliderAreas(sasToGetInDb).then((sas) => {
      if (sas.length > 0) {
        this.store.dispatch(new AddSliderAreas(sas));
      }
    }, this.errorHandler.handleError);

    const srasInStore = this.workspaceAreasService.getAllSpeechRecognitionAreaIdsInStore();
    const srasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.sras.map((wa) => wa.dbId.toString().toString())), strArr)
      .filter((sra) => !srasInStore.includes(sra));
    this.workspaceAreasService.getSpeechRecognitionAreas(srasToGetInDb).then((sras) => {
      if (sras.length > 0) {
        this.store.dispatch(new AddSpeechRecognitionAreas(sras));
      }
    }, this.errorHandler.handleError);

    const tabasInStore = this.workspaceAreasService.getAllTableAreaIdsInStore();
    const tabasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.tabas.map((wa) => wa.dbId.toString())), strArr)
      .filter((taba) => !tabasInStore.includes(taba));
    this.workspaceAreasService.getTableAreas(tabasToGetInDb).then((tabas) => {
      if (tabas.length > 0) {
        this.store.dispatch(new AddTableAreas(tabas));
      }
    }, this.errorHandler.handleError);

    const tasInStore = this.workspaceAreasService.getAllTextAreaIdsInStore();
    const tasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.tas.map((wa) => wa.dbId.toString())), strArr)
      .filter((ta) => !tasInStore.includes(ta));
    this.workspaceAreasService.getTextAreas(tasToGetInDb).then((tas) => {
      if (tas.length > 0) {
        this.store.dispatch(new AddTextAreas(tas));
      }
    }, this.errorHandler.handleError);

    const timasInStore = this.workspaceAreasService.getAllTimelineAreaIdsInStore();
    const timasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.timas.map((wa) => wa.dbId.toString())), strArr)
      .filter((tima) => !timasInStore.includes(tima));
    this.workspaceAreasService.getTimelineAreas(timasToGetInDb).then((timas) => {
      if (timas.length > 0) {
        this.store.dispatch(new AddTimelineAreas(timas));
      }
    }, this.errorHandler.handleError);

    const tibsInStore = this.workspaceAreasService.getAllTypeInBoxAreaIdsInStore();
    const tibsToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.tibs.map((wa) => wa.dbId.toString())), strArr)
      .filter((tib) => !tibsInStore.includes(tib));
    this.workspaceAreasService.getTypeInBoxAreas(tibsToGetInDb).then((tibs) => {
      if (tibs.length > 0) {
        this.store.dispatch(new AddTypeInBoxAreas(tibs));
      }
    }, this.errorHandler.handleError);

    const vasInStore = this.workspaceAreasService.getAllViewer3DAreaIdsInStore();
    const vasToGetInDb = workspaces
      .reduce((acc, cur) => acc.concat(cur.vas.map((wa) => wa.dbId.toString())), strArr)
      .filter((va) => !vasInStore.includes(va));
    this.workspaceAreasService.getViewer3DAreas(vasToGetInDb).then((vas) => {
      if (vas.length > 0) {
        this.store.dispatch(new AddViewer3DAreas(vas));
      }
    }, this.errorHandler.handleError);
  }

  private removeWASFromStore(workspace: WorkspaceDB): void {
    this.store.dispatch(new RemoveActionAreas(workspace.aas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveChartAreas(workspace.cas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveConnectionAreas(workspace.conas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveDashboardButtonAreas(workspace.dbas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveEmbeddedVideoAreas(workspace.evas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveFormAreas(workspace.fas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveFrameAreas(workspace.fras.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveImageAreas(workspace.imas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveMarkmapAreas(workspace.maras.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveMapAreas(workspace.mas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveMenuAreas(workspace.menas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveMermaidAreas(workspace.meras.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveNavigationAreas(workspace.nas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveSliderAreas(workspace.sas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveSpeechRecognitionAreas(workspace.sras.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveTableAreas(workspace.tabas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveTextAreas(workspace.tas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveTimelineAreas(workspace.timas.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveTypeInBoxAreas(workspace.tibs.map((wa) => wa.dbId.toString())));
    this.store.dispatch(new RemoveViewer3DAreas(workspace.vas.map((wa) => wa.dbId.toString())));
  }
}

const hasMoved = (waPrevious: WorkspaceArea | undefined, wa: WorkspaceArea): boolean =>
  !waPrevious ||
  !(waPrevious.bottom === wa.bottom && waPrevious.left === wa.left && waPrevious.width === wa.width && waPrevious.height === wa.height);

interface WorkspacesStateModel {
  workspaces: Array<WorkspaceDB>;
  monitoredWorkspaces: Array<string>;
  namedWorkspacesLoaded: boolean;
  namedWorkspaces: Array<NamedWorkspace>;
  minimizedWorkspaces: Array<MinimizedWorkspace>;
  workspacesModules: Array<WorkspaceModule>;
  aas: Array<ActionArea>;
  cas: Array<ChartArea>;
  conas: Array<ConnectionArea>;
  dbas: Array<DashboardButtonArea>;
  evas: Array<EmbeddedVideoArea>;
  fas: Array<FormArea>;
  fras: Array<FrameArea>;
  imas: Array<ImageArea>;
  maras: Array<MarkmapArea>;
  mas: Array<MapArea>;
  menas: Array<MenuArea>;
  meras: Array<MermaidArea>;
  nas: Array<NavigationArea>;
  sas: Array<SliderArea>;
  sras: Array<SpeechRecognitionArea>;
  tabas: Array<TableArea>;
  tas: Array<TextArea>;
  timas: Array<TimelineArea>;
  tibs: Array<TypeInBoxArea>;
  vas: Array<Viewer3DArea>;
}
