// eslint-disable-next-line @typescript-eslint/no-var-requires
const debounceFn = require('lodash.debounce');

export function debounce(milliseconds = 0, options = {}) {
  return function (target: unknown, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
    const map = new WeakMap();
    const originalMethod = descriptor.value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    descriptor.value = function (...params: any) {
      let debounced = map.get(this);
      if (!debounced) {
        debounced = debounceFn(originalMethod, milliseconds, options).bind(this);
        map.set(this, debounced);
      }
      debounced(...params);
    };
    return descriptor;
  };
}
