import 'hammerjs';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/python/python';
import 'codemirror/mode/markdown/markdown';

import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';

if (environment.isProdMod) {
  enableProdMode();
}

const linkServer = document.createElement('link');
linkServer.rel = 'preconnect';
linkServer.href = environment.serverUrl;
document.head.appendChild(linkServer);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((module) => {
    if (environment.isDevMod) {
      enableDebugTools(module.injector.get(ApplicationRef).components[0]);
    }
  })
  .catch((err) => {
    console.error(err);
  });
