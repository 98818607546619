import { AES, enc } from 'crypto-js';

import { Injectable } from '@angular/core';
import { LoggerService } from '@services/logger.service';

const PRIVATE_KEY = 'EYq&3QdF1zQo88%BX';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private readonly logger: LoggerService) {}

  public setItem(key: string, data: string): void {
    try {
      localStorage.setItem(key, AES.encrypt(data, PRIVATE_KEY).toString());
    } catch (err) {
      this.logger.log('warn', 'Local Storage is full.', err);
      this.clearStorage();
    }
  }

  public getItem(key: string): string {
    try {
      const item = localStorage.getItem(key);
      return item === null ? '' : AES.decrypt(item, PRIVATE_KEY).toString(enc.Utf8);
    } catch (err) {
      this.logger.log('warn', 'Local Storage is corrupt.', err);
      this.clearStorage();
      return '';
    }
  }

  private clearStorage(): void {
    localStorage.clear();
    this.logger.log('warn', 'Local Storage has been cleared.');
  }
}
