import { umlType } from '@admin/components/uml/uml.component';
import { Injectable } from '@angular/core';
import { QueryStringParameters } from '@classes/query-string-parameters';
// import { QueryStringParameters } from '@classes/query-string-parameters';
import { UrlBuilder } from '@classes/url-builder';
import { isLocal, utf8ToB64 } from '@config/utils';
import { environment } from '@environments/environment';
import {
  ROUTE_DASHBOARD_DASHBOARD_LISTING,
  ROUTE_DASHBOARD_UPDATE_DASHBOARD_INFO,
  ROUTE_DASHBOARD_UPDATE_DASHBOARD_OWNER,
  ROUTE_G2_G2_CURRENT_MODULES,
  ROUTE_G2_GET_G2_DOC_LINK,
  ROUTE_G2_GET_G2_DOC_MODULES,
  ROUTE_G2_GET_G2_ROLES,
  ROUTE_G2_GET_GEOJSON_CONTENT,
  ROUTE_G2_GET_INIT_DATA,
  ROUTE_G2_GET_NAMED_WS,
  ROUTE_G2_GET_WS_TITLES,
  ROUTE_G2_PREFIX,
  ROUTE_G2_PROCESS_CLICKED_AREA,
  ROUTE_G2_VALIDATE_G2_PROC_SYNTAX,
  ROUTE_G2_WS_ACTIVATE,
  ROUTE_KEYCLOAK_GROUPS_AND_MEMBERS,
  ROUTE_KEYCLOAK_GROUPS_LISTING,
  ROUTE_KEYCLOAK_PREFIX,
  ROUTE_KEYCLOAK_ROLES_AND_MEMBERS,
  ROUTE_KEYCLOAK_USER_GROUPS_IDS,
  ROUTE_KEYCLOAK_USERS,
  ROUTE_KEYCLOAK_USERS_LISTING,
  ROUTE_MERMAID_PREFIX,
  ROUTE_SERVER_BACKEND_INFO,
  ROUTE_SERVER_G2_LAST_PING,
  ROUTE_SERVER_G2_LAST_PING_DATA,
  ROUTE_SERVER_PREFIX,
  ROUTE_SESSIONS_SESSIONS_LISTING,
  ROUTE_SESSIONS_UPDATE_SESSION_CONFIG,
  ROUTE_SESSIONS_UPDATE_SESSION_INFO,
  ROUTE_SESSIONS_UPDATE_SESSION_OWNER,
  ROUTE_WS_WS_MODULE,
  WorkspaceAreasServiceName
} from '@g2view/g2view-commons';
import { sessionInfoFields } from '@sessions/state/sessions.state';

const FEATHERS = environment.serverUrl;
const DEV_BACKEND_URL = 'https://dev-api.g2-view.com';

@Injectable({
  providedIn: 'root'
})
export class ApiEndpointsService {
  public getDashboardsEndpoint(params?: QueryParams): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['dashboard'], (qs: QueryStringParameters) => {
      if (params) {
        params.forEach((param) => {
          qs.push(param.key, param.value);
        });
      }
    });
  }

  public getDashboardsListingEndpoint(): string {
    return FEATHERS + ROUTE_DASHBOARD_DASHBOARD_LISTING;
  }

  public addDashboardEndpoint(): string {
    return createUrlWithPathVariables(FEATHERS, ['dashboard']);
  }

  public updateDashboardInfoEndpoint(): string {
    return FEATHERS + ROUTE_DASHBOARD_UPDATE_DASHBOARD_INFO;
  }

  public updateDashboardOwnerEndpoint(): string {
    return FEATHERS + ROUTE_DASHBOARD_UPDATE_DASHBOARD_OWNER;
  }

  public removeDashboardEndpoint(dashboardId: string): string {
    return createUrlWithPathVariables(FEATHERS, ['dashboard', dashboardId]);
  }

  public getSessionsEndpoint(params?: QueryParams): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['sessions'], (qs: QueryStringParameters) => {
      if (params) {
        params.forEach((param) => {
          qs.push(param.key, param.value);
        });
      }
    });
  }

  public getSessionsInfoEndpoint(): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['sessions'], (qs: QueryStringParameters) => {
      sessionInfoFields.forEach((field) => {
        qs.push('$select[]', field);
      });
    });
  }

  public getSessionEndpoint(sessionId: string): string {
    return createUrlWithPathVariables(FEATHERS, ['sessions', sessionId]);
  }

  public getSessionsListingEndpoint(): string {
    return FEATHERS + ROUTE_SESSIONS_SESSIONS_LISTING;
  }

  public addSessionEndpoint(): string {
    return createUrlWithPathVariables(FEATHERS, ['sessions']);
  }

  public updateSessionEndpoint(sessionId: string): string {
    return createUrlWithPathVariables(FEATHERS, ['sessions', sessionId]);
  }

  public updateSessionInfoEndpoint(): string {
    return FEATHERS + ROUTE_SESSIONS_UPDATE_SESSION_INFO;
  }

  public updateSessionOwnerEndpoint(): string {
    return FEATHERS + ROUTE_SESSIONS_UPDATE_SESSION_OWNER;
  }

  public updateSessionConfigEndpoint(): string {
    return FEATHERS + ROUTE_SESSIONS_UPDATE_SESSION_CONFIG;
  }

  public removeSessionEndpoint(sessionId: string): string {
    return createUrlWithPathVariables(FEATHERS, ['sessions', sessionId]);
  }

  public getWorkspacesModulesEndpoint(): string {
    return FEATHERS + ROUTE_WS_WS_MODULE;
  }

  public getAllWorkspacesEndpoint(params: QueryParams): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['workspaces'], (qs: QueryStringParameters) => {
      params.forEach((param) => {
        qs.push(param.key, param.value);
      });
    });
  }

  public getWorkspacesEndpoint(uuids: Array<string>): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['workspaces'], (qs: QueryStringParameters) => {
      if (uuids.length === 0) {
        qs.push('$limit', 0);
      } else if (uuids.length === 1) {
        qs.push('uuid', uuids[0]);
      } else {
        uuids.forEach((uuid) => {
          qs.push('uuid[$in]', uuid);
        });
      }
    });
  }

  public getAllWorkspaceAreasEndpoint(service: WorkspaceAreasServiceName): string {
    return createUrlWithPathVariables(FEATHERS, [service]);
  }

  public getWorkspaceAreasEndpoint(service: WorkspaceAreasServiceName, ids: Array<string>): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, [service], (qs: QueryStringParameters) => {
      if (ids.length === 0) {
        qs.push('$limit', 0);
      } else if (ids.length === 1) {
        qs.push('_id', ids[0]);
      } else {
        ids.forEach((id) => {
          qs.push('_id[$in]', id);
        });
      }
    });
  }

  public getDashboardFavoritesEndpoint(params?: QueryParams): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['dashboardFavorite'], (qs: QueryStringParameters) => {
      if (params) {
        params.forEach((param) => {
          qs.push(param.key, param.value);
        });
      }
    });
  }

  public getFavoritesOfDashboardsEndpoint(dashboardIds: Array<string>, userId: string): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['dashboardFavorite'], (qs: QueryStringParameters) => {
      qs.push('userId', userId);
      if (dashboardIds.length === 0) {
        qs.push('$limit', 0);
      } else if (dashboardIds.length === 1) {
        qs.push('dashboardId', dashboardIds[0]);
      } else {
        dashboardIds.forEach((dashboardId) => {
          qs.push('dashboardId[$in]', dashboardId);
        });
      }
    });
  }

  public getDashboardAccessRequestsEndpoint(dashboardDbIds: Array<string>): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['accessRequests'], (qs: QueryStringParameters) => {
      qs.push('type', 'dashboard');
      if (dashboardDbIds.length === 0) {
        qs.push('$limit', 0);
      } else if (dashboardDbIds.length === 1) {
        qs.push('objectDbId', dashboardDbIds[0]);
      } else {
        dashboardDbIds.forEach((dashboardDbId) => {
          qs.push('objectDbId[$in]', dashboardDbId);
        });
      }
    });
  }

  public getOwnDashboardAccessRequestsEndpoint(userId: string): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['accessRequests'], (qs: QueryStringParameters) => {
      qs.push('type', 'dashboard');
      qs.push('userId', userId);
    });
  }

  public addDashboardFavoritesEndpoint(): string {
    return createUrlWithPathVariables(FEATHERS, ['dashboardFavorite']);
  }

  public removeDashboardFavoritesEndpoint(dashboardFavoriteId: string): string {
    return createUrlWithPathVariables(FEATHERS, ['dashboardFavorite', dashboardFavoriteId]);
  }

  public getSessionFavoritesEndpoint(params?: QueryParams): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['sessionFavorite'], (qs: QueryStringParameters) => {
      if (params) {
        params.forEach((param) => {
          qs.push(param.key, param.value);
        });
      }
    });
  }

  public getFavoritesOfSessionsEndpoint(sessionDbIds: Array<string>, userId: string): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['sessionFavorite'], (qs: QueryStringParameters) => {
      qs.push('userId', userId);
      if (sessionDbIds.length === 0) {
        qs.push('$limit', 0);
      } else if (sessionDbIds.length === 1) {
        qs.push('sessionDbId', sessionDbIds[0]);
      } else {
        sessionDbIds.forEach((sessionDbId) => {
          qs.push('sessionDbId[$in]', sessionDbId);
        });
      }
    });
  }

  public getAccessRequestsEndpoint(params?: QueryParams): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['accessRequests'], (qs: QueryStringParameters) => {
      if (params) {
        params.forEach((param) => {
          qs.push(param.key, param.value);
        });
      }
    });
  }

  public getSessionAccessRequestsEndpoint(sessionDbIds: Array<string>): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['accessRequests'], (qs: QueryStringParameters) => {
      qs.push('type', 'session');
      if (sessionDbIds.length === 0) {
        qs.push('$limit', 0);
      } else if (sessionDbIds.length === 1) {
        qs.push('objectDbId', sessionDbIds[0]);
      } else {
        sessionDbIds.forEach((sessionDbId) => {
          qs.push('objectDbId[$in]', sessionDbId);
        });
      }
    });
  }

  public getOwnSessionAccessRequestsEndpoint(userId: string): string {
    return createUrlWithPathVariablesAndQueryParameters(FEATHERS, ['accessRequests'], (qs: QueryStringParameters) => {
      qs.push('type', 'session');
      qs.push('userId', userId);
    });
  }

  public addSessionFavoritesEndpoint(): string {
    return createUrlWithPathVariables(FEATHERS, ['sessionFavorite']);
  }

  public removeSessionFavoritesEndpoint(sessionFavoriteId: string): string {
    return createUrlWithPathVariables(FEATHERS, ['sessionFavorite', sessionFavoriteId]);
  }

  public addAccessRequestEndpoint(): string {
    return createUrlWithPathVariables(FEATHERS, ['accessRequests']);
  }

  public removeAccessRequestEndpoint(dbId: string): string {
    return createUrlWithPathVariables(FEATHERS, ['accessRequests', dbId]);
  }

  public getUMLMermaidDataEndpoint(type: umlType): string {
    return createUrlWithPathVariables(FEATHERS + ROUTE_MERMAID_PREFIX, [type]);
  }

  public fileUploadEndpoint(): string {
    return createUrlWithPathVariables(FEATHERS, ['uploads']);
  }

  public getRoomMembershipsEndpoint(): string {
    return createUrlWithPathVariables(FEATHERS, ['roomMembership']);
  }

  public getUsersEndpoint(): string {
    return FEATHERS + ROUTE_KEYCLOAK_PREFIX + ROUTE_KEYCLOAK_USERS;
  }

  public getUsersListingEndpoint(): string {
    return FEATHERS + ROUTE_KEYCLOAK_PREFIX + ROUTE_KEYCLOAK_USERS_LISTING;
  }

  public getGroupsAndMembersEndpoint(): string {
    return FEATHERS + ROUTE_KEYCLOAK_PREFIX + ROUTE_KEYCLOAK_GROUPS_AND_MEMBERS;
  }

  public getGroupsListingEndpoint(): string {
    return FEATHERS + ROUTE_KEYCLOAK_PREFIX + ROUTE_KEYCLOAK_GROUPS_LISTING;
  }

  public getRolesAndMembersEndpoint(): string {
    return FEATHERS + ROUTE_KEYCLOAK_PREFIX + ROUTE_KEYCLOAK_ROLES_AND_MEMBERS;
  }

  public getGroupIdsEndpoint(): string {
    return FEATHERS + ROUTE_KEYCLOAK_PREFIX + ROUTE_KEYCLOAK_USER_GROUPS_IDS;
  }

  public getG2RolesEndpoint(): string {
    return FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_GET_G2_ROLES;
  }

  public getG2CurrentModulesEndpoint(): string {
    return FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_G2_CURRENT_MODULES;
  }

  public getG2DocumentedModulesEndpoint(): string {
    return FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_GET_G2_DOC_MODULES;
  }

  public getG2DocumentationLinkEndpoint(): string {
    return FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_GET_G2_DOC_LINK;
  }

  public activateWorkspacesEndpoint(): string {
    return FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_WS_ACTIVATE;
  }

  public getNamedWorkspacesEndpoint(): string {
    return FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_GET_NAMED_WS;
  }

  public geWorkspaceTitlesEndpoint(): string {
    return FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_GET_WS_TITLES;
  }

  public processClickedAreaEndpoint(): string {
    return FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_PROCESS_CLICKED_AREA;
  }

  public getFAInitialDataEndpoint(uuid: string, faKey: string, faType: string, miKey: string, rowId: string, buttonId: string): string {
    return createUrlWithPathVariables(FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_GET_INIT_DATA, [
      uuid,
      faKey,
      faType,
      miKey,
      utf8ToB64(rowId),
      utf8ToB64(buttonId)
    ]);
  }

  public getG2SyntaxValidatorEndpoint(): string {
    return FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_VALIDATE_G2_PROC_SYNTAX;
  }

  public getGeoJsonFileEndpoint(): string {
    return FEATHERS + ROUTE_G2_PREFIX + ROUTE_G2_GET_GEOJSON_CONTENT;
  }

  public getBackendInfoEndpoint(): string {
    return FEATHERS + ROUTE_SERVER_PREFIX + ROUTE_SERVER_BACKEND_INFO;
  }

  public getG2LastPingEndpoint(): string {
    return FEATHERS + ROUTE_SERVER_PREFIX + ROUTE_SERVER_G2_LAST_PING;
  }

  public getG2LastPingDataEndpoint(): string {
    return FEATHERS + ROUTE_SERVER_PREFIX + ROUTE_SERVER_G2_LAST_PING_DATA;
  }

  public getCurrentWorkspaceImageUrlEndpoint(workspaceUuid: string): string {
    return createUrlWithPathVariablesAndQueryParameters(
      isLocal() ? DEV_BACKEND_URL : FEATHERS,
      ['workspaces', `${workspaceUuid}.jpeg`],
      (qs: QueryStringParameters) => {
        qs.push('timestamp', Date.now());
      }
    );
  }

  public getIconUrlEndpoint(iconName: string): string {
    return createUrlWithPathVariables(
      FEATHERS,
      ['ma_icon_images'].concat(removeQueryParametersFromURI(iconName).split('/')).filter((i) => i)
    );
  }

  public getImageExternalUrlEndpoint(imagePath: string, includeTimestamp = false): string {
    return createUrlWithQueryParameters(removeQueryParametersFromURI(imagePath), '', (qs: QueryStringParameters) => {
      if (includeTimestamp) {
        qs.push('timestamp', Date.now());
      }
    });
  }

  public getImageInternalUrlEndpoint(imagePath: string, includeTimestamp = false): string {
    return createUrlWithPathVariablesAndQueryParameters(
      FEATHERS,
      ['ima_images'].concat(removeQueryParametersFromURI(imagePath).split('/')).filter((i) => i),
      (qs: QueryStringParameters) => {
        if (includeTimestamp) {
          qs.push('timestamp', Date.now());
        }
      }
    );
  }

  public get3DEnvironmentsUrlEndpoint(modelPath: string): string {
    return createUrlWithPathVariables(
      FEATHERS,
      ['3d-environments'].concat(removeQueryParametersFromURI(modelPath).split('/')).filter((i) => i)
    );
  }

  public get3DModelsUrlEndpoint(modelPath: string): string {
    return createUrlWithPathVariables(
      FEATHERS,
      ['3d-models'].concat(removeQueryParametersFromURI(modelPath).split('/')).filter((i) => i)
    );
  }
}

const removeQueryParametersFromURI = (uri: string): string => uri.split('?')[0];

// const createUrl = (server: string, action: string): string => new UrlBuilder(server, action).toString();

const createUrlWithQueryParameters = (
  server: string,
  action: string,
  queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
): string => {
  const urlBuilder = new UrlBuilder(server, action);
  if (queryStringHandler) {
    queryStringHandler(urlBuilder.queryString);
  }

  return urlBuilder.toString();
};

const createUrlWithPathVariables = (server: string, pathVariables: Array<string>): string => {
  const encodedPathVariablesUrl = [];
  for (const pathVariable of pathVariables) {
    if (pathVariable !== null) {
      encodedPathVariablesUrl.push(encodeURIComponent(pathVariable.toString()));
    }
  }

  return new UrlBuilder(server, encodedPathVariablesUrl.join('/')).toString();
};

const createUrlWithPathVariablesAndQueryParameters = (
  server: string,
  pathVariables: Array<string>,
  queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
): string => {
  const encodedPathVariablesUrl = [];
  for (const pathVariable of pathVariables) {
    if (pathVariable !== null) {
      encodedPathVariablesUrl.push(encodeURIComponent(pathVariable.toString()));
    }
  }

  return createUrlWithQueryParameters(server, encodedPathVariablesUrl.join('/'), queryStringHandler);
};

export type QueryParams = Array<QueryParam>;

interface QueryParam {
  key: string;
  value: string | number;
}
