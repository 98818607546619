import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from '@services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MatPaginatorI18nService extends MatPaginatorIntl {
  constructor(private readonly translocoService: TranslocoService, private readonly errorHandler: ErrorHandlerService) {
    super();
    /**
     * t(app.paginator.itemsPerPageLabel,
        app.paginator.nextPageLabel,
        app.paginator.previousPageLabel,
        app.paginator.firstPageLabel,
        app.paginator.lastPageLabel,
        app.paginator.ofLabel)
     */
    this.translocoService
      .selectTranslate(
        [
          'paginator.itemsPerPageLabel',
          'paginator.nextPageLabel',
          'paginator.previousPageLabel',
          'paginator.firstPageLabel',
          'paginator.lastPageLabel',
          'paginator.ofLabel'
        ],
        {},
        'app'
      )
      .subscribe({
        next: (translation: Array<string>) => {
          this.itemsPerPageLabel = translation[0];
          this.nextPageLabel = translation[1];
          this.previousPageLabel = translation[2];
          this.firstPageLabel = translation[3];
          this.lastPageLabel = translation[4];
          this.getRangeLabel = (page: number, pageSize: number, length: number): string => getRangeLabelI18n(page, pageSize, length, translation[5]);
          this.changes.next();
        },
        error: (err: unknown) => this.errorHandler.handleError(err)
      });
  }
}

const getRangeLabelI18n = (page: number, pageSize: number, length: number, of: string) => {
  if (length === 0 || pageSize === 0) {
    return `0 ${of} ${length}`;
  }
  const lengthMax = Math.max(length, 0);
  const startIndex: number = page * pageSize;
  const endIndex: number = startIndex < lengthMax ? Math.min(startIndex + pageSize, lengthMax) : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} ${of} ${lengthMax}`;
};
