import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {
  public triggerDownload(url: string, fileName?: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName ?? url.split('/').pop() ?? 'file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
