<ng-container *transloco="let t; scope: 'app'; read: 'app.timeline'">
  <ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="state.loaded">
      <div [id]="'timeline-' + timelineArea?._id" #timelineDiv class="timeline-div"></div>
      <div fxLayout="column" fxLayoutGap="10px" class="action-buttons">
        <a *ngIf="displayModalLink" mat-icon-button color="accent" class="button" (click)="onGoToModal()" title="{{ t('buttonModalTitle') }}">
          <mat-icon fontIcon="fullscreen"></mat-icon>
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="!state.loaded">
      <ngx-g2v-progress-spinner></ngx-g2v-progress-spinner>
    </ng-container>
    <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
  </ng-container>
</ng-container>