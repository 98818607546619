import { forkJoin, Observable } from 'rxjs';
import { map as obsMap } from 'rxjs/operators';

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { STATE_G2VIEW_DASHBOARDS } from '@config/constants';
import { hasEnoughRoles } from '@config/utils';
import { DbasService } from '@dashboards/services/dbas.service';
import { RemoveDashboard, SetDashboards, SetDashboardView } from '@dashboards/state/dashboards.actions';
import {
  AccessRequest,
  Dashboard,
  DashboardDBAConfig,
  DashboardFavorite,
  DASHBOARDS_ADMIN_CLIENT_ROLES,
  DashboardView,
  Frame,
  UpdateDashboardInfoEvent,
  UpdateDashboardOwnerEvent
} from '@g2view/g2view-commons';
import { ListingItem } from '@interfaces/listing-item';
import { Store } from '@ngxs/store';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';
import { CacheService } from '@services/cache.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { LoggerService } from '@services/logger.service';
import { ServerService } from '@services/server.service';
import { UserService } from '@services/user.service';
import { WorkspaceAreasService } from '@workspaces/services/workspace-areas.service';
import { WorkspacesService } from '@workspaces/services/workspaces.service';
import { SetMonitoredWorkspaces } from '@workspaces/state/workspaces.actions';

@Injectable({
  providedIn: 'root'
})
export class DashboardsService {
  constructor(
    private readonly userService: UserService,
    private readonly serverService: ServerService,
    private readonly dbasService: DbasService,
    private readonly http: ApiHttpService,
    private readonly apiEndpointsService: ApiEndpointsService,
    private readonly workspacesService: WorkspacesService,
    private readonly workspaceAreasService: WorkspaceAreasService,
    private readonly store: Store,
    private readonly logger: LoggerService,
    private readonly cacheService: CacheService,
    private readonly errorHandler: ErrorHandlerService
  ) {
    this.logger.log('trace', 'Init of DashboardsService');
  }

  get areDashboardsInitialized(): boolean {
    return this.store.selectSnapshot<boolean>((state) => state[STATE_G2VIEW_DASHBOARDS]?.isLoaded ?? false);
  }

  get dashboardsListing$(): Observable<Array<ListingItem>> {
    return this.http.get(this.apiEndpointsService.getDashboardsListingEndpoint());
  }

  get ownDashboardAccessRequests$(): Observable<Array<AccessRequest>> {
    return this.http
      .get(this.apiEndpointsService.getOwnDashboardAccessRequestsEndpoint(this.userService.currentUserId))
      .pipe(obsMap((accessRequests) => accessRequests.data));
  }

  public getDashboardInStore(id: string): Dashboard | undefined {
    const dashboards = this.store
      .selectSnapshot<Array<Dashboard>>((state) => state[STATE_G2VIEW_DASHBOARDS].dashboards)
      .filter((dashboard) => dashboard._id === id);

    return dashboards.length > 0 ? dashboards[dashboards.length - 1] : undefined;
  }

  public init(): void {
    this.logger.log('trace', 'Init of DashboardsService -> init');
    this.refreshDashboards();
  }

  public refreshDashboards(): void {
    this.fetchDashboards().subscribe({
      next: (dashboards) => {
        const dashboardIds = dashboards.map((dashboard) => dashboard._id ?? '');
        // eslint-disable-next-line rxjs/no-nested-subscribe
        forkJoin([this.fetchDashboardFavorites(dashboardIds), this.fetchDashboardAccessRequests(dashboardIds)]).subscribe({
          next: ([favorites, accessRequests]) => {
            this.store.dispatch(new SetDashboards(dashboards, favorites, accessRequests));
          },
          error: (err: unknown) => this.errorHandler.handleError(err)
        });
      }
    });
  }

  public isAdminOfThisDashboard(dashboardOwner: string): boolean {
    return (
      hasEnoughRoles(this.userService.currentClientRoles, [], [], DASHBOARDS_ADMIN_CLIENT_ROLES, [], []) ||
      this.userService.currentUserId === dashboardOwner
    );
  }

  public createDashboard(dashboard: Dashboard): void {
    const defaultColor = 'white';
    dashboard.internalBackgroundColor = dashboard.internalBackgroundColor === '' ? defaultColor : dashboard.internalBackgroundColor;
    dashboard.externalBackgroundColor = dashboard.externalBackgroundColor === '' ? defaultColor : dashboard.externalBackgroundColor;
    dashboard.borderColor.forEach((v, i) => {
      if (v === '') {
        dashboard.borderColor[i] = defaultColor;
      }
    });
    this.http
      .post(this.apiEndpointsService.addDashboardEndpoint(), { ...dashboard }, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public updateDashboardInfo(dashboard: Dashboard): void {
    const data: UpdateDashboardInfoEvent = {
      did: dashboard._id ?? '',
      title: dashboard.title,
      users: dashboard.users,
      groups: dashboard.groups,
      canBeRequested: dashboard.canBeRequested
    };
    this.http
      .post(this.apiEndpointsService.updateDashboardInfoEndpoint(), data, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public updateDashboardOwner(dashboard: Dashboard): void {
    const data: UpdateDashboardOwnerEvent = { did: dashboard._id ?? '', owner: dashboard.owner };
    this.http
      .post(this.apiEndpointsService.updateDashboardOwnerEndpoint(), data, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public setCurrentDashboardViewId(viewId: string): void {
    this.store.dispatch(new SetDashboardView(viewId));
  }

  public getUuidsOfDashboardViewAndCurrentFrames(dashboard: Dashboard, view: DashboardView): Array<string> {
    const viewOfDashboard = dashboard.views.find((v) => v.id === view.id);
    const frameIds: Array<string> = viewOfDashboard ? [...new Set(viewOfDashboard.frames)] : [];
    const frames: Array<Frame> = dashboard.frames.filter((l) => frameIds.includes(l.key));
    const uuids: Array<string> = [];
    frames.forEach((frame) => {
      let uuid = frame.uuids.length === 1 ? frame.uuids[0] : '';
      if (frame.uuids.length > 1) {
        const lastWorkspace = this.dbasService.getLastWSOfFrame(frame.key);
        uuid = lastWorkspace ? lastWorkspace : frame.uuids[0];
      }
      if (uuid) {
        uuids.push(uuid);
      }
    });

    return [...new Set(uuids)];
  }

  public removeDashboard(dashboardId: string): void {
    this.store.dispatch(new RemoveDashboard(dashboardId));
    this.http
      .delete(this.apiEndpointsService.removeDashboardEndpoint(dashboardId), { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public createDashboardAccessRequest(dashboardId: string): void {
    const accessRequest: AccessRequest = {
      type: 'dashboard',
      objectDbId: dashboardId,
      userId: this.userService.currentUserId,
      timestamp: new Date().getTime()
    };
    this.http
      .post(this.apiEndpointsService.addAccessRequestEndpoint(), accessRequest, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public removeDashboardAccessRequests(dbIds: Array<string>): void {
    const observables: Array<Observable<unknown>> = [];
    dbIds.forEach((dbId) => {
      observables.push(this.http.delete(this.apiEndpointsService.removeAccessRequestEndpoint(dbId), { headers: httpHeaders }));
    });
    forkJoin(observables).subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public createDashboardFavorite(dashboard: Dashboard): void {
    const dashboardFavorite: DashboardFavorite = {
      dashboardId: dashboard._id ?? '',
      userId: this.userService.currentUserId
    };
    this.http
      .post(this.apiEndpointsService.addDashboardFavoritesEndpoint(), dashboardFavorite, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public removeDashboardFavorite(dashboard: Dashboard): void {
    this.fetchDashboardFavorites([dashboard._id ?? '']).subscribe({
      next: (favorites) => {
        favorites.forEach((fav) => {
          if (fav._id) {
            this.http
              .delete(this.apiEndpointsService.removeDashboardFavoritesEndpoint(fav._id), { headers: httpHeaders })
              // eslint-disable-next-line rxjs/no-nested-subscribe
              .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
          }
        });
      }
    });
  }

  public storeDashboardConfig(dashboardId: string, config: DashboardStorageConfig): void {
    this.cacheService.updateConfig('dashboard', dashboardId, config);
  }

  public getDashboardConfig(dashboardId: string): DashboardStorageConfig {
    return this.cacheService.getConfig('dashboard', dashboardId) as DashboardStorageConfig;
  }

  public allRequiredModulesAreLoaded(dashboard: Dashboard): boolean {
    return dashboard.modules.every((m) => this.serverService.currentG2ModulesLoaded.includes(m));
  }

  public missingModulesOfThisDashboard(dashboard: Dashboard): Array<string> {
    const missingModules: Array<string> = [];
    dashboard.modules.forEach((m) => {
      if (!this.serverService.currentG2ModulesLoaded.includes(m)) {
        missingModules.push(m);
      }
    });
    return missingModules;
  }

  public checkIfAllDBAsOfDashboardAreConfigured(dashboard: Dashboard): Promise<Array<string>> {
    return new Promise((resolve) => {
      const dashboardDbaKeys = dashboard.dbas.map((dba) => dba.dbaKey);
      const uuids = getAllUuidsOfDashboard(dashboard);
      this.store.dispatch(new SetMonitoredWorkspaces(uuids));
      const interval = setInterval(() => {
        this.workspacesService.getWorkspacesInDB(uuids).subscribe((workspaces) => {
          if (workspaces.length === uuids.length) {
            clearInterval(interval);
            let dbaDbIds: Array<string> = [];
            workspaces.forEach((ws) => {
              dbaDbIds = dbaDbIds.concat(ws.dbas.map((waData) => waData.dbId));
            });
            this.workspaceAreasService.getDashboardButtonAreas(dbaDbIds).then((dbas) => {
              const missing = dbas.map((dba) => dba.key).filter((dba) => dashboardDbaKeys.indexOf(dba) < 0);
              resolve(missing);
            });
          }
        });
      }, 1000);
    });
  }

  private fetchDashboards(): Observable<Array<Dashboard>> {
    return this.http.get(this.apiEndpointsService.getDashboardsEndpoint()).pipe(obsMap((dashboards) => dashboards.data));
  }

  private fetchDashboardFavorites(dashboardIds: Array<string>): Observable<Array<DashboardFavorite>> {
    return this.http
      .get(this.apiEndpointsService.getFavoritesOfDashboardsEndpoint(dashboardIds, this.userService.currentUserId))
      .pipe(obsMap((favorites) => favorites.data));
  }

  private fetchDashboardAccessRequests(dashboardIds: Array<string>): Observable<Array<AccessRequest>> {
    return this.http
      .get(this.apiEndpointsService.getDashboardAccessRequestsEndpoint(dashboardIds))
      .pipe(obsMap((accessRequests) => accessRequests.data));
  }
}

export const getAllUuidsOfDashboard = (dashboard: Dashboard): Array<string> => getUuidsOfFrames(dashboard.frames);

const getFramesIds = (dashboard: Dashboard, view: DashboardView, levels: number): Array<string> => {
  let viewIds: Array<string> = [];
  viewIds = [view.id];
  for (let level = 0; level < levels; level++) {
    const viewIdsOfThisLevels = [...viewIds];
    viewIdsOfThisLevels.forEach((viewId) => {
      const currentView = dashboard.views.find((v) => v.id === viewId);
      viewIds = currentView ? viewIds.concat(getChildrenViewsIdsOfView(dashboard, currentView)) : [];
    });
    viewIds = [...new Set(viewIds)];
  }

  let frameIds: Array<string> = [];
  viewIds.forEach((viewId) => {
    const view = dashboard.views.find((v) => v.id === viewId);
    frameIds = frameIds.concat(view ? view.frames : []);
  });
  frameIds = [...new Set(frameIds)];

  return frameIds;
};

export const getAllUuidsOfDashboardViewAndChildrenViews = (dashboard: Dashboard, view: DashboardView, levels: number): Array<string> =>
  getUuidsOfFrames(getAllFramesOfDashboardViewAndChildrenViews(dashboard, view, levels));

export const getAllFramesOfDashboardViewAndChildrenViews = (dashboard: Dashboard, view: DashboardView, levels: number): Array<Frame> =>
  dashboard.frames.filter((f) => getFramesIds(dashboard, view, levels).includes(f.key));

const getUuidsOfFrames = (frames: Array<Frame>): Array<string> => {
  let uuids: Array<string> = [];
  frames.forEach((frame) => {
    uuids = uuids.concat(frame.uuids);
  });

  return [...new Set(uuids)];
};

const getChildrenViewsIdsOfView = (dashboard: Dashboard, view: DashboardView): Array<string> => {
  let childrenIds: Array<string> = [];
  view.frames.forEach((frame) => {
    childrenIds = childrenIds.concat(getChildrenViewsOfFrame(dashboard.dbas, view.id, frame));
  });

  return [...new Set(childrenIds)];
};

export const dashboardToMermaid = (dashboard: Dashboard, direction: 'TD' | 'LR', includeLoop: boolean): { code: string; hasLoop: boolean } => {
  let hasLoop = false;
  const intro = `graph ${direction}\n`;
  const nodes: Array<string> = [];
  dashboard.views.forEach((v) => {
    nodes.push(`${v.id}[${v.label}]`);
    nodes.push(`click ${v.id} call mermaidTreeViewNodeClickCallBack(${v.id})`);
  });
  const links: Array<{ from: string; to: string }> = [];
  dashboard.dbas.forEach((dba) => {
    const isALoop = dba.viewKey === dba.targetView;
    if (isALoop) {
      hasLoop = true;
    }
    if ((includeLoop || !isALoop) && !links.find((l) => l.from === dba.viewKey && l.to === dba.targetView)) {
      links.push({ from: dba.viewKey, to: dba.targetView });
    }
  });
  return { code: intro + nodes.join('\n') + '\n' + links.map((l) => `${l.from} --> ${l.to}`).join('\n'), hasLoop };
};

const getChildrenViewsOfFrame = (dbasConfig: Array<DashboardDBAConfig>, viewKey: string, frameKey: string): Array<string> => {
  const childrenViews: Array<string> = [];
  dbasConfig.forEach((dbaConfig) => {
    if (dbaConfig.viewKey === viewKey && dbaConfig.frameKey === frameKey) {
      childrenViews.push(dbaConfig.targetView);
    }
  });

  return [...new Set(childrenViews)];
};

const httpHeaders = new HttpHeaders({ Accept: 'application/json' });

export interface DashboardStorageConfig {
  dashboardZoom: number;
  highlightWAS: boolean;
}

export interface DashboardsStorageConfig {
  filter: string;
  favSortActive: string;
  favSortDirection: SortDirection;
  sortActive: string;
  sortDirection: SortDirection;
}

export const DEFAULT_DASHBOARD_CONFIG: DashboardStorageConfig = {
  dashboardZoom: 1,
  highlightWAS: false
};

export const DEFAULT_DASHBOARDS_CONFIG: DashboardsStorageConfig = {
  filter: '',
  favSortActive: 'title',
  favSortDirection: 'asc',
  sortActive: 'title',
  sortDirection: 'asc'
};
