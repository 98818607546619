<ng-container *ngIf="state$ | async as state">
  <ng-container *ngIf="state.loaded">
    <ng-container *ngIf="state.menuItems as menuItems">
      <ng-container *ngIf="state.defaultLang as defaultLang">
        <ng-container *ngIf="state.g2Status as g2Status">
          <ngx-g2v-layout [title]="title" [menuItems]="menuItems" [logoUrl]="logoUrl" [g2Status]="g2Status">
            <router-outlet></router-outlet>
          </ngx-g2v-layout>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!state.loaded">
    <ngx-g2v-progress-bar></ngx-g2v-progress-bar>
  </ng-container>
  <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
</ng-container>