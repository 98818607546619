import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AfkComponent } from '@components/afk/afk.component';
import { ErrorSocketComponent } from '@components/error-socket/error-socket.component';
import { AFK_PATH, ERROR_SOCKET_PATH } from '@config/constants';

const routes: Routes = [
  { path: AFK_PATH, component: AfkComponent },
  { path: ERROR_SOCKET_PATH, component: ErrorSocketComponent, data: { hasTopBar: false } }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule {}
