import { combineLatest, distinctUntilChanged, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  ADMIN_DB_PATH,
  ADMIN_DEV_PATH,
  ADMIN_DOC_PATH,
  ADMIN_G2_PATH,
  ADMIN_PATH,
  ALL_LANGUAGES,
  DASHBOARDS_PATH,
  KEYCLOAK_GROUPS_PATH,
  KEYCLOAK_PATH,
  KEYCLOAK_ROLES_PATH,
  KEYCLOAK_USERS_PATH,
  SESSIONS_PATH,
  SHORTCUT_HELP,
  SNACKBAR_DURATION
} from '@config/constants';
import { BACKEND_NAME, hasEnoughRoles, isLocal } from '@config/utils';
import { CoreState, UserAuth } from '@core/state/core.state';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { environment } from '@environments/environment';
import {
  ADMIN_DB_CLIENT_ROLES,
  ADMIN_DOC_CLIENT_ROLES,
  DASHBOARDS_CLIENT_ROLES,
  G2DocumentationLinkResponse,
  GET_G2_INFO_ROLES,
  KEYCLOAK_GROUPS_REALM_ROLES,
  KEYCLOAK_ROLES_REALM_ROLES,
  KEYCLOAK_USERS_REALM_ROLES,
  SESSIONS_CLIENT_ROLES
} from '@g2view/g2view-commons';
import { MenuItem } from '@interfaces/menu-item';
import { CreateHotToastRef, HotToastService, ToastOptions } from '@ngneat/hot-toast';
import { HotkeysService } from '@ngneat/hotkeys';
import { TranslocoService } from '@ngneat/transloco';
import { Select } from '@ngxs/store';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';
import { CacheService, UserStorageConfig } from '@services/cache.service';
import { FeathersService } from '@services/feathers.service';
import { HelpService } from '@services/help.service';
import { KeycloakCoreService } from '@services/keycloak-core.service';
import { g2Status, ServerService } from '@services/server.service';
import { UserConfigService } from '@services/user-config.service';

// TODO2: write doc to launch via Docker
// TODO3: for all WAS: test update + WS resize

interface ComponentState {
  menuItems: Array<MenuItem> | undefined;
  g2Status: g2Status;
  defaultLang: string;
  loaded: boolean;
  error: unknown;
}
@Component({
  selector: 'ngx-g2v-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends StatefulComponentDirective<ComponentState> implements OnInit, AfterViewInit {
  @Select(CoreState.SelectUserAuth) public userAuth$: Observable<UserAuth> | undefined;
  @Select(CoreState.SelectUserId) public userId$: Observable<string> | undefined;

  public title = environment.settings.global.title;
  public logoUrl = environment.settings.global.logoUrl;

  private previousStatus: g2Status | undefined;
  private toasts: Array<CreateHotToastRef<unknown>> = [];
  private availableLanguages: Array<{ id: string; label: string }> = [];

  constructor(
    private readonly hotkeys: HotkeysService,
    private readonly translocoService: TranslocoService,
    private readonly cacheService: CacheService,
    private readonly feathersService: FeathersService,
    private readonly keycloakCoreService: KeycloakCoreService,
    private readonly userConfigService: UserConfigService,
    private readonly http: ApiHttpService,
    private readonly apiEndpointsService: ApiEndpointsService,
    private readonly toast: HotToastService,
    private readonly helpService: HelpService,
    private readonly serverService: ServerService
  ) {
    super({
      menuItems: [],
      g2Status: 'check',
      defaultLang: '',
      loaded: false,
      error: undefined
    });

    environment.settings.global.langAvailable.split(',').forEach((l) => {
      const lang = ALL_LANGUAGES.filter((lan) => lan.id === l);
      if (lang.length === 1) {
        this.availableLanguages.push(lang[0]);
      }
    });
    this.translocoService.setAvailableLangs(this.availableLanguages);
    this.translocoService.setDefaultLang(environment.settings.global.langDefault);

    this.keycloakCoreService.init();
    this.feathersService.init();
  }

  public ngOnInit(): void {
    this.initTranslations();
    this.initConfig();
    this.initServerSub();
  }

  public ngAfterViewInit(): void {
    this.hotkeys.registerHelpModal(() => {
      this.helpService.openHelp();
    }, SHORTCUT_HELP);
  }

  private initServerSub(): void {
    const toastConfig: ToastOptions<unknown> = {
      duration: SNACKBAR_DURATION,
      position: 'bottom-center'
    };
    this.serverService.g2Status$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe({
      next: (status) => {
        this.updateComponentState({ g2Status: status });
        if (status === 'online' && this.previousStatus === 'offline') {
          this.toasts.forEach((t) => {
            t.close();
          });
          this.toasts.push(this.toast.success(this.translocoService.translate('toast.g2Status.backOnline', {}, 'app'), toastConfig));
        } else if (status === 'offline') {
          this.toasts.push(
            this.toast.warning(this.translocoService.translate('toast.g2Status.offline', {}, 'app'), {
              ...toastConfig,
              autoClose: false,
              dismissible: true
            })
          );
        } else if (status === 'check' && this.previousStatus) {
          this.toasts.push(this.toast.info(this.translocoService.translate('toast.g2Status.check', {}, 'app'), toastConfig));
        }
        this.previousStatus = status;
      }
    });
  }

  private initConfig(): void {
    if (!this.userId$) {
      return;
    }
    this.userId$.subscribe({
      next: (userId) => {
        if (userId) {
          const config = this.cacheService.getConfig('user', '') as UserStorageConfig;
          let lang = config.lang;
          if (!this.availableLanguages.map((al) => al.id).includes(lang)) {
            lang = environment.settings.global.langDefault;
            this.cacheService.updateConfigItem('user', '', 'lang', lang);
          }
          this.translocoService.setActiveLang(lang);
          this.userConfigService.setDevMode(config.devMode);

          this.updateComponentState({
            defaultLang: lang
          });
        }
      }
    });
  }

  private initTranslations(): void {
    if (!this.userAuth$) {
      return;
    }
    const awsLogURL = [
      `https://${environment.aws.region}.console.aws.amazon.com`,
      `/cloudwatch/home?`,
      `${environment.aws.region}#logsV2:log-groups/log-group/G2View-${isLocal() ? 'dev' : environment.envName.toLocaleLowerCase()}/`,
      `log-events/${BACKEND_NAME}`
    ].join('');
    /**
     * t(app.menu.dashboardsLabel,
        app.menu.dashboardsDescription,
        app.menu.sessionsLabel,
        app.menu.sessionsDescription,
        app.menu.adminLabel,
        app.menu.adminDescription,
        app.menu.groupsLabel,
        app.menu.groupsDescription,
        app.menu.rolesLabel,
        app.menu.rolesDescription,
        app.menu.usersLabel,
        app.menu.usersDescription,
        app.menu.databaseLabel,
        app.menu.databaseDescription,
        app.menu.docLabel,
        app.menu.docDescription,
        app.menu.apiDocLabel,
        app.menu.apiDocDescription,
        app.menu.g2DocLabel,
        app.menu.g2DocDescription,
        app.menu.g2Label,
        app.menu.g2Description,
        app.menu.devLabel,
        app.menu.devDescription,
        app.menu.awsLogLabel,
        app.menu.awsLogDescription)
     */
    combineLatest([
      this.fetchG2DocumentationLink$(),
      this.userAuth$,
      this.translocoService.selectTranslate(
        [
          'menu.dashboardsLabel',
          'menu.dashboardsDescription',
          'menu.sessionsLabel',
          'menu.sessionsDescription',
          'menu.adminLabel',
          'menu.adminDescription',
          'menu.groupsLabel',
          'menu.groupsDescription',
          'menu.rolesLabel',
          'menu.rolesDescription',
          'menu.usersLabel',
          'menu.usersDescription',
          'menu.databaseLabel',
          'menu.databaseDescription',
          'menu.docLabel',
          'menu.docDescription',
          'menu.apiDocLabel',
          'menu.apiDocDescription',
          'menu.g2DocLabel',
          'menu.g2DocDescription',
          'menu.g2Label',
          'menu.g2Description',
          'menu.devLabel',
          'menu.devDescription',
          'menu.awsLogLabel',
          'menu.awsLogDescription'
        ],
        {},
        'app'
      )
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([g2DocLink, userData, translations]) => {
          const adminMenuItems = [
            {
              key: [KEYCLOAK_PATH, KEYCLOAK_GROUPS_PATH].join('-'),
              name: translations[6],
              title: translations[7],
              isRoot: false,
              route: KEYCLOAK_GROUPS_PATH,
              relativePath: true,
              external: false,
              clientRoles: [],
              realmRoles: KEYCLOAK_GROUPS_REALM_ROLES,
              accountRoles: []
            },
            {
              key: [KEYCLOAK_PATH, KEYCLOAK_ROLES_PATH].join('-'),
              name: translations[8],
              title: translations[9],
              isRoot: false,
              route: KEYCLOAK_ROLES_PATH,
              relativePath: true,
              external: false,
              clientRoles: [],
              realmRoles: KEYCLOAK_ROLES_REALM_ROLES,
              accountRoles: []
            },
            {
              key: [KEYCLOAK_PATH, KEYCLOAK_USERS_PATH].join('-'),
              name: translations[10],
              title: translations[11],
              isRoot: false,
              route: KEYCLOAK_USERS_PATH,
              relativePath: true,
              external: false,
              clientRoles: [],
              realmRoles: KEYCLOAK_USERS_REALM_ROLES,
              accountRoles: []
            },
            {
              key: [ADMIN_PATH, ADMIN_DB_PATH].join('-'),
              name: translations[12],
              title: translations[13],
              isRoot: false,
              route: [ADMIN_PATH, ADMIN_DB_PATH],
              relativePath: false,
              external: false,
              clientRoles: ADMIN_DB_CLIENT_ROLES,
              realmRoles: [],
              accountRoles: []
            },
            {
              key: [ADMIN_PATH, ADMIN_DOC_PATH].join('-'),
              name: translations[14],
              title: translations[15],
              isRoot: false,
              route: [ADMIN_PATH, ADMIN_DOC_PATH],
              relativePath: false,
              external: false,
              clientRoles: ADMIN_DOC_CLIENT_ROLES,
              realmRoles: [],
              accountRoles: []
            },
            {
              key: 'apiDoc',
              name: translations[16],
              title: translations[17],
              isRoot: false,
              route: environment.apiDocUrl,
              relativePath: false,
              external: true,
              clientRoles: ADMIN_DOC_CLIENT_ROLES,
              realmRoles: [],
              accountRoles: []
            },
            {
              key: 'g2Doc',
              name: translations[18],
              title: translations[19],
              isRoot: false,
              route: g2DocLink.url,
              relativePath: false,
              external: true,
              clientRoles: ADMIN_DOC_CLIENT_ROLES,
              realmRoles: [],
              accountRoles: []
            },
            {
              key: [ADMIN_PATH, ADMIN_G2_PATH].join('-'),
              name: translations[20],
              title: translations[21],
              isRoot: false,
              route: [ADMIN_PATH, ADMIN_G2_PATH],
              relativePath: false,
              external: false,
              clientRoles: GET_G2_INFO_ROLES,
              realmRoles: [],
              accountRoles: []
            },
            {
              key: [ADMIN_PATH, ADMIN_DEV_PATH].join('-'),
              name: translations[22],
              title: translations[23],
              isRoot: false,
              route: [ADMIN_PATH, ADMIN_DEV_PATH],
              relativePath: false,
              external: false,
              clientRoles: GET_G2_INFO_ROLES,
              realmRoles: [],
              accountRoles: []
            },
            {
              key: 'awsLog',
              name: translations[24],
              title: translations[25],
              isRoot: false,
              route: awsLogURL,
              relativePath: false,
              external: true,
              clientRoles: ADMIN_DB_CLIENT_ROLES,
              realmRoles: [],
              accountRoles: []
            }
          ];
          const menuItems: Array<MenuItem> = [
            {
              key: DASHBOARDS_PATH,
              name: translations[0],
              title: translations[1],
              isRoot: true,
              route: DASHBOARDS_PATH,
              relativePath: true,
              external: false,
              clientRoles: DASHBOARDS_CLIENT_ROLES,
              realmRoles: [],
              accountRoles: []
            },
            {
              key: SESSIONS_PATH,
              name: translations[2],
              title: translations[3],
              isRoot: true,
              route: SESSIONS_PATH,
              relativePath: true,
              external: false,
              clientRoles: SESSIONS_CLIENT_ROLES,
              realmRoles: [],
              accountRoles: []
            },
            {
              name: translations[4],
              title: translations[5],
              key: KEYCLOAK_PATH,
              isRoot: true,
              menuItems: adminMenuItems
            }
          ];
          let menuItemsIndex = menuItems.length;
          while (menuItemsIndex--) {
            const item = menuItems[menuItemsIndex];
            if (!item.isRoot) {
              if (
                !hasEnoughRoles(
                  userData.clientRoles,
                  userData.realmRoles,
                  userData.accountRoles,
                  item.clientRoles ?? [],
                  item.realmRoles ?? [],
                  item.accountRoles ?? []
                )
              ) {
                menuItems.splice(menuItemsIndex, 1);
              }
            } else if (item.menuItems) {
              let subMenuItemsIndex = item.menuItems.length;
              while (subMenuItemsIndex--) {
                const subMenuItem = item.menuItems[subMenuItemsIndex];
                if (
                  !hasEnoughRoles(
                    userData.clientRoles,
                    userData.realmRoles,
                    userData.accountRoles,
                    subMenuItem.clientRoles ?? [],
                    subMenuItem.realmRoles ?? [],
                    subMenuItem.accountRoles ?? []
                  )
                ) {
                  item.menuItems.splice(subMenuItemsIndex, 1);
                }
              }
              if (item.menuItems.length === 0) {
                menuItems.splice(menuItemsIndex, 1);
              }
            }
          }
          this.updateComponentState({
            menuItems,
            loaded: true,
            error: undefined
          });
        },
        error: (error: unknown) => {
          this.setComponentState({
            menuItems: undefined,
            g2Status: 'check',
            defaultLang: '',
            loaded: true,
            error
          });
        }
      });
  }

  private fetchG2DocumentationLink$(): Observable<G2DocumentationLinkResponse> {
    return this.http.get(this.apiEndpointsService.getG2DocumentationLinkEndpoint());
  }
}
