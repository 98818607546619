<ng-container *transloco="let t; scope: 'app'; read: 'app.forms.validationMessage'">
  <mat-form-field>
    <ngx-mat-file-input #removableInput [accept]="field.props.fileType ?? ''" [placeholder]="field.props.label ?? ''" [formControl]="formControl" [formlyAttributes]="field">
    </ngx-mat-file-input>
    <mat-icon fontIcon="folder" matSuffix></mat-icon>
    <button mat-icon-button matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)" style="vertical-align: bottom;" title="{{ t('buttonClearTitle') }}">
      <mat-icon fontIcon="clear"></mat-icon>
    </button>
    <mat-error *ngIf="removableInput.empty">
      {{ t('required') }}
    </mat-error>
  </mat-form-field>
</ng-container>