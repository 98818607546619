import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { StatefulComponentDirective } from '@directives/stateful.directive';
import { ErrorHandlerService } from '@services/error-handler.service';

interface ComponentState {
  loaded: boolean;
  error: unknown;
}

@Component({
  selector: 'ngx-g2v-snack-bar-no-backend',
  templateUrl: './snack-bar-no-backend.component.html',
  styleUrls: ['./snack-bar-no-backend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackBarNoBackendComponent extends StatefulComponentDirective<ComponentState> implements OnInit {
  constructor(
    private readonly errorHandler: ErrorHandlerService,
    private readonly router: Router,
    public snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: unknown
  ) {
    super({
      loaded: false,
      error: undefined
    });
  }

  public ngOnInit(): void {
    this.updateComponentState({
      loaded: true
    });
  }

  public reload(): void {
    this.snackBar.dismiss();
    this.reloadPage();
  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }

  private reloadPage(): void {
    this.router
      .navigate(['/'])
      .then(() => {
        location.reload();
      })
      .catch(this.errorHandler.handleError);
  }
}
