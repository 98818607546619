/* eslint-disable @typescript-eslint/no-explicit-any */
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { inject } from '@angular/core';
import { HotkeysService } from '@ngneat/hotkeys';
import { TranslocoScope, TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ShortcutsService } from '@services/shortcuts.service';

import { RxJSBaseDirective } from '../directives/rxjs-base.directive';
import { StatefulComponentDirective } from '../directives/stateful.directive';

const TIME_BETWEEN_SCOPE_TEST = 50;

type Constructor<T> = new (...args: Array<any>) => T;

// eslint-disable-next-line @typescript-eslint/ban-types
export class StatefulComponentDirectiveWithShortcutsServices<State extends {}> extends StatefulComponentDirective<State> {
  public readonly shortcutsService = inject(ShortcutsService);
  public readonly translocoService = inject(TranslocoService);
  public readonly hotkeys = inject(HotkeysService);
  public readonly errorHandler = inject(ErrorHandlerService);

  constructor(protected readonly initState: State) {
    super(initState);
  }
}

export class RxJSBaseDirectiveWithServices extends RxJSBaseDirective {
  public readonly shortcutsService = inject(ShortcutsService);
  public readonly errorHandler = inject(ErrorHandlerService);
  public readonly translocoService = inject(TranslocoService);
  public readonly hotkeys = inject(HotkeysService);

  constructor() {
    super();
  }
}

interface IServices {
  errorHandler: ErrorHandlerService;
  shortcutsService: ShortcutsService;
  translocoService: TranslocoService;
  hotkeys: HotkeysService;
  destroy$: Subject<void>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function WithShortcuts<T extends Constructor<IServices>>(Base: T): T {
  return class extends Base {
    protected readonly shortcutSubscription: Array<Subscription> = [];
    protected transScope: TranslocoScope = 'app';
    protected shortcuts: Array<Shortcut> = [];
    private groupTranslationKeys: Array<string> = [];
    private descriptionTranslationKeys: Array<string> = [];
    private shortcutTimer: NodeJS.Timeout | undefined;

    constructor(...args: Array<any>) {
      super(...args);
      this.initShortcuts();
      this.destroy$.subscribe({
        next: () => {
          if (this.shortcutTimer) {
            clearTimeout(this.shortcutTimer);
          }
        }
      });
    }

    protected getInitShortcuts(): { shortcuts: Array<Shortcut>; scope: TranslocoScope } {
      return { shortcuts: [], scope: 'app' };
    }

    private initShortcuts(): void {
      const { shortcuts, scope } = this.getInitShortcuts();
      if (scope) {
        this.shortcuts = shortcuts;
        this.transScope = scope;
        this.groupTranslationKeys = this.shortcuts.map((shortcut) => shortcut.group);
        this.descriptionTranslationKeys = this.shortcuts.map((shortcut) => shortcut.description);
        this.refreshShortcuts();
        this.refreshTranslation();
      } else {
        if (this.shortcutTimer) {
          clearTimeout(this.shortcutTimer);
        }
        this.shortcutTimer = setTimeout(() => {
          this.initShortcuts();
        }, TIME_BETWEEN_SCOPE_TEST);
      }
    }

    private refreshShortcuts(): void {
      this.shortcutSubscription.forEach((sub) => {
        sub.unsubscribe();
      });
      this.shortcuts.forEach((shortcut) => {
        const sub = this.hotkeys
          .addShortcut(shortcut)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              if (this.shortcutsService.areActive) {
                shortcut.callback.bind(this)();
              }
            },
            error: (err: unknown) => this.errorHandler.handleError(err)
          });
        this.shortcutSubscription.push(sub);
      });
    }

    private refreshTranslation(): void {
      const keys = [...this.groupTranslationKeys, ...this.descriptionTranslationKeys];
      this.translocoService
        .selectTranslate(keys, {}, this.transScope)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (translations: Array<string>) => {
            translations.forEach((translation, index) => {
              if (index < this.groupTranslationKeys.length) {
                this.shortcuts[index].group = translation;
              } else {
                this.shortcuts[index - this.groupTranslationKeys.length].description = translation;
              }
              this.refreshShortcuts();
            });
          },
          error: (err: unknown) => this.errorHandler.handleError(err)
        });
    }
  };
}

export interface Shortcut {
  keys: string;
  group: string;
  description: string;
  callback: () => void;
}
