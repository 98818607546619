import { Position } from '@core/state/core.actions';
import { AccessRequest, Session, SessionFavorite, UpdateSessionInfoEvent } from '@g2view/g2view-commons';
import { SessionInfo } from '@sessions/state/sessions.state';

export class SetSessions {
  public static readonly type = '[Sessions API] Set Sessions and favorites';
  constructor(public sessions: Array<SessionInfo>, public favorites: Array<SessionFavorite>, public accessRequests: Array<AccessRequest>) {}
}

export class AddSession {
  public static readonly type = '[Sessions API] Add Session';
  constructor(public session: Session) {}
}

export class UpdateCurrentSession {
  public static readonly type = '[Sessions API] Update current session';
  constructor(public session: Session) {}
}

export class ResetCurrentSession {
  public static readonly type = '[Sessions API] Reset current session';
}

export class SetDragPosition {
  public static readonly type = '[Core API] Set drag position';
  constructor(public dragPosition: Position) {}
}

export class UpdateSessionInfo {
  public static readonly type = '[Sessions API] Update Session info';
  constructor(public data: UpdateSessionInfoEvent) {}
}

export class RemoveSession {
  public static readonly type = '[Sessions API] Remove Session';
  constructor(public sessionId: string) {}
}

export class AddSessionFavorite {
  public static readonly type = '[Sessions API] Add Session Favorite';
  constructor(public sessionFavorite: SessionFavorite) {}
}

export class RemoveSessionFavorite {
  public static readonly type = '[Sessions API] Remove Session Favorite';
  constructor(public sessionFavorite: SessionFavorite) {}
}
export class AddSessionAccessRequest {
  public static readonly type = '[Sessions API] Add Session access request';
  constructor(public accessRequest: AccessRequest) {}
}

export class RemoveSessionAccessRequest {
  public static readonly type = '[Sessions API] Remove Session access request';
  constructor(public accessRequest: AccessRequest) {}
}
