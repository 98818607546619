import {
  ActionArea,
  ChartArea,
  ConnectionArea,
  DashboardButtonArea,
  EmbeddedVideoArea,
  FormArea,
  FrameArea,
  ImageArea,
  MapArea,
  MarkmapArea,
  MenuArea,
  MermaidArea,
  MinimizedWorkspace,
  NamedWorkspace,
  NavigationArea,
  SliderArea,
  SpeechRecognitionArea,
  TableArea,
  TextArea,
  TimelineArea,
  TypeInBoxArea,
  Viewer3DArea,
  WorkspaceDB,
  WorkspaceModule
} from '@g2view/g2view-commons';

export class AddWorkspaces {
  public static readonly type = '[Workspaces API] Add Workspaces';
  constructor(public workspaces: Array<WorkspaceDB>) {}
}

export class UpdateWorkspace {
  public static readonly type = '[Workspaces API] Update Workspace';
  constructor(public workspace: WorkspaceDB) {}
}

export class RemoveWorkspaces {
  public static readonly type = '[Workspaces API] Remove Workspaces';
  constructor(public workspaces: Array<WorkspaceDB>) {}
}

export class RefreshWorkspacesImageUrl {
  public static readonly type = '[Workspaces API] Refresh Workspace img url';
  constructor(public uuids: Array<string>) {}
}

export class SetMonitoredWorkspaces {
  public static readonly type = '[Workspaces API] Set monitored Workspaces';
  constructor(public workspaces: Array<string>) {}
}

export class ResetMonitoredWorkspaces {
  public static readonly type = '[Workspaces API] Reset monitored Workspaces';
}

export class SetNamedWorkspaces {
  public static readonly type = '[Workspaces API] Set named Workspaces';
  constructor(public namedWorkspaces: Array<NamedWorkspace>) {}
}

export class ResetNamedWorkspaces {
  public static readonly type = '[Workspaces API] Reset named Workspaces';
}

export class SetMinimizedWorkspaces {
  public static readonly type = '[Workspaces API] Set minimized Workspaces';
  constructor(public minimizedWorkspacesUuids: Array<string>) {}
}

export class AddMinimizedWorkspaces {
  public static readonly type = '[Workspaces API] Add minimized Workspaces';
  constructor(public minimizedWorkspaces: Array<MinimizedWorkspace>) {}
}

export class UpdateMinimizedWorkspaces {
  public static readonly type = '[Workspaces API] Update minimized Workspaces';
  constructor(public minimizedWorkspaces: Array<MinimizedWorkspace>) {}
}

export class ResetMinimizedWorkspaces {
  public static readonly type = '[Workspaces API] Reset minimized Workspaces';
}

export class SetWorkspacesModule {
  public static readonly type = "[Workspaces API] Set current workspace's modules";
  constructor(public workspacesModules: Array<WorkspaceModule>) {}
}

export class AddActionAreas {
  public static readonly type = '[Workspaces API] Add Action areas';
  constructor(public actionAreas: Array<ActionArea>) {}
}

export class UpdateActionArea {
  public static readonly type = '[Workspaces API] Update Action area';
  constructor(public actionArea: ActionArea) {}
}

export class RemoveActionAreas {
  public static readonly type = '[Workspaces API] Remove Action areas';
  constructor(public ids: Array<string>) {}
}

export class AddChartAreas {
  public static readonly type = '[Workspaces API] Add Chart areas';
  constructor(public chartAreas: Array<ChartArea>) {}
}

export class UpdateChartArea {
  public static readonly type = '[Workspaces API] Update Chart area';
  constructor(public chartArea: ChartArea) {}
}

export class RemoveChartAreas {
  public static readonly type = '[Workspaces API] Remove Chart areas';
  constructor(public ids: Array<string>) {}
}

export class AddConnectionAreas {
  public static readonly type = '[Workspaces API] Add Connection areas';
  constructor(public connectionAreas: Array<ConnectionArea>) {}
}

export class UpdateConnectionArea {
  public static readonly type = '[Workspaces API] Update Connection area';
  constructor(public connectionArea: ConnectionArea) {}
}

export class RemoveConnectionAreas {
  public static readonly type = '[Workspaces API] Remove Connection areas';
  constructor(public ids: Array<string>) {}
}

export class AddDashboardButtonAreas {
  public static readonly type = '[Workspaces API] Add Dashboard Button areas';
  constructor(public dashboardButtonAreas: Array<DashboardButtonArea>) {}
}

export class UpdateDashboardButtonArea {
  public static readonly type = '[Workspaces API] Update Dashboard Button area';
  constructor(public dashboardButtonArea: DashboardButtonArea) {}
}

export class RemoveDashboardButtonAreas {
  public static readonly type = '[Workspaces API] Remove Dashboard Button areas';
  constructor(public ids: Array<string>) {}
}

export class AddEmbeddedVideoAreas {
  public static readonly type = '[Workspaces API] Add Embedded Video areas';
  constructor(public embeddedVideoAreas: Array<EmbeddedVideoArea>) {}
}

export class UpdateEmbeddedVideoArea {
  public static readonly type = '[Workspaces API] Update Embedded Video area';
  constructor(public embeddedVideoArea: EmbeddedVideoArea) {}
}

export class RemoveEmbeddedVideoAreas {
  public static readonly type = '[Workspaces API] Remove Embedded Video areas';
  constructor(public ids: Array<string>) {}
}

export class AddFormAreas {
  public static readonly type = '[Workspaces API] Add Form areas';
  constructor(public formArea: Array<FormArea>) {}
}

export class UpdateFormArea {
  public static readonly type = '[Workspaces API] Update Form area';
  constructor(public formArea: FormArea) {}
}

export class RemoveFormAreas {
  public static readonly type = '[Workspaces API] Remove Form areas';
  constructor(public ids: Array<string>) {}
}

export class AddFrameAreas {
  public static readonly type = '[Workspaces API] Add Frame areas';
  constructor(public frameAreas: Array<FrameArea>) {}
}

export class UpdateFrameArea {
  public static readonly type = '[Workspaces API] Update Frame area';
  constructor(public frameArea: FrameArea) {}
}

export class RemoveFrameAreas {
  public static readonly type = '[Workspaces API] Remove Frame areas';
  constructor(public ids: Array<string>) {}
}

export class AddImageAreas {
  public static readonly type = '[Workspaces API] Add Image areas';
  constructor(public imageAreas: Array<ImageArea>) {}
}

export class UpdateImageArea {
  public static readonly type = '[Workspaces API] Update Image area';
  constructor(public imageArea: ImageArea) {}
}

export class RemoveImageAreas {
  public static readonly type = '[Workspaces API] Remove Image areas';
  constructor(public ids: Array<string>) {}
}

export class AddMarkmapAreas {
  public static readonly type = '[Workspaces API] Add Markmap areas';
  constructor(public markmapAreas: Array<MarkmapArea>) {}
}

export class UpdateMarkmapArea {
  public static readonly type = '[Workspaces API] Update Markmap area';
  constructor(public markmapArea: MarkmapArea) {}
}

export class RemoveMarkmapAreas {
  public static readonly type = '[Workspaces API] Remove Markmap areas';
  constructor(public ids: Array<string>) {}
}

export class AddMapAreas {
  public static readonly type = '[Workspaces API] Add Map areas';
  constructor(public mapAreas: Array<MapArea>) {}
}

export class UpdateMapArea {
  public static readonly type = '[Workspaces API] Update Map area';
  constructor(public mapArea: MapArea) {}
}

export class RemoveMapAreas {
  public static readonly type = '[Workspaces API] Remove Map areas';
  constructor(public ids: Array<string>) {}
}

export class AddMenuAreas {
  public static readonly type = '[Workspaces API] Add Menu areas';
  constructor(public menuAreas: Array<MenuArea>) {}
}

export class UpdateMenuArea {
  public static readonly type = '[Workspaces API] Update Menu area';
  constructor(public menuArea: MenuArea) {}
}

export class RemoveMenuAreas {
  public static readonly type = '[Workspaces API] Remove Menu areas';
  constructor(public ids: Array<string>) {}
}

export class AddMermaidAreas {
  public static readonly type = '[Workspaces API] Add Mermaid areas';
  constructor(public mermaidAreas: Array<MermaidArea>) {}
}

export class UpdateMermaidArea {
  public static readonly type = '[Workspaces API] Update Mermaid area';
  constructor(public mermaidArea: MermaidArea) {}
}

export class RemoveMermaidAreas {
  public static readonly type = '[Workspaces API] Remove Mermaid areas';
  constructor(public ids: Array<string>) {}
}

export class AddNavigationAreas {
  public static readonly type = '[Workspaces API] Add Navigation areas';
  constructor(public navigationAreas: Array<NavigationArea>) {}
}

export class UpdateNavigationArea {
  public static readonly type = '[Workspaces API] Update Navigation area';
  constructor(public navigationArea: NavigationArea) {}
}

export class RemoveNavigationAreas {
  public static readonly type = '[Workspaces API] Remove Navigation areas';
  constructor(public ids: Array<string>) {}
}

export class AddSliderAreas {
  public static readonly type = '[Workspaces API] Add Slider areas';
  constructor(public sliderAreas: Array<SliderArea>) {}
}

export class UpdateSliderArea {
  public static readonly type = '[Workspaces API] Update Slider area';
  constructor(public sliderArea: SliderArea) {}
}

export class RemoveSliderAreas {
  public static readonly type = '[Workspaces API] Remove Slider areas';
  constructor(public ids: Array<string>) {}
}

export class AddSpeechRecognitionAreas {
  public static readonly type = '[Workspaces API] Add Speech Recognition areas';
  constructor(public speechRecognitionAreas: Array<SpeechRecognitionArea>) {}
}

export class UpdateSpeechRecognitionArea {
  public static readonly type = '[Workspaces API] Update Speech Recognition area';
  constructor(public speechRecognitionArea: SpeechRecognitionArea) {}
}

export class RemoveSpeechRecognitionAreas {
  public static readonly type = '[Workspaces API] Remove Speech Recognition areas';
  constructor(public ids: Array<string>) {}
}

export class AddTextAreas {
  public static readonly type = '[Workspaces API] Add Text areas';
  constructor(public textAreas: Array<TextArea>) {}
}

export class UpdateTextArea {
  public static readonly type = '[Workspaces API] Update Text area';
  constructor(public textArea: TextArea) {}
}

export class RemoveTextAreas {
  public static readonly type = '[Workspaces API] Remove Text areas';
  constructor(public ids: Array<string>) {}
}

export class AddTableAreas {
  public static readonly type = '[Workspaces API] Add Table areas';
  constructor(public tableAreas: Array<TableArea>) {}
}

export class UpdateTableArea {
  public static readonly type = '[Workspaces API] Update Table area';
  constructor(public tableArea: TableArea) {}
}

export class RemoveTableAreas {
  public static readonly type = '[Workspaces API] Remove Table areas';
  constructor(public ids: Array<string>) {}
}

export class AddTimelineAreas {
  public static readonly type = '[Workspaces API] Add Timeline areas';
  constructor(public timelineAreas: Array<TimelineArea>) {}
}

export class UpdateTimelineArea {
  public static readonly type = '[Workspaces API] Update Timeline area';
  constructor(public timelineArea: TimelineArea) {}
}

export class RemoveTimelineAreas {
  public static readonly type = '[Workspaces API] Remove Timeline areas';
  constructor(public ids: Array<string>) {}
}

export class AddTypeInBoxAreas {
  public static readonly type = '[Workspaces API] Add TypeInBox areas';
  constructor(public typeInBoxAreas: Array<TypeInBoxArea>) {}
}

export class UpdateTypeInBoxArea {
  public static readonly type = '[Workspaces API] Update TypeInBox area';
  constructor(public typeInBoxArea: TypeInBoxArea) {}
}

export class RemoveTypeInBoxAreas {
  public static readonly type = '[Workspaces API] Remove TypeInBox areas';
  constructor(public ids: Array<string>) {}
}

export class AddViewer3DAreas {
  public static readonly type = '[Workspaces API] Add Viewer 3D areas';
  constructor(public viewer3DAreas: Array<Viewer3DArea>) {}
}

export class UpdateViewer3DArea {
  public static readonly type = '[Workspaces API] Update Viewer 3D area';
  constructor(public viewer3DArea: Viewer3DArea) {}
}

export class RemoveViewer3DAreas {
  public static readonly type = '[Workspaces API] Remove Viewer 3D areas';
  constructor(public ids: Array<string>) {}
}
