import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SESSIONS_PATH, STATE_G2VIEW_SESSIONS } from '@config/constants';
import { removeDuplicates } from '@config/utils';
import { Position } from '@core/state/core.actions';
import { AccessRequest, Session, SessionFavorite } from '@g2view/g2view-commons';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ErrorHandlerService } from '@services/error-handler.service';
import { UserService } from '@services/user.service';
import {
  AddSession,
  AddSessionAccessRequest,
  AddSessionFavorite,
  RemoveSession,
  RemoveSessionAccessRequest,
  RemoveSessionFavorite,
  ResetCurrentSession,
  SetDragPosition,
  SetSessions,
  UpdateCurrentSession,
  UpdateSessionInfo
} from '@sessions/state/sessions.actions';

@State<SessionsStateModel>({
  name: STATE_G2VIEW_SESSIONS,
  defaults: {
    isLoaded: false,
    currentSession: new Session(),
    currentDragPosition: new Position(),
    sessions: [],
    favorites: [],
    accessRequests: []
  }
})
@Injectable()
export class SessionsState {
  constructor(private readonly userService: UserService, private readonly errorHandler: ErrorHandlerService, private readonly router: Router) {}

  @Selector() public static SelectSessions(state: SessionsStateModel): Array<SessionInfo> {
    return state.sessions;
  }

  @Selector() public static SelectCurrentSession(state: SessionsStateModel): Session {
    return state.currentSession;
  }

  @Selector() public static SelectSessionFavorites(state: SessionsStateModel): Array<SessionFavorite> {
    return state.favorites;
  }

  @Selector() public static SelectSessionAccessRequests(state: SessionsStateModel): Array<AccessRequest> {
    return state.accessRequests;
  }

  @Action(SetSessions) public setSessions(ctx: StateContext<SessionsStateModel>, action: SetSessions): void {
    ctx.patchState({
      isLoaded: true,
      sessions: action.sessions,
      favorites: action.favorites,
      accessRequests: action.accessRequests
    });
  }

  @Action(AddSession) public addSession(ctx: StateContext<SessionsStateModel>, action: AddSession): void {
    ctx.patchState({
      sessions: removeDuplicates([...ctx.getState().sessions, action.session], '_id')
    });
  }

  @Action(UpdateCurrentSession) public updateCurrentSession(ctx: StateContext<SessionsStateModel>, action: UpdateCurrentSession): void {
    ctx.patchState({
      currentSession: action.session
    });
  }

  @Action(ResetCurrentSession) public resetCurrentSession(ctx: StateContext<SessionsStateModel>): void {
    ctx.patchState({
      currentSession: new Session(),
      currentDragPosition: new Position()
    });
  }

  @Action(UpdateSessionInfo) public updateSessionInfo(ctx: StateContext<SessionsStateModel>, action: UpdateSessionInfo): void {
    const { sid, title, users, groups, canBeRequested } = action.data;
    const sessions = [...ctx.getState().sessions];
    const sessionIndex = sessions.findIndex((s) => s._id === sid);
    if (sessionIndex > -1) {
      sessions[sessionIndex] = { ...sessions[sessionIndex], title, users, groups, canBeRequested };
      ctx.patchState({
        sessions
      });
    }
  }

  @Action(RemoveSession) public removeSession(ctx: StateContext<SessionsStateModel>, action: RemoveSession): void {
    ctx.patchState({
      sessions: [...ctx.getState().sessions.filter((s) => s._id !== action.sessionId)]
    });
    const urlInfo = this.userService.getUrlInfo({ id: action.sessionId, viewId: '' });
    if (urlInfo.alreadyOnSession) {
      this.router.navigate([SESSIONS_PATH]).catch(this.errorHandler.handleError);
    }
  }

  @Action(SetDragPosition) public setDragPosition(ctx: StateContext<SessionsStateModel>, action: SetDragPosition): void {
    ctx.patchState({
      currentDragPosition: action.dragPosition
    });
  }

  @Action(AddSessionFavorite) public addSessionFavorite(ctx: StateContext<SessionsStateModel>, action: AddSessionFavorite): void {
    ctx.patchState({
      favorites: removeDuplicates([...ctx.getState().favorites, action.sessionFavorite], '_id')
    });
  }

  @Action(RemoveSessionFavorite) public removeSessionFavorite(ctx: StateContext<SessionsStateModel>, action: RemoveSessionFavorite): void {
    ctx.patchState({
      favorites: [...ctx.getState().favorites.filter((s) => s._id !== action.sessionFavorite._id)]
    });
  }

  @Action(AddSessionAccessRequest) public addSessionAccessRequest(ctx: StateContext<SessionsStateModel>, action: AddSessionAccessRequest): void {
    ctx.patchState({
      accessRequests: removeDuplicates([...ctx.getState().accessRequests, action.accessRequest], '_id')
    });
  }

  @Action(RemoveSessionAccessRequest) public removeSessionAccessRequest(
    ctx: StateContext<SessionsStateModel>,
    action: RemoveSessionAccessRequest
  ): void {
    ctx.patchState({
      accessRequests: [...ctx.getState().accessRequests.filter((ar) => ar._id !== action.accessRequest._id)]
    });
  }
}

export interface SessionInfo {
  _id: string;
  title: string;
  owner: string;
  users: Array<string>;
  groups: Array<string>;
  canBeRequested: boolean;
  createdAt: number;
}
export const sessionInfoFields = ['_id', 'title', 'owner', 'users', 'groups', 'canBeRequested', 'createdAt'];

interface SessionsStateModel {
  isLoaded: boolean;
  currentSession: Session;
  currentDragPosition: Position;
  sessions: Array<SessionInfo>;
  favorites: Array<SessionFavorite>;
  accessRequests: Array<AccessRequest>;
}
