<ng-container *transloco="let t; scope: 'app'; read: 'app.socketError'">
  <ng-container *ngIf="state$ | async as state">
    <div class="container">
      <ng-container *ngIf="state.loaded && state.maxSessions > 0">
        <h1>{{ t('tooManyConnections', { maxSessions: state.maxSessions }) }}</h1>
        <h1>{{ t('automaticLogout', { timeLeft: state.timeLeft / 1000 | number: '1.0-0' }) }}</h1>
        <button mat-raised-button color="primary" title="{{ t('buttonRetryTitle') }}" (click)="onRetry()">Try to connect</button>&nbsp;
        <button mat-raised-button color="primary" title="{{ t('buttonLogoutTitle') }}" (click)="onLogout()">Logout now</button>
      </ng-container>
      <ng-container *ngIf="!state.loaded || state.maxSessions === 0">
        <ngx-g2v-progress-spinner></ngx-g2v-progress-spinner>
      </ng-container>
      <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
    </div>
  </ng-container>
</ng-container>