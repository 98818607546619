import mermaid from 'mermaid';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { map as obsMap } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MERMAID_DEFAULT_CONFIG,
  SNACKBAR_DURATION,
  SNACKBAR_HORIZONTAL_POSITION,
  SNACKBAR_VERTICAL_POSITION,
  STATE_G2VIEW_WORKSPACES
} from '@config/constants';
import { b64ToUtf8, workspaceLastPositionToWorkspaceSession } from '@config/utils';
import { Position } from '@core/state/core.actions';
import {
  ActionArea,
  AddMAItemsEvent,
  AddTABARowsEvent,
  ChartArea,
  ConnectionArea,
  DashboardButtonArea,
  DeleteAllTABARowsEvent,
  DeleteMAItemsEvent,
  DeleteTABARowsEvent,
  EmbeddedVideoArea,
  FAInitData,
  FAInitDataResponse,
  FormArea,
  FrameArea,
  G2SyntaxValidationData,
  G2SyntaxValidationG2Event,
  G2SyntaxValidatorResponse,
  GeoJsonData,
  GeoJsonDataG2Event,
  GeoJsonDataResponse,
  HighlightMeshesEvent,
  ImageArea,
  MapArea,
  MarkmapArea,
  MenuArea,
  MermaidArea,
  MI_KEY_NONE,
  MoveCameraEvent,
  NavigationArea,
  ProcessClickedAreaEvent,
  ProcessClickedAreaFrontEvent,
  Session,
  SliderArea,
  SpeechRecognitionArea,
  SRA_COMMAND_NOT_DETECTED,
  TableArea,
  TextArea,
  TimelineArea,
  ToggleLayerGroupsEvent,
  ToggleMeshesVisibilityEvent,
  ToggleWAVisibilityEvent,
  TypeInBoxArea,
  UpdateChartConfigEvent,
  UpdateChartDataEvent,
  UpdateMAItemsEvent,
  UpdateTABARowsEvent,
  UpdateTextEvent,
  Viewer3DArea,
  WorkspaceArea,
  WorkspaceAreasType,
  WorkspaceLastPosition,
  WorkspaceSession,
  ZoomMAItemsEvent,
  ZoomOnMeshesEvent
} from '@g2view/g2view-commons';
import { HotToastService, ToastPosition } from '@ngneat/hot-toast';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';
import { ContextService } from '@services/context.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { UserService } from '@services/user.service';
import { SessionsService } from '@sessions/services/sessions.service';
import { FormAreaOpenEvent } from '@workspaces/components/workspace-areas/form-area/form-area.component';

const DISABLED = 'DISABLED';
const DEFAULT = 'default';
const HIDE = 'HIDE';
const SHOW = 'SHOW';
const HIDE_AND_SHOW = 'HIDE-AND-SHOW';
const OPEN_FORM = 'OPEN-FORM';

interface WorkspaceAreaEvent {
  type: WorkspaceAreasType;
  ids: Array<string>;
  keys: Array<string>;
  hasMoved: Array<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class WorkspaceAreasService {
  private readonly httpHeaders: HttpHeaders;

  private readonly workspaceAreasCreatedSubject = new Subject<WorkspaceAreaEvent>();
  private readonly workspaceAreasUpdatedSubject = new Subject<WorkspaceAreaEvent>();

  private readonly workspaceAreasToggleVisibilitySubject = new Subject<ToggleVisibilityIndividual>();

  private readonly chartAreasUpdateChartDataSubject: Subject<UpdateChartDataEvent> = new Subject();
  private readonly chartAreasUpdateChartConfigSubject: Subject<UpdateChartConfigEvent> = new Subject();
  private readonly formAreaOpenSubject: Subject<FormAreaOpenEvent> = new Subject();
  private readonly mapAreasAddMAItemsSubject: Subject<AddMAItemsEvent> = new Subject();
  private readonly mapAreasUpdateMAItemsSubject: Subject<UpdateMAItemsEvent> = new Subject();
  private readonly mapAreasDeleteMAItemsSubject: Subject<DeleteMAItemsEvent> = new Subject();
  private readonly mapAreasToggleLayerGroupsSubject: Subject<ToggleLayerGroupsEvent> = new Subject();
  private readonly mapAreasZoomMAItemsSubject: Subject<ZoomMAItemsEvent> = new Subject();
  private readonly tableAreasAddTABARowsSubject: Subject<AddTABARowsEvent> = new Subject();
  private readonly tableAreasUpdateTABARowsSubject: Subject<UpdateTABARowsEvent> = new Subject();
  private readonly tableAreasDeleteTABARowsSubject: Subject<DeleteTABARowsEvent> = new Subject();
  private readonly tableAreasDeleteAllTABARowsSubject: Subject<DeleteAllTABARowsEvent> = new Subject();
  private readonly textAreaUpdateTextSubject: Subject<UpdateTextEvent> = new Subject();
  private readonly viewerAreaHighlightMeshesSubject: Subject<HighlightMeshesEvent> = new Subject();
  private readonly viewerAreaToggleMeshesVisibilitySubject: Subject<ToggleMeshesVisibilityEvent> = new Subject();
  private readonly viewerAreaZoomOnMeshesSubject: Subject<ZoomOnMeshesEvent> = new Subject();
  private readonly viewerAreaMoveCameraSubject: Subject<MoveCameraEvent> = new Subject();

  private readonly processClickableAreaSubject: Subject<ClickableAreaEvent> = new Subject();

  private readonly bodyClickSubject: Subject<MouseEvent> = new Subject();

  constructor(
    private readonly http: ApiHttpService,
    private readonly apiEndpointsService: ApiEndpointsService,
    private readonly store: Store,
    private readonly errorHandler: ErrorHandlerService,
    @Inject(DOCUMENT) document: Document,
    private readonly contextService: ContextService,
    private readonly sessionService: SessionsService,
    private readonly userService: UserService,
    private readonly translocoService: TranslocoService,
    private readonly toast: HotToastService
  ) {
    this.httpHeaders = new HttpHeaders({ Accept: 'application/json' });
    document.addEventListener('click', (event: MouseEvent) => {
      this.bodyClickSubject.next(event);
    });
    mermaid.initialize(MERMAID_DEFAULT_CONFIG);
  }

  get workspaceAreasCreated$(): Observable<WorkspaceAreaEvent> {
    return this.workspaceAreasCreatedSubject.asObservable();
  }

  get workspaceAreasUpdated$(): Observable<WorkspaceAreaEvent> {
    return this.workspaceAreasUpdatedSubject.asObservable();
  }

  get workspaceAreasToggleVisibility$(): Observable<ToggleVisibilityIndividual> {
    return this.workspaceAreasToggleVisibilitySubject.asObservable();
  }

  get chartAreasUpdateChartData$(): Observable<UpdateChartDataEvent> {
    return this.chartAreasUpdateChartDataSubject.asObservable();
  }

  get chartAreasUpdateChartConfig$(): Observable<UpdateChartConfigEvent> {
    return this.chartAreasUpdateChartConfigSubject.asObservable();
  }

  get mapAreasAddMAItems$(): Observable<AddMAItemsEvent> {
    return this.mapAreasAddMAItemsSubject.asObservable();
  }

  get mapAreasUpdateMAItems$(): Observable<UpdateMAItemsEvent> {
    return this.mapAreasUpdateMAItemsSubject.asObservable();
  }

  get mapAreasDeleteMAItems$(): Observable<DeleteMAItemsEvent> {
    return this.mapAreasDeleteMAItemsSubject.asObservable();
  }

  get mapAreasToggleLayerGroups$(): Observable<ToggleLayerGroupsEvent> {
    return this.mapAreasToggleLayerGroupsSubject.asObservable();
  }

  get mapAreasZoomMAItems$(): Observable<ZoomMAItemsEvent> {
    return this.mapAreasZoomMAItemsSubject.asObservable();
  }

  get tableAreasAddTABARows$(): Observable<AddTABARowsEvent> {
    return this.tableAreasAddTABARowsSubject.asObservable();
  }

  get tableAreasUpdateTABARows$(): Observable<UpdateTABARowsEvent> {
    return this.tableAreasUpdateTABARowsSubject.asObservable();
  }

  get tableAreasDeleteTABARows$(): Observable<DeleteTABARowsEvent> {
    return this.tableAreasDeleteTABARowsSubject.asObservable();
  }

  get tableAreasDeleteAllTABARows$(): Observable<DeleteAllTABARowsEvent> {
    return this.tableAreasDeleteAllTABARowsSubject.asObservable();
  }

  get textAreaUpdateText$(): Observable<UpdateTextEvent> {
    return this.textAreaUpdateTextSubject.asObservable();
  }

  get viewerAreaHighlightMeshes$(): Observable<HighlightMeshesEvent> {
    return this.viewerAreaHighlightMeshesSubject.asObservable();
  }

  get viewerAreaToggleMeshesVisibility$(): Observable<ToggleMeshesVisibilityEvent> {
    return this.viewerAreaToggleMeshesVisibilitySubject.asObservable();
  }

  get viewerAreaZoomOnMeshes$(): Observable<ZoomOnMeshesEvent> {
    return this.viewerAreaZoomOnMeshesSubject.asObservable();
  }

  get viewerAreaMoveCamera$(): Observable<MoveCameraEvent> {
    return this.viewerAreaMoveCameraSubject.asObservable();
  }

  get formAreaOpen$(): Observable<FormAreaOpenEvent> {
    return this.formAreaOpenSubject.asObservable();
  }

  get processClickableArea$(): Observable<ClickableAreaEvent> {
    return this.processClickableAreaSubject.asObservable();
  }

  get bodyClick$(): Observable<MouseEvent> {
    return this.bodyClickSubject.asObservable();
  }

  public processClick(data: ProcessClickedAreaFrontEventMandatory & Partial<ProcessClickedAreaFrontEvent>): void {
    const context = this.contextService.getContext();
    const session = context.session ? { ...context.session } : new Session();
    const workspaces = [...session.workspaces];
    const uuid = data.uuid ?? '';
    const targetUuid = data.targetUuid ?? '';
    data.role = this.userService.currentG2Role;
    data.sessionId = session._id;
    data.dashboardId = context.dashboard ? context.dashboard._id : '';
    data.dashboardViewId = context.currentViewId;
    if (!data.targetUuid && [SHOW, HIDE_AND_SHOW, OPEN_FORM].includes(data.eventType ?? '')) {
      data.eventType = DEFAULT;
    }

    switch (data.eventType) {
      case DISABLED:
        return;
      case SHOW:
        if (workspaces.filter((ws) => ws.uuid === targetUuid).length === 0) {
          workspaces.push(getNewWorkspaceSession(context.dragPosition, session.lastPositions, targetUuid));
          session.workspaces = workspaces;
        }
        this.sessionService.updateSession(this.sessionService.getSessionWithWorkspacesZIndex(session, targetUuid));
        break;
      case HIDE:
        this.sessionService.removeWorkspacesFromSession(session, [uuid]);
        break;
      case HIDE_AND_SHOW:
        if (workspaces.filter((ws) => ws.uuid === targetUuid).length === 0) {
          workspaces.push(getNewWorkspaceSession(context.dragPosition, session.lastPositions, targetUuid));
        }
        this.sessionService.removeWorkspacesFromSession(this.sessionService.getSessionWithWorkspacesZIndex({ ...session, workspaces }, targetUuid), [
          uuid
        ]);
        break;
      case OPEN_FORM:
        this.updateFormAreaOpenSubject({
          uuid,
          faKey: targetUuid,
          miKey: data.menuEntryKey ?? '',
          rowId: data.tableRowId ?? '',
          buttonId: data.tabaData && data.tabaData['button-id'] ? data.tabaData['button-id'] : ''
        });
        break;
      default:
        this.processClickedArea(completeMissingProperties(data));
        break;
    }
  }

  public emitCreatedWorkspaceAreas(type: WorkspaceAreasType, ids: Array<string>, keys: Array<string>): void {
    this.workspaceAreasCreatedSubject.next({ type, ids, keys, hasMoved: new Array(ids.length).fill(true) });
  }

  public emitUpdatedWorkspaceAreas(type: WorkspaceAreasType, ids: Array<string>, keys: Array<string>, hasMoved: Array<boolean>): void {
    this.workspaceAreasUpdatedSubject.next({ type, ids, keys, hasMoved });
  }

  public emitToggleVisibilityWorkspaceAreas(data: ToggleWAVisibilityEvent): void {
    data.visibility.forEach((v) => {
      this.workspaceAreasToggleVisibilitySubject.next({
        uuid: data.uuid,
        dbId: v.waDbId,
        hidden: v.hidden
      });
    });
  }

  public updateChartAreaUpdateChartData(data: UpdateChartDataEvent): void {
    this.chartAreasUpdateChartDataSubject.next(data);
  }

  public updateChartAreaUpdateChartConfig(data: UpdateChartConfigEvent): void {
    this.chartAreasUpdateChartConfigSubject.next(data);
  }

  public updateMapAreaAddMAItems(data: AddMAItemsEvent): void {
    this.mapAreasAddMAItemsSubject.next(data);
  }

  public updateMapAreaUpdateMAItems(data: UpdateMAItemsEvent): void {
    this.mapAreasUpdateMAItemsSubject.next(data);
  }

  public updateMapAreaDeleteMAItems(data: DeleteMAItemsEvent): void {
    this.mapAreasDeleteMAItemsSubject.next(data);
  }

  public updateMapAreaToggleLayerGroups(data: ToggleLayerGroupsEvent): void {
    this.mapAreasToggleLayerGroupsSubject.next(data);
  }

  public updateMapAreaZoomMAItems(data: ZoomMAItemsEvent): void {
    this.mapAreasZoomMAItemsSubject.next(data);
  }

  public updateTableAreaAddTABARows(data: AddTABARowsEvent): void {
    this.tableAreasAddTABARowsSubject.next(data);
  }

  public updateTableAreaUpdateTABARows(data: UpdateTABARowsEvent): void {
    this.tableAreasUpdateTABARowsSubject.next(data);
  }

  public updateTableAreaDeleteTABARows(data: DeleteTABARowsEvent): void {
    this.tableAreasDeleteTABARowsSubject.next(data);
  }

  public updateTableAreaDeleteAllTABARows(data: DeleteAllTABARowsEvent): void {
    this.tableAreasDeleteAllTABARowsSubject.next(data);
  }

  public updateTextAreaUpdateText(data: UpdateTextEvent): void {
    this.textAreaUpdateTextSubject.next(data);
  }

  public updateViewerAreaHighlightMeshes(data: HighlightMeshesEvent): void {
    this.viewerAreaHighlightMeshesSubject.next(data);
  }

  public updateViewerAreaToggleMeshesVisibility(data: ToggleMeshesVisibilityEvent): void {
    this.viewerAreaToggleMeshesVisibilitySubject.next(data);
  }

  public updateViewerAreaZoomOnMeshes(data: ZoomOnMeshesEvent): void {
    this.viewerAreaZoomOnMeshesSubject.next(data);
  }

  public updateViewerAreaMoveCamera(data: MoveCameraEvent): void {
    this.viewerAreaMoveCameraSubject.next(data);
  }

  public updateProcessClickableArea(data: ClickableAreaEvent): void {
    this.processClickableAreaSubject.next(data);
  }

  public async getFAInitialData(uuid: string, faKey: string, faType: string, miKey: string, rowId: string, buttonId: string): Promise<FAInitData> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: any = {};

    try {
      const response = await this.fetchFAInitialData(uuid, faKey, faType, miKey, rowId, buttonId);
      if (!response || response.code !== 200) {
        this.errorHandler.handleError(response.message ? response.message : 'Error', true);
      }
      data = JSON.parse(b64ToUtf8(response.data));
    } catch (err: any) {
      this.errorHandler.handleError(err);
    }

    return { data };
  }

  public async getG2SyntaxValidation(text: string): Promise<G2SyntaxValidationData> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: G2SyntaxValidationData = {
      ok: false,
      endableP: false,
      description: false,
      errorIndex: false,
      longestCommonCompletion: false,
      categoryChoices: [],
      completionChoices: [],
      tokenCompleteP: false,
      tokenData: []
    };

    try {
      const response = await this.fetchG2SyntaxValidation(text);
      if (!response || response.code !== 200) {
        this.errorHandler.handleError(response.message ? response.message : 'Error', true);
      }
      data = response.data;
    } catch (err: any) {
      this.errorHandler.handleError(err);
    }

    return data;
  }

  public async loadGeoJsonFile(filename: string): Promise<GeoJsonData> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: any = {};

    try {
      const response = await this.fetchGeoJsonFile(filename);
      if (!response || response.code !== 200) {
        this.errorHandler.handleError(response.message ? response.message : 'Error', true);
      }
      data = response.data;
    } catch (err: any) {
      this.errorHandler.handleError(err);
    }

    return { data };
  }

  public getAllActionAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<ActionArea>>((state) => state[STATE_G2VIEW_WORKSPACES].aas).map((aa) => aa._id ?? '');
  }

  public getAllChartAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<ChartArea>>((state) => state[STATE_G2VIEW_WORKSPACES].cas).map((ca) => ca._id ?? '');
  }

  public getAllConnectionAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<ConnectionArea>>((state) => state[STATE_G2VIEW_WORKSPACES].conas).map((cona) => cona._id ?? '');
  }

  public getAllDashboardButtonAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<DashboardButtonArea>>((state) => state[STATE_G2VIEW_WORKSPACES].dbas).map((dba) => dba._id ?? '');
  }

  public getAllEmbeddedVideoAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<EmbeddedVideoArea>>((state) => state[STATE_G2VIEW_WORKSPACES].evas).map((eva) => eva._id ?? '');
  }

  public getAllFormAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<FormArea>>((state) => state[STATE_G2VIEW_WORKSPACES].fas).map((fa) => fa._id ?? '');
  }

  public getAllFrameAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<FrameArea>>((state) => state[STATE_G2VIEW_WORKSPACES].fras).map((fra) => fra._id ?? '');
  }

  public getAllImageAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<ImageArea>>((state) => state[STATE_G2VIEW_WORKSPACES].imas).map((ima) => ima._id ?? '');
  }

  public getAllMarkmapAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<MarkmapArea>>((state) => state[STATE_G2VIEW_WORKSPACES].maras).map((mara) => mara._id ?? '');
  }

  public getAllMapAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<MapArea>>((state) => state[STATE_G2VIEW_WORKSPACES].mas).map((ma) => ma._id ?? '');
  }

  public getAllMenuAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<MenuArea>>((state) => state[STATE_G2VIEW_WORKSPACES].menas).map((mena) => mena._id ?? '');
  }

  public getAllMermaidAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<MermaidArea>>((state) => state[STATE_G2VIEW_WORKSPACES].meras).map((mera) => mera._id ?? '');
  }

  public getAllNavigationAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<NavigationArea>>((state) => state[STATE_G2VIEW_WORKSPACES].nas).map((na) => na._id ?? '');
  }

  public getAllSliderAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<SliderArea>>((state) => state[STATE_G2VIEW_WORKSPACES].sas).map((sa) => sa._id ?? '');
  }

  public getAllSpeechRecognitionAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<SpeechRecognitionArea>>((state) => state[STATE_G2VIEW_WORKSPACES].sras).map((sra) => sra._id ?? '');
  }

  public getAllTableAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<TableArea>>((state) => state[STATE_G2VIEW_WORKSPACES].tabas).map((taba) => taba._id ?? '');
  }

  public getAllTextAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<TextArea>>((state) => state[STATE_G2VIEW_WORKSPACES].tas).map((ta) => ta._id ?? '');
  }

  public getAllTimelineAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<TimelineArea>>((state) => state[STATE_G2VIEW_WORKSPACES].timas).map((tima) => tima._id ?? '');
  }

  public getAllTypeInBoxAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<TypeInBoxArea>>((state) => state[STATE_G2VIEW_WORKSPACES].tibs).map((tib) => tib._id ?? '');
  }

  public getAllViewer3DAreaIdsInStore(): Array<string> {
    return this.store.selectSnapshot<Array<Viewer3DArea>>((state) => state[STATE_G2VIEW_WORKSPACES].vas).map((va) => va._id ?? '');
  }

  public getWorkspaceAreaInStore(id: string, type: WorkspaceAreasType): WorkspaceArea | undefined {
    switch (type) {
      case 'aas':
        return this.getActionAreaInStore(id);
      case 'cas':
        return this.getChartAreaInStore(id);
      case 'conas':
        return this.getConnectionAreaInStore(id);
      case 'dbas':
        return this.getDashboardButtonAreaInStore(id);
      case 'evas':
        return this.getEmbeddedVideoAreaInStore(id);
      case 'fas':
        return this.getFormAreaInStore(id);
      case 'fras':
        return this.getFrameAreaInStore(id);
      case 'imas':
        return this.getImageAreaInStore(id);
      case 'maras':
        return this.getMarkmapAreaInStore(id);
      case 'mas':
        return this.getMapAreaInStore(id);
      case 'menas':
        return this.getMenuAreaInStore(id);
      case 'meras':
        return this.getMermaidAreaInStore(id);
      case 'nas':
        return this.getNavigationAreaInStore(id);
      case 'sas':
        return this.getSliderAreaInStore(id);
      case 'sras':
        return this.getSpeechRecognitionAreaInStore(id);
      case 'tabas':
        return this.getTableAreaInStore(id);
      case 'tas':
        return this.getTextAreaInStore(id);
      case 'timas':
        return this.getTimelineAreaInStore(id);
      case 'tibs':
        return this.getTypeInBoxAreaInStore(id);
      case 'vas':
        return this.getViewer3DAreaInStore(id);
      default:
        return undefined;
    }
  }

  public getWorkspaceAreasInDB(ids: Array<string>, type: WorkspaceAreasType): Promise<Array<WorkspaceArea>> {
    switch (type) {
      case 'aas':
        return this.getActionAreas(ids);
      case 'cas':
        return this.getChartAreas(ids);
      case 'conas':
        return this.getConnectionAreas(ids);
      case 'dbas':
        return this.getDashboardButtonAreas(ids);
      case 'evas':
        return this.getEmbeddedVideoAreas(ids);
      case 'fas':
        return this.getFormAreas(ids);
      case 'fras':
        return this.getFrameAreas(ids);
      case 'imas':
        return this.getImageAreas(ids);
      case 'maras':
        return this.getMarkmapAreas(ids);
      case 'mas':
        return this.getMapAreas(ids);
      case 'menas':
        return this.getMenuAreas(ids);
      case 'meras':
        return this.getMermaidAreas(ids);
      case 'nas':
        return this.getNavigationAreas(ids);
      case 'sas':
        return this.getSliderAreas(ids);
      case 'sras':
        return this.getSpeechRecognitionAreas(ids);
      case 'tabas':
        return this.getTableAreas(ids);
      case 'tas':
        return this.getTextAreas(ids);
      case 'timas':
        return this.getTimelineAreas(ids);
      case 'tibs':
        return this.getTypeInBoxAreas(ids);
      case 'vas':
        return this.getViewer3DAreas(ids);
    }
  }

  public async getActionAreas(ids: Array<string>): Promise<Array<ActionArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('actionAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getChartAreas(ids: Array<string>): Promise<Array<ChartArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('chartAreas', ids)).pipe(obsMap((response) => response.data)));
  }

  public async getConnectionAreas(ids: Array<string>): Promise<Array<ConnectionArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('connectionAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getDashboardButtonAreas(ids: Array<string>): Promise<Array<DashboardButtonArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('dashboardButtonAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getEmbeddedVideoAreas(ids: Array<string>): Promise<Array<EmbeddedVideoArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('embeddedVideoAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getFormAreas(ids: Array<string>): Promise<Array<FormArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('formAreas', ids)).pipe(obsMap((response) => response.data)));
  }

  public async getFrameAreas(ids: Array<string>): Promise<Array<FrameArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('frameAreas', ids)).pipe(obsMap((response) => response.data)));
  }

  public async getImageAreas(ids: Array<string>): Promise<Array<ImageArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('imageAreas', ids)).pipe(obsMap((response) => response.data)));
  }

  public async getMarkmapAreas(ids: Array<string>): Promise<Array<MarkmapArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('markmapAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getMapAreas(ids: Array<string>): Promise<Array<MapArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('mapAreas', ids)).pipe(obsMap((response) => response.data)));
  }

  public async getMenuAreas(ids: Array<string>): Promise<Array<MenuArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('menuAreas', ids)).pipe(obsMap((response) => response.data)));
  }

  public async getMermaidAreas(ids: Array<string>): Promise<Array<MermaidArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('mermaidAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getNavigationAreas(ids: Array<string>): Promise<Array<NavigationArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('navigationAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getSliderAreas(ids: Array<string>): Promise<Array<SliderArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('sliderAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getSpeechRecognitionAreas(ids: Array<string>): Promise<Array<SpeechRecognitionArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('speechRecognitionAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getTableAreas(ids: Array<string>): Promise<Array<TableArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('tableAreas', ids)).pipe(obsMap((response) => response.data)));
  }

  public async getTextAreas(ids: Array<string>): Promise<Array<TextArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('textAreas', ids)).pipe(obsMap((response) => response.data)));
  }

  public async getTimelineAreas(ids: Array<string>): Promise<Array<TimelineArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('timelineAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getTypeInBoxAreas(ids: Array<string>): Promise<Array<TypeInBoxArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('typeInBoxAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public async getViewer3DAreas(ids: Array<string>): Promise<Array<Viewer3DArea>> {
    return ids.length === 0
      ? []
      : lastValueFrom(
          this.http.get(this.apiEndpointsService.getWorkspaceAreasEndpoint('viewer3DAreas', ids)).pipe(obsMap((response) => response.data))
        );
  }

  public updateFormAreaOpenSubject(data: FormAreaOpenEvent): void {
    data.miKey = data.miKey === '' ? MI_KEY_NONE : data.miKey;
    data.rowId = data.rowId === '' ? MI_KEY_NONE : data.rowId;
    data.buttonId = data.buttonId === '' ? MI_KEY_NONE : data.buttonId;
    this.formAreaOpenSubject.next(data);
  }

  // Send a request to G2 to notify that a clickable area has been clicked.
  private processClickedArea(data: ProcessClickedAreaFrontEvent): void {
    const postData: ProcessClickedAreaEvent = {
      'user-id': this.userService.currentUserId,
      'socket-id': this.userService.currentSocketId,
      uuid: data.uuid,
      'workspace-left': data.workspaceLeft,
      'workspace-top': data.workspaceTop,
      'workspace-zoom': data.workspaceZoom,
      'wa-type': data.waType,
      'event-type': data.eventType,
      key: data.key,
      role: data.role,
      'session-id': data.sessionId,
      'dashboard-id': data.dashboardId,
      'dashboard-view-id': data.dashboardViewId,
      'menu-entry-key': data.menuEntryKey,
      'table-row-id': data.tableRowId,
      'tib-data': data.tibData,
      'fa-data': data.faData,
      'fa-id': data.faId,
      'sa-data': data.saData,
      'sa-range-data': data.saRangeData,
      'sra-data': data.sraData,
      'mera-node-id': data.meraNodeId,
      'taba-data': data.tabaData,
      'ma-id': data.maId,
      'ma-menu-data': data.maMenuData,
      'tima-data': { 'item-ids': data.timaData.itemIds },
      'dyn-form-data': data.dynFormData
    };
    this.http.post(this.apiEndpointsService.processClickedAreaEndpoint(), postData, { headers: this.httpHeaders }).subscribe({
      next: (result) => {
        if (result.message && result.message === SRA_COMMAND_NOT_DETECTED) {
          this.toast.warning(this.translocoService.translate('workspaceAreas.speechRecognitionArea.toast.sraCommandNotDetected', {}, 'workspaces'), {
            duration: SNACKBAR_DURATION,
            position: `${SNACKBAR_VERTICAL_POSITION}-${SNACKBAR_HORIZONTAL_POSITION}` as ToastPosition
          });
        }
        this.updateProcessClickableArea({
          uuid: data.uuid,
          key: data.key,
          status: 'ACK'
        });
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
    this.updateProcessClickableArea({
      uuid: data.uuid,
      key: data.key,
      status: 'SENT'
    });
  }

  private getActionAreaInStore(id: string): ActionArea | undefined {
    return this.store.selectSnapshot<Array<ActionArea>>((state) => state[STATE_G2VIEW_WORKSPACES].aas).find((aa) => aa._id === id);
  }

  private getChartAreaInStore(id: string): ChartArea | undefined {
    return this.store.selectSnapshot<Array<ChartArea>>((state) => state[STATE_G2VIEW_WORKSPACES].cas).find((ca) => ca._id === id);
  }

  private getConnectionAreaInStore(id: string): ConnectionArea | undefined {
    return this.store.selectSnapshot<Array<ConnectionArea>>((state) => state[STATE_G2VIEW_WORKSPACES].conas).find((cona) => cona._id === id);
  }

  private getDashboardButtonAreaInStore(id: string): DashboardButtonArea | undefined {
    return this.store.selectSnapshot<Array<DashboardButtonArea>>((state) => state[STATE_G2VIEW_WORKSPACES].dbas).find((dba) => dba._id === id);
  }

  private getEmbeddedVideoAreaInStore(id: string): EmbeddedVideoArea | undefined {
    return this.store.selectSnapshot<Array<EmbeddedVideoArea>>((state) => state[STATE_G2VIEW_WORKSPACES].evas).find((eva) => eva._id === id);
  }

  private getFormAreaInStore(id: string): FormArea | undefined {
    return this.store.selectSnapshot<Array<FormArea>>((state) => state[STATE_G2VIEW_WORKSPACES].fas).find((fa) => fa._id === id);
  }

  private getFrameAreaInStore(id: string): FrameArea | undefined {
    return this.store.selectSnapshot<Array<FrameArea>>((state) => state[STATE_G2VIEW_WORKSPACES].fras).find((fra) => fra._id === id);
  }

  private getImageAreaInStore(id: string): ImageArea | undefined {
    return this.store.selectSnapshot<Array<ImageArea>>((state) => state[STATE_G2VIEW_WORKSPACES].imas).find((ima) => ima._id === id);
  }

  private getMarkmapAreaInStore(id: string): MarkmapArea | undefined {
    return this.store.selectSnapshot<Array<MarkmapArea>>((state) => state[STATE_G2VIEW_WORKSPACES].maras).find((mara) => mara._id === id);
  }

  private getMapAreaInStore(id: string): MapArea | undefined {
    return this.store.selectSnapshot<Array<MapArea>>((state) => state[STATE_G2VIEW_WORKSPACES].mas).find((ma) => ma._id === id);
  }

  private getMenuAreaInStore(id: string): MenuArea | undefined {
    return this.store.selectSnapshot<Array<MenuArea>>((state) => state[STATE_G2VIEW_WORKSPACES].menas).find((mena) => mena._id === id);
  }

  private getMermaidAreaInStore(id: string): MermaidArea | undefined {
    return this.store.selectSnapshot<Array<MermaidArea>>((state) => state[STATE_G2VIEW_WORKSPACES].meras).find((mera) => mera._id === id);
  }

  private getNavigationAreaInStore(id: string): NavigationArea | undefined {
    return this.store.selectSnapshot<Array<NavigationArea>>((state) => state[STATE_G2VIEW_WORKSPACES].nas).find((na) => na._id === id);
  }

  private getSliderAreaInStore(id: string): SliderArea | undefined {
    return this.store.selectSnapshot<Array<SliderArea>>((state) => state[STATE_G2VIEW_WORKSPACES].sas).find((sa) => sa._id === id);
  }

  private getSpeechRecognitionAreaInStore(id: string): SpeechRecognitionArea | undefined {
    return this.store.selectSnapshot<Array<SpeechRecognitionArea>>((state) => state[STATE_G2VIEW_WORKSPACES].sras).find((sra) => sra._id === id);
  }

  private getTableAreaInStore(id: string): TableArea | undefined {
    return this.store.selectSnapshot<Array<TableArea>>((state) => state[STATE_G2VIEW_WORKSPACES].tabas).find((taba) => taba._id === id);
  }

  private getTextAreaInStore(id: string): TextArea | undefined {
    return this.store.selectSnapshot<Array<TextArea>>((state) => state[STATE_G2VIEW_WORKSPACES].tas).find((ta) => ta._id === id);
  }

  private getTimelineAreaInStore(id: string): TimelineArea | undefined {
    return this.store.selectSnapshot<Array<TimelineArea>>((state) => state[STATE_G2VIEW_WORKSPACES].timas).find((tima) => tima._id === id);
  }

  private getTypeInBoxAreaInStore(id: string): TypeInBoxArea | undefined {
    return this.store.selectSnapshot<Array<TypeInBoxArea>>((state) => state[STATE_G2VIEW_WORKSPACES].tibs).find((tib) => tib._id === id);
  }

  private getViewer3DAreaInStore(id: string): Viewer3DArea | undefined {
    return this.store.selectSnapshot<Array<Viewer3DArea>>((state) => state[STATE_G2VIEW_WORKSPACES].vas).find((va) => va._id === id);
  }

  private async fetchFAInitialData(
    uuid: string,
    faKey: string,
    faType: string,
    miKey: string,
    rowId: string,
    buttonId: string
  ): Promise<FAInitDataResponse> {
    return lastValueFrom(
      this.http.get(this.apiEndpointsService.getFAInitialDataEndpoint(uuid, faKey, faType, miKey, rowId, buttonId), { headers: this.httpHeaders })
    );
  }

  private async fetchG2SyntaxValidation(text: string): Promise<G2SyntaxValidatorResponse> {
    const postData: G2SyntaxValidationG2Event = {
      text
    };
    return lastValueFrom(this.http.post(this.apiEndpointsService.getG2SyntaxValidatorEndpoint(), postData, { headers: this.httpHeaders }));
  }

  private async fetchGeoJsonFile(filename: string): Promise<GeoJsonDataResponse> {
    const postData: GeoJsonDataG2Event = {
      filename
    };
    return lastValueFrom(this.http.post(this.apiEndpointsService.getGeoJsonFileEndpoint(), postData, { headers: this.httpHeaders }));
  }
}

const getNewWorkspaceSession = (
  currentDragPosition: Position,
  workspacesPositions: Array<WorkspaceLastPosition>,
  targetUuid: string
): WorkspaceSession => {
  const wsIndex = workspacesPositions.findIndex((ws) => ws.uuid === targetUuid);
  const position = wsIndex > -1 ? workspacesPositions[wsIndex] : currentDragPosition;
  return workspaceLastPositionToWorkspaceSession(targetUuid, position);
};

const completeMissingProperties = (data: Partial<ProcessClickedAreaFrontEvent>): ProcessClickedAreaFrontEvent => ({
  uuid: data.uuid ?? '',
  workspaceLeft: data.workspaceLeft ?? 0,
  workspaceTop: data.workspaceTop ?? 0,
  workspaceZoom: data.workspaceZoom ?? 1,
  waType: data.waType ?? 'aas',
  key: data.key ?? '',
  role: data.role ?? '',
  sessionId: data.sessionId ?? '',
  dashboardId: data.dashboardId ?? '',
  dashboardViewId: data.dashboardViewId ?? '',
  menuEntryKey: data.menuEntryKey ?? '',
  tableRowId: data.tableRowId ?? '',
  tibData: data.tibData ?? '',
  tabaData: data.tabaData ?? { 'button-id': '', 'row-ids': [] },
  maId: data.maId ?? '',
  maMenuData: data.maMenuData ?? {
    'lat-lng': { lat: 0, lng: 0 },
    'layer-point': { x: 0, y: 0 },
    'container-point': { x: 0, y: 0 }
  },
  faData: data.faData ?? {},
  faId: data.faId ?? '',
  saData: data.saData ?? 0,
  saRangeData: data.saRangeData ?? [0, 0],
  sraData: data.sraData ?? { transcript: '', lang: 'en-US', command: '', confidence: 0 },
  meraNodeId: data.meraNodeId ?? '',
  eventType: data.eventType ?? '',
  targetUuid: data.targetUuid ?? '',
  timaData: data.timaData ?? { itemIds: [] },
  dynFormData: data.dynFormData ?? {}
});

export const getWAId = (waKey: string): string => `WA-${waKey}`;

interface ToggleVisibilityIndividual {
  uuid: string;
  dbId: string;
  hidden: boolean;
}

interface ClickableAreaEvent {
  uuid: string;
  key: string;
  status: 'SENT' | 'ACK';
}

export type ProcessClickedAreaFrontEventMandatory = {
  uuid: string;
  workspaceLeft: number;
  workspaceTop: number;
  workspaceZoom: number;
  waType: WorkspaceAreasType | 'dyn-form';
  key: string;
  eventType: string;
};
