import { Position } from '@core/state/core.actions';
import { environment } from '@environments/environment';
import { TypeInBoxArea, WorkspaceLastPosition, WorkspaceSession } from '@g2view/g2view-commons';
import { BubblePoint, Points, PointsExpanded } from '@workspaces/components/workspace-areas/chart-area/chart-area.component';

export const isLocal = (): boolean => environment.envName === 'LOCAL';
export const BACKEND_NAME = 'backend';

export const refreshPage = (): void => {
  location.reload();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeDuplicates = (myArr: Array<any>, prop: string): Array<any> =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  myArr.filter((obj: { [x: string]: any }, pos: any, arr: Array<any>) => arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos);

export const logBaseX = (x: number, n: number): number => (n === 1 ? x : x <= 0 || n <= 0 ? 0 : Math.log(x) / Math.log(n));

export const areStringArraysEqual = (a: Array<string>, b: Array<string>): boolean =>
  JSON.stringify([...a].sort()).toLowerCase() === JSON.stringify([...b].sort()).toLowerCase();

export const filterOnlyUnique = (value: string, index: number, self: Array<string>): boolean => self.indexOf(value) === index;

export const intersectArray = (array1: Array<string>, array2: Array<string>): Array<string> => array1.filter((value) => array2.includes(value));

export const isTabVisible = (): boolean => !document.hidden;

export const b64ToUtf8 = (b64: string): string => decodeURIComponent(escape(window.atob(b64)));
export const utf8ToB64 = (str: string): string => {
  try {
    return btoa(str).toString();
  } catch (err) {
    return '';
  }
};

export const getKeyOfMap = (map: Map<string, string>, searchValue: string): string | undefined => {
  for (const [key, value] of map.entries()) {
    if (value === searchValue) return key;
  }
  return undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const deepFind = (obj: any, path: Array<string>): any => {
  let current = obj;
  for (let i = 0; i < path.length; ++i) {
    if (current[path[i]] == undefined) {
      return undefined;
    } else {
      current = current[path[i]];
    }
  }
  return current;
};

export const getWindowWidth = (): number => Math.min(window.innerWidth, window.outerWidth);
export const getWindowHeight = (): number => Math.min(window.innerHeight, window.outerHeight);

export const g2RoleToRoleTransform = (role: string): string =>
  role.startsWith(environment.keycloak.g2Namespace) ? role.replace(environment.keycloak.g2Namespace, '') : role;

export const roleToG2RoleTransform = (role: string): string =>
  role.startsWith(environment.keycloak.g2Namespace) ? role : environment.keycloak.g2Namespace + role;

export const validateTIBData = (
  value: string,
  tib: TypeInBoxArea,
  errorMsgTranslated: { quantity: string; integer: string; default: string }
): { isValid: boolean; errorMsg: string } => {
  if (!value) {
    return { isValid: false, errorMsg: 'Please enter a value' };
  }
  let errorMsg = errorMsgTranslated.default;
  let isTypeOk = false;
  switch (tib.inputDataType) {
    case 'FLOAT':
    case 'QUANTITY':
      if (/^-?\d+(\.\d+)?$/.test(value)) {
        const nb = +value;
        if (nb >= tib.min && nb <= tib.max) {
          isTypeOk = true;
        } else {
          errorMsg = errorMsgTranslated.quantity;
        }
      }
      break;
    case 'INTEGER':
    case 'LONG':
      if (/^-?\d+$/.test(value)) {
        const nb = +value;
        if (nb >= tib.min && nb <= tib.max) {
          isTypeOk = true;
        } else {
          errorMsg = errorMsgTranslated.integer;
        }
      }
      break;
    case 'SYMBOL':
      if (/^[a-zA-Z0-9-_.]+$/.test(value)) {
        isTypeOk = true;
      }
      break;
    case 'TEXT':
      isTypeOk = true;
      break;
    default:
      break;
  }

  return { isValid: isTypeOk, errorMsg };
};

export const hasEnoughRoles = (
  currentClientRoles: Array<string>,
  currentRealmRoles: Array<string>,
  currentAccountRoles: Array<string>,
  requiredClientRoles: Array<string>,
  requiredRealmRoles: Array<string>,
  requiredAccountRoles: Array<string>
): boolean =>
  requiredClientRoles.every((role) => currentClientRoles.indexOf(role) > -1) &&
  requiredRealmRoles.every((role) => currentRealmRoles.indexOf(role) > -1) &&
  requiredAccountRoles.every((role) => currentAccountRoles.indexOf(role) > -1);

/*export const isLeftClick = (event: MouseEvent): boolean => {
  if ('which' in event && event.which === 1) {
    // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
    return true;
  }
  if ('button' in event && event.button === 0) {
    // IE, Opera
    return true;
  }

  return false;
};

export const isRightClick = (event: MouseEvent): boolean => {
  if ('which' in event && event.which === 3) {
    // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
    return true;
  }
  if ('button' in event && event.button === 2) {
    // IE, Opera
    return true;
  }

  return false;
};

export const animateCSS = (node: Element, animationName: string, callback: () => void): void => {
  node.classList.add('animated', 'faster', animationName);
  const handleAnimationEnd = () => {
    node.classList.remove('animated', 'faster', animationName);
    node.removeEventListener('animationend', handleAnimationEnd);
    if (typeof callback === 'function') {
      callback();
    }
  };
  node.addEventListener('animationend', handleAnimationEnd);
};
*/

export const transposePoints = (data: PointsExpanded): Points => Object.keys(data[0]).map((c) => data.map((r) => formatPoint(r[parseInt(c)])));

const formatPoint = (p: string | number | Array<string | number> | BubblePoint, delimiter = ','): string => {
  if (typeof p === 'string') {
    return p;
  } else if (typeof p === 'number') {
    return p.toString();
  } else if (Array.isArray(p)) {
    return p.join(delimiter);
  } else {
    return [p.y, p.z].join(delimiter);
  }
};

export const jsonToCSV = (data: Points, headerList: Array<string>, delimiter = ';'): string => {
  let csv = headerList.join(delimiter) + '\r\n';
  data.forEach((r) => {
    csv += r.join(delimiter) + '\r\n';
  });
  return csv;
};

export const getFormattedDate = (timestamp = Date.now()): string => {
  const date = new Date(timestamp);
  return `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}_${('0' + date.getHours()).slice(-2)}${(
    '0' + date.getMinutes()
  ).slice(-2)}${('0' + date.getSeconds()).slice(-2)}`;
};

export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export const randomInRange = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const replaceAllWithMapping = (str: string, mapObj: any) => {
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
  return str.replace(re, (matched) => mapObj[matched]);
};

export const workspaceLastPositionToWorkspaceSession = (uuid: string, lastPosition: WorkspaceLastPosition | Position): WorkspaceSession => {
  const ws: WorkspaceSession = {
    uuid,
    ...lastPosition,
    zoom: 1,
    state: 'ui',
    zIndex: 0,
    socketIds: []
  };
  return {
    uuid: ws.uuid,
    top: ws.top,
    left: ws.left,
    zoom: ws.zoom,
    state: ws.state,
    zIndex: ws.zIndex,
    socketIds: ws.socketIds
  };
};
