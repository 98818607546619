import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_CONFIG } from '@config/constants';
import { FormArea, MI_KEY_NONE } from '@g2view/g2view-commons';
import { FormAreaResponse } from '@interfaces/modal-responses';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ShortcutsService } from '@services/shortcuts.service';
import {
  DialogFAData,
  FormAreaModalComponent,
  getFileFromModel
} from '@workspaces/components/workspace-areas/form-area/form-area-modal/form-area-modal.component';
import { FileUploadResponse, FormAreaService } from '@workspaces/services/form-area.service';
import { WorkspaceAreasService } from '@workspaces/services/workspace-areas.service';

const DYNAMIC_FORM_EVENT_TYPE = 'DYNAMIC-FORM';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormAreaService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly workspaceAreasService: WorkspaceAreasService,
    private readonly formAreaService: FormAreaService,
    private readonly shortcutsService: ShortcutsService,
    private readonly errorHandler: ErrorHandlerService
  ) {}

  public displayForm(
    id: string,
    elements: Array<FormlyFieldConfig> = [],
    options: FormlyFormOptions = {},
    dialogMinWidth?: number,
    dialogTitle?: string,
    initData?: any,
    position?: { top: number; left: number }
  ): void {
    this.shortcutsService.setActive(false);
    const formArea: FormArea = {
      ...new FormArea(),
      dialogTitle,
      dialogMinWidth,
      options,
      elements
    };
    const minWidth = formArea.dialogMinWidth ? `min(${formArea.dialogMinWidth}px, 95%)` : MAT_DIALOG_CONFIG.minWidth;
    const data: DialogFAData = { fa: formArea, miKey: MI_KEY_NONE, rowId: MI_KEY_NONE, buttonId: MI_KEY_NONE, initData: initData ?? {} };
    const dialogFA = this.dialog.open(FormAreaModalComponent, { ...MAT_DIALOG_CONFIG, minWidth, data });
    if (position) {
      dialogFA.updatePosition({ top: `${position.top}px`, left: `${position.left}px` });
    }
    dialogFA.afterClosed().subscribe({
      next: (response: FormAreaResponse) => {
        if (response) {
          const sendFilePromises: Array<Promise<FileUploadResponse>> = [];
          response.fileConfigs.forEach((file) => {
            const fileUploaded = getFileFromModel(response.model, file.id);
            if (fileUploaded) {
              sendFilePromises.push(this.formAreaService.postFile(fileUploaded));
            }
          });
          // Send files to server then send processClick with the id of each files
          Promise.all(sendFilePromises).then((sendFileResponse) => {
            response.fileConfigs.forEach((file, index) => {
              response.model[file.id] = sendFileResponse[index];
            });
            this.workspaceAreasService.processClick({
              uuid: '',
              workspaceLeft: 0,
              workspaceTop: 0,
              workspaceZoom: 1,
              waType: 'dyn-form',
              key: id,
              eventType: DYNAMIC_FORM_EVENT_TYPE,
              dynFormData: response.model
            });
          }, this.errorHandler.handleError);
        }
        this.shortcutsService.setActive(true);
      },
      error: (err: unknown) => this.errorHandler.handleError(err)
    });
  }
}
