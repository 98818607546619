import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MermaidConfig } from '@components/mermaid-chart/mermaid-chart.component';
import { ModalConfig } from '@interfaces/modal-config';

@Component({
  selector: 'ngx-g2v-mermaid-chart-modal',
  templateUrl: './mermaid-chart-modal.component.html',
  styleUrls: ['./mermaid-chart-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MermaidChartModalComponent implements AfterViewInit {
  public mermaidConfig: MermaidConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly config: ModalConfig<MermaidConfig>,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialogRef: MatDialogRef<MermaidChartModalComponent>
  ) {
    this.mermaidConfig = { ...config.initConfig };
  }

  public ngAfterViewInit(): void {
    this.config.config$.subscribe({
      next: (config) => {
        this.mermaidConfig = { ...config };
        this.cdr.detectChanges();
      }
    });
  }

  public onExitModal(): void {
    this.dialogRef.close();
  }
}
