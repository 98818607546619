import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';
import { STATE_G2VIEW_CORE } from '@config/constants';
import { SetCurrentG2ModulesLoaded } from '@core/state/core.actions';
import { G2PingData, PING_G2_INTERVAL_UPDATE_MS, PING_G2_THRESHOLD_OFFLINE_MIN } from '@g2view/g2view-commons';
import { Store } from '@ngxs/store';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';

const PING_THRESHOLD_OFFLINE_MS = PING_G2_THRESHOLD_OFFLINE_MIN * 60 * 1000; // If G2 didn't respond more than this time, display an error

@Injectable({
  providedIn: 'root'
})
export class ServerService implements OnDestroy {
  private g2PingIntervalId: NodeJS.Timeout;
  private _lastG2Ping = 0;
  private g2Status = new BehaviorSubject<g2Status>('check');

  constructor(private readonly http: ApiHttpService, private readonly store: Store, private readonly apiEndpointsService: ApiEndpointsService) {
    this.updateLastG2Ping();
    this.g2PingIntervalId = setInterval(() => {
      this.updateLastG2Ping();
    }, PING_G2_INTERVAL_UPDATE_MS);
    this.updateCurrentG2ModulesLoaded();
  }

  get g2Status$(): Observable<g2Status> {
    return this.g2Status.asObservable();
  }

  get currentG2ModulesLoaded(): Array<string> {
    return this.store.selectSnapshot<Array<string>>((state) => state[STATE_G2VIEW_CORE].currentG2ModulesLoaded);
  }

  public ngOnDestroy(): void {
    clearInterval(this.g2PingIntervalId);
  }

  public getLastG2PingData(): Promise<Array<G2PingData>> {
    return lastValueFrom(this.http.get(this.apiEndpointsService.getG2LastPingDataEndpoint()));
  }

  public updateCurrentG2ModulesLoaded(): void {
    this.fetchG2CurrentModules().then((modules) => {
      this.setCurrentG2ModulesLoaded(modules);
    });
  }

  public setCurrentG2ModulesLoaded(modules: Array<string>): void {
    this.store.dispatch(new SetCurrentG2ModulesLoaded(modules));
  }

  private updateLastG2Ping(): void {
    this.fetchLastG2Ping()
      .then((timestamp) => {
        this._lastG2Ping = timestamp;
      })
      .finally(() => {
        const timeSinceLastPing = new Date().getTime() - this._lastG2Ping;
        this.g2Status.next(timeSinceLastPing < PING_THRESHOLD_OFFLINE_MS ? 'online' : 'offline');
      });
  }

  private fetchLastG2Ping(): Promise<number> {
    return lastValueFrom(this.http.get(this.apiEndpointsService.getG2LastPingEndpoint()));
  }

  private fetchG2CurrentModules(): Promise<Array<string>> {
    return lastValueFrom(this.http.get(this.apiEndpointsService.getG2CurrentModulesEndpoint()));
  }
}

export type g2Status = 'check' | 'online' | 'offline';
