<ng-container *ngIf="state$ | async as state">
  <ng-container *ngIf="state.loaded">
    <ng-container *ngIf="state.visible">
      <ng-container *ngIf="state.global as global">
        <ng-container *ngIf="state.workspaces as workspaces">
          <ng-container *ngIf="state.viewerDimension as viewerDimension">
            <ng-container *ngIf="state.viewerPosition as viewerPosition">
              <ng-container *ngIf="state.scale as scale">
                <div id="dragZone" #dragZone>
                  <div *ngFor="let workspace of workspaces; trackBy: workspacesTrackBy" [ngStyle]="{
                      position: 'absolute',
                      width: workspace.width / scale.x + 'px',
                      height: workspace.height / scale.y + 'px',
                      left: (-global.left + workspace.left) / scale.x + 'px',
                      top: (-global.top + workspace.top) / scale.y + 'px',
                      'background-color': workspace.backgroundColor
                    }">
                    <img *ngIf="workspace.useWorkspaceImage === true" [src]="workspace.imgUrl" [alt]="'Workspace-' + workspace.uuid" (mousedown)="onMouseDown()"
                      class="no-select" />
                    <div *ngFor="let waToHide of workspace.wasToHide; trackBy: chartAreasTrackBy" class="waToHide" [ngStyle]="{
                        position: 'absolute',
                        width: waToHide.width / scale.x + 'px',
                        height: waToHide.height / scale.y + 'px',
                        left: waToHide.left / scale.x + 'px',
                        bottom: waToHide.bottom / scale.y + 'px',
                        'background-color': waToHide.backgroundColor
                      }">
                      <img [src]="waToHide.imgLogo" [alt]="'WorkspaceArea-' + waToHide.uuid" class="no-select" *ngIf="waToHide.imgLogo" />
                    </div>
                  </div>
                  <div id="viewer" ngDraggable (mousedown)="onMouseDown()" [bounds]="dragZone" [inBounds]="true" (movingOffset)="onMoving($event)" (endOffset)="onMovingEnd($event)"
                    [position]="viewerPosition" [ngStyle]="{
                      width: viewerDimension.width + 'px',
                      height: viewerDimension.height + 'px'
                    }"></div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!state.loaded">
    <ngx-g2v-progress-bar></ngx-g2v-progress-bar>
  </ng-container>
  <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
</ng-container>