import { Injectable } from '@angular/core';
import {
  DashboardTreeViewStorageConfig,
  DEFAULT_DASHBOARD_TREE_VIEW_CONFIG
} from '@dashboards/components/dashboard-tree-view/dashboard-tree-view.component';
import {
  DashboardsStorageConfig,
  DashboardStorageConfig,
  DEFAULT_DASHBOARD_CONFIG,
  DEFAULT_DASHBOARDS_CONFIG
} from '@dashboards/services/dashboards.service';
import { environment } from '@environments/environment';
import { ErrorHandlerService } from '@services/error-handler.service';
import { StorageService } from '@services/storage.service';
import { UserService } from '@services/user.service';
import { DEFAULT_SESSION_CONFIG, DEFAULT_SESSIONS_CONFIG, SessionsStorageConfig, SessionStorageConfig } from '@sessions/services/sessions.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  constructor(
    private readonly userService: UserService,
    private readonly storage: StorageService,
    private readonly errorHandler: ErrorHandlerService
  ) {}

  public getConfig(configType: ConfigType, configId: string): ConfigDataType {
    const defaultData = this.getDefaultData(configType);
    const data = this.storage.getItem(this.getStorageKey(configType, configId));
    try {
      return data ? { ...defaultData, ...JSON.parse(data) } : defaultData;
    } catch (err) {
      this.errorHandler.handleError(err);
      return defaultData;
    }
  }

  public updateConfig(configType: ConfigType, configId: string, data: ConfigDataType): void {
    const storageKey = this.getStorageKey(configType, configId);
    if (!storageKey) {
      return;
    }
    try {
      this.storage.setItem(storageKey, JSON.stringify(data));
    } catch (err) {
      this.errorHandler.handleError(err);
    }
  }

  public getConfigItem(configType: ConfigType, configId: string, key: string): any {
    const config = this.getConfig(configType, configId);
    if (key in config) {
      return (config as any)[key];
    } else {
      this.errorHandler.handleError(`${key} doest'n exist in ${configType} cache.`);
      return '';
    }
  }

  public updateConfigItem(configType: ConfigType, configId: string, key: string, data: any): void {
    const defaultData = this.getConfig(configType, configId);
    this.updateConfig(configType, configId, { ...defaultData, ...{ [key]: data } });
  }

  private getStorageKey(configType: ConfigType, configId = ''): string {
    return this.userService.currentUserId
      ? configType === 'user' || configType === 'sessions' || configType === 'dashboards'
        ? `g2v-${this.userService.currentUserId}-${configType}`
        : `g2v-${this.userService.currentUserId}-${configType}-${configId}`
      : '';
  }

  private getDefaultData(configType: ConfigType): ConfigDataType {
    switch (configType) {
      case 'user': {
        return DEFAULT_USER_CONFIG;
      }
      case 'session': {
        return DEFAULT_SESSION_CONFIG;
      }
      case 'dashboard': {
        return DEFAULT_DASHBOARD_CONFIG;
      }
      case 'dashboardTreeView': {
        return DEFAULT_DASHBOARD_TREE_VIEW_CONFIG;
      }
      case 'sessions': {
        return DEFAULT_SESSIONS_CONFIG;
      }
      case 'dashboards': {
        return DEFAULT_DASHBOARDS_CONFIG;
      }
    }
  }
}

export type ConfigType = 'user' | 'session' | 'dashboard' | 'dashboardTreeView' | 'sessions' | 'dashboards';
type ConfigDataType =
  | UserStorageConfig
  | SessionStorageConfig
  | DashboardStorageConfig
  | DashboardTreeViewStorageConfig
  | SessionsStorageConfig
  | DashboardsStorageConfig;

export interface UserStorageConfig {
  lang: string;
  defaultG2Role: string;
  devMode: boolean;
  groupsMermaidToggle: boolean;
}

const DEFAULT_USER_CONFIG: UserStorageConfig = {
  lang: environment.settings.global.langDefault,
  defaultG2Role: '',
  devMode: false,
  groupsMermaidToggle: false
};
