import { forkJoin, lastValueFrom, Observable, Subject } from 'rxjs';
import { map as obsMap } from 'rxjs/operators';

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { STATE_G2VIEW_SESSIONS } from '@config/constants';
import { hasEnoughRoles, workspaceLastPositionToWorkspaceSession } from '@config/utils';
import { Position } from '@core/state/core.actions';
import {
  AccessRequest,
  Session,
  SessionConfig,
  SessionFavorite,
  SESSIONS_ADMIN_CLIENT_ROLES,
  UpdateSessionConfigEvent,
  UpdateSessionInfoEvent,
  UpdateSessionOwnerEvent,
  WorkspaceLastPosition,
  WorkspaceSession
} from '@g2view/g2view-commons';
import { ListingItem } from '@interfaces/listing-item';
import { Store } from '@ngxs/store';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';
import { CacheService } from '@services/cache.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { LoggerService } from '@services/logger.service';
import { ServerService } from '@services/server.service';
import { UserService } from '@services/user.service';
import { BEVConfig, bevConfigDefault } from '@session/components/bev/bev.component';
import { RemoveSession, SetSessions, UpdateCurrentSession } from '@sessions/state/sessions.actions';
import { SessionInfo } from '@sessions/state/sessions.state';
import { WorkspacesService } from '@workspaces/services/workspaces.service';

@Injectable({
  providedIn: 'root'
})
export class SessionsService {
  private workspaceResizingSubject = new Subject<boolean>();

  constructor(
    private readonly userService: UserService,
    private readonly serverService: ServerService,
    private readonly workspacesService: WorkspacesService,
    private readonly http: ApiHttpService,
    private readonly apiEndpointsService: ApiEndpointsService,
    private readonly cacheService: CacheService,
    private readonly store: Store,
    private readonly logger: LoggerService,
    private readonly errorHandler: ErrorHandlerService
  ) {
    this.logger.log('trace', 'Init of SessionsService');
  }

  get areSessionsInitialized(): boolean {
    return this.store.selectSnapshot<boolean>((state) => state[STATE_G2VIEW_SESSIONS]?.isLoaded ?? false);
  }

  get sessionsListing$(): Observable<Array<ListingItem>> {
    return this.http.get(this.apiEndpointsService.getSessionsListingEndpoint());
  }

  get ownSessionAccessRequests$(): Observable<Array<AccessRequest>> {
    return this.http
      .get(this.apiEndpointsService.getOwnSessionAccessRequestsEndpoint(this.userService.currentUserId))
      .pipe(obsMap((accessRequests) => accessRequests.data));
  }

  get workspaceResizing$(): Observable<boolean> {
    return this.workspaceResizingSubject.asObservable();
  }

  public emitWorkspaceResizing(isResizing: boolean): void {
    this.workspaceResizingSubject.next(isResizing);
  }

  public getSessionInfoInStore(id: string): SessionInfo | undefined {
    return this.store.selectSnapshot<Array<SessionInfo>>((state) => state[STATE_G2VIEW_SESSIONS].sessions).find((s) => s._id === id) ?? undefined;
  }

  public init(): void {
    this.logger.log('trace', 'Init of SessionsService -> init');
    this.refreshSessions();
  }

  public refreshSessions(): void {
    this.fetchSessions().subscribe({
      next: (sessions) => {
        const sessionIds = sessions.map((session) => session._id);
        // eslint-disable-next-line rxjs/no-nested-subscribe
        forkJoin([this.fetchSessionFavorites(sessionIds), this.fetchSessionAccessRequests(sessionIds)]).subscribe({
          next: ([favorites, accessRequests]) => {
            this.store.dispatch(new SetSessions(sessions, favorites, accessRequests));
          },
          error: (err: unknown) => this.errorHandler.handleError(err)
        });
      }
    });
  }

  public isAdminOfThisSession(sessionOwner: string): boolean {
    return (
      hasEnoughRoles(this.userService.currentClientRoles, [], [], SESSIONS_ADMIN_CLIENT_ROLES, [], []) ||
      this.userService.currentUserId === sessionOwner
    );
  }

  public getCurrentSession(): null | Session {
    const currentSession = this.store.selectSnapshot<Session>((state) =>
      state[STATE_G2VIEW_SESSIONS] && state[STATE_G2VIEW_SESSIONS].currentSession ? state[STATE_G2VIEW_SESSIONS].currentSession : new Session()
    );
    return currentSession._id ? currentSession : null;
  }

  public getOneSession(sessionId: string): Promise<Session> {
    return lastValueFrom(this.http.get(this.apiEndpointsService.getSessionEndpoint(sessionId)));
  }

  public createSession(session: Session): void {
    this.http
      .post(this.apiEndpointsService.addSessionEndpoint(), { ...session }, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public updateSessionInfo(session: SessionInfo): void {
    const data: UpdateSessionInfoEvent = {
      sid: session._id,
      title: session.title,
      users: session.users,
      groups: session.groups,
      canBeRequested: session.canBeRequested
    };
    this.http
      .post(this.apiEndpointsService.updateSessionInfoEndpoint(), data, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public updateSessionOwner(session: SessionInfo): void {
    const data: UpdateSessionOwnerEvent = { sid: session._id, owner: session.owner };
    this.http
      .post(this.apiEndpointsService.updateSessionOwnerEndpoint(), data, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public updateSessionConfig(sid: string, config: SessionConfig): void {
    const data: UpdateSessionConfigEvent = { sid, config };
    this.http
      .post(this.apiEndpointsService.updateSessionConfigEndpoint(), data, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public updateSession(session: Session): void {
    const config = session.config;
    session.config = { snapSize: config.snapSize, sessionLocked: config.sessionLocked };
    this.store.dispatch(new UpdateCurrentSession(session));
    this.http
      .put(this.apiEndpointsService.updateSessionEndpoint(session._id ?? ''), { ...session }, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public addWorkspacesToSession(session: Session, wsUuids: Array<string>, currentDragPosition: Position): void {
    let wsAdded = false;
    const workspaces = session.workspaces.slice();
    const workspacesUuids = workspaces.map((ws) => ws.uuid);
    const workspacesPositions = session.lastPositions;
    wsUuids.forEach((uuid) => {
      if (!workspacesUuids.includes(uuid)) {
        const wsIndex = workspacesPositions.findIndex((ws) => ws.uuid === uuid);
        const position = wsIndex > -1 ? workspacesPositions[wsIndex] : currentDragPosition;
        wsAdded = true;
        workspacesUuids.push(uuid);
        workspaces.push(workspaceLastPositionToWorkspaceSession(uuid, position));
      }
    });
    if (wsAdded) {
      this.updateSession({ ...session, workspaces });
    }
  }

  public removeWorkspacesFromSession(sessionInit: Session, wsUuids: Array<string>, onlyChildren = false): void {
    const workspacesInStore = this.workspacesService.getWorkspacesInStore();
    const session = { ...sessionInit };
    wsUuids.forEach((wsUuid) => {
      const workspaceToRemove = workspacesInStore.find((ws) => ws.uuid === wsUuid);
      const descendantUuids = this.workspacesService.getWorkspaceDescendants(wsUuid, session.workspaces);
      if (workspaceToRemove && workspaceToRemove.type === 'itemTable') {
        const lastPositions = this.computeSessionLastPositions(session, [wsUuid]);
        const workspaces = this.computeWorkspacesAfterRemovePrivateWorkspaces(session, descendantUuids);
        session.workspaces = workspaces;
        session.lastPositions = lastPositions;
      } else {
        const workspaceUuidsToRemove = onlyChildren ? descendantUuids.filter((descendantUuid) => descendantUuid !== wsUuid) : descendantUuids;
        const lastPositions = this.computeSessionLastPositions(session, workspaceUuidsToRemove);
        const workspaces = session.workspaces.filter((ws) => !workspaceUuidsToRemove.includes(ws.uuid));
        session.workspaces = workspaces;
        session.lastPositions = lastPositions;
      }
    });
    this.updateSession(session);
  }

  public removeSession(sessionId: string): void {
    this.store.dispatch(new RemoveSession(sessionId));
    this.http
      .delete(this.apiEndpointsService.removeSessionEndpoint(sessionId), { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public getSessionWithWorkspacesZIndex(session: Session, uuidTop = ''): Session {
    const sess = { ...session };
    const workspaces = [...sess.workspaces];
    const uuidsInOrder = workspaces
      .sort((wsA, wsB) => {
        if (wsA.uuid === uuidTop) {
          return 1;
        } else if (wsB.uuid === uuidTop) {
          return -1;
        }

        return wsA.zIndex - wsB.zIndex;
      })
      .map((ws) => ws.uuid);
    for (let i = 0; i < workspaces.length; i++) {
      const workspace = { ...workspaces[i] };
      if (workspace.uuid === uuidTop) {
        workspace.state = 'ui';
      }
      workspace.zIndex = uuidsInOrder.findIndex((uuid) => uuid === workspace.uuid);
      workspaces[i] = workspace;
    }
    sess.workspaces = workspaces;

    return sess;
  }

  public createSessionAccessRequest(sessionId: string): void {
    const accessRequest: AccessRequest = {
      type: 'session',
      objectDbId: sessionId,
      userId: this.userService.currentUserId,
      timestamp: new Date().getTime()
    };
    this.http
      .post(this.apiEndpointsService.addAccessRequestEndpoint(), accessRequest, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public removeSessionAccessRequests(dbIds: Array<string>): void {
    const observables: Array<Observable<unknown>> = [];
    dbIds.forEach((dbId) => {
      observables.push(this.http.delete(this.apiEndpointsService.removeAccessRequestEndpoint(dbId), { headers: httpHeaders }));
    });
    forkJoin(observables).subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public createSessionFavorite(sessionId: string): void {
    const sessionFavorite: SessionFavorite = {
      sessionDbId: sessionId,
      userId: this.userService.currentUserId
    };
    this.http
      .post(this.apiEndpointsService.addSessionFavoritesEndpoint(), sessionFavorite, { headers: httpHeaders })
      .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
  }

  public removeSessionFavorite(sessionId: string): void {
    this.fetchSessionFavorites([sessionId]).subscribe({
      next: (favorites) => {
        favorites.forEach((fav) => {
          this.http
            .delete(this.apiEndpointsService.removeSessionFavoritesEndpoint(fav._id ?? ''), { headers: httpHeaders })
            // eslint-disable-next-line rxjs/no-nested-subscribe
            .subscribe({ error: (err: unknown) => this.errorHandler.handleError(err) });
        });
      }
    });
  }

  public storeSessionConfig(sessionId: string, config: SessionStorageConfig): void {
    this.cacheService.updateConfig('session', sessionId, config);
  }

  public getSessionConfig(sessionId: string): SessionStorageConfig | undefined {
    return this.cacheService.getConfig('session', sessionId) as SessionStorageConfig;
  }

  public allRequiredModulesAreLoaded(session: Session): boolean {
    return this.getModulesNeededForThisSession(session).every((m) => this.serverService.currentG2ModulesLoaded.includes(m));
  }

  public missingModulesOfThisSession(session: Session): Array<string> {
    const missingModules: Array<string> = [];
    this.getModulesNeededForThisSession(session).forEach((m) => {
      if (!this.serverService.currentG2ModulesLoaded.includes(m)) {
        missingModules.push(m);
      }
    });
    return missingModules;
  }

  public getWorkspacesWithoutTheirModuleLoaded(session: Session, missingModules: Array<string>): Array<string> {
    const workspaces: Array<string> = [];
    const workspacesModules = this.workspacesService.workspacesModules;
    session.workspaces.forEach((w) => {
      const index = workspacesModules.findIndex((wm) => wm.uuid === w.uuid);
      if (index > -1) {
        const moduleOfThisWorkspace = workspacesModules[index].module;
        if (missingModules.includes(moduleOfThisWorkspace)) {
          workspaces.push(w.uuid);
        }
      }
    });
    return workspaces;
  }

  private getModulesNeededForThisSession(session: Session): Array<string> {
    const modules: Array<string> = [];
    const workspacesModules = this.workspacesService.workspacesModules;
    session.workspaces.forEach((w) => {
      const index = workspacesModules.findIndex((wm) => wm.uuid === w.uuid);
      if (index > -1) {
        modules.push(workspacesModules[index].module);
      }
    });
    return modules;
  }

  private computeSessionLastPositions(session: Session, wsUuids: Array<string>): Array<WorkspaceLastPosition> {
    const lastPositions = [...session.lastPositions];
    wsUuids.forEach((uuid) => {
      const wsIndex = session.workspaces.findIndex((ws) => ws.uuid === uuid);
      if (wsIndex > -1) {
        const workspace = session.workspaces[wsIndex];
        const wsPosition: WorkspaceLastPosition = {
          uuid,
          top: workspace.top,
          left: workspace.left,
          zoom: workspace.zoom,
          timestamp: new Date().getTime()
        };
        const wsPositionIndex = lastPositions.findIndex((wsPos) => wsPos.uuid === uuid);
        if (wsPositionIndex > -1) {
          lastPositions[wsPositionIndex] = wsPosition;
        } else {
          lastPositions.push(wsPosition);
        }
      }
    });
    return lastPositions;
  }

  private computeWorkspacesAfterRemovePrivateWorkspaces(session: Session, wsUuids: Array<string>): Array<WorkspaceSession> {
    let workspaces = [...session.workspaces];
    wsUuids.forEach((wsUuid) => {
      const ws = workspaces.find((ws) => ws.uuid === wsUuid);
      if (ws) {
        if (ws.socketIds.length === 0 || (ws.socketIds.length === 1 && ws.socketIds[0] === this.userService.currentSocketId)) {
          workspaces = workspaces.filter((ws) => ws.uuid !== wsUuid);
        } else {
          workspaces = workspaces.map((ws) =>
            ws.uuid === wsUuid ? { ...ws, socketIds: ws.socketIds.filter((socketId) => socketId !== this.userService.currentSocketId) } : ws
          );
        }
      }
    });
    return workspaces;
  }

  private fetchSessions(): Observable<Array<SessionInfo>> {
    return this.http.get(this.apiEndpointsService.getSessionsInfoEndpoint()).pipe(obsMap((sessions) => sessions.data));
  }

  private fetchSessionFavorites(sessionDbIds: Array<string>): Observable<Array<SessionFavorite>> {
    return this.http
      .get(this.apiEndpointsService.getFavoritesOfSessionsEndpoint(sessionDbIds, this.userService.currentUserId))
      .pipe(obsMap((favorites) => favorites.data));
  }

  private fetchSessionAccessRequests(sessionDbIds: Array<string>): Observable<Array<AccessRequest>> {
    return this.http
      .get(this.apiEndpointsService.getSessionAccessRequestsEndpoint(sessionDbIds))
      .pipe(obsMap((accessRequests) => accessRequests.data));
  }
}

const httpHeaders = new HttpHeaders({ Accept: 'application/json' });

export interface SessionStorageConfig {
  sessionScale: number;
  bevConfig: BEVConfig;
  constrainedWorkspaces: boolean;
  highlightWAS: boolean;
  position: Position;
}

export interface SessionsStorageConfig {
  filter: string;
  favSortActive: string;
  favSortDirection: SortDirection;
  sortActive: string;
  sortDirection: SortDirection;
}

export const DEFAULT_SESSION_CONFIG: SessionStorageConfig = {
  sessionScale: 1,
  bevConfig: bevConfigDefault,
  constrainedWorkspaces: false,
  highlightWAS: false,
  position: new Position()
};

export const DEFAULT_SESSIONS_CONFIG: SessionsStorageConfig = {
  filter: '',
  favSortActive: 'title',
  favSortDirection: 'asc',
  sortActive: 'title',
  sortDirection: 'asc'
};
