import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'ngx-g2v-formly-file',
  templateUrl: './formly-file.component.html',
  styleUrls: ['./formly-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFileComponent extends FieldType<FieldTypeConfig> {}
