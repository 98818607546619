import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MarkmapConfig, refitMarkmap } from '@components/markmap-chart/markmap-chart.component';
import { ModalConfig } from '@interfaces/modal-config';

const TIMEOUT_INIT_MS = 200; // Timeout needed to refit the markmap after modal init

@Component({
  selector: 'ngx-g2v-markmap-chart-modal',
  templateUrl: './markmap-chart-modal.component.html',
  styleUrls: ['./markmap-chart-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkmapChartModalComponent implements AfterViewInit {
  public markmapConfig: MarkmapConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly config: ModalConfig<MarkmapConfig>,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialogRef: MatDialogRef<MarkmapChartModalComponent>
  ) {
    this.markmapConfig = { ...config.initConfig };
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      refitMarkmap(this.getMarkmapInDomId());
      setTimeout(() => {
        refitMarkmap(this.getMarkmapInDomId());
      }, TIMEOUT_INIT_MS * 9);
    }, TIMEOUT_INIT_MS);

    this.config.config$.subscribe({
      next: (config) => {
        this.markmapConfig = { ...config };
        this.cdr.detectChanges();
      }
    });
  }

  public onExitModal(): void {
    this.dialogRef.close();
  }

  private getMarkmapInDomId(): string {
    return `markmap-${this.markmapConfig.chartId}`;
  }
}
