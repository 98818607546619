import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';

@Component({
  selector: 'ngx-g2v-formly-checkboxes-label',
  templateUrl: './checkboxes-label.component.html',
  styleUrls: ['./checkboxes-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyCheckboxesLabelComponent extends FieldType<FieldTypeConfig> {}
