<ng-container *transloco="let t; scope: 'dashboard'; read: 'dashboard'">
  <ng-container *ngIf="state$ | async as state">
    <ng-container *ngIf="!state.loaded">
      <div class="loader">
        <ngx-g2v-progress-bar [mode]="'determinate'" [value]="state.wsToLoad === 0 ? 0 : 100 * state.wsLoaded / state.wsToLoad"></ngx-g2v-progress-bar>
        <div class="text">{{ t('loading', { percent: Math.round(100 * state.wsLoaded / state.wsToLoad) }) }}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="state.dashboard as dashboard">
      <ng-container *ngIf="state.currentFrames as currentFrames">
        <ngx-g2v-dashboard-frame *ngFor="let frame of currentFrames" [updateActivity]="state.activity" [updateZoom]="currentZoom" [areWASHighlighted]="state.areWASHighlighted"
          [frame]="frame" [dbasConfig]="dashboard.dbas" [style.visibility]="state.loaded ? 'visible' : 'hidden'">
        </ngx-g2v-dashboard-frame>
      </ng-container>
      <ng-container *ngIf="dashboard.showMenuIcons">
        <div id="activity-state">
          <mat-icon fontIcon="wb_sunny" title="{{ t('iconActivityOnTitle') }}" *ngIf="state.activity"></mat-icon>
          <mat-icon fontIcon="bedtime" title="{{ t('iconActivityOffTitle') }}" *ngIf="!state.activity"></mat-icon>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menu" id="dashboard-menu" title="{{ t('buttonMenuTitle') }}">
          <mat-icon fontIcon="menu"></mat-icon>
        </button>
        <button mat-icon-button (click)="onQRCodeButtonClick()" id="qr-code-button" title="{{ t('buttonQRCodeTitle') }}">
          <mat-icon fontIcon="qr_code"></mat-icon>
        </button>
        <button mat-icon-button (click)="onShareButtonClick()" id="share-button" title="{{ t('buttonShareTitle') }}">
          <mat-icon fontIcon="share"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="$event.stopPropagation()" class="menu-item-title" title="{{ t('buttonDashboardTitleTitle') }}">
            {{ dashboard.title }}
          </button>
          <button mat-menu-item (click)="$event.stopPropagation()" *ngIf="dashboard.views.length > 1" title="{{ t('buttonViewsSwitchTitle') }}">
            <mat-icon fontIcon="visibility"></mat-icon>
            <mat-select hideSingleSelectionIndicator="true" [(ngModel)]="currentViewId" class="views-switcher" (selectionChange)="onChangeViewFromUI()">
              <mat-option *ngFor="let view of dashboard.views; trackBy: viewsTrackBy" [value]="view.id">
                {{ view.label }}
              </mat-option>
            </mat-select>
          </button>
          <button mat-menu-item *ngIf="!screenTooSmall" (click)="$event.stopPropagation()" title="{{ t('buttonZoomTitle') }}">
            <mat-icon fontIcon="zoom_in"></mat-icon>
            <mat-slider class="slider-zoom" (click)="$event.stopPropagation()" min="1" [max]="zoomMax" [step]="zoomStep">
              <input matSliderThumb [(ngModel)]="currentZoom" (valueChange)="refreshZoom($event)">
            </mat-slider>
          </button>
          <ngx-g2v-g2-role-switch (switchEmitter)="closeMenu()"></ngx-g2v-g2-role-switch>
          <button mat-menu-item (click)="onOpenSettings()" title="{{ t('buttonSettingsTitle') }}">
            <mat-icon fontIcon="settings"></mat-icon>{{ t('settings') }}
          </button>
          <button mat-menu-item (click)="onOpenHelp()" *ngIf="layoutService.isDesktop" title="{{ t('buttonShortcutsTitle') }}">
            <mat-icon fontIcon="keyboard"></mat-icon>{{ t('help') }}
          </button>
          <button mat-menu-item (click)="onExit()" title="{{ t('buttonExitTitle') }}">
            <mat-icon fontIcon="close"></mat-icon>{{ t('exit') }}
          </button>
        </mat-menu>
      </ng-container>
    </ng-container>
    <ngx-g2v-component-error *ngIf="state.error as error" [error]="error"></ngx-g2v-component-error>
  </ng-container>
</ng-container>