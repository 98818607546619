import { KeycloakCore, UserAuth, UserData } from '@core/state/core.state';
import { RoomMemberships } from '@g2view/g2view-commons';

export class Position {
  public top = 0;
  public left = 0;
}

export class SetSocketId {
  public static readonly type = '[Core API] Set socket id';
  constructor(public socketId: string) {}
}

export class SetCurrentG2Role {
  public static readonly type = '[Core API] Set current G2 role';
  constructor(public g2Role: string) {}
}

export class SetCurrentG2ModulesLoaded {
  public static readonly type = '[Core API] Set current G2 modules loaded';
  constructor(public modules: Array<string>) {}
}

export class SetMaxSessions {
  public static readonly type = '[Core API] Set max sessions';
  constructor(public maxSessions: number) {}
}

export class SetDevMode {
  public static readonly type = '[Core API] Set dev mode';
  constructor(public devMode: boolean) {}
}

export class SetBackgroundColor {
  public static readonly type = '[Core API] Set background color';
  constructor(public backgroundColor: string) {}
}

export class SetUserData {
  public static readonly type = '[Core API] Set user data';
  constructor(public userData: UserData) {}
}

export class SetUserAuth {
  public static readonly type = '[Core API] Set user auth';
  constructor(public userAuth: UserAuth) {}
}

export class SetKeycloakData {
  public static readonly type = '[Core API] Set keycloak data';
  constructor(public keycloakData: KeycloakCore) {}
}

export class SetRooms {
  public static readonly type = '[Core API] Set Rooms';
  constructor(public rooms: RoomMemberships) {}
}

export class ResetRooms {
  public static readonly type = '[Core API] Reset Rooms';
}
