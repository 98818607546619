import { ErrorHandler, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogErrorComponent } from '@components/dialog-error/dialog-error.component';
import { MAT_DIALOG_CONFIG } from '@config/constants';
import { LoggerService } from '@services/logger.service';
import { ShortcutsService } from '@services/shortcuts.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  private isDevMode = false;

  constructor(private readonly logger: LoggerService, private readonly dialog: MatDialog, private readonly shortcutsService: ShortcutsService) {}

  public handleError = (error: unknown, inDialog = false): void => {
    this.logger.log('error', 'Error', error);
    if (this.isDevMode || inDialog) {
      this.shortcutsService.setActive(false);
      const errorDialog = this.dialog.open(DialogErrorComponent, { ...MAT_DIALOG_CONFIG, data: error });
      errorDialog.afterClosed().subscribe({
        next: () => {
          this.shortcutsService.setActive(true);
        },
        error: (err: unknown) => {
          this.logger.log('error', 'Error', err);
        }
      });
    }
  };

  public setDevMode(devMode: boolean): void {
    this.isDevMode = devMode;
  }
}
