import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ngx-g2v-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalMessageComponent implements OnInit {
  constructor(private readonly dialogRef: MatDialogRef<ModalMessageComponent>, @Inject(MAT_DIALOG_DATA) public message: string) {}

  public ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '10vh' });
  }

  public onConfirm(): void {
    this.dialogRef.close();
  }
}
