import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FA_UPLOAD_FIELD_NAME } from '@g2view/g2view-commons';
import { ApiEndpointsService } from '@services/api-endpoints.service';
import { ApiHttpService } from '@services/api-http.service';

@Injectable({
  providedIn: 'root'
})
export class FormAreaService {
  private readonly httpHeaders: HttpHeaders;

  constructor(private readonly http: ApiHttpService, private readonly apiEndpointsService: ApiEndpointsService) {
    this.httpHeaders = new HttpHeaders({ Accept: 'application/json' });
  }

  public postFile(fileUpload: File): Promise<FileUploadResponse> {
    const formData: FormData = new FormData();
    formData.append(FA_UPLOAD_FIELD_NAME, fileUpload, fileUpload.name);
    return lastValueFrom(
      this.http
        .post(this.apiEndpointsService.fileUploadEndpoint(), formData, { headers: this.httpHeaders })
        .pipe(map((o) => ({ 'backend-filename': o.id, 'user-filename': fileUpload.name })))
    );
  }
}
export interface FileUploadResponse {
  'backend-filename': string;
  'user-filename': string;
}
