<ng-container *transloco="let t; scope: 'app'; read: 'app.dialog'">
  <div mat-dialog-actions>
    <ng-content></ng-content>
    <button mat-raised-button title="{{ t('buttonCancelTitle') }}" (click)="onCancel()">
      <mat-icon fontIcon="cancel"></mat-icon>&nbsp;&nbsp;{{ t('cancel') }}
    </button>
    <button mat-raised-button title="{{ t('buttonValidateTitle') }}" color="primary" (click)="onValidate()" [disabled]="!formValid">
      <mat-icon fontIcon="check"></mat-icon>&nbsp;&nbsp;{{ t('ok') }}
    </button>
  </div>
</ng-container>